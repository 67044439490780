import React from "react";
import { Carousel } from "react-responsive-carousel";
import VideoPopup from "../../popups/VideoPopup";
import clsx from 'clsx';
import striptags from 'striptags';
import IconMap from "../../icons/icon_map";
import IconNavArrow from "../../icons/icon_nav_arrow";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./featureSection.scss";


const FeatureSection = () => {
  const [activeSection, setActiveSection] = React.useState(0);
  const [blogs] = React.useState([
    {
      title: 'Da Vinci House',
      location: '44 Saffron Hill, London, EC1N 8FH, UK',
      description: 'A unique, bright, contemporary, and open plan space made to encourage a friendly collaborative environment. This subterranean office space in Farringdon was designed and fitted out by E&A Securities, a business known for developing the highest quality spaces in unique locations.',
      media: '/assets/images/pages/landing/meeting-featured.jpg',
      url: 'https://office-share.io/#/property/60',
    }
  ]);

  const [open, setOpen] = React.useState(false);
  const [blogVideoUrl] = React.useState("");

  const goToBlog = (url) => {
    window.open(url);
  };

  if (!blogs?.length) return <></>;

  return (
    <section id="featured" className="featured position-relative">
      <div className="container">
        <div className="featured--slider">
          <Carousel
            emulateTouch
            infiniteLoop={false}
            interval={1000 * 60 * 60}
            transitionTime={200}
            showThumbs={false}
            swipeable={true}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            selectedItem={activeSection}
            onChange={(index) => setActiveSection(index)}
          >
            {blogs?.map((v, key) => (
              <div key={key} className="featured--wrapper">
                <div className="featured--d-wrapper">
                  <p className="featured--label">featured listings</p>
                  <h2 className="featured--title">{v.title}</h2>
                  {v.location && (
                    <div className="featured--loc">
                      <IconMap color="#DFDFDF" size={21} />
                      <p className="featured--desc">{v.location}</p>
                    </div>
                  )}
                  <p className="featured--desc">{striptags(v.description)}</p>
                  <button
                    type="button"
                    className="featured--view"
                    onClick={() => goToBlog(v.url)}
                  >
                    View Property
                  </button>
                </div>
                <div className="featured--wrapper--img">
                  <img src={v?.media} alt="featured listings" />
                </div>
                <button
                    type="button"
                    className="featured--view for-mobile"
                    onClick={() => goToBlog(v.url)}
                  >
                    View Property
                  </button>
              </div>
            ))}
          </Carousel>
          <div className="selector--wrapper">
            {blogs?.map((_, key) => (
              <button
                key={key}
                type="button"
                className={clsx('featured--selector', { active: activeSection === key})}
                onClick={() => setActiveSection(key)}
              />
            ))}
          </div>
        </div>
        <button
          type="button"
          className="nav-arrow nav-arrow--left"
          disabled={!(activeSection > 0)}
          onClick={activeSection > 0 ? () => setActiveSection((key) => key - 1) : undefined}
        >
            <IconNavArrow size={34} />
        </button>
        <button
            type="button"
            className="nav-arrow nav-arrow--right"
            disabled={!(activeSection < (blogs?.length - 1))}
            onClick={activeSection < (blogs?.length - 1) ? () => setActiveSection((key) => key + 1) : undefined}
          >
              <IconNavArrow size={34} />
          </button>
        <VideoPopup
          src={blogVideoUrl}
          open={open}
          onClose={() => setOpen(!open)}
        />
      </div>
    </section>
  );
};

export default FeatureSection;
