import React from "react";
import "./../popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import './landlordStyle.scss';

const SuccessNewLandlord = ({
  onClose,
  open,
  edit,
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "amenities-popup-modal experience-modal" }}
    >
      <div className="la-modal__wrapper">
        <h5 className="text-primary">Landlord successfully {edit?.id ? 'edited' : 'added'}</h5>

        <div className="la-modal__form">
          The addition of a new landlord has been completed. An agreement between you and this landlord may now be uploaded.
        </div>
        
        <div className="la-modal__btn">
          <button type="button" className="la-modal__btn__cancel" onClick={onClose}>Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessNewLandlord;

