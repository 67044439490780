import React from 'react';

const IconFile = ({ color = '#E35447', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M26.831 5.836l-4.664-4.665c-0.754-0.75-1.773-1.171-2.829-1.171h-11.338c-2.209 0-4 1.791-4 4l0.001 24c0 2.209 1.791 4 4 4h15.999c2.2 0 4-1.8 4-4v-19.338c0-1.056-0.419-2.075-1.169-2.826zM25 28c0 0.552-0.448 1-1 1h-15.999c-0.552 0-1-0.448-1-1l-0.001-23.992c0-0.552 0.448-1 1-1h10v4.992c0 1.104 0.896 2 2 2h4.944v18h0.056zM10 17.5c0 0.831 0.675 1.5 1.5 1.5h9c0.831 0 1.5-0.669 1.5-1.5s-0.669-1.5-1.5-1.5h-9c-0.825 0-1.5 0.675-1.5 1.5zM20.5 22h-9c-0.825 0-1.5 0.675-1.5 1.5s0.672 1.5 1.5 1.5h9c0.828 0 1.5-0.672 1.5-1.5s-0.669-1.5-1.5-1.5z"></path>
    </svg>
  )
};

export default IconFile;