export const SET_LOADING = "SET_LOADING";
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export const SET_REGISTER_LOADING = "SET_REGISTER_LOADING";
export const SET_OTP_LOADING = "SET_OTP_LOADING";
export const SET_BOOK_SPACE_LOADING = "SET_BOOK_SPACE_LOADING";
export const SET_ADD_CARD_LOADING = "SET_ADD_CARD_LOADING";

export const SET_REGISTER_USER_DETAIL = "SET_REGISTER_USER_DETAIL";

export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";

export const SET_BLOGS = "SET_BLOGS";

export const SET_SAFE_SECTION = "SET_SAFE_SECTION";

export const SET_TAB_SECTION = "SET_TAB_SECTION";

export const SET_FILTERS = "SET_FILTERS";

export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";

export const SET_REGIONS = "SET_REGIONS";

export const SET_PROPERTY_ATTRIBUTE_LIST = "SET_PROPERTY_ATTRIBUTE_LIST";

export const SET_PROPERTY_BY_USER = "SET_PROPERTY_BY_USER";

export const SET_PROPERTY_BY_ID = "SET_PROPERTY_BY_ID";

export const SET_ADD_PROPERTY_DETAILS = "SET_ADD_PROPERTY_DETAILS";

export const SET_SPACE_TYPE = "SET_SPACE_TYPE";

export const SET_PROPERTY_SPACES = "SET_PROPERTY_SPACES";

export const SET_BOOK_SPACE_DETAILS = "SET_BOOK_SPACE_DETAILS";

export const SET_DAY_AVAILABLE_DATES = "SET_DAY_AVAILABLE_DATES";
export const SET_NIGHT_AVAILABLE_DATES = "SET_NIGHT_AVAILABLE_DATES";

export const SET_TERMS_CONDITIONS = "SET_TERMS_CONDITIONS";

export const SET_LIKED_PROPERTY = "SET_LIKED_PROPERTY";

export const SET_PLAN_LIST = "SET_PLAN_LIST";

export const SET_BOOKING_LIST = "SET_BOOKING_LIST";

export const SET_BLOG_DETAILS = "SET_BLOG_DETAILS";

export const SET_LANDLORD_BOOKING_LIST = "SET_LANDLORD_BOOKING_LIST";

export const SET_INITIALIZE_CHAT_DETAILS = "SET_INITIALIZE_CHAT_DETAILS";

export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";

export const SET_CARDS_LIST = "SET_CARDS_LIST";

export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const SET_SPACE_AVAILIBILITY_DATES = "SET_SPACE_AVAILIBILITY_DATES";

export const SET_BUY_PLAN_DETAILS = "SET_BUY_PLAN_DETAILS";

export const SET_ADMIN_USERS_LIST = "SET_ADMIN_USERS_LIST";

export const SET_ADD_SPACE_DETAILS = "SET_ADD_SPACE_DETAILS";

export const SET_IS_ADMIN_USER_ADDED = "SET_IS_ADMIN_USER_ADDED";

export const SET_FAQ_PAGE_CONTENT = "SET_FAQ_PAGE_CONTENT";

export const SET_HOME_PAGE_CONTENT = "SET_HOME_PAGE_CONTENT";

export const SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS";

export const SET_SHOW_CONFIRM_BOOKING_POPUP = "SET_SHOW_CONFIRM_BOOKING_POPUP";

export const SET_REDIRECT_URL_ON_ENQUIRE = "SET_REDIRECT_URL_ON_ENQUIRE";

export const SET_EARNING_REPORT = "SET_EARNING_REPORT";

export const SET_FORGOT_PASSWORD_OTP_SENT = "SET_FORGOT_PASSWORD_OTP_SENT";

export const SET_FORGOT_PASSWORD_OTP_VERIFY = "SET_FORGOT_PASSWORD_OTP_VERIFY";

export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";

export const SET_STATE_LIST = "SET_STATE_LIST";

export const SET_VERIFICATION_FILES = "SET_VERIFICATION_FILES";

export const SET_EMPTY_VERIFICATION_FILES = "SET_EMPTY_VERIFICATION_FILES";

export const SET_CHAT_NOTIFICATION = "SET_CHAT_NOTIFICATION";

export const SET_ADD_STRIPE_ACCOUNT_LOADING = "SET_ADD_STRIPE_ACCOUNT_LOADING";

export const SET_PLAN_BUY_LOADING = "SET_PLAN_BUY_LOADING";

export const SET_WITHDRAW_LOADING = "SET_WITHDRAW_LOADING";

export const SET_CLOSE_WITHDRAW_MODAL = "SET_CLOSE_WITHDRAW_MODAL";

export const SET_ADD_BANK_ACCOUNT_LOADING = "SET_ADD_BANK_ACCOUNT_LOADING";

export const SET_CLOSE_BANK_ACCOUNT_MODAL = "SET_CLOSE_BANK_ACCOUNT_MODAL";

export const SET_BOOKING_CONFIRM_DETAILS = "SET_BOOKING_CONFIRM_DETAILS";

export const SET_KYC_DATA = "SET_KYC_DATA";

export const SET_FCM_TOKEN = "SET_FCM_TOKEN";

export const SET_PERSON_ACCOUNT_DATA = "SET_PERSON_ACCOUNT_DATA";

export const SET_STRIPE_ACCOUNT_DATA = "SET_STRIPE_ACCOUNT_DATA";

export const SET_VOUCHER_CODE = "SET_VOUCHER_CODE";

export const SET_CREATE_VOUCHER_CODE = "SET_CREATE_VOUCHER_CODE";

export const SET_VOUCHER_LIST_DATA = "SET_VOUCHER_LIST_DATA";

export const SET_DELETE_VOUCHER = "SET_DELETE_VOUCHER";

export const SET_ALL_USERS_DATA = "SET_ALL_USERS_DATA";

export const SET_AGREEMENT_LANDLORD_DATA = "SET_AGREEMENT_LANDLORD_DATA";

export const SET_NEW_LANDLORD_DATA = "SET_NEW_LANDLORD_DATA";

export const SET_EDIT_LANDLORD_DATA = "SET_EDIT_LANDLORD_DATA";

export const SET_LANDLORD_AGREEMENT_FILES = "SET_LANDLORD_AGREEMENT_FILES";
