import { useState } from "react";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "./stripePayment.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Wrapper = (props) => (
  <Elements stripe={stripePromise}>
    <MyComponent {...props} />
  </Elements>
);

const MyComponent = ({ onBuyPlan, type }) => {
  const addCardLoading = useSelector((state) => state.home.addCardLoading);
  const planBuyLoading = useSelector((state) => state.home.planBuyLoading);

  const [isLoading, setIsLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);

    const cardElement = elements.getElement("card");

    stripe.createToken(cardElement).then((payload) => {

      if (payload.error) {
        toast(payload.error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
        return;
      } else {
        toast('Card Added Successfully', {
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
      }

      if (type === "add") {
        onBuyPlan(payload.token, "addCard");
      } else {
        onBuyPlan(payload.token, "newCard");
      }
    });
  };

  const onInputChange = (empty, complete, error) => {
    setComplete(complete);
  };

  return (
    <div className="stripe-wrapper">
      <div className="d-flex pb-3 align-items-center">
        <h4 className="text-primary"> Add New Card Details </h4>{" "}
        <div className="stripeLogo">
          <img src="/assets/icons/stripe_logo.png" alt="" />
          <h6>(Secured by Stripe)</h6>
        </div>
      </div>

      <form className="card card-body" onSubmit={handleSubmit}>
        <label>Card details</label>
        <div className="py-4">
          <CardElement
            onChange={({ empty, complete, error }) =>
              onInputChange(empty, complete, error)
            }
          />
        </div>

        <div>
          {addCardLoading || planBuyLoading ? (
            <span className="button">
              <span className="fa fa-spinner fa-spin px-3"></span>
            </span>
          ) : type === "add" ? (
            complete ? (
              <button className="button" disabled={!stripe}>
                Add
              </button>
            ) : (
              <div className="button disabled">Add</div>
            )
          ) : complete ? (
            <button className="button" disabled={!stripe}>
              Pay
            </button>
          ) : (
            <div className="button disabled">Pay</div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Wrapper;
