import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertySpaces, getPropertyById } from "../../store/homeAction";
import { SET_BOOK_SPACE_DETAILS } from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SwitchMap from "./SwitchMap";
import "./userBooking.scss";
import PropertyWithSpaceDetails from "./PropertyWithSpaceDetails";

const UserBookingOne = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [numberOfDesks, setNumberOfDesks] = useState(1);
  const [availability, setAvailability] = useState(false);

  useEffect(() => {
    getPropertyList();
    getSpaceList();
  }, [match]);

  useEffect(() => {
    if (bookSpaceDetails) {
      setNumberOfDesks(bookSpaceDetails.numberOfDesks);
      setAvailability(bookSpaceDetails.request_together);
    }
  }, [bookSpaceDetails]);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onNext = () => {
    const userDetais = JSON.parse(localStorage.getItem("os-user"));
    dispatch({
      type: SET_BOOK_SPACE_DETAILS,
      payload: {
        ...bookSpaceDetails,
        space_id: match.params.spaceId,
        user_id: userDetais.id,
        numberOfDesks: numberOfDesks,
        request_together: availability,
      },
    });

    history.push(
      `/userbooking/2/${match.params.propertyId}/${match.params.spaceId}`
    );
  };

  const showAlert = () => {
    toast(`Only ${spaceDetails.available_desk} desks are available!`, {
      type: toast.TYPE.ERROR,
      autoClose: 10000,
    });
  };

  return (
    <div className="user-detail-with-background">
      <div className="container mt-5">
        <div className="user-details-wrapper">
          <div className="user-booking">
            <div className="user-booking-main-wrapper">
              <div className="user-booking-main">
                <div className="user-left">
                  <Link
                    to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                    className="back-btn"
                  >
                    Back to space
                  </Link>
                  {propertyListById && spaceDetails && (
                    <PropertyWithSpaceDetails
                      propertyListById={propertyListById}
                      spaceDetails={spaceDetails}
                    />
                  )}
                  <hr />
                  <div className="user-middle">
                    <h6>How many desks do you need?</h6>
                    <div className="one-desc">
                      We will only show you available dates with the amount of
                      desks you require available and next to each other. If you
                      do not require desks next to each other, please uncheck
                      the box below.
                    </div>
                    <form>
                      <div className="form-group d-flex align-items-center">
                        <input
                            id="availability"
                            type="radio"
                            className="form-control-md m-0 mr-1"
                            onChange={() => setAvailability(!availability)}
                            checked={availability}
                        />
                        <label
                            htmlFor="availability"
                            className={`four-title m-0 ${availability && "selected"}`}
                        >
                          Only show available desks that are next to each other
                        </label>
                      </div>

                    </form>
                  </div>
                  <hr />
                  <div className="user-bottom">
                    <div className="user-select">
                      <h6>Select number of desks</h6>
                      <div>
                        <i
                          className="fas fa-chevron-left"
                          onClick={() =>
                            setNumberOfDesks(
                              numberOfDesks > 1 ? numberOfDesks - 1 : 1
                            )
                          }
                        ></i>
                        <span>{numberOfDesks}</span>
                        <i
                          className="fas fa-chevron-right"
                          onClick={() =>
                            numberOfDesks <
                            parseInt(spaceDetails?.available_desk)
                              ? setNumberOfDesks(numberOfDesks + 1)
                              : showAlert()
                          }
                        ></i>
                      </div>
                    </div>

                    <div className="buttons">
                      <div onClick={onNext} className="one-btn btn">
                        Continue
                      </div>
                    </div>
                  </div>
                </div>
                <SwitchMap
                  propertyListById={propertyListById}
                  spaceDetails={spaceDetails}
                ></SwitchMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookingOne;

const Marker = () => {
  return (
    <img
      className="pointer"
      width="30px"
      src="assets/images/marker.png"
      style={{ transform: "translate(-50%, -50%)" }}
    />
  );
};
