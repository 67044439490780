import React, { Component, useState } from "react";
import { render } from "react-dom";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import "./dragAndDrop.scss";

const SortableItem = sortableElement(({ value, className }) => (
  <li className={`sortable-item ${className}`}>{value}</li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="sortable-wrapper">{children}</ul>;
});

const DragAndDropImage = ({
  gallaryImage,
  checkHttpInUrl,
  onUploadImage,
  FeaturedImageUpload,
  removeImage,
}) => {
  const [items, setItems] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
    "Item 4",
    "Item 5",
    "Item 6",
  ]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  return (
    <SortableContainer pressDelay={100} axis={"xy"} onSortEnd={onSortEnd}>
      {gallaryImage.map((image, key) => (
        <SortableItem
          key={`item-${key}`}
          index={key}
          className="image-upload"
          value={
            image ? (
              <FeaturedImageUpload
                uploadImageSrc={checkHttpInUrl(image) || null}
                type="gallary"
                onUpload={onUploadImage}
                index={key}
              ></FeaturedImageUpload>
            ) : (
              <FeaturedImageUpload
                uploadImageSrc={null}
                type="gallary"
                onUpload={onUploadImage}
                index={key}
                fixed={true}
              ></FeaturedImageUpload>
            )
          }
        />
      ))}
    </SortableContainer>
  );
};

export default DragAndDropImage;
