import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendOtp, verifyOtp } from "../../store/homeAction";
import axios from "../../store/axios";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OnboardingStepOne = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const otpLoading = useSelector((state) => state.home.otpLoading);
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);
  const [isVerifying, setIsVerifying] = useState("");
  const [countdown, setCountDown] = useState("1:00");

  let time = "1:00";

  useEffect(() => {
    startCountdown();
  }, []);

  const handleOtp = (value) => {
    setOtp(value);
  };
  const onVerify = () => {
    if (otp === "") {
      toast("Select enter otp", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    setIsVerifying(true);

    const phoneNumber = localStorage.getItem("os-phone-number");
    const selectedRegion = localStorage.getItem("os-region");

    const data = {
      otp: otp,
      phone: selectedRegion + phoneNumber,
    };
    dispatch(verifyOtp(data));
  };

  const onSendOtp = () => {
    const phoneNumber = localStorage.getItem("os-phone-number");
    const selectedRegion = localStorage.getItem("os-region");

    let data = {
      phone: selectedRegion + phoneNumber,
    };

    dispatch(sendOtp(data));
    setResend(false);
    time = "1:00";
    startCountdown();
  };

  let interval;
  const startCountdown = () => {
    clearInterval(interval);
    interval = setInterval(function () {
      var timer = time;
      timer = timer.split(":");
      var minutes = timer[0];
      var seconds = timer[1];
      seconds -= 1;
      if (minutes < 0) {
        return false;
      } else if (seconds < 0 && minutes != 0) {
        minutes -= 1;
        seconds = 59;
      } else if (seconds < 10 && seconds.length != 2) seconds = "0" + seconds;

      time = minutes + ":" + seconds;
      setCountDown(time);

      if (minutes == 0 && seconds == 0) {
        clearInterval(interval);
        setResend(true);
      }
    }, 1000);
  };

  return (
    <div className="onboarding-main py-5 ">
      <div className="left"></div>
      <div className="right">
        <div className="download-section">
          <h5>
            <b>Download</b>
          </h5>
          <h5>
            The OfficeShare app<span className="text-primary">...</span>
          </h5>
          <div className="image-div">
            <img src="./assets/images/playstore.png" alt="" />
            <img src="./assets/images/appstore.png" alt="" />
          </div>
        </div>
      </div>
      <div className="onboarding-background"></div>
      <div className="container">
        <div className="onboarding-wrapper">
          <div className="left-section">
            <div className="step-progress">
              <p className="text-text">
                <b> Step 1:</b> Verification{" "}
              </p>
              <div className="progress-bar">
                <div className="fill" style={{ width: "20%" }}></div>
              </div>
            </div>

            <div className="input-group-wrapper">
              <p className="title text-primary">
                <b>Enter PIN</b>
              </p>
              <div className="link" onClick={onSendOtp}>
                I haven't received a PIN
              </div>

              <div className="pin-wrapper">
                <OtpInput
                  value={otp}
                  onChange={handleOtp}
                  numInputs={6}
                  disabledStyle
                />
              </div>
              <br></br>
              <br></br>
              {otp.length === 6 && otpLoading ? (
                <div className="button float-left">
                  <span className="fa fa-spinner fa-spin px-3"></span>
                </div>
              ) : (
                <div className="button float-left" onClick={onVerify}>
                  Verify
                </div>
              )}
              <br />
              <br />
              <br />
              <div className="resend-otp-div">
                {resend ? (
                  <div className="resend-button bg-primary" onClick={onSendOtp}>
                    Resend otp
                  </div>
                ) : (
                  <div className="resend-button">Resend otp</div>
                )}

                <div className="resend-timer">{countdown}</div>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="download-section">
              <h5>
                <b>Download</b>
              </h5>
              <h5>
                The OfficeShare app<span className="text-primary">...</span>
              </h5>
              <div className="image-div">
                <img src="./assets/images/playstore.png" alt="" />
                <img src="./assets/images/appstore.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default OnboardingStepOne;
