import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import SignaturePad from "react-signature-canvas";

const SignatureCanvas = ({ clearPad, savePad, signedPad }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="signaturePad">
      <p className="signaturePad-para">
        Please sign here to confirm your booking and then agree to the Terms and
        Conditions below.
      </p>
      <SignaturePad
        ref={signedPad}
        penColor="#f25657"
        canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
      />
      <div className="signatureButton">
        <button onClick={clearPad}>Clear</button>
        <button
          onClick={() => {
            savePad();
            setLoading(true);

            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }}
        >
          {loading === true ? (
            <span className="fa fa-spinner fa-spin px-3"></span>
          ) : (
            "Use this Signature"
          )}
        </button>
      </div>
    </div>
  );
};

export default SignatureCanvas;
