import React from "react";
import "./articleDetails.scss";

const ArticleDetails = () => {
  return (
    <div className="article-details-wrapper">
      <div className="article-details">
        <div className="back-home">Back to Home</div>
        <h1 className="article-heading">
          Relax<span className="text-primary">.</span>&nbsp;OfficeShare has you
          Covered<span className="text-primary">.</span>
        </h1>
        <h6 className="article-date">23.06.2021</h6>
        <hr />
        <img
          src="assets/images/article-details1.png"
          alt=""
          className="article-image1"
        />
        <p className="font-weight-bold">
          Looking for a desk on a semi-parmanent basis? You can ow set a
          recurring booking for up to 6 months.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim cum,
          omnis dolorum earum nesciunt, recusandae numquam eaque accusamus dicta
          quis repellendus? Error atque facilis temporibus recusandae vitae,
          mollitia esse commodi illum sint tenetur molestias at dolores fuga
          nihil, tempore delectus culpa ipsam, ab voluptate fugit cumque
          pariatur sequi. Reprehenderit quis accusantium dolor porro esse, modi
          eaque cumque est et in magni perspiciatis ullam odit qui libero ut
          necessitatibus officiis repudiandae optio inventore earum magnam
          praesentium rerum unde? Eligendi, ab iste sed possimus cupiditate
          expedita dolor doloremque sit sint blanditiis reiciendis nobis ad
          quaerat molestiae ducimus quasi, repudiandae ipsum fugiat quam.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione
          itaque est officiis quas saepe aut? Quam, deserunt iste? Voluptates
          quia corrupti tempore, nobis vel aperiam est aspernatur at unde.
          Animi?
        </p>
        <p className="article-para">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione
          itaque est officiis quas saepe aut? Quam, deserunt iste? Voluptates
          quia corrupti tempore,
        </p>
        <hr />
        <img
          src="assets/images/article-details2.png"
          alt=""
          className="article-image2"
        />
        <hr />
        <p className="mt-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel ab omnis
          blanditiis, culpa voluptatem sint autem possimus dolore sequi voluptas
          recusandae explicabo illum, animi quam voluptate inventore modi earum
          dignissimos, doloremque ipsa? Placeat totam asperiores eligendi, iste
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, delectus
          harum nostrum quia voluptas culpa laborum eaque rerum eius consequatur
          optio impedit velit. Laboriosam ab iure officiis quam odio ipsa est
          vel, ad modi ipsam asperiores, dolorem iusto dolorum, optio molestias
          deserunt voluptatibus. Adipisci molestias consequatur rerum non cum
          veritatis, dolorum quod enim deleniti. Aspernatur illo eos excepturi
          veniam similique?Aspernatur illo eos excepturi veniam similique?
        </p>
        <div className="article-div">
          <div className="line"></div>
          <h3>
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
            necessitatibus est totam possimus hic quam rem, aperiam, reiciendis
            perspiciatis corporis"
          </h3>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
          tenetur voluptatem, ex nemo sint ipsam libero consequatur
          necessitatibus doloribus eligendi! Placeat facere adipisci hic libero,
          in, quidem modi iusto
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
          tenetur voluptatem, ex nemo sint ipsam libero consequatur
          necessitatibus doloribus eligendi! Placeat
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
          nostrum fuga reiciendis voluptate expedita distinctio ipsam excepturi
          voluptates nesciunt iste culpa suscipit ipsa exercitationem sed
          architecto enim, accusantium repellendus corrupti porro quos modi
          numquam molestias labore. Nobis odio quis nisi?
        </p>
        <p className="mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
          tenetur voluptatem, ex nemo sint ipsam libero consequatur
          necessitatibus doloribus eligendi! Placeat Placeat Placeat Placeat
        </p>
        <hr />
        <img
          src="assets/images/article-details3.png"
          alt=""
          className="article-image3"
        />
        <hr />
        <p className="mt-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
          nostrum fuga reiciendis voluptate expedita distinctio ipsam excepturi
          voluptates nesciunt iste culpa suscipit ipsa exercitationem sed
          architecto enim, accusantium repellendus corrupti porro quos modi
          numquam molestias labore. Nobis odio quis nisi?
        </p>
        <p className="mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
          tenetur voluptatem, ex nemo sint ipsam libero consequatur
          necessitatibus doloribus eligendi! Placeat Placeat Placeat Placeat
        </p>
        <div className="back-top">Back to Top</div>
      </div>
    </div>
  );
};

export default ArticleDetails;
