import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertySpaces,
  getPropertyById,
  bookSpace,
  bookingPayment,
  getCardList,
  getUserDetails,
  enquiryPayment,
  getBookingList,
  onVoucherCode,
} from "../.././../store/homeAction";
import {
  SET_SHOW_CONFIRM_BOOKING_POPUP,
  SET_BOOK_SPACE_DETAILS,
} from "../../../store/types";
import { Link, useHistory } from "react-router-dom";
import StripePaymentBooking from "../../../components/stripePayment/StripePaymentBooking";
import { ToastContainer, toast } from "react-toastify";
import BookingPricePopup from "../../../popups/BookingDatesPopup";

const EnquiryPayment = ({ match }) => {
  const dispatch = useDispatch();
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceLoading = useSelector((state) => state.home.bookSpaceLoading);
  const userDetails = useSelector((state) => state.home.userDetails);
  const showConfirmBookingPopup = useSelector(
    (state) => state.home.showConfirmBookingPopup
  );
  const appliedVoucherCode = useSelector(
    (state) => state.home.appliedVoucherCode
  );

  const [spaceDetails, setSpaceDetails] = useState({});
  const [values, setValues] = useState({});
  const [loadingCardId, setLoadingCardId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [voucherCode, setVoucherCode] = useState();
  const cardsList = useSelector((state) => state.home.cardsList);

  const userDetail = JSON.parse(localStorage.getItem("os-user"));

  const bookingList = useSelector((state) => state.home.bookingList);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [bookingDetailsList, setBookingDetailsList] = useState({});

  useEffect(() => {
    onGetBookingList();
  }, []);

  useEffect(() => {
    if (bookingList && bookingList.upcomming) {
      const booking = bookingList?.upcomming?.filter(
        (booking) =>
          booking.booking_details.booking_id == match.params.bookingId
      );
      setBookingDetailsList(...booking);
    }
  }, [bookingList]);

  useEffect(() => {
    dispatch(getUserDetails({ user_id: userDetail.id }));
    getPropertyList();
    getSpaceList();
  }, [match]);

  const onGetBookingList = () => {
    let data = {
      user_id: userDetail.id,
      booking_id: match.params.bookingId,
    };
    dispatch(getBookingList(data));
  };

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].space_id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  useEffect(() => {
    if (userDetails) {
      const data = {
        customer_id: userDetails.customer_id,
      };
      dispatch(getCardList(data));
    }
  }, [userDetails]);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onNext = (cardDetails) => {
    if (cardDetails) {
      values.src_token = cardDetails.src_token;
      values.new = cardDetails.new;
    }

    const enquiryPayementDetails = {
      src_token: cardDetails.src_token,
      new: cardDetails.new,
      booking_id: match.params.bookingId,
      price: bookingDetailsList.booking_details.desk_price,
      dob: userDetail.dob,
      email: userDetail.email,
      first_name: userDetail.first_name,
      last_name: userDetail.last_name,
      phone: userDetail.phone,
      user_id: userDetail.id,
      address_1: "",
      address_2: "",
      city: "",
      country: "",
      postcode: "",
      state: "",
    };

    const spaceDetail = bookingDetailsList.space;
    spaceDetail.id = bookingDetailsList.space.space_id;
    spaceDetail.property_id = bookingDetailsList.property.property_id;
    dispatch(enquiryPayment(enquiryPayementDetails, spaceDetail));
  };

  const onBookSpace = (details, type) => {
    setLoadingCardId(details.id);

    let cardDetails;
    if (type === "newCard") {
      cardDetails = {
        new: true,
        src_token: details.id,
      };
    } else {
      cardDetails = {
        new: false,
        src_token: details.id,
      };
    }

    onNext(cardDetails);
  };

  const applyVoucherCode = () => {
    const data = {
      user_id: userDetail && userDetail.id,
      voucher_code: voucherCode,
    };

    dispatch(onVoucherCode(data));
  };

  const changeVoucherCode = (e) => {
    setVoucherCode(e.target.value);
  };

  return (
    <>
      <div className="user-detail-with-background">
        <div className="container mt-5">
          <div className="user-details-wrapper">
            <div className="user-booking">
              <div className="user-booking-main-wrapper">
                <div className="user-booking-main">
                  <div className="user-left">
                    <Link
                      to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                      className="back-btn"
                    >
                      Back to space
                    </Link>

                    <br />
                    <br />
                    <br />

                    {spaceDetails.booking_approval ? (
                      <div className="user-bottom">
                        <div className="buttons">
                          {bookSpaceLoading ? (
                            <div className="one-btn btn mt-4">
                              <span className="fa fa-spinner fa-spin px-3"></span>
                            </div>
                          ) : (
                            <div onClick={onNext} className="one-btn btn mt-4">
                              Submit to Enquiry
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h4>Payment Details</h4>
                        <div className="card-list-wrapper py-5">
                          <div className="row">
                            <div className="col-md-12 mx-auto">
                              <h4 className="text-primary pb-3">
                                Apply Voucher Code
                              </h4>
                              <div className="card card-body">
                                <div className="list-group">
                                  <div className="list-group-item d-flex justify-content-between align-items-center">
                                    <input
                                      type="text"
                                      placeholder="Enter Voucher Code"
                                      className="applyButton-input"
                                      onChange={(e) => changeVoucherCode(e)}
                                    />
                                    <div
                                      className="applyButton"
                                      onClick={applyVoucherCode}
                                    >
                                      Apply
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mx-auto">
                              <h4 className="text-primary pb-3"> Cards List</h4>
                              <div className="card card-body">
                                <div className="list-group">
                                  {cardsList && cardsList.length > 0
                                    ? cardsList.map((card, key) => (
                                      <div
                                        key={key}
                                        className="list-group-item d-flex justify-content-between align-items-center"
                                      >
                                        <span>
                                          {card.brand === "Visa" ? (
                                            <img
                                              className="card-image"
                                              src="assets/images/visa-card.png"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className="card-image"
                                              src="assets/images/master-card.png"
                                              alt=""
                                            />
                                          )}{" "}
                                          <b className="card-number">
                                            **** **** **** {card.last4}{" "}
                                          </b>
                                        </span>
                                        {loadingCardId === card.id ? (
                                          <span className="button btn-small">
                                            <span className="fa fa-spinner fa-spin px-3"></span>
                                          </span>
                                        ) : (
                                          <div
                                            className="button btn-small"
                                            onClick={() =>
                                              onBookSpace(card, "oldCard")
                                            }
                                          >
                                            Pay
                                          </div>
                                        )}
                                      </div>
                                    ))
                                    : "No Card Available"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 mx-auto">
                              <StripePaymentBooking
                                onBookSpace={onBookSpace}
                                detailsAreCorrect={true}
                                type="book"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="user-payment-wrapper">
                    <h4>Summary</h4>
                    <div className="user-right-image">
                      <img
                        src={bookingDetailsList?.space?.space_featured_image}
                        alt=""
                      />
                    </div>

                    <h6 className="mt-4">
                      {bookingDetailsList?.property?.property_title}
                    </h6>

                    <h2 className="text-primary mt-1">
                      {bookingDetailsList?.space?.space_title}
                    </h2>

                    <div className="payment-property-type">
                      <span>{bookingDetailsList?.space?.space_type}</span>
                    </div>
                    <div className="user-edit">
                      <p>
                        {bookingDetailsList?.booking_details?.booked_desk}{" "}
                        {bookingDetailsList?.booking_details?.booked_desk === 1
                          ? "Desk"
                          : "Desks"}{" "}
                        <span>for</span>{" "}
                        {bookingDetailsList?.booking_details?.booking_dates_day
                          ?.length > 0 &&
                          `${bookingDetailsList?.booking_details
                            ?.booking_dates_day?.length
                          } ${bookingDetailsList?.booking_details
                            ?.booking_dates_day?.length === 1
                            ? "Day"
                            : "Day(s)"
                          }`}
                        {bookingDetailsList?.booking_details?.booking_dates_day
                          ?.length > 0 &&
                          bookingDetailsList?.nightSelectedDates?.length > 0 &&
                          " and "}
                        {bookingDetailsList?.booking_details
                          ?.booking_dates_night?.length > 0 &&
                          `${bookingDetailsList?.booking_details
                            ?.booking_dates_night?.length
                          } ${bookingDetailsList?.booking_details
                            ?.booking_dates_night?.length === 1
                            ? "Night"
                            : "Night(s)"
                          } `}
                      </p>
                      <div>
                        {/* <Link
                        to={`/userbooking/1/${match.params.propertyId}/${match.params.spaceId}`}
                      >
                        Edit
                      </Link>{" "} */}
                        {/* <span className="text-primary">/</span>{" "} */}
                        <span
                          className="previewButton"
                          onClick={() => setModalOpen(true)}
                        >
                          Preview
                        </span>
                      </div>
                    </div>
                    <h6 className="desk-rate">
                      <span className="text-dark">Total Amount:</span> &#8356;
                      <>
                        {appliedVoucherCode ? (
                          <>
                            {(
                              bookingDetailsList?.booking_details?.desk_price?.toLocaleString() -
                              (appliedVoucherCode &&
                                appliedVoucherCode.discount *
                                bookingDetailsList?.booking_details?.desk_price?.toLocaleString()) /
                              100
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {bookingDetailsList?.booking_details?.desk_price?.toLocaleString()}
                          </>
                        )}
                        {/* {bookingDetailsList?.booking_details?.desk_price} */}
                      </>{" "}
                      <span style={{ fontSize: "12px", fontWeight: "800" }}>
                        +VAT
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookingPricePopup
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        bookingDetails={bookingDetailsList?.booking_details}
      />
    </>
  );
};

export default EnquiryPayment;
