import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_INITIALIZE_CHAT_DETAILS } from "../../../store/types";
import MyBooking from "../myBookings/MyBooking";
import "./bookingDetails.scss";
import { Link } from "react-router-dom";
import Rating from "../../../components/rating/Rating";
import Map from "../../../components/map/Map";
import { LoadScript } from "@react-google-maps/api";
import db from "../../../config";
import { cancelBooking } from "../../../store/homeAction";
import CancelReasonPopup from "../../../popups/CancelReasonPopup";
import BookingDatesPopup from "../../../popups/BookingDatesPopup";
const libraries = ["places"];

const BookingDetails = ({ bookingDetails, handleCancelBooking }) => {
  const dispatch = useDispatch();
  const [openMyBooking, setOpenMyBooking] = useState(false);
  const [open, setOpen] = useState(false);
  const [bookingDates, setBookingDates] = useState({});
  const [isOpenBookingDatesPopup, setIsOpenBookingDatesPopup] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  const onSetBookingDates = (booking_details) => {
    setBookingDates(booking_details);
    setIsOpenBookingDatesPopup(true);
  };

  const onCancelBooking = (booking_details) => {
    setBookingDates(booking_details);
    setOpen(true);
  };

  const sendRequest = (landlordDetails) => {
    setIsLoading(true);
    let collection;
    if (parseInt(landlordDetails.id) < parseInt(userDetails.id)) {
      collection = `${landlordDetails.id}-${userDetails.id}`;
    } else {
      collection = `${userDetails.id}-${landlordDetails.id}`;
    }

    const rootRef = db.ref();
    const urlRef = rootRef.child(`messages/${collection}`);

    // Check if request already sent or not
    let exist = false;

    urlRef
      .orderByChild("senderid")
      .equalTo(userDetails.id.toString())
      .on("value", function (snapshot) {
        snapshot.forEach(function (child) {
          const chatDetails = child.val();
          // console.log(
          //   "chatDetails",
          //   chatDetails,
          //   chatDetails.senderid,
          //   userDetails.id.toString(),
          //   chatDetails.receiverid,
          //   landlordDetails.id.toString()
          // );
          if (
            chatDetails.senderid == userDetails.id.toString() &&
            chatDetails.receiverid == landlordDetails.id.toString()
          ) {
            exist = true;
            window.location.href = `#/chat/${userDetails?.id}`;
            return false;
          }
        });
      });

    const data =
      users[landlordDetails.id.toString()]?.chatRequestAcceptedUserIds;
    let chatUsers = [];
    if (data) {
      var keys = Object.values(data);
      for (var i = 0; i < keys.length; i++) {
        chatUsers.push(keys[i]);
      }
    }

    if (chatUsers.some((user) => user == userDetails.id.toString())) {
      exist = true;
      window.location.href = `#/chat/${userDetails?.id}`;
      return false;
    }

    setTimeout(() => {
      if (exist == false) {
        urlRef.push({
          // propertyName: propertyDetails.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          text: "Hello \nI want to enquire about your space.",
          timestamp: Date.now(),
        });

        const lastMessageRef = rootRef.child(`lastMessages/${collection}`);
        lastMessageRef.set({
          // propertyName: propertyDetails.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          // text: "Hello \nI want to enquiry about your space.",
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          timestamp: Date.now(),
        });

        window.location.href = `#/chat/${userDetails?.id}`;
      }

      setIsLoading(false);
    }, 1000);
  };

  const calculateDesks = (bookingDetails) => {
    return (
      <h6>
        <b>
          {" "}
          {bookingDetails?.booking_details?.booked_desk}{" "}
          {bookingDetails?.booking_details?.booked_desk == 1 ? "Desk" : "Desks"}{" "}
        </b>{" "}
        <span className="text-primary">for</span>{" "}
        {bookingDetails?.booking_details?.booking_dates_day?.length > 0 &&
          `${bookingDetails?.booking_details?.booking_dates_day?.length} ${
            bookingDetails?.booking_details?.booking_dates_day?.length == 1
              ? "Day"
              : "Day(s)"
          } `}
        {bookingDetails?.booking_details?.booking_dates_day?.length > 0 &&
          bookingDetails?.booking_details?.booking_dates_night?.length > 0 &&
          "and "}
        {bookingDetails?.booking_details?.booking_dates_night?.length > 0 &&
          `${bookingDetails?.booking_details?.booking_dates_night?.length} ${
            bookingDetails?.booking_details?.booking_dates_night?.length == 1
              ? "Night"
              : "Night(s)"
          } `}
      </h6>
    );
  };

  return (
    <>
      <BookingDatesPopup
        open={isOpenBookingDatesPopup}
        onClose={() => setIsOpenBookingDatesPopup(false)}
        bookingDetails={bookingDates}
      />
      <CancelReasonPopup
        open={open}
        onClose={() => setOpen(!open)}
        bookingDetails={bookingDates}
      />
      {openMyBooking ? (
        <MyBooking />
      ) : (
        <>
          {bookingDetails.booking_details.booking_status ===
            "enquiry pending" ||
          bookingDetails.booking_details.booking_status === "cancelled" ? (
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              libraries={libraries}
              language="en"
              region="uk"
            >
              <div className="booking-details-wrapper fade-in-bottom">
                <div className="booking-details-header">
                  <h4 className="text-primary font-weight-bold">
                    Booking Details:
                  </h4>
                  <small
                    className="back-button"
                    onClick={() => setOpenMyBooking(!openMyBooking)}
                  >
                    Back to all bookings
                  </small>
                </div>
                <hr />
                <div className="booking-details">
                  <div className="booking-details-top">
                    <div className="left-section">
                      <div className="booking-details-list">
                        <img
                          src={
                            bookingDetails?.property?.property_featured_image
                          }
                          alt=""
                          className="booking-details-poster"
                        />
                        <div className="booking-details-text">
                          <h6>
                            {bookingDetails?.booking_details?.booking_status}
                            <span className="text-primary">...</span>
                            {/* <small>
                              Start date:&nbsp;
                              <b>
                                {bookingDetails?.booking_details?.start_date}
                              </b>
                            </small> */}
                          </h6>
                          <h5 className="text-primary font-weight-bold">
                            {bookingDetails?.property?.property_title}
                          </h5>
                          <p className="space-title m-0">
                            {bookingDetails?.space?.space_title}
                          </p>
                          <Rating
                            rating={bookingDetails?.property?.avg_rating}
                          />
                          {/* <small className="font-weight-bold mt-2">
                      <img
                        src="assets/images/watch-icon.png"
                        alt=""
                        className="dot-image"
                      />
                      &nbsp; Awaiting Landlord Confirmation
                      <span className="text-primary">...</span>
                    </small> */}
                        </div>
                      </div>
                      <div className="booking_details-button">
                        <Link
                          to={`/space/${bookingDetails?.property?.property_id}/${bookingDetails?.space?.space_id}`}
                          className="button"
                        >
                          View Space
                        </Link>
                        {/* <small className="cancel-button">Cancel Booking</small> */}
                      </div>
                      <h6 className="booking-details-heading">
                        {bookingDetails?.property?.property_title}
                      </h6>
                      <h4 className="booking-details-title">
                        {bookingDetails?.space?.space_title}
                      </h4>
                      <h6 className="desk-btn">
                        {" "}
                        {bookingDetails?.space?.space_type}
                      </h6>
                      <div className="booking-details-edit">
                        {calculateDesks(bookingDetails)}
                      </div>
                    </div>
                    <div className="right-section">
                      <Map
                        location={{
                          lat: parseFloat(bookingDetails?.property?.latitude),
                          lng: parseFloat(bookingDetails?.property?.longitude),
                        }}
                      ></Map>
                    </div>
                  </div>
                  <div className="booking-breakdown">
                    <div className="breakdown-left">
                      <div className="d-flex">
                        <h6>Breakdown:</h6>
                        <div
                          className="view-dates-btn"
                          onClick={() =>
                            onSetBookingDates(bookingDetails?.booking_details)
                          }
                        >
                          View Dates
                          <i className="fa fa-chevron-down ml-1"></i>
                        </div>
                      </div>

                      <p>
                        Total Amount: &nbsp;
                        <span className="text-primary font-weight-bold">
                          &#8356;{bookingDetails?.booking_details?.desk_price}
                        </span>
                      </p>
                    </div>
                    <div className="breakdown-right">
                      <h6>
                        Location:&nbsp;
                        <span className="text-primary font-weight-bold">
                          {bookingDetails?.property?.property_title}
                        </span>
                      </h6>
                      <h6 className="my-2">
                        Space:&nbsp;
                        <span className="text-primary font-weight-bold">
                          {bookingDetails?.space?.space_title}
                        </span>
                      </h6>
                      <h6 className="bd-address">
                        <a
                          href={`https://www.google.com/maps/place/${bookingDetails?.property?.address}/data=!3m1!1e3!4m5!3m4!1s0x89c25b292893b677:0xc27900b5360cfdb3!8m2!3d40.6426977!4d-73.9774347!5m1!1e4`}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {bookingDetails?.property?.address}
                        </a>
                      </h6>
                    </div>
                  </div>
                  <hr />
                  <div className="landlord-booking-details">
                    <div className="landlord-details-left">
                      <b className="text-primary mb-3">Landlord Details:</b>
                      <b>{bookingDetails?.landlord?.name}</b>
                      <b className="my-1">Contact:</b>
                      <h6>{bookingDetails?.landlord?.email}</h6>
                      <h6 className="mt-1">
                        {bookingDetails?.landlord?.phone}
                      </h6>
                    </div>
                    <div className="landlord-details-right">
                      <b className="text-primary mb-3">Tenant Details:</b>
                      <b>{bookingDetails?.user?.user}</b>
                      <b className="my-1">Contact:</b>
                      <h6>{bookingDetails?.user?.email}</h6>
                      <h6 className="mt-1">{bookingDetails?.user?.phone}</h6>
                    </div>
                  </div>
                  {bookingDetails?.landlord?.id !== userDetails?.id}
                  <div
                    onClick={() => sendRequest(bookingDetails?.landlord)}
                    className="open-chat-btn"
                  >
                    Open a Chat
                  </div>
                </div>
              </div>
            </LoadScript>
          ) : bookingDetails.booking_details.booking_status ===
              "enquiry approved" ||
            bookingDetails.booking_details.booking_status === "booked" ? (
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              libraries={libraries}
              language="en"
              region="uk"
            >
              <div className="booking-details-wrapper fade-in-bottom">
                <div className="booking-details-header">
                  <h4 className="text-primary font-weight-bold">
                    Booking Details:
                  </h4>
                  <small
                    className="back-button"
                    onClick={() => setOpenMyBooking(!openMyBooking)}
                  >
                    Back to all bookings
                  </small>
                </div>
                <hr />
                <div className="booking-details">
                  <div className="booking-details-top">
                    <div className="left-section">
                      <div className="booking-details-list">
                        <img
                          src={
                            bookingDetails?.property?.property_featured_image
                          }
                          alt=""
                          className="booking-details-poster"
                        />
                        <div className="booking-details-text">
                          <h6>
                            {bookingDetails?.booking_details?.booking_status}
                            <span className="text-primary">...</span>
                            {/* <small>
                              Start date:&nbsp;
                              <b>
                                {bookingDetails?.booking_details?.start_date}
                              </b>
                            </small> */}
                          </h6>
                          <h5 className="text-primary font-weight-bold">
                            {bookingDetails?.property?.property_title}
                          </h5>
                          <p className="space-title m-0">
                            {bookingDetails?.space?.space_title}
                          </p>
                          <Rating
                            rating={bookingDetails?.property?.avg_rating}
                          />
                          {/* <small className="font-weight-bold mt-2">
                      <img
                        src="assets/images/watch-icon.png"
                        alt=""
                        className="dot-image"
                      />
                      &nbsp; Awaiting Landlord Confirmation
                      <span className="text-primary">...</span>
                    </small> */}
                        </div>
                      </div>
                      <div className="booking_details-button">
                        <Link
                          to={`/space/${bookingDetails?.property?.property_id}/${bookingDetails?.space?.space_id}`}
                          className="button"
                        >
                          View Space
                        </Link>
                        <small
                          className="cancel-button"
                          onClick={() =>
                            onCancelBooking(bookingDetails?.booking_details)
                          }
                        >
                          Cancel Booking
                        </small>
                      </div>
                      <h6 className="booking-details-heading">
                        {bookingDetails?.property?.property_title}
                      </h6>
                      <h4 className="booking-details-title">
                        {bookingDetails?.space?.space_title}
                      </h4>
                      <h6 className="desk-btn">
                        {" "}
                        {bookingDetails?.space?.space_type}
                      </h6>
                      <div className="booking-details-edit">
                        {calculateDesks(bookingDetails)}
                      </div>
                    </div>
                    <div className="right-section">
                      <Map
                        location={{
                          lat: parseFloat(bookingDetails?.property?.latitude),
                          lng: parseFloat(bookingDetails?.property?.longitude),
                        }}
                      ></Map>
                    </div>
                  </div>
                  <div className="booking-breakdown">
                    <div className="breakdown-left">
                      <div className="d-flex">
                        <h6>Breakdown:</h6>
                        <div
                          className="view-dates-btn"
                          onClick={() =>
                            onSetBookingDates(bookingDetails?.booking_details)
                          }
                        >
                          View Dates
                          <i className="fa fa-chevron-down ml-1"></i>
                        </div>
                      </div>

                      <p>
                        Total Amount: &nbsp;
                        <span className="text-primary font-weight-bold">
                          &#8356;{bookingDetails?.booking_details?.desk_price}
                        </span>
                      </p>
                    </div>
                    <div className="breakdown-right">
                      <h6>
                        Location:&nbsp;
                        <span className="text-primary font-weight-bold">
                          {bookingDetails?.property?.property_title}
                        </span>
                      </h6>
                      <h6 className="my-2">
                        Space:&nbsp;
                        <span className="text-primary font-weight-bold">
                          {bookingDetails?.space?.space_title}
                        </span>
                      </h6>
                      <h6 className="bd-address">
                        <a
                          href={`https://www.google.com/maps/place/${bookingDetails?.property?.address}/data=!3m1!1e3!4m5!3m4!1s0x89c25b292893b677:0xc27900b5360cfdb3!8m2!3d40.6426977!4d-73.9774347!5m1!1e4`}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {bookingDetails?.property?.address}
                        </a>
                      </h6>
                    </div>
                  </div>
                  <hr />
                  <div className="landlord-booking-details">
                    <div className="landlord-details-left">
                      <b className="text-primary mb-3">Landlord Details:</b>
                      <b>{bookingDetails?.landlord?.name}</b>
                      <b className="my-1">Contact:</b>
                      <h6>{bookingDetails?.landlord?.email}</h6>
                      <h6 className="mt-1">
                        {bookingDetails?.landlord?.phone}
                      </h6>
                    </div>
                    <div className="landlord-details-right">
                      <b className="text-primary mb-3">Tenant Details:</b>
                      <b>{bookingDetails?.user?.user}</b>
                      <b className="my-1">Contact:</b>
                      <h6>{bookingDetails?.user?.email}</h6>
                      <h6 className="mt-1">{bookingDetails?.user?.phone}</h6>
                    </div>
                  </div>
                  {bookingDetails?.landlord?.id !== userDetails?.id}
                  <div
                    onClick={() => sendRequest(bookingDetails?.landlord)}
                    className="open-chat-btn"
                  >
                    Open a Chat
                  </div>
                </div>
              </div>
            </LoadScript>
          ) : null}
        </>
      )}
    </>
  );
};

export default BookingDetails;

const Marker = () => {
  return (
    <img
      className="pointer"
      width="30px"
      src="assets/images/marker.png"
      style={{ transform: "translate(-50%, -50%)" }}
    />
  );
};
