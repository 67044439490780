import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandlordBar from "../../../components/landlordBar/LandlordBar";
import Loading from "../../../components/loading/Loading";
import CreateVoucherPopup from "../../../popups/CreateVoucherPopup";
import {
  deleteVoucherData,
  getAllVoucherData,
} from "../../../store/homeAction";
import "./vouchers.scss";

const Vouchers = () => {
  const dispatch = useDispatch();
  const [voucherCode, setVoucherCode] = useState(false);
  const getVoucherCodeList = useSelector(
    (state) => state.home.getVoucherCodeList
  );
  const deleteVoucher = useSelector((state) => state.home.deleteVoucher);
  const createVoucher = useSelector((state) => state.home.createVoucher);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    const data = {
      user_id: userDetails && userDetails.id,
    };
    dispatch(getAllVoucherData(data));
  }, [deleteVoucher, createVoucher]);

  const handleDeleteVoucher = (id) => {
    const data = {
      voucher_id: id,
    };
    dispatch(deleteVoucherData(data));
  };

  return (
    <>
      <div className="my-voucher-main-wrapper">
        <LandlordBar />
        {!getVoucherCodeList ? (
          <div className="py-5">
            <Loading />
          </div>
        ) : (
          <div className="container my-voucher-wrapper">
            <div className="top-section">
              <div className="top-left-section">
                <h4>Vouchers List</h4>
              </div>
              <div className="top-right-section">
                <div
                  className="button-withdrawal"
                  onClick={() => setVoucherCode(true)}
                >
                  Create new Voucher
                </div>
              </div>
            </div>
            <p className="mt-2">
              You can generate vouchers here for your prospective Sharers, to
              allow them to redeem discounts or offers.
            </p>
            <div className="bottom-section fade-in-bottom">
              <table>
                <thead>
                  <tr>
                    <th>Voucher Created on</th>
                    <th>Voucher Code</th>
                    <th>Assigned To</th>
                    <th>Valid Up to</th>
                    <th>Discount(%)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {getVoucherCodeList &&
                    getVoucherCodeList.map((data, key) => (
                      <tr key={key}>
                        <td>
                          {moment(data.created_at).format("DD-MMMM-YYYY")}
                        </td>
                        <td>{data.code}</td>
                        <td>{data.user_to}</td>
                        <td>{data.validity}</td>
                        <td>{data.discount}%</td>
                        {data?.status === 1 ?
                          <td className="text-success">Active</td>
                         : <td className="text-danger">Expired</td>}
                        
                        <td>
                          {/* <span className="sendButton">Send</span>{" "} */}
                          <span
                            className="deleteButton"
                            onClick={() => handleDeleteVoucher(data.id)}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <CreateVoucherPopup
        open={voucherCode}
        onCloseModel={() => setVoucherCode(false)}
      />
    </>
  );
};

export default Vouchers;
