import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Map from "../../components/map/Map";
import "./userBooking.scss";
const libraries = ["places"];

const SwitchMap = ({ propertyListById, spaceDetails }) => {
  const [switchToMap, setSwitchToMap] = useState(false);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 51.5074,
      lng: -0.1278,
    },
    zoom: 12,
  });

  useEffect(() => {
    window.scrollTo(0, 200);
  }, []);

  useEffect(() => {
    if (propertyListById) {
      setDefaultProps({
        center: {
          lat: parseFloat(propertyListById?.lang),
          lng: parseFloat(propertyListById?.long),
        },
        zoom: 12,
      });
    }
  }, [propertyListById]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      libraries={libraries}
      language="en"
      region="uk"
    >
      <div className="user-right">
        {switchToMap ? (
          <div className="user-right-image" style={{ overflow: "hidden" }}>
            <Map location={defaultProps.center}></Map>
          </div>
        ) : (
          <img
            src={spaceDetails && spaceDetails.featured_image}
            alt=""
            className="user-right-image"
          />
        )}

        <div className="row justify-content-between mt-2">
          <div
            className="location"
            onClick={() => setSwitchToMap(!switchToMap)}
          >
            {switchToMap ? (
              <>
                {" "}
                <img src="assets/icons/upload.png" />
                <span>Switch to Image</span>
              </>
            ) : (
              <>
                {" "}
                <img src="assets/images/marker.png" />
                <span>Switch to map</span>
              </>
            )}
          </div>
          <div className="user-address">
            <small>
              Location:{" "}
              <span>{propertyListById && propertyListById.property_title}</span>
            </small>
            <small>
              Space: <span>{spaceDetails && spaceDetails.space_title}</span>
            </small>
            {/* <span className="address">
              {propertyListById && propertyListById.address_line_one}
            </span> */}

            <span className="address">
              <a
                href={`https://www.google.com/maps/place/${
                  propertyListById && propertyListById.address_line_one
                }/data=!3m1!1e3!4m5!3m4!1s0x89c25b292893b677:0xc27900b5360cfdb3!8m2!3d40.6426977!4d-73.9774347!5m1!1e4`}
                rel="noopener noreferrer"
                target="_blank"
                className="address-link"
              >
                {propertyListById && propertyListById.address_line_one}
              </a>
            </span>
          </div>
        </div>
      </div>
    </LoadScript>
  );
};

export default SwitchMap;

const Marker = () => {
  return (
    <img
      className="pointer"
      width="30px"
      src="assets/images/marker.png"
      style={{ transform: "translate(-50%, -50%)" }}
    />
  );
};
