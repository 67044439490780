import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRegions } from "../../../store/homeAction";
import { useHistory } from "react-router-dom";
import { SET_REGISTER_USER_DETAIL } from "../../../store/types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "./landlordAll.scss";
import Datetime from "react-datetime";
import moment from "moment";

let inputProps = {
  placeholder: "DOB",
};

const LandlordRegister = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.home.regions);
  const registerUserDetail = useSelector(
    (state) => state.home.registerUserDetail
  );

  const [values, setValues] = React.useState({});
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [terms, setTerms] = React.useState(false);

  const onInputChange = (e) => {
    if (
      e.target.value.length > 0 &&
      e.target.name === "first_name" &&
      /^[A-Za-z ]+$/.test(e.target.value) === false
    ) {
      return;
    }

    if (
      e.target.value.length > 0 &&
      e.target.name === "last_name" &&
      /^[A-Za-z ]+$/.test(e.target.value) === false
    ) {
      return;
    }

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    if (terms === false) {
      toast("Please accept terms & conditions", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    if (
      values.dob == "" ||
      values.dob == null ||
      values.dob == undefined ||
      values.dob === ""
    ) {
      toast("DOB is required!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    let data = {
      first_name: values.first_name,
      last_name: values.last_name,
      dob: moment(values.dob).format("DD-MM-YYYY"),
      email: values.email,
      region: selectedRegion,
      phone: values.phone,
      domestic_postcode: values.domestic_postcode,
      newsletter: false,
      social_type: "manual", //social login => google,facebook,apple
      social_id: "0", //social id
    };

    localStorage.setItem("os-phone-number", values.phone);
    localStorage.setItem("os-region", selectedRegion);

    dispatch({ type: SET_REGISTER_USER_DETAIL, payload: data });

    history.push("/landlord-signup");
  };

  React.useEffect(() => {
    const phone = localStorage.getItem("os-phone-number");
    const region = localStorage.getItem("os-region-name");
    setValues({ ...values, phone: phone });
    setSelectedRegion(region);
    dispatch(getRegions());
  }, []);

  React.useEffect(() => {
    if (registerUserDetail) {
      setValues(registerUserDetail);
    }
  }, [registerUserDetail]);

  // disable future dates
  const today = moment();
  const disableFutureDt = (current) => {
    return current.isBefore(today);
  };

  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  if (userDetails?.id) {
    history.replace("/");
  }

  return (
    <div className="register-main-wrapper">
      <div className="container">
        <div className="register-section">
          <div className="register-header">
            <h2>
              Sign up as a Host<span className="text-primary">:</span>
            </h2>
            <h3>
              Already registered?{" "}
              <Link to="/landlord-login">
                <span className="text-primary">Log in</span>
              </Link>
            </h3>
          </div>
          <h4 className="register-title">Let's get to know each other!</h4>
          <h4 className="mt-1">
            Before you start to add your space, let's get accquianted.
          </h4>
          <div className="register-main">
            <form onSubmit={onSubmitForm}>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <select
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  required
                >
                  <option value="">Select Region</option>
                  {regions &&
                    regions.map((region, i) => (
                      <option value={region.phonecode} key={i}>
                        {region.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Your Mobile Number"
                  name="phone"
                  value={values.phone}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  placeholder="Postcode"
                  name="domestic_postcode"
                  value={values.domestic_postcode}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <Datetime
                  id="date-input"
                  dateFormat={"DD/MM/YYYY"}
                  timeFormat={false}
                  value={values.dob}
                  onChange={(date) => setValues({ ...values, dob: date._d })}
                  inputProps={inputProps}
                  isValidDate={disableFutureDt}
                />
              </div>
              <div className="form-group d-flex align-items-center m-0">
                <input
                  id="terms"
                  type="radio"
                  className="form-control-md"
                  onClick={() => setTerms(!terms)}
                  checked={terms}
                />
                <label htmlFor="terms" className="four-title mx-1 my-0">
                  Agree to{" "}
                  <Link to="/terms" target="_blank">
                    {" "}
                    Terms and Conditions
                  </Link>
                </label>
              </div>

              <div className="input-group">
                <button type="submit" className="btn-submit">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandlordRegister;
