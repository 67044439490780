import { Link } from "react-router-dom";
import "./register.scss";

const Register = () => {
  return (
    <div className="register-wrapper pt-4 register-page">
      <div className="container success-wrapper p-5">
        <h2 className="mb-3">
          Email Verification Link Sent<span className="text-primary">!</span>{" "}
        </h2>
        <p className="mb-4">
          Thank you for joining! You should receive an email shortly where you
          can verify your email address.{" "}
        </p>
      </div>
    </div>
  );
};

export default Register;
