import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPropertySpaces } from "../../store/homeAction";
import { SET_SPACE_AVAILIBILITY_DATES } from "../../store/types";
import "./spaceAvailableDates.scss";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";

const SpaceAvailableDates = ({ history, match, propertyId, spaceId, wrapper = true }) => {

  propertyId = propertyId || match.params.propertyId;
  spaceId = spaceId || match.params.spaceId;

  const dispatch = useDispatch();
  const path = useLocation().search;

  const [availableDates, setAvailableDates] = useState([]);

  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const spaceAvailabilityDates = useSelector((state) => state.home.spaceAvailabilityDates);
  const isLoading = useSelector((state) => state.home.isLoading);

  useEffect(() => {
    if (propertyId) {
      dispatch(getPropertySpaces({ property_id: propertyId }));
    }
  }, [propertyId])

  useEffect(() => {
    if (propertySpaces?.length) {
      const spaceDetails = propertySpaces?.filter((v) => v.id === parseInt(spaceId));
      if (spaceDetails?.length) {
        spaceDetails?.forEach((v) => {
          const availability = v.availability;
          let modAvailableDates = [];
          if (availability) {
            for (let i = 0; i < availability.length; i++) {
              let data = {
                type: availability[i].type,
                desk: availability[i].available_desk,
              };
              if (availability[i].type === "date") {
                data.date = {
                  startDate:
                    availability[i].from_date ||
                    moment(new Date()).format("YYYY-MM-DD"),
                };
              } else {
                data.date = {
                  startDate:
                    availability[i].from_date ||
                    moment(new Date()).format("YYYY-MM-DD"),
                  endDate:
                    availability[i].to_date ||
                    moment(new Date()).format("YYYY-MM-DD"),
                };
              }
              modAvailableDates.push(data);
            }
          }
          const params = (!modAvailableDates?.length && !spaceAvailabilityDates?.length) ? [
            {
              type: "date",
              date: { startDate: moment(new Date()).format("YYYY-MM-DD") },
              desk: 1,
            },
          ] : path !== '?q=set'
            ? [...modAvailableDates]
            : spaceAvailabilityDates?.length
            ? spaceAvailabilityDates
            : [...modAvailableDates];
  
          const loadedParams = params.length ? params : [
            {
              type: "date",
              date: { startDate: moment(new Date()).format("YYYY-MM-DD") },
              desk: 1,
            },
          ];
  
          setAvailableDates(loadedParams);
          dispatch({
            type: SET_SPACE_AVAILIBILITY_DATES,
            payload: loadedParams,
          });
        });
      } else {
        const params = spaceAvailabilityDates?.length ? spaceAvailabilityDates : [
          {
            type: "date",
            date: { startDate: moment(new Date()).format("YYYY-MM-DD") },
            desk: 1,
          },
        ];
        setAvailableDates(params);
        dispatch({
          type: SET_SPACE_AVAILIBILITY_DATES,
          payload: params,
        });
      }
    } else {
      const params = spaceAvailabilityDates?.length ? spaceAvailabilityDates : [
        {
          type: "date",
          date: { startDate: moment(new Date()).format("YYYY-MM-DD") },
          desk: 1,
        },
      ];

      setAvailableDates(params);
      dispatch({
        type: SET_SPACE_AVAILIBILITY_DATES,
        payload: params,
      });
    }
  }, [propertySpaces]);

  const onAddDate = () => {
    const params = [
      ...availableDates,
      {
        type: "date",
        date: { startDate: moment(new Date()).format("YYYY-MM-DD") },
        desk: 1,
      },
    ];
    setAvailableDates(params);
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: params,
    });
  };

  const onChangeType = (type, key) => {
    for (let i = 0; i < availableDates.length; i++) {
      if (key === i) {
        if (availableDates[i].type) {
          availableDates[i].type = type;
        } else {
          availableDates[i].type = type;
        }
      }
    }
    setAvailableDates([...availableDates]);
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: [...availableDates],
    });
  };

  const onDateChange = (type, date, key) => {
    for (let i = 0; i < availableDates.length; i++) {
      if (key === i) {
        if (availableDates[i].date) {
          availableDates[i].date[type] = moment(date).format("YYYY-MM-DD");
        } else {
          availableDates[i].date = {};
          availableDates[i].date[type] = moment(date).format("YYYY-MM-DD");
        }
      }
    }
    setAvailableDates([...availableDates]);
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: [...availableDates],
    });
  };

  const onDeskChange = (value, key) => {
    for (let i = 0; i < availableDates.length; i++) {
      if (key === i) {
        if (availableDates[i].desk) {
          availableDates[i].desk = value;
        } else {
          availableDates[i].desk = value;
        }
      }
    }
    setAvailableDates([...availableDates]);
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: [...availableDates],
    });
  };

  const onSave = () => {
    let finalData = [];
    let availability = availableDates;
    if (availability) {
      for (let i = 0; i < availability.length; i++) {
        let data = {
          type: availability[i].type,
          desk: availability[i].desk,
        };

        if (availability[i].type === "date") {
          data.date = {
            startDate:
              availability[i].date.startDate ||
              moment(new Date()).format("YYYY-MM-DD"),
          };
        } else {
          data.date = {
            startDate:
              availability[i].date.startDate ||
              moment(new Date()).format("YYYY-MM-DD"),
            endDate:
              availability[i].date.endDate ||
              moment(new Date()).format("YYYY-MM-DD"),
          };
        }

        finalData.push(data);
      }
    }

    setAvailableDates(finalData);
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: finalData,
    });

    if (spaceId && wrapper) {
      // If we are in a model, then we need to save and close the modal with handleClosePopup

      toast("Spaces added, You must Save to confirm!", {
        type: toast.TYPE.WARNING,
        autoClose: 10000,
      });

      history.push(
        `/add-space/${propertyId}/${spaceId}?q=set`
      );

    } else {
      history.push(`/add-space/${propertyId}?q=set`);
    }
  };

  const startDateValid = (current) => {
    const startDateYesterday = moment().subtract(1, "day");

    return current.isAfter(startDateYesterday);
  };

  const endDateValid = (current, value) => {
    const endDate = moment(new Date(value));

    return current.isAfter(endDate);
  };

  const toDate = (dateStr) => {
    const date = moment(dateStr, "YYYY-MM-DD").toDate();
    return new Date(date);
  };

  const deleteAvailabilityDates = (key) => {
    for (let i = 0; i < availableDates.length; i++) {
      if (key === i) {
        availableDates.splice(i, 1);
      }
    }
    setAvailableDates([...availableDates]);
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: [...availableDates],
    });
  };

  return (
    <>
      {wrapper && <LandlordBar />}
      <div className="available-space-wrapper">
        <div className="container">
          <div className="available-space">

            <h4>Add Available Space</h4>
            <div className="available-card-wrapper">
              <h6 className="available-title">Select Available Dates and No. of desks</h6>
              {isLoading && path !== '?q=set' ? (
                <div className="btns d-flex justify-content-center">
                  <span className="fa fa-spinner fa-spin px-3"></span>
                </div>
              ) : availableDates &&
                    availableDates.map((date, key) => (
                      <div className="available-card" key={key}>
                        <div className="available-text">
                          <div className="available-card-one">
                            <div className="available-card-item">
                              <input
                                  id={`date${key}`}
                                  type="radio"
                                  onChange={(e) => onChangeType("date", key)}
                                  checked={date.type === "date"}
                              />
                              <label htmlFor={`date${key}`}> Date</label>
                            </div>
                            <div className="available-card-item">
                              <input
                                  id={`daterange${key}`}
                                  type="radio"
                                  onChange={(e) => onChangeType("range", key)}
                                  checked={date.type === "range"}
                              />
                              <label htmlFor={`daterange${key}`}> Date Range</label>
                            </div>
                          </div>


                          <div className="available-card-two">
                            {date.type && (
                              <>
                                <label htmlFor="date-input">
                                  <img
                                    src="assets/images/calender-icon.png"
                                    alt=""
                                    className="calendar-image-icon unstyled"
                                  />
                                </label>
                                <Datetime
                                  id="date-input"
                                  dateFormat={"DD/MM/YYYY"}
                                  timeFormat={false}
                                  isValidDate={startDateValid}
                                  value={
                                    date.date
                                      ? date.date.startDate
                                        ? new Date(toDate(date.date.startDate))
                                        : new Date()
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    onDateChange("startDate", date._d, key)
                                  }
                                />

                                {date.type === "range" && (
                                  <>
                                    <span className="mr-5">To</span>
                                    <label htmlFor="date-input">
                                      <img
                                        src="assets/images/calender-icon.png"
                                        alt=""
                                        className="calendar-image-icon unstyled"
                                      />
                                    </label>
                                    <Datetime
                                      id="date-input"
                                      dateFormat={"DD/MM/YYYY"}
                                      timeFormat={false}
                                      isValidDate={(currentDate) =>
                                        endDateValid(
                                          currentDate,
                                          date.date.startDate
                                        )
                                      }
                                      value={
                                        date.date
                                          ? date.date.endDate
                                            ? new Date(toDate(date.date.endDate))
                                            : new Date()
                                          : new Date()
                                      }
                                      onChange={(date) =>
                                        onDateChange("endDate", date._d, key)
                                      }
                                    />
                                  </>
                                )}
                                {/* <p>{date.endDate}</p> */}
                              </>
                            )}
                          </div>

                          <div className="available-card-three">
                            <input
                              type="number"
                              placeholder="Desks"
                              value={date.desk}
                              onChange={(e) => onDeskChange(e.target.value, key)}
                            ></input>
                          </div>
                        </div>
                        <img
                          src="assets/images/delete-icon.png"
                          alt=""
                          className="delete-icon-image"
                          onClick={() => deleteAvailabilityDates(key)}
                        />
                      </div>
                    ))}
              <div className="available-buttons">
                <div className="available-button" onClick={onSave}>
                  Submit
                </div>
                <div className="available-button" onClick={onAddDate}>
                  Add <img src="assets/images/add.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpaceAvailableDates;
