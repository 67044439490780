import React from 'react';

const IconClock = ({ color = '#CBCBCB', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color}  d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16c8.836 0 16-7.164 16-16s-7.164-16-16-16zM23.384 18.461h-7.384c-0.326 0-0.639-0.13-0.87-0.36s-0.361-0.544-0.361-0.87v-11.077c0-0.327 0.13-0.639 0.361-0.87s0.544-0.361 0.87-0.361c0.326 0 0.639 0.13 0.87 0.361s0.36 0.544 0.36 0.87v9.846h6.154c0.326 0 0.639 0.13 0.87 0.361s0.36 0.544 0.36 0.87-0.13 0.64-0.36 0.87c-0.231 0.231-0.544 0.36-0.87 0.36z"></path>
    </svg>
  )
};

export default IconClock;