import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelBooking, getBookingList, addRating } from "../../../store/homeAction";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import BookingDetails from "../bookingDetails/BookingDetails";
import BookingDatesPopup from "../../../popups/BookingDatesPopup";
import Rating from "../../../components/rating/Rating";
import RatingPopup from "../../../popups/RatingPopup";
import "./myBooking.scss";

const MyBooking = () => {
  const dispatch = useDispatch();
  const [openBookingDetails, setOpenBookingDetails] = useState(false);
  const bookingList = useSelector((state) => state.home.bookingList);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [bookingDetails, setBookingDetails] = useState({});
  const [bookingDates, setBookingDates] = useState({});
  const [isOpenBookingDatesPopup, setIsOpenBookingDatesPopup] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const [rateBookingID, setRateBookingId] = useState(null);
  const [ratePropertyId, setRatePropertyId] = useState(null);

  // RatingPopup
  const [isExperiencePopupOpened, setIsExperiencePopupOpened] = useState(false);
  const [rating, setRating] = useState([true, true, true, true, true]);

  const onOpenExperiencePopup = (item) => {
    const bookingId = item?.booking_details?.booking_id;
    const propertyId = item?.property?.property_id;
    setRateBookingId(bookingId);
    setIsExperiencePopupOpened(true);
    setRatePropertyId(propertyId)
  };
  const onCloseExperiencePopup = (type) => {
    setIsExperiencePopupOpened(false);
    setRating([false, false, false, false, false]);
    if (type === 'submit') {

      dispatch(addRating({
        booking_id: rateBookingID,
        rating: rating?.filter((v) => !!v).length,
        type: 'property',
        type_id: ratePropertyId,
      }))
    }
  };

  const onSelectExperienceRating = (rating) => {
    setRating(rating);
  };

  useEffect(() => {
    onGetBookingList();
  }, []);

  const onGetBookingList = () => {
    let data = {
      user_id: userDetails && userDetails.id,
    };
    dispatch(getBookingList(data));
  };

  const onOpenBookingDetails = (booking) => {
    setOpenBookingDetails(!openBookingDetails);
    setBookingDetails(booking);
  };

  const handleCancelBooking = (id) => {
    const data = {
      booking_id: id,
      user_id: userDetails && userDetails.id,
    };

    dispatch(cancelBooking(data));
  };

  const onSetBookingDates = (booking_details) => {
    setBookingDates(booking_details);
    setIsOpenBookingDatesPopup(true);
  };

  const calculatedDesks = (item) => {
    return (
      <div className="desk-details">
        <b>
          {" "}
          {item?.booking_details?.booked_desk}{" "}
          {item?.booking_details?.booked_desk == 1 ? "Desk" : "Desks"}{" "}
        </b>{" "}
        for{" "}
        {item?.booking_details?.booking_dates_day?.length > 0 &&
          `${item?.booking_details?.booking_dates_day?.length} ${
            item?.booking_details?.booking_dates_day?.length == 1
              ? "Day"
              : "Day(s)"
          } `}
        {item?.booking_details?.booking_dates_day?.length > 0 &&
          item?.booking_details?.booking_dates_night?.length > 0 &&
          "and "}
        {item?.booking_details?.booking_dates_night?.length > 0 &&
          `${item?.booking_details?.booking_dates_night?.length} ${
            item?.booking_details?.booking_dates_night?.length == 1
              ? "Night"
              : "Night(s)"
          } `}
      </div>
    );
  };

  const calculateAvailability = (item) => {
    return (
      <div className="availability-details">
        {item?.booking_details?.period_of_day === 1 ? (
          <span>
            <img src="assets/images/day.svg" alt="" /> Day
          </span>
        ) : item?.booking_details?.period_of_day === 2 ? (
          <span>
            <img src="assets/images/night.svg" alt="" /> Night
          </span>
        ) : item?.booking_details?.period_of_day === 3 ? (
          <>
            <span>
              <img src="assets/images/day.svg" alt="" /> Day
            </span>
            <span className="light-text">and</span>
            <span>
              <img src="assets/images/night.svg" alt="" /> Night
            </span>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      {openBookingDetails ? (
        <BookingDetails
          bookingDetails={bookingDetails}
          handleCancelBooking={handleCancelBooking}
        />
      ) : (
        <>
          <BookingDatesPopup
            open={isOpenBookingDatesPopup}
            onClose={() => setIsOpenBookingDatesPopup(false)}
            bookingDetails={bookingDates}
          />
          <div className="mybooking-wrapper">
            {isLoading ? (
              <div className="py-5">
                <Loading></Loading>
              </div>
            ) : (
              <div className="fade-in-bottom">
                <div className="mybooking-main">
                  <h5 className="text-primary font-weight-bold">Upcoming:</h5>
                  <hr />
                  {bookingList && bookingList?.upcomming?.length > 0 ? (
                    bookingList?.upcomming.map((item, key) => (
                      <div key={key}>
                        {item?.booking_details?.booking_status ===
                        "enquiry pending" ? (
                          <div className="mybooking">
                            <div className="mybooking-left">
                              <div className="mybooking-poster">
                                <img
                                  src={item?.property?.property_featured_image}
                                  alt=""
                                  className="poster-image"
                                  onClick={() => onOpenBookingDetails(item)}
                                />
                              </div>
                              <div className="mybooking-text">
                                <div className="booking-date-div">
                                  <p className="mr-1 mb-0">Booking Dates:</p>
                                  <div
                                    className="view-dates-btn"
                                    onClick={() =>
                                      onSetBookingDates(item?.booking_details)
                                    }
                                  >
                                    View Dates
                                    <i className="fa fa-chevron-down ml-1"></i>
                                  </div>
                                </div>
                                <h5
                                  className="text-primary font-weight-bold"
                                  onClick={() => onOpenBookingDetails(item)}
                                >
                                  {item?.property?.property_title}
                                </h5>
                                <p className="space-title m-0">
                                  {item?.space?.space_title}
                                </p>
                                <p className="space-address">
                                  {" "}
                                  <img
                                    src="assets/images/marker.png"
                                    alt=""
                                  />{" "}
                                  {item?.property?.address}{" "}
                                </p>
                                <Rating rating={item?.property?.avg_rating} />
                                <div className="booking-availability-details">
                                  {calculatedDesks(item)}
                                  {calculateAvailability(item)}
                                </div>
                                <small className="font-weight-bold mt-2 text-capitalize">
                                  <img
                                    src="assets/images/red-dot.png"
                                    alt=""
                                    className="dot-image"
                                  />
                                  {item?.booking_details?.booking_status}
                                </small>
                              </div>
                            </div>
                          </div>
                        ) : item?.booking_details?.booking_status ===
                          "booked" ? (
                          <div className="mybooking">
                            <div className="mybooking-left">
                              <div className="mybooking-poster">
                                <img
                                  src={item?.property?.property_featured_image}
                                  alt=""
                                  className="poster-image"
                                  onClick={() => onOpenBookingDetails(item)}
                                />
                              </div>
                              <div className="mybooking-text">
                                <div className="booking-date-div">
                                  <p className="mr-1 mb-0">Booking Dates:</p>
                                  <div
                                    className="view-dates-btn"
                                    onClick={() =>
                                      onSetBookingDates(item?.booking_details)
                                    }
                                  >
                                    View Dates
                                    <i className="fa fa-chevron-down ml-1"></i>
                                  </div>
                                </div>
                                <h5
                                  className="text-primary font-weight-bold"
                                  onClick={() => onOpenBookingDetails(item)}
                                >
                                  {item?.property?.property_title}
                                </h5>
                                <p className="space-title m-0">
                                  {item?.space?.space_title}
                                </p>
                                <p className="space-address">
                                  {" "}
                                  <img
                                    src="assets/images/marker.png"
                                    alt=""
                                  />{" "}
                                  {item?.property?.address}{" "}
                                </p>
                                <Rating rating={item?.property?.avg_rating} />
                                <div className="booking-availability-details">
                                  {calculatedDesks(item)}
                                  {calculateAvailability(item)}
                                </div>
                                <small className="font-weight-bold mt-2 text-capitalize">
                                  <img
                                    src="assets/images/green-dot.png"
                                    alt=""
                                    className="dot-image"
                                  />
                                  {item?.booking_details?.booking_status}
                                </small>
                              </div>
                            </div>
                          </div>
                        ) : item?.booking_details?.booking_status ===
                          "enquiry approved" ? (
                          <div className="mybooking">
                            <div className="mybooking-left">
                              <div className="mybooking-poster">
                                <img
                                  src={item?.property?.property_featured_image}
                                  alt=""
                                  className="poster-image"
                                  onClick={() => onOpenBookingDetails(item)}
                                />
                              </div>
                              <div className="mybooking-text">
                                <div className="booking-date-div">
                                  <p className="mr-1 mb-0">Booking Dates:</p>
                                  <div
                                    className="view-dates-btn"
                                    onClick={() =>
                                      onSetBookingDates(item?.booking_details)
                                    }
                                  >
                                    View Dates
                                    <i className="fa fa-chevron-down ml-1"></i>
                                  </div>
                                </div>
                                <h5
                                  className="text-primary font-weight-bold"
                                  onClick={() => onOpenBookingDetails(item)}
                                >
                                  {item?.property?.property_title}
                                </h5>
                                <p className="space-title m-0">
                                  {item?.space?.space_title}
                                </p>
                                <p className="space-address">
                                  {" "}
                                  <img
                                    src="assets/images/marker.png"
                                    alt=""
                                  />{" "}
                                  {item?.property?.address}{" "}
                                </p>
                                <Rating rating={item?.property?.avg_rating} />
                                <div className="booking-availability-details">
                                  {calculatedDesks(item)}
                                  {calculateAvailability(item)}
                                </div>
                                <small className="font-weight-bold mt-2 text-capitalize">
                                  <img
                                    src="assets/images/green-dot.png"
                                    alt=""
                                    className="dot-image"
                                  />
                                  {item?.booking_details?.booking_status}
                                </small>
                              </div>
                            </div>
                            <div className="mybooking-right">
                              <Link
                                to={`/enquiry-payment/${item?.booking_details?.booking_id}`}
                                className="mybooking-btn"
                              >
                                <img
                                  src="assets/images/arrow-right.png"
                                  alt=""
                                />
                                <small>Complete Booking</small>
                              </Link>
                              <small
                                className="cancel-enquiry ml-4"
                                onClick={() =>
                                  handleCancelBooking(
                                    item?.booking_details?.booking_id
                                  )
                                }
                              >
                                Cancel Enquiry
                              </small>
                            </div>
                          </div>
                        ) : item?.booking_details?.booking_status ===
                          "cancelled" ? (
                          <div className="mybooking">
                            <div className="mybooking-left">
                              <div className="mybooking-poster">
                                <img
                                  src={item?.property?.property_featured_image}
                                  alt=""
                                  className="poster-image"
                                  onClick={() => onOpenBookingDetails(item)}
                                />
                              </div>
                              <div className="mybooking-text">
                                <div className="booking-date-div">
                                  <p className="mr-1 mb-0">Booking Dates:</p>
                                  <div
                                    className="view-dates-btn"
                                    onClick={() =>
                                      onSetBookingDates(item?.booking_details)
                                    }
                                  >
                                    View Dates
                                    <i className="fa fa-chevron-down ml-1"></i>
                                  </div>
                                </div>
                                <h5
                                  className="text-primary font-weight-bold"
                                  onClick={() => onOpenBookingDetails(item)}
                                >
                                  {item?.property?.property_title}
                                </h5>
                                <p className="space-title m-0">
                                  {item?.space?.space_title}
                                </p>
                                <p className="space-address">
                                  {" "}
                                  <img
                                    src="assets/images/marker.png"
                                    alt=""
                                  />{" "}
                                  {item?.property?.address}{" "}
                                </p>
                                <Rating rating={item?.property?.avg_rating} />
                                <div className="booking-availability-details">
                                  {calculatedDesks(item)}
                                  {calculateAvailability(item)}
                                </div>
                                <small className="font-weight-bold mt-2 text-capitalize">
                                  <img
                                    src="assets/images/red-dot.png"
                                    alt=""
                                    className="dot-image"
                                  />
                                  {item?.booking_details?.booking_status}
                                </small>
                              </div>
                            </div>
                          </div>
                        ) : item?.booking_details?.booking_status ===
                          "pending" ? (
                          <div className="mybooking">
                            <div className="mybooking-left">
                              <div className="mybooking-poster">
                                <img
                                  src={item?.property?.property_featured_image}
                                  alt=""
                                  className="poster-image"
                                  onClick={() => onOpenBookingDetails(item)}
                                />
                              </div>
                              <div className="mybooking-text">
                                <div className="booking-date-div">
                                  <p className="mr-1 mb-0">Booking Dates:</p>
                                  <div
                                    className="view-dates-btn"
                                    onClick={() =>
                                      onSetBookingDates(item?.booking_details)
                                    }
                                  >
                                    View Dates
                                    <i className="fa fa-chevron-down ml-1"></i>
                                  </div>
                                </div>
                                <h5
                                  className="text-primary font-weight-bold"
                                  onClick={() => onOpenBookingDetails(item)}
                                >
                                  {item?.property?.property_title}
                                </h5>
                                <p className="space-title m-0">
                                  {item?.space?.space_title}
                                </p>
                                <p className="space-address">
                                  {" "}
                                  <img
                                    src="assets/images/marker.png"
                                    alt=""
                                  />{" "}
                                  {item?.property?.address}{" "}
                                </p>
                                <Rating rating={item?.property?.avg_rating} />
                                <div className="booking-availability-details">
                                  {calculatedDesks(item)}
                                  {calculateAvailability(item)}
                                </div>
                                <small className="font-weight-bold mt-2 text-capitalize">
                                  <img
                                    src="assets/images/red-dot.png"
                                    alt=""
                                    className="dot-image"
                                  />
                                  {item?.booking_details?.booking_status}
                                </small>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div className="py-5 text-center">No Data Found !</div>
                  )}
                </div>
                <div className="mybooking-main mt-5">
                  <h5 className="text-primary font-weight-bold">Previous Bookings:</h5>
                  <hr />
                  {bookingList && bookingList?.previous?.length > 0 ? (
                    bookingList?.previous.map((item, key) => (
                      <>
                        <div className="mybooking" key={key}>
                          <div className="mybooking-left">
                            <div className="mybooking-poster">
                              <img
                                src={item?.property?.property_featured_image}
                                alt=""
                                className="poster-image"
                                onClick={() => onOpenBookingDetails(item)}
                              />
                            </div>
                            <div className="mybooking-text">
                              <div className="booking-date-div">
                                <p className="mr-1 mb-0">Booking Dates:</p>
                                <div
                                  className="view-dates-btn"
                                  onClick={() =>
                                    onSetBookingDates(item?.booking_details)
                                  }
                                >
                                  View Dates
                                  <i className="fa fa-chevron-down ml-1"></i>
                                </div>
                              </div>
                              <h5
                                className="text-primary font-weight-bold"
                                onClick={() => onOpenBookingDetails(item)}
                              >
                                {item?.property?.property_title}
                              </h5>
                              <p className="space-title m-0">
                                {item?.space?.space_title}
                              </p>
                              <p className="space-address">
                                {" "}
                                <img
                                  src="assets/images/marker.png"
                                  alt=""
                                />{" "}
                                {item?.property?.address}{" "}
                              </p>
                              <Rating rating={item?.property?.avg_rating} />
                              <div className="booking-availability-details">
                                {calculatedDesks(item)}
                                {calculateAvailability(item)}
                              </div>
                              <small className="rating" onClick={() => onOpenExperiencePopup(item)}>
                                Rate your experience
                              </small>
                            </div>
                          </div>
                          <div className="mybooking-right">
                            <Link
                              to={`/space/${item?.property?.property_id}/${item?.space?.space_id}`}
                            >
                              <small className="booking-button">Book Again</small>
                            </Link>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))
                  ) : (
                    <div className="py-5 text-center">No Data Found !</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <RatingPopup
        open={isExperiencePopupOpened}
        rating={rating}
        onClose={onCloseExperiencePopup}
        onSelectExperienceRating={onSelectExperienceRating}
      />
    </>
  );
};

export default MyBooking;
