import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import GeoLocation from "../geoLocation/GeoLocation";
import "./searchInput.scss";
import { toast } from "react-toastify";
const libraries = ["places"];

const SearchInput = ({ value, onSearchInput, onApplySearch }) => {
  const history = useHistory();
  const ref = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchBox, setSearchBox] = useState(null);
  const [location, setLocation] = useState({});
  const [hideSmallPlaceHolder, setHideSmallPlaceholder] = useState(true);
  const currentLocation = useSelector((state) => state.home.currentLocation);

  const onSearchChange = () => {
    if (!value) {
      toast("Please Enter Something to search", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else {
      history.push(`/search/${searchInput}`);
      onApplySearch(searchInput, location);
    }
  };

  const onSearchBoxLoad = React.useCallback((ref) => {
    setSearchBox(ref);
  }, []);

  const onPlacesChanged = React.useCallback(() => {
    const data = searchBox.getPlaces();

    let location = {
      lat: data[0].geometry.location.lat(),
      lng: data[0].geometry.location.lng(),
    };

    setLocation(location);
    setSearchInput(data[0].formatted_address);
    onSearchInput(data[0].formatted_address);

    // history.push(`/search/${data[0].formatted_address}`);
    onApplySearch(data[0].formatted_address, location);
  }, [searchBox]);

  const onSearchCurrentLocation = () => {
    setLocation(location);
    reverseGeoCode(currentLocation.latitude, currentLocation.longitude);
  };

  const reverseGeoCode = async (lat, lng) => {
    const latlng = { lat, lng };

    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({ location: latlng });

    setSearchInput(response && response.results[0].formatted_address);
    onSearchInput(response && response.results[0].formatted_address);

    if (response.results) {
      // history.push(
      //   `/search/${response && response.results[0].formatted_address}`
      // );

      let currentLatLng = {
        lat: currentLocation.latitude,
        lng: currentLocation.longitude,
      };

      onApplySearch(
        response && response.results[0].formatted_address,
        currentLatLng
      );
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      libraries={libraries}
      language="en"
      region="uk"
    >
      <div className="search-input-wrapper">
        <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            ref={ref}
            type="text"
            value={searchInput}
            placeholder="Start your search."
            onFocus={() =>
              setHideSmallPlaceholder(
                value.length === 0 && !hideSmallPlaceHolder
              )
            }
            onBlur={() =>
              setTimeout(() => {
                setHideSmallPlaceholder(
                  value.length === 0 && !hideSmallPlaceHolder
                );
              }, 1000)
            }
            onChange={(e) => {
              setSearchInput(e.target.value);
              onSearchInput && onSearchInput(e.target.value);
            }}
          />
        </StandaloneSearchBox>

        <div className="search-icon">
          {hideSmallPlaceHolder &&
            value.length === 0 &&
            searchInput.length === 0 && (
              <span
                className="small-placeholder text-primary"
                onClick={() => ref.current.focus()}
              >
                City, Postcode or Name
              </span>
            )}
          {/* {!hideSmallPlaceHolder && (
            <img
              onClick={onSearchCurrentLocation}
              className="location-icon"
              src="assets/icons/location.png"
            />
          )} */}
          {searchInput?.length === 0 ? null : (
            <i
              className="fa fa-times mr-1"
              aria-hidden="true"
              onClick={() => setSearchInput("")}
            ></i>
          )}

          <i
            onClick={onSearchChange}
            className={`fa fa-search  ${
              !hideSmallPlaceHolder && "text-primary"
            }`}
          ></i>
        </div>
      </div>
      <GeoLocation></GeoLocation>
    </LoadScript>
  );
};

export default SearchInput;
