import React from 'react';

const IconLogout = ({ color = '#E35447', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M12.622 22.4l2.489 2.489 8.889-8.889-8.889-8.889-2.489 2.489 4.622 4.622h-17.244v3.555h17.244l-4.622 4.622zM28.444-0h-24.889c-1.956 0-3.555 1.6-3.555 3.555v7.111h3.555v-7.111h24.889v24.889h-24.889v-7.111h-3.555v7.111c0 1.956 1.6 3.555 3.555 3.555h24.889c1.956 0 3.555-1.6 3.555-3.555v-24.889c0-1.956-1.6-3.555-3.555-3.555z"></path>
    </svg>
  );
};

export default IconLogout;