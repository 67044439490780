import React from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useEffect } from "react";
import moment from "moment";

const BookingPricePopup = ({ open, onClose, bookingDetails }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "price-grid-popup" }}
    >
      <h5 className="title text-primary font-weight-bold mb-2">
        Booking Dates:
      </h5>

      <div className="list-group">
        <p className="text-primary font-weight-bold text-center text-uppercase mb-1">
          Day Dates
        </p>

        {bookingDetails?.booking_dates_day?.length > 0 ? (
          bookingDetails?.booking_dates_day?.map((item, key) => (
            <div className="list-group-item" key={key}>
              <span className="day">
                {moment(item.booked_date).format("DD-MMM-YYYY")}
              </span>

              <>
                <span className="price">
                  {" "}
                  {moment(item.booked_date).format("dddd").slice(0, 3)}{" "}
                </span>
                <span className="price">&#8356;{item.booking_price}</span>
              </>
            </div>
          ))
        ) : (
          <div className="text-center py-3"> No bookings for day</div>
        )}
      </div>

      <div className="list-group">
        <p className="text-primary font-weight-bold text-center text-uppercase mb-1">
          Night Dates
        </p>

        {bookingDetails?.booking_dates_night?.length > 0 ? (
          bookingDetails?.booking_dates_night?.map((item, key) => (
            <div className="list-group-item" key={key}>
              <span className="day">{item.booked_date}</span>

              <>
                <span className="price">
                  {moment(item.booked_date).format("dddd").slice(0, 3)}{" "}
                </span>
                <span className="price">&#8356;{item.booking_price}</span>
              </>
            </div>
          ))
        ) : (
          <div className="text-center py-3"> No bookings for night</div>
        )}
      </div>

      <p
        className="text-center text-underline close-btn"
        onClick={() => onClose(false)}
      >
        Close
      </p>
    </Modal>
  );
};

export default BookingPricePopup;
