import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdminUser,
  getAdminUserList,
  editAdminUser,
  deleteAdminUser,
} from "../../../store/homeAction";
import { SET_IS_ADMIN_USER_ADDED } from "../../../store/types";
import "./manageAdminUser.scss";
import LandlordBar from "../../../components/landlordBar/LandlordBar";
import ConfirmPopup from "../../../popups/ConfirmPopup";
import ChangePasswordPopup from "../../../popups/ChangePasswordPopup";

const ManageAdminUser = () => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const adminUsersList = useSelector((state) => state.home.adminUsersList);
  const isAdminUserAdded = useSelector((state) => state.home.isAdminUserAdded);
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const [values, setValues] = useState({});
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    dispatch(getAdminUserList({ landlord_id: userDetails.id }));
  }, []);

  useEffect(() => {
    if (isAdminUserAdded) {
      setValues({});
      setOpenAddAdmin(!openAddAdmin);

      dispatch({
        type: SET_IS_ADMIN_USER_ADDED,
        payload: false,
      });
    }
    setIsAdding(false);
  }, [isAdminUserAdded]);

  const onAddAdminUser = (e) => {
    e.preventDefault();
    setIsAdding(true);
    const firstName = values.name.split(' ').length < 2 ? values.name : values.name.split(' ').filter((v) => v !== values.name.split(' ').slice(-1)[0]).toString().replace(',', ' ');
    const lastName = values.name.split(' ').slice(-1)[0];
    const data = {
      landlord_id: userDetails.id,
      name: values.name,
      first_name: firstName,
      last_name: lastName,
      email: values.email,
      password: values.password,
    };
    dispatch(addAdminUser(data));
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangePassword = (userId) => {
    setOpenChangePassword(true);
    setUserId(userId);
  };

  const onDeleteAccount = () => {
    dispatch(deleteAdminUser({ id: userId }, userDetails.id));
  };

  return (
    <>
      <LandlordBar />
      <div className="container">
        <div className="manage-admin-wrapper">
          <h2 className="admin-title">Manage Admin Users</h2>
          <div
            className="add-admin"
            onClick={() => setOpenAddAdmin(!openAddAdmin)}
          >
            <img src="assets/images/add-icon.png" alt="" />
            <span>Add new admin account</span>
          </div>
          {openAddAdmin && (
            <form className="admin-input-group" onSubmit={onAddAdminUser}>
              <div className="admin-input-list">
                <div className="admin-input-item">
                  <h6 className="text-primary">Admin:</h6>&nbsp;
                  <input
                    type="text"
                    placeholder="Type admin name"
                    onChange={onInputChange}
                    name="name"
                    value={values.name}
                    required
                  />
                </div>
                <div className="admin-input-item">
                  <h6 className="text-primary">Email:</h6>&nbsp;
                  <input
                    type="email"
                    placeholder="Type admin email"
                    onChange={onInputChange}
                    name="email"
                    value={values.email}
                    required
                  />
                </div>
                <div className="admin-input-item">
                  <h6 className="text-primary">Password:</h6>&nbsp;
                  <input
                    type="password"
                    placeholder="Create a password"
                    onChange={onInputChange}
                    name="password"
                    value={values.password}
                    required
                  />
                </div>
              </div>

              {isAdding ? (
                <div className="create-admin">
                  <span className="fa fa-spinner fa-spin"></span>
                </div>
              ) : (
                <button type="submit" className="create-admin">
                  Create admin account
                </button>
              )}
            </form>
          )}

          {adminUsersList && adminUsersList !== undefined ? (
            adminUsersList.length !== 0 &&
            adminUsersList.map((user, key) => (
              <div className="admin-user-list">
                <div className="admin-user-item">
                  <div className="admin-user-items">
                    <span className="text-primary">Admin:</span>&nbsp;
                    <b>{`${user?.name?.length > 20 ? `${user.name.substring(0, 20)}...` : user?.name}`}</b>
                    <small className="text-primary ml-4">Last Logged in:</small>
                    &nbsp;
                    <small>Today</small>
                  </div>
                </div>
                <div className="admin-user-item">
                  <div className="admin-user-items">
                    <span className="text-primary mr-2">Email: </span>{" "}
                    {user.email.substring(0, 20) + "..."}
                  </div>
                  <div className="admin-user-items">
                    <span className="text-primary">Password:</span>
                    &nbsp;********{" "}
                    <small
                      className="admin-edit"
                      onClick={() => onChangePassword(user.id)}
                    >
                      Edit
                    </small>
                  </div>
                  <h6
                    className="remove-admin"
                    onClick={() => {
                      setOpen(true);
                      setUserId(user.id);
                    }}
                  >
                    Remove Admin Account
                  </h6>
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center p-5">
              No User Found
            </div>
          )}
        </div>
        <ChangePasswordPopup
          open={openChangePassword}
          onClose={() => setOpenChangePassword(false)}
          userId={userId}
        />
        <ConfirmPopup
          title={"Delete User"}
          description={"Are you sure you want to delete this user?"}
          open={open}
          onClose={() => setOpen(false)}
          onDelete={onDeleteAccount}
        ></ConfirmPopup>
      </div>
    </>
  );
};

export default ManageAdminUser;
