import Axios from "./axios";
import axios from "axios";
import { toast } from "react-toastify";
import {
  SET_LOADING,
  SET_LOGIN_LOADING,
  SET_REGISTER_LOADING,
  SET_OTP_LOADING,
  SET_BOOK_SPACE_LOADING,
  SET_SEARCH_RESULT,
  SET_BLOGS,
  SET_SAFE_SECTION,
  SET_TAB_SECTION,
  SET_FILTERS,
  SET_REGIONS,
  SET_PROPERTY_ATTRIBUTE_LIST,
  SET_PROPERTY_BY_USER,
  SET_PROPERTY_BY_ID,
  SET_SPACE_TYPE,
  SET_PROPERTY_SPACES,
  SET_DAY_AVAILABLE_DATES,
  SET_NIGHT_AVAILABLE_DATES,
  SET_TERMS_CONDITIONS,
  SET_LIKED_PROPERTY,
  SET_PLAN_LIST,
  SET_BOOKING_LIST,
  SET_BLOG_DETAILS,
  SET_LANDLORD_BOOKING_LIST,
  SET_CARDS_LIST,
  SET_USER_DETAILS,
  SET_BUY_PLAN_DETAILS,
  SET_ADMIN_USERS_LIST,
  SET_IS_ADMIN_USER_ADDED,
  SET_FAQ_PAGE_CONTENT,
  SET_HOME_PAGE_CONTENT,
  SET_BOOKING_DETAILS,
  SET_SHOW_CONFIRM_BOOKING_POPUP,
  SET_ADD_CARD_LOADING,
  SET_REDIRECT_URL_ON_ENQUIRE,
  SET_EARNING_REPORT,
  SET_FORGOT_PASSWORD_OTP_SENT,
  SET_FORGOT_PASSWORD_OTP_VERIFY,
  SET_COUNTRY_LIST,
  SET_STATE_LIST,
  SET_VERIFICATION_FILES,
  SET_ADD_STRIPE_ACCOUNT_LOADING,
  SET_PLAN_BUY_LOADING,
  SET_WITHDRAW_LOADING,
  SET_ADD_BANK_ACCOUNT_LOADING,
  SET_CLOSE_WITHDRAW_MODAL,
  SET_CLOSE_BANK_ACCOUNT_MODAL,
  SET_BOOKING_CONFIRM_DETAILS,
  SET_CHAT_NOTIFICATION,
  SET_KYC_DATA,
  SET_STRIPE_ACCOUNT_DATA,
  SET_PERSON_ACCOUNT_DATA,
  SET_VOUCHER_CODE,
  SET_VOUCHER_LIST_DATA,
  SET_DELETE_VOUCHER,
  SET_ALL_USERS_DATA,
  SET_CREATE_VOUCHER_CODE,
  SET_AGREEMENT_LANDLORD_DATA,
  SET_NEW_LANDLORD_DATA,
  SET_EDIT_LANDLORD_DATA,
  SET_LANDLORD_AGREEMENT_FILES,
} from "./types";
import firebase from "firebase";

import db from "../config";

const token = localStorage.getItem('access_token');
// const token_expiry = localStorage.getItem('access_token');

export const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const userDetails = JSON.parse(localStorage.getItem("os-user"));

// SET LOADING
export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};

// SET LOGIN LOADING
export const setLoginLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOGIN_LOADING,
    payload: data,
  });
};

// SET REGISTER LOADING
export const setRegisterLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_REGISTER_LOADING,
    payload: data,
  });
};

// SET OTP LOADING
export const setOtpLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_OTP_LOADING,
    payload: data,
  });
};

// SET BOOK SPACE LOADING
export const setBookSpaceLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_BOOK_SPACE_LOADING,
    payload: data,
  });
};

// ON REGISTER
export const onRegister = (data, type) => (dispatch) => {
  dispatch(setRegisterLoading(true));
  Axios.post("/user-register", data, config).then((res) => {
    if (res.data.status) {
      toast("Registered successfully", {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });

      if (type === "landlord") {
        // localStorage.setItem("os-user", JSON.stringify(res.data.user));
        window.location.href = "#/register-success";
      } else {
        window.location.href = "#/register-success";
      }

      window.location.reload();
    } else {
      dispatch(setRegisterLoading(false));
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// ON LOGIN
export const onLogin = (data) => (dispatch, getState) => {
  dispatch(setLoginLoading(true));
  Axios.post("/user-login", data, config).then(async (res) => {
    if (res.data.status) {
      localStorage.setItem("os-user", JSON.stringify(res.data.user));
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("access_token_expiry", res.data.expires_at);

      dispatch({
        type: SET_USER_DETAILS,
        payload: res.data.user,
      });

      const firebaseCurrentUser  = await firebase.auth().signInWithCustomToken(res.data.user.gf_token);

      if (firebaseCurrentUser.user.email === null) {
        firebaseCurrentUser.user.updateEmail(res.data.user.email);
      }

      dispatch(setFirebaseEntry(res.data.user));
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });

      if (getState()?.home?.redirectUrl) {
        window.location.href = `#${getState().home.redirectUrl}`;
        dispatch({
          type: SET_REDIRECT_URL_ON_ENQUIRE,
          payload: null,
        });
      } else {
        window.location.href = "#/";
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      dispatch(setLoginLoading(false));
      toast(res.data.message, {
        position: "top-center",
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

export const setFirebaseEntry = (user) => (dispatch) => {
  const rootRef = db.ref();
  const usersRef = rootRef.child(`users/${user.id}`);
  usersRef.update({
    id: user.id.toString(),
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    isOnline: true,
    photo: user.profile_pic,
  });
};

export const onSocialLogin = (data) => (dispatch, getState) => {
  dispatch(setLoginLoading(true));
  Axios.post("/social-login", data, config)
    .then((res) => {
      if (res.data.status) {
        localStorage.setItem("os-user", JSON.stringify(res.data.user));
        localStorage.setItem("access_token",res.data.access_token);
        localStorage.setItem("access_token_expiry",res.data.expires_at);
        dispatch(setFirebaseEntry(res.data.user));

        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
        window.location.href = "#/";

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

    })
    .catch((err) => {
      console.log(err);
    });
};

export const onAppleLogin = (data) => (dispatch, getState) => {
  dispatch(setLoginLoading(true));
  Axios.post("/social-login", data, config).then((res) => {
    if (res.data.status) {
      localStorage.setItem("os-user", JSON.stringify(res.data.user));
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("access_token_expiry", res.data.expires_at);

      dispatch(setFirebaseEntry(res.data.user));
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });


      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

  });
};

export const onLogout = (data) => (dispatch) => {
  Axios.post("/user-logout", data, config).then((res) => {
    if (res.data.status === true) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      localStorage.clear();
      window.location.reload();
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// ON SEARCH
export const onSearch = (data, loading) => (dispatch) => {
  if (loading === undefined) {
    dispatch(setLoading(true));
  }
  Axios.post("/search-property", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_SEARCH_RESULT,
      payload: res.data,
    });
  });
};

// GET BLOG ITEM
export const getblogSection = () => (dispatch) => {
  Axios.post("/blog-web", {}, config).then((res) => {
    dispatch({
      type: SET_BLOGS,
      payload: res.data.blogs,
    });
  });
};

// GET SAFE ITEM
export const getSafeSection = () => (dispatch) => {
  Axios.post("safe-working", {}, config).then((res) => {
    dispatch({
      type: SET_SAFE_SECTION,
      payload: res.data.data[0],
    });
  });
};

// GET TAB ITEM
export const getTabSection = () => (dispatch) => {
  Axios.post("/tab-section", {}, config).then((res) => {
    dispatch({
      type: SET_TAB_SECTION,
      payload: res.data.data,
    });
  });
};

//GET AMENITIES
export const getAmenities = () => (dispatch) => {
  Axios.get("/filters", config).then((res) => {
    dispatch({
      type: SET_FILTERS,
      payload: res.data.filter,
    });
  });
};

// GET REGIONS
export const getRegions = () => (dispatch) => {
  Axios.post("/region-list", {}, config).then((res) => {
    dispatch({
      type: SET_REGIONS,
      payload: res.data.region,
    });
  });
};

// SEND OTP
export const sendOtp = (data, type, history) => (dispatch) => {
  dispatch(setRegisterLoading(true));
  Axios.post("/send-otp", data, config)
    .then((res) => {
      dispatch(setRegisterLoading(false));
      if (res.data.status) {
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
        if (type === "landlord") {
          history.push("/landlord-verification");
        } else {
          setTimeout(() => {
            window.location.href = "#/onboarding/1";
          }, 1000);
        }
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      // toast("Please enter valid mobile number", {
      //   type: toast.TYPE.ERROR,
      //   autoClose: 10000,
      // });
    });
};

// VERIFY OTP
export const verifyOtp = (data, type, registerDetails) => (dispatch) => {
  dispatch(setOtpLoading(true));
  Axios.post("/verify-otp", data, config).then((res) => {
    dispatch(setOtpLoading(false));
    if (res.data.status) {
      toast("Code successfully verified!", {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });

      if (type === "landlord") {
        dispatch(onRegister(registerDetails, type));
      } else {
        setTimeout(() => {
          window.location.href = "#/register";
        }, 1000);
      }
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// GET PROPERTY ATTRIBUTES
export const getPropertyAttributes = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/space-attr-list", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_PROPERTY_ATTRIBUTE_LIST,
      payload: res.data.facilities,
    });
  });
};

// ADD PROPERTY
export const addProperty = (data, type) => (dispatch) => {
  Axios.post("/add-property", data, config).then((res) => {
    if (type === "update") {
      if (res.data.status) {
        toast("Property Updated Successfully!", {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      }
    } else {
      if (res.data.status) {
        toast("Property Added Successfully!", {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      }

      // if(localStorage.getItem('firstProperty') == true){
      //   window.location.href = `/add-space/${item.id}`
      // }
    }

    window.location.href = "#/add-property/3";
  });
};

// GET PROPERTY BY USER
export const getPropertyByUser = (data, history) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/get-properties", data, config).then((res) => {
    dispatch(setLoading(false));
    if (res?.data?.data && res?.data?.data?.length === 0) {
      history.push("/add-property/1");
    }
    dispatch({
      type: SET_PROPERTY_BY_USER,
      payload: res.data.data,
    });
  });
};

// GET PROPERTY BY ID
export const getPropertyById = (data, loading) => (dispatch) => {
  if (loading === undefined) {
    dispatch(setLoading(true));
  }

  Axios.post("/get-property", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_PROPERTY_BY_ID,
      payload: (res?.data?.data?.length > 0 && res.data.data[0]) || null,
    });
  });
};

// GET SPAVE TYPE
export const getSpaceType = (data) => (dispatch) => {
  Axios.post("/space-type", data, config).then((res) => {
    dispatch({
      type: SET_SPACE_TYPE,
      payload: res.data.data,
    });
  });
};

// ADD SPACE
export const addSpace = (data, type) => (dispatch) => {
  dispatch(setLoading(true));
  // Create a new FormData object
  const filteredData = new FormData();

  // Iterate over the entries of the original FormData object
  for (let [key, value] of data.entries()) {
    if (value === 'undefined') {
      // Add the entry to the filtered FormData object if the value is not undefined
      value = ''
    }
    filteredData.append(key, value);
  }

  Axios.post("/add-space", filteredData, config).then((res) => {
    if (res.data.status === true) {
      if (type === "update") {
        toast("Space Updated successfully!", {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
      } else {
        toast("Space added successfully!", {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
      }
      window.location.href = "#/add-property/3";
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
    dispatch(setLoading(false));
  });
};

// GET PROPERTY SPACES
export const getPropertySpaces = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/get-spaces", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_PROPERTY_SPACES,
      payload: res.data.data,
    });
  });
};

// ON LIKE
export const onLike = (data, searchData, type) => (dispatch) => {
  Axios.post("/add-to-wishlist", data, config).then((res) => {
    dispatch(onSearch(searchData, "noLoading"));

    type === "likedLocation" && dispatch(getLikedProperty(data));
    type === "property-details" && dispatch(getPropertyById(data, "noLoading"));
  });
};

// ON UNLIKE
export const onUnlike = (data, searchData, type) => (dispatch) => {
  Axios.post("/remove-from-wishlist", data, config).then((res) => {
    dispatch(onSearch(searchData, "noLoading"));

    type === "likedLocation" && dispatch(getLikedProperty(data));
    type === "property-details" && dispatch(getPropertyById(data, "noLoading"));
  });
};

// GET AVAILABLE DATES
export const getAvailableDates = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/avaliable-date", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_DAY_AVAILABLE_DATES,
      payload: res?.data?.data?.day_price,
    });
    dispatch({
      type: SET_NIGHT_AVAILABLE_DATES,
      payload: res?.data?.data?.night_price,
    });
  });
};

// GET TERMS AND CONDITIONS
export const getTermsConditions = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/terms", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_TERMS_CONDITIONS,
      payload: res.data.data,
    });
  });
};

// GET LIKED PROPERTY
export const getLikedProperty = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/wishlists", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_LIKED_PROPERTY,
      payload: res.data.property,
    });
  });
};

// GET PLAN LIST
export const getPlanLists = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/plans", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_PLAN_LIST,
      payload: res.data.plans,
    });
  });
};

// GET MY BOOKING LIST
export const getBookingList = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/my-booking", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_BOOKING_LIST,
      payload: res.data.data,
    });
  });
};

export const addRating = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/add-rating", data, config).then((res) => {
    dispatch(setLoading(false));
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
}

// GET BLOG DETAILS
export const getBlogDetails = (data) => (dispatch) => {
  Axios.post("/blog", data, config).then((res) => {
    dispatch({
      type: SET_BLOG_DETAILS,
      payload: res.data.blog,
    });
  });
};

// GET LANDLORD BOOKING LIST
export const getLandlordBookingList = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/landlord-bookings", data, config).then((res) => {
    dispatch(setLoading(false));
    dispatch({
      type: SET_LANDLORD_BOOKING_LIST,
      payload: res.data.data,
    });
  });
};

// DELETE PROPERTY
export const deleteProperty = (data, history) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/delete-property", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(getPropertyByUser({ user_id: userDetails.id }, history));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// DELETE SPACE
export const deleteSpace = (data, history) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/delete-space", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(getPropertyByUser({ user_id: userDetails.id }, history));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// GET CARD LIST
export const getCardList = (data) => (dispatch) => {
  Axios.post("/all-cards", data, config).then((res) => {
    dispatch({
      type: SET_CARDS_LIST,
      payload: res?.data?.data?.data,
    });
  });
};

// ADD CARD
export const addCard = (data, user) => (dispatch) => {
  dispatch({
    type: SET_ADD_CARD_LOADING,
    payload: true,
  });
  data.user_id = userDetails.id;
  Axios.post("/add-card", data, config)
    .then((res) => {
      if (res.data.status) {
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
        dispatch(getCardList(user));
        window.location.reload();
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      }

      dispatch({
        type: SET_ADD_CARD_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      toast(err?.response?.data?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });

      dispatch({
        type: SET_ADD_CARD_LOADING,
        payload: false,
      });
    });
};

// DELETE CARD
export const deleteCard = (data, user) => (dispatch) => {
  Axios.post("/delete-card", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(getCardList(user));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

export const setBuyPlanLoading = (boolen) => (dispatch) => {
  dispatch({
    type: SET_PLAN_BUY_LOADING,
    payload: boolen,
  });
};


// BUY PACKAGE
export const buyPlan = (data) => (dispatch) => {
  dispatch(setBuyPlanLoading(true));
  Axios.post("/buy-plan", data, config).then((res) => {
    if (res.data.status) {
      dispatch({
        type: SET_BUY_PLAN_DETAILS,
        payload: res.data.data,
      });

      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(getUserDetails({ user_id: userDetails.id }));

      dispatch(setBuyPlanLoading(false));
      window.location.href = "#/plan-success";
    } else {
      dispatch(setBuyPlanLoading(false));
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// GET USER DETAILS
export const getUserDetails = (data) => (dispatch) => {
  Axios.post("/get-user-info", data, config)
      .then((res) => {
        userDetails.user_role_type = res.data.data.user_type;
        userDetails.stripe_account = res.data.data.stripe_account;
        userDetails.new = true;
        localStorage.setItem("os-user", JSON.stringify(userDetails));
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("access_token_expiry", res.data.expires_at);
        dispatch({
          type: SET_USER_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Clear os-user and access_token
          localStorage.removeItem("os-user");
          localStorage.removeItem("access_token");
          localStorage.removeItem("access_token_expiry");
        }
      });
};


// UPDATE USER DETAILS
export const updateUser = (data) => (dispatch) => {
  Axios.post("/update-user", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }

    localStorage.setItem("os-user", JSON.stringify(res.data.user));
    localStorage.setItem("access_token", res.data.access_token);
    localStorage.setItem("access_token_expiry", res.data.expires_at);

    window.location.reload();
  });
};

// UPDATE USER PROFILE IMAGE
export const updateImage = (data) => (dispatch) => {
  Axios.post("/update-image", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }

    localStorage.setItem("os-user", JSON.stringify(res.data.user));
    localStorage.setItem("access_token", JSON.stringify(res.data.access_token));
    localStorage.setItem("access_token_expiry", JSON.stringify(res.data.expires_at));

    window.location.reload();
  });
};

// ADD ADMIN USER
export const addAdminUser = (data) => (dispatch) => {
  Axios.post("/register-staff", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch({
        type: SET_IS_ADMIN_USER_ADDED,
        payload: true,
      });
      dispatch(getAdminUserList({ landlord_id: data.landlord_id }));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      dispatch({
        type: SET_IS_ADMIN_USER_ADDED,
        payload: false,
      });
      setTimeout(() => {
        dispatch({
          type: SET_IS_ADMIN_USER_ADDED,
          payload: null,
        });
      }, 10);
    }
  });
};

// GET ADMIN USER
export const getAdminUserList = (data) => (dispatch) => {
  Axios.post("/get-staff", data, config).then((res) => {
    dispatch({
      type: SET_ADMIN_USERS_LIST,
      payload: res.data.data,
    });
  });
};

// EDIT ADMIN USER
export const editAdminUser = (data) => (dispatch) => {
  Axios.post("/edit-staff", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// DELETE ADMIN USER
export const deleteAdminUser = (data, userId) => (dispatch) => {
  Axios.post("/delete-staff", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(getAdminUserList({ landlord_id: userId }));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// DELETE ACCOUNT
export const deleteAccount = (data) => (dispatch) => {
  Axios.post("/user-inactive", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(getAdminUserList({ landlord_id: data.id }));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// LANDLORD BOOKING
export const landlordBookingAction = (data, formData) => (dispatch) => {
  Axios.post("/booking-actions", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }

    dispatch(getLandlordBookingList(formData));
  });
};

// CHANGE PASSWORD
export const changePassword = (data, loading, close) => (dispatch) => {
  loading(true)
  Axios.post("/change-password", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      close()
      loading(false)
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      loading(false)
    }
  });
};

// FAQ PAGE
export const getPageContent = (data) => (dispatch) => {
  Axios.post("/pages", data, config).then((res) => {
    dispatch({
      type: SET_FAQ_PAGE_CONTENT,
      payload: res.data.data,
    });
  });
};

// Home Page Content
export const getHomePageContent = (data) => (dispatch) => {
  Axios.post("/pages", data, config).then((res) => {
    dispatch({
      type: SET_HOME_PAGE_CONTENT,
      payload: res.data.data,
    });
  });
};

// GET USER DETAILS BY TOKEN
export const verifyUser = (data) => (dispatch) => {
  Axios.post("/verify-user", data, config).then((res) => {
    // localStorage.setItem("os-user", JSON.stringify(res.data.user));
    dispatch({
      type: SET_USER_DETAILS,
      payload: res.data.data,
    });
    // Set the data after verifying the user
    if(res.data.user) {
      localStorage.setItem("os-user", JSON.stringify(res.data.user));
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("access_token_expiry", res.data.expires_at);
    }

    window.location.reload();
  });
};

// BOOK SPACE
export const bookSpace =
  (data, address, spaceDetails, country) => (dispatch) => {
    dispatch({
      type: SET_ADD_CARD_LOADING,
      payload: true,
    });
    try {
      dispatch(setBookSpaceLoading(true));
      Axios.post("/book-space", data, config).then((res) => {
        const bookingId = res && res.data && res.data.data && res.data.data.id;
        if (res.data.status) {
          address.booking_id = res.data.data.id;
          address.country = country;
          address.type = "direct_payment";

          if (spaceDetails.booking_approval === true) {
            // Enquiry Type Booking
            dispatch(bookingEnquiry(address));
            dispatch(saveSingature(bookingId));
            localStorage.removeItem("signature");
          } else {
            // Not Enquiry Type Booking
            dispatch({
              type: SET_BOOKING_DETAILS,
              payload: res.data.data,
            });
            dispatch(bookingPayment(address, spaceDetails));
            dispatch(saveSingature(bookingId));
            localStorage.removeItem("signature");
          }
        } else {
          toast(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
          dispatch({
            type: SET_ADD_CARD_LOADING,
            payload: false,
          });
        }
      });
    } catch (error) {
      dispatch(setBookSpaceLoading(false));
    }
  };

// BOOKING ENQUIRY
export const bookingEnquiry = (address) => (dispatch) => {
  Axios.post("/booking-enquiry", address, config).then((res) => {
    if (res.data.status) {
      // toast(res.data.message, {
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 10000,
      // });
      dispatch({
        type: SET_SHOW_CONFIRM_BOOKING_POPUP,
        payload: true,
      });
      dispatch({
        type: SET_ADD_CARD_LOADING,
        payload: false,
      });
      dispatch(setBookSpaceLoading(false));
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      dispatch({
        type: SET_ADD_CARD_LOADING,
        payload: false,
      });
      dispatch(setBookSpaceLoading(false));
    }
  });
};

// BOOKING PAYMENT
export const bookingPayment = (address, spaceDetails) => (dispatch) => {
  Axios.post("/booking-payment", address, config)
    .then((res) => {
      if (res.data.status) {
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 10000,
        });
        dispatch({
          type: SET_BOOKING_CONFIRM_DETAILS,
          payload: res.data,
        });

        window.location.href = `#/booking-confirm/${spaceDetails.property_id}/${spaceDetails.id}`;
        // window.location.reload();
        dispatch({
          type: SET_ADD_CARD_LOADING,
          payload: false,
        });
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
        dispatch({
          type: SET_ADD_CARD_LOADING,
          payload: false,
        });
      }
    })
    .catch((err) => {
      toast(err?.response?.data?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });

      dispatch({
        type: SET_ADD_CARD_LOADING,
        payload: false,
      });
    });
};

// ENQUIRY PAYMENT
export const enquiryPayment = (data, spaceDetails) => (dispatch) => {
  Axios.post("/booking-payment", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch({
        type: SET_BOOKING_CONFIRM_DETAILS,
        payload: res.data.data,
      });
      window.location.href = `#/booking-confirm/${spaceDetails.property_id}/${spaceDetails.id}`;
      // window.location.reload();
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// CANCEL BOOKING
export const cancelBooking = (data, stopLoading) => (dispatch) => {
  Axios.post("/cancel-booking", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      window.location.reload();
      stopLoading();
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      stopLoading();
    }
  });
};

// SAVE SIGNATURE
export const saveSingature = (bookingId) => (dispatch) => {
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  Axios.post(
    "/signature",
    {
      user_id: userDetails && userDetails.id,
      signature: localStorage.getItem("signature"),
      booking_id: bookingId,
    },
    config
  ).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// get earning report landlord
export const getEarningReport = (data) => (dispatch) => {
  setLoading(true);
  Axios.post("/earning-report", data, config).then((res) => {
    setLoading(false);
    dispatch({
      type: SET_EARNING_REPORT,
      payload: res.data.data,
    });
  });
};

export const setWithdrawLoading = (boolen) => (dispatch) => {
  dispatch({ type: SET_WITHDRAW_LOADING, payload: boolen });
};

// Send withdraw request
export const sendWithdrawRequest = (data) => (dispatch) => {
  dispatch(setWithdrawLoading(true));
  Axios.post("/withdrawal-request", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch(setWithdrawLoading(false));
      dispatch({
        type: SET_CLOSE_WITHDRAW_MODAL,
        payload: true,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      dispatch(setWithdrawLoading(false));
    }
  });
};

// Send forgot password otp
export const sendForgotPasswordOtp = (data) => (dispatch) => {
  setLoading(true);
  Axios.post("/send-forget-otp", data, config).then((res) => {
    if (res.data.status) {
      dispatch({
        type: SET_FORGOT_PASSWORD_OTP_SENT,
        payload: true,
      });
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      localStorage.setItem("os_forgot_password_user_id", res.data.otp.user_id);
      localStorage.setItem("os_forgot_password_phone", res.data.phone);
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// Verify forgot password otp
export const verifyForgotPasswordOtp = (data) => (dispatch) => {
  setLoading(true);
  Axios.post("/verify-forget-otp", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      dispatch({
        type: SET_FORGOT_PASSWORD_OTP_VERIFY,
        payload: true,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// Update with new password
export const createNewPassword = (data) => (dispatch) => {
  setLoading(true);
  Axios.post("/forget-password", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      window.location.reload();
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

export const setAddStripeAccountLoading = (boolen) => (dispatch) => {
  dispatch({ type: SET_ADD_STRIPE_ACCOUNT_LOADING, payload: boolen });
};

// Create the stripe account

export const addAccountInStripe =
    (params, history, personData) => async (dispatch) => {

      dispatch(setAddStripeAccountLoading(true));

      let object = {};
      for(let pair of params.entries()) {
        let value = pair[1];
        if(value === 'undefined') {
          value= '';
        }
        object[pair[0]] = value;
      }

      params = object;

      try {
        const response = await Axios
            .post("/create-stripe-account", {params, personData}, config)
            .then((res) => {
              if (res.data) {
                if (personData) {
                  dispatch(createPersonInStripe(personData, res.data.id));
                  dispatch(
                      onBoardingPendingReason({
                        landlord_id: userDetails && userDetails.id,
                        reason: res.data.requirements.currently_due[1],
                      })
                  );
                } else {
                  dispatch(addStripeAccount(res.data.id));
                  dispatch(
                      onBoardingPendingReason({
                        landlord_id: userDetails && userDetails.id,
                        reason: res.data.requirements.currently_due[0],
                      })
                  );
                }
              } else {
                dispatch(setAddStripeAccountLoading(false));
                toast(res.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 10000,
                });
              }
              dispatch({
                type: SET_STRIPE_ACCOUNT_DATA,
                payload: res.data,
              });
            });
      } catch (error) {
        dispatch(setAddStripeAccountLoading(false));
        // console.log(error.response.data);
        toast(error.response.data.error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      }
    };

export const updateBankAccountInStripe = (params, type, history) => async (dispatch) => {

  dispatch(setAddBankAccountLoading(true));

  let object = {};
  for(let pair of params.entries()) {
    let value = pair[1];
    if(value === 'undefined') {
      value= '';
    }
    object[pair[0]] = value;
  }

  params = object;

  console.log('updateBankAccountInStripe')
  console.log(params);
  Axios
      .post('/stripe-add-bank-account', { params, userDetails }, config)
      .then(res => {
        console.log(params)
        // Handle the response
        if (res.data) {
          let accountId;
          let verified = true;
          dispatch(addStripeAccount((accountId = null), (verified = true)));
        } else {
          toast(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
          dispatch(setAddBankAccountLoading(false));
        }
      })
      .catch(error => {
        console.log('Stripe Add Bank Account Error: ' + error.response.data);
        dispatch(setAddBankAccountLoading(false));
        toast(error.response.data.error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      });
};

export const updateSpaces = (params, type, history) => async (dispatch) => {

  dispatch(setAddBankAccountLoading(true));

  let object = {};
  for(let pair of params.entries()) {
    let value = pair[1];
    if(value === 'undefined') {
      value= '';
    }
    object[pair[0]] = value;
  }

  params = object;

  console.log('updateBankAccountInStripe')
  console.log(params);
  Axios
      .post('/stripe-add-bank-account', { params, userDetails }, config)
      .then(res => {
        // Handle the response
        if (res.data) {
          let accountId;
          let verified = true;
          dispatch(addStripeAccount((accountId = null), (verified = true)));
        } else {
          toast(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
          dispatch(setAddBankAccountLoading(false));
        }
      })
      .catch(error => {
        dispatch(setAddBankAccountLoading(false));
        toast(error.response.data.error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      });
};

export const createPersonInStripe = (personData, accountId) => async (dispatch) => {

  setLoading(true);

  Axios
      .post('stripe-create-person', { personData, accountId }, config)
      .then(res => {
        // Handle the response
        if (res.data) {
          dispatch(addStripeAccount(accountId));
          dispatch({
            type: SET_PERSON_ACCOUNT_DATA,
            payload: res.data,
          });
        } else {
          toast(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
        }
      })
      .catch(error => {
        toast(error.response.data.error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      });
};

// Update with new password
export const addStripeAccount = (accountId, verified) => (dispatch) => {
  setLoading(true);
  const data = {
    user_id: userDetails.id,
  };
  if (accountId) {
    data.stripe_account = accountId;
  }
  if (verified) {
    data.bank_account = true;
    data.stripe_verified = true;
  }
  Axios.post("/add-stripe-account", data, config).then((res) => {
    if (res.data.status) {
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });

      // While create stripe account
      if (accountId) {
        dispatch(setAddStripeAccountLoading(false));
        window.location.href = "#/plan-list";
      } else if (verified) {
        // While add bank account and get verified on stripe
        dispatch(setAddStripeAccountLoading(false));
        dispatch(setAddBankAccountLoading(false));
        dispatch({
          type: SET_CLOSE_BANK_ACCOUNT_MODAL,
          payload: true,
        });
      }
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

export const getCountryList = (data) => (dispatch) => {
  setLoading(true);
  Axios.post("/country-list", data, config).then((res) => {
    setLoading(false);
    dispatch({
      type: SET_COUNTRY_LIST,
      payload: res.data.countries,
    });
  });
};

export const getStateList = (data) => (dispatch) => {
  setLoading(true);
  Axios.post("/state-list", data, config).then((res) => {
    setLoading(false);
    dispatch({
      type: SET_STATE_LIST,
      payload: res.data.states,
    });
  });
};

export const createFileLink = (data, type) => async (dispatch) => {

      const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    };

  // build the formData from data object passed to this function
  const formData = new FormData();
  formData.append("file", data.get('file'));
  formData.append("purpose", data.get('purpose'));

  setLoading(true);
  try {
    const response = await Axios
      .post("/stripe-get-file-link", formData, config)
      .then((res) => {
        if (res.data) {
          const fileData = {
            type: type,
            id: res.data.id,
          };
          dispatch({
            type: SET_VERIFICATION_FILES,
            payload: fileData,
          });
        } else {
          toast(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
        }
      });
  } catch (error) {
    toast(error.response.data.error.message, {
      type: toast.TYPE.ERROR,
      autoClose: 10000,
    });
  }
};

export const onVerificationSession = (params) => async (dispatch) => {
  Axios
      .post('/stripe-verification-session', params, config)
      .then(res => {
        // Handle the response
        dispatch({
          type: SET_KYC_DATA,
          payload: res.data,
        });
      })
      .catch(error => {
        toast(error.response.data.error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      });
};


// STRIPE ACCOUNT KYC
export const stripeAccountKyc = (data) => (dispatch) => {
  Axios.post("/stripe-account-kyc", data, config).then((res) => {
    console.log(res);
  });
};

// CHAT NOTIFICATION
export const onChatNotification = (data) => (dispatch) => {
  Axios.post("/notifications", data, config).then((res) => {
    if (res.data.status) {
      dispatch({
        type: SET_CHAT_NOTIFICATION,
        payload: res.data,
      });
    }
  });
};

// CHAT NOTIFICATION
export const sendEnquiryEmail = (data) => (dispatch) => {
  Axios.post("/send-enquiry-mail", data, config).then((res) => {
    console.log(res);
  });
};

export const setAddBankAccountLoading = (boolen) => (dispatch) => {
  dispatch({ type: SET_ADD_BANK_ACCOUNT_LOADING, payload: boolen });
};

// APPLY VOUCHER CODE
export const onVoucherCode = (data) => (dispatch) => {
  Axios.post("/apply-voucher-code", data, config).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: SET_VOUCHER_CODE,
        payload: res.data.data,
      });
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// ALL LANDLORD LIST

export const getAllAgreementLandlordData = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/agreements/all-landlords", data, config).then((res) => {
    dispatch({
      type: SET_AGREEMENT_LANDLORD_DATA,
      payload: res.data.data,
    });
    dispatch(setLoading(false));
  });
};

export const createLandlord = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/agreements/add-landlord", data, config).then((res) => {
    dispatch({
      type: SET_NEW_LANDLORD_DATA,
      payload: res.data.data,
    });
    dispatch(setLoading(false));
  });
};

export const editLandlord = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/agreements/edit-landlord", data, config).then((res) => {
    dispatch({
      type: SET_EDIT_LANDLORD_DATA,
      payload: res,
    });
    dispatch(setLoading(false));
  });
};

export const getAgreementFiles = (data) => (dispatch) => {
  dispatch(setLoading(true));
  Axios.post("/agreements/files", data, config).then((res) => {
    dispatch({
      type: SET_LANDLORD_AGREEMENT_FILES,
      payload: res?.data,
    });
    dispatch(setLoading(false));
  });
}
// VOUCHER CODE LIST
export const getAllVoucherData = (data) => (dispatch) => {
  Axios.post("/all-vouchers", data, config).then((res) => {
    dispatch({
      type: SET_VOUCHER_LIST_DATA,
      payload: res.data.data,
    });
  });
};

// DELETE VOUCHER
export const deleteVoucherData = (data) => (dispatch) => {
  Axios.post("/delete-voucher", data, config).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: SET_DELETE_VOUCHER,
        payload: res.data,
      });
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// ALL USERS LIST
export const getAllUsersData = (data) => (dispatch) => {
  Axios.post("/all-users-list", data, config).then((res) => {
    dispatch({
      type: SET_ALL_USERS_DATA,
      payload: res.data.data,
    });
  });
};

// CREATE VOUCHER CODE
export const createVoucherCode = (data, onCloseModel) => (dispatch) => {
  Axios.post("/add-edit-voucher", data, config).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: SET_CREATE_VOUCHER_CODE,
        payload: res.data,
      });
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      onCloseModel();
    } else {
      toast(res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  });
};

// LANDLORD USER CONNECTION
export const landlordUserConnection = (data) => (dispatch) => {
  Axios.post("/landlord-user-connection", data, config).then((res) => {
    console.log(res);
  });
};

// ONBOARDING PENDING REASON
export const onBoardingPendingReason = (data) => (dispatch) => {
  Axios.post("/onboarding-pending-reason", data, config).then((res) => {
    console.log(res);
  });
};
