import React, { useState } from "react";
import "./popup.scss";
import { useHistory } from "react-router-dom";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const BookingConfirmPopup = ({ open, onClose, bookingDetails }) => {
  const history = useHistory();
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "booking-confirm-popup-modal text-center" }}
    >
      <h5 className="text-primary font-weight-bold">
        Hey There <span className="text-primary">!</span>
      </h5>
      <div className="details py-4">
        Thank you for your enquiry. Your booking will be confirmed as soon as
        the landlord approves it. <br />
        <br />
        You can view your pending bookings via the My OfficeShare
      </div>
      <div className="btns d-flex justify-content-center">
        <div
          className="enquiry-status-btn"
          onClick={() => history.push("/mydashboard/2")}
        >
          View enquiry status
        </div>
      </div>
    </Modal>
  );
};

export default BookingConfirmPopup;
