import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertySpaces,
  getPropertyById,
  bookSpace,
  bookingPayment,
  getCardList,
  getUserDetails,
  getCountryList,
  getStateList,
  onVoucherCode,
} from "../../store/homeAction";
import {
  SET_SHOW_CONFIRM_BOOKING_POPUP,
  SET_BOOK_SPACE_DETAILS,
  SET_BOOK_SPACE_LOADING,
  SET_ADD_CARD_LOADING,
} from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import "./userBooking.scss";
import StripePaymentBooking from "../../components/stripePayment/StripePaymentBooking";
import { ToastContainer, toast } from "react-toastify";
import BookingConfirmPopup from "../../popups/BookingConfirmPopup";
import DatesModalPopup from "../../popups/DatesModalPopup";
import BillingAddressForm from "./BillingAddressForm";
import moment from "moment";
import SignatureCanvas from "./SignatureCanvas";

let inputProps = {
  placeholder: "DOB",
};

const UserBookingSeven = ({ match }) => {
  let signedPad = useRef({});
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState({});
  const [country, setCountry] = useState("United Kingdom");
  const [modalOpen, setModalOpen] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);
  const [loadingCardId, setLoadingCardId] = useState(null);
  const [countrySelect, setCountrySelect] = useState("");
  const [voucherCode, setVoucherCode] = useState();
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [detailsAreCorrect, setDetailsAreCorrect] = useState(false);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState(false);
  const [terms, setTerms] = useState(false);

  const cardsList = useSelector((state) => state.home.cardsList);
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const bookSpaceLoading = useSelector((state) => state.home.bookSpaceLoading);
  const bookingDetails = useSelector((state) => state.home.bookingDetails);
  const userDetails = useSelector((state) => state.home.userDetails);
  const isLoading = useSelector((state) => state.home.isLoading);
  const countryList = useSelector((state) => state.home.countryList);
  const stateList = useSelector((state) => state.home.stateList);
  
  const appliedVoucherCode = useSelector(
    (state) => state.home.appliedVoucherCode
  );
  const showConfirmBookingPopup = useSelector(
    (state) => state.home.showConfirmBookingPopup
  );

  //console.log(appliedVoucherCode);

  const userDetail = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    dispatch(getUserDetails({ user_id: userDetail.id }));
    dispatch(getStateList({ country_id: 230 }));
    getPropertyList();
    getSpaceList();
    dispatch(getCountryList());

    dispatch({
      type: SET_BOOK_SPACE_LOADING,
      payload: false,
    });
    dispatch({
      type: SET_ADD_CARD_LOADING,
      payload: false,
    });
  }, [match]);

  useEffect(() => {
    if (bookSpaceDetails) {
      const { daySelectedDates, nightSelectedDates } = bookSpaceDetails;
      const spaceList = propertySpaces;

      let price = 0;
      const spaceDetail = getSpaceDetail(spaceList);
      const { space_days_price_list } = spaceDetail;
      if (spaceDetail && space_days_price_list) {
        if (spaceDetail.availability_type === 1) {
          // for day
          for (let i = 0; i < daySelectedDates.length; i++) {
            const todayDayName = moment(daySelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].price);
              }
            }
          }
        } else if (spaceDetail.availability_type === 2) {
          // for night
          for (let i = 0; i < nightSelectedDates.length; i++) {
            const todayDayName = moment(nightSelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].night_price);
              }
            }
          }
        } else {
          // for day and night
          for (let i = 0; i < daySelectedDates.length; i++) {
            const todayDayName = moment(daySelectedDates[i])
              .format("dddd")
              .slice(0, 3);
            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].price);
              }
            }
          }

          for (let i = 0; i < nightSelectedDates.length; i++) {
            const todayDayName = moment(nightSelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].night_price);
              }
            }
          }
        }
      }

      if(price <= 0) {
        price = parseInt(spaceDetail.price);
      }

      setCalculatedPrice(price);
    }
  }, []);

  const getSpaceDetail = () => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          return spaceList[i];
        }
      }
    }
  };

  // useEffect(() => {
  //   if (countryList) {
  //     for (let i = 0; i < countryList.length; i++) {
  //       const element = countryList[i];
  //       if (element.id === 230) {
  //         setCountrySelect(element);
  //       }
  //     }
  //   }
  // }, [countryList]);

  const onInputChange = (e) => {
    if (
      e.target.value.length > 0 &&
      e.target.name === "first_name" &&
      /^[A-Za-z ]+$/.test(e.target.value) === false
    ) {
      return;
    }

    if (
      e.target.value.length > 0 &&
      e.target.name === "last_name" &&
      /^[A-Za-z ]+$/.test(e.target.value) === false
    ) {
      return;
    }

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onCountryChange = (e) => {
    setCountry(e.target.value);

    if (countryList) {
      for (let i = 0; i < countryList.length; i++) {
        if (countryList[i].name === e.target.value) {
          dispatch(getStateList({ country_id: countryList[i].id }));
        }
      }
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.address) {
      setValues(userDetails.address);
    }
  }, [userDetails]);

  useEffect(() => {
    if (showConfirmBookingPopup) {
      setIsConfirmPopupOpened(showConfirmBookingPopup);

      dispatch({
        type: SET_SHOW_CONFIRM_BOOKING_POPUP,
        payload: false,
      });

      dispatch({
        type: SET_BOOK_SPACE_DETAILS,
        payload: null,
      });
    }
  }, [showConfirmBookingPopup]);

  useEffect(() => {
    if (bookSpaceDetails === undefined) {
      history.push(`/space/${match.params.propertyId}/${match.params.spaceId}`);
    }
  }, []);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  useEffect(() => {
    if (userDetails) {
      setValues(userDetails);
      const data = {
        customer_id: userDetails.customer_id,
      };
      dispatch(getCardList(data));
    }
  }, [userDetails]);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const isNotAllDataSupplied = () => {
    return !values?.first_name ||
      !values?.last_name ||
      !values?.email ||
      !values?.phone ||
      !values?.address_1 ||
      !values?.city ||
      !values?.state ||
      !country ||
      !values?.postcode ||
      !terms ||
      !localStorage.getItem("signature")
  }

  const onNext = (cardDetails) => {
    if (isNotAllDataSupplied()) {
      toast("All Fields are required!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return;
    }

    if (detailsAreCorrect) {
      const data = {
        property_id: match.params.propertyId,
        space_id: match.params.spaceId,
        user_id: bookSpaceDetails.user_id,
        number_of_desk: bookSpaceDetails.numberOfDesks,
        desk_price: appliedVoucherCode
          ? calculatedPrice * bookSpaceDetails?.numberOfDesks.toLocaleString() -
            (appliedVoucherCode &&
              appliedVoucherCode.discount *
                calculatedPrice *
                bookSpaceDetails?.numberOfDesks.toLocaleString()) /
              100
          : calculatedPrice * bookSpaceDetails?.numberOfDesks.toLocaleString(),
        is_recurring: true,
        request_together: bookSpaceDetails.request_together,
      };

      data.date = bookSpaceDetails.daySelectedDates;
      data.night_date = bookSpaceDetails.nightSelectedDates;
      data.period_of_day = spaceDetails.availability_type;
      data.signature = localStorage.getItem("signature");

      if (cardDetails) {
        values.src_token = cardDetails.src_token;
        values.new = cardDetails.new;
      }

      dispatch(bookSpace(data, values, spaceDetails, country));
    } else {
      toast("Please confirm all the above details are correct", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  };

  const onBookSpace = (details, type) => {
    if (isNotAllDataSupplied()) {
      toast("All Fields are required!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return;
    }

    if (detailsAreCorrect) {
      setLoadingCardId(details.id);

      let cardDetails;
      if (type === "newCard") {
        cardDetails = {
          new: true,
          src_token: details.id,
        };
      } else {
        cardDetails = {
          new: false,
          src_token: details.id,
        };
      }

      onNext(cardDetails);
    } else {
      toast("Please confirm all the above details are correct", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  };

  const applyVoucherCode = () => {
    const data = {
      user_id: userDetail && userDetail.id,
      voucher_code: voucherCode,
    };

    dispatch(onVoucherCode(data));
  };

  const changeVoucherCode = (e) => {
    setVoucherCode(e.target.value);
  };

  const clearPad = () => {
    signedPad.current.clear();
    localStorage.removeItem("signature");
  };

  const savePad = () => {
    localStorage.setItem("signature", signedPad.current.toDataURL());
  };

  return (
    <div className="user-detail-with-background">
      <div className="container mt-5">
        <div className="user-details-wrapper">
          <div className="user-booking">
            <div className="user-booking-main-wrapper">
              <div className="user-booking-main">
                <div className="user-left">
                  <Link to={`/search`} className="back-btn">
                    Cancel
                  </Link>

                  <div className="address-wrapper pt-5">
                    <h4>Billing Address</h4>
                    <form className="mt-3">
                      <div className="address-form">
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            value={values?.first_name}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            value={values?.last_name}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={values?.email}
                            onChange={onInputChange}
                            required
                          />
                        </div>

                        <div className="input-group">
                          <input
                            type="number"
                            placeholder="Your Mobile Number"
                            name="phone"
                            value={values?.phone}
                            onChange={onInputChange}
                            onWheel={(e) => e.target.blur()}
                            required
                          />
                        </div>
                        {/* <div className="input-group">
                          <Datetime
                            id="date-input"
                            dateFormat={"DD-MM-YYYY"}
                            timeFormat={false}
                            value={values.dob}
                            onChange={(date) =>
                              setValues({ ...values, dob: date._d })
                            }
                            placeholder="dob"
                            inputProps={inputProps}
                          />
                        </div> */}

                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Address Line 1"
                            name="address_1"
                            value={values?.address_1}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Address Line 2"
                            name="address_2"
                            value={values?.address_2}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <select
                            type="text"
                            value={country}
                            onChange={onCountryChange}
                            required
                          >
                            {countryList &&
                              countryList.map((country, key) => (
                                <option key={key} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="input-group">
                          <select
                            type="text"
                            name="state"
                            value={values?.state}
                            onChange={onInputChange}
                            required
                          >
                            <option value="">Select</option>
                            {stateList &&
                              stateList.map((state, key) => (
                                <option key={key} value={state.sortname}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                        </div>

                        {/* <div className="input-group">
                          <input
                            type="text"
                            placeholder="Country"
                            name="country"
                            value={values.country}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="State"
                            name="state"
                            value={values.state}
                            onChange={onInputChange}
                            required
                          />
                        </div> */}
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="City"
                            name="city"
                            value={values?.city}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Postcode"
                            name="postcode"
                            value={values?.postcode}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group d-flex align-items-center m-0">
                        <input
                          id="terms"
                          type="radio"
                          className="form-control-md"
                          onClick={() => setTerms(!terms)}
                          checked={terms}
                        />
                        <label htmlFor="terms" className="four-title mx-1 my-0">
                          Agree to{" "}
                          <Link to="/terms" target="_blank">
                            {" "}
                            Terms and Conditions
                          </Link>
                        </label>
                      </div>

                      <div>
                        <div className="form-group d-flex align-items-center m-0">
                          <input
                            id="terms"
                            type="radio"
                            className="form-control-md"
                            onClick={() =>
                              setDetailsAreCorrect(!detailsAreCorrect)
                            }
                            checked={detailsAreCorrect}
                          />
                          <label
                            htmlFor="terms"
                            className="four-title mx-1 my-0"
                          >
                            I confirm all the above details are correct
                          </label>
                        </div>
                      </div>
                    </form>
                    <br />
                    <br />
                    <br />
                  </div>

                  <SignatureCanvas
                    clearPad={clearPad}
                    savePad={savePad}
                    signedPad={signedPad}
                  />

                  {spaceDetails.booking_approval ? (
                    <div className="user-bottom">
                      <div className="buttons">
                        {bookSpaceLoading ? (
                          <div className="one-btn btn mt-4">
                            <span className="fa fa-spinner fa-spin px-3"></span>
                          </div>
                        ) : (
                          <div onClick={onNext} className="one-btn btn mt-4">
                            Confirm your Enquiry
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h4>Payment Details</h4>
                      <div className="card-list-wrapper py-4">
                        <div className="row">
                          <div className="col-md-12 mx-auto">
                            <h4 className="text-primary pb-3">
                              Apply Voucher Code
                            </h4>
                            <div className="card card-body">
                              <div className="list-group">
                                <div className="list-group-item d-flex justify-content-between align-items-center">
                                  <input
                                    type="text"
                                    placeholder="Enter Voucher Code"
                                    className="applyButton-input"
                                    onChange={(e) => changeVoucherCode(e)}
                                  />
                                  {appliedVoucherCode ? (
                                    <div className="applyButton">Applied</div>
                                  ) : (
                                    <div
                                      className="applyButton"
                                      onClick={applyVoucherCode}
                                    >
                                      Apply
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mx-auto">
                            <h4 className="text-primary pb-3">Cards List</h4>
                            <div className="card card-body">
                              <div className="list-group">
                                {cardsList && cardsList.length > 0
                                  ? cardsList.map((card, key) => (
                                      <div
                                        key={key}
                                        className="list-group-item d-flex justify-content-between align-items-center"
                                      >
                                        <span>
                                          {card.brand === "Visa" ? (
                                            <img
                                              className="card-image"
                                              src="assets/images/visa-card.png"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className="card-image"
                                              src="assets/images/master-card.png"
                                              alt=""
                                            />
                                          )}{" "}
                                          <b className="card-number">
                                            **** **** **** {card.last4}{" "}
                                          </b>
                                        </span>
                                        {loadingCardId === card.id ? (
                                          <span className="button btn-small">
                                            <span className="fa fa-spinner fa-spin px-3"></span>
                                          </span>
                                        ) : (
                                          <div
                                            className="button btn-small"
                                            onClick={() =>
                                              onBookSpace(card, "oldCard")
                                            }
                                          >
                                            Pay
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : "No Card Available"}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 mx-auto">
                            <StripePaymentBooking
                              onBookSpace={onBookSpace}
                              detailsAreCorrect={detailsAreCorrect}
                              type="book"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="user-payment-wrapper">
                  <h4>Summary</h4>
                  <div className="user-right-image">
                    <img
                      src={spaceDetails && spaceDetails.featured_image}
                      alt=""
                    />
                  </div>
                  <h6 className="mt-4">
                    {propertyListById && propertyListById.property_title}
                  </h6>
                  <h2 className="text-primary mt-1">
                    {spaceDetails && spaceDetails.space_title}
                  </h2>
                  <div className="payment-property-type">
                    <span>{spaceDetails && spaceDetails.space_type}</span>
                  </div>
                  <div className="user-edit">
                    <p>
                      {bookSpaceDetails?.numberOfDesks}{" "}
                      {bookSpaceDetails?.numberOfDesks === 1 ? "Desk" : "Desks"}{" "}
                      <span>for</span>{" "}
                      {bookSpaceDetails?.daySelectedDates?.length > 0 &&
                        `${bookSpaceDetails?.daySelectedDates?.length} ${
                          bookSpaceDetails?.daySelectedDates?.length === 1
                            ? "Day"
                            : "Day(s)"
                        }`}
                      {bookSpaceDetails?.daySelectedDates?.length > 0 &&
                        bookSpaceDetails?.nightSelectedDates?.length > 0 &&
                        " and "}
                      {bookSpaceDetails?.nightSelectedDates?.length > 0 &&
                        `${bookSpaceDetails?.nightSelectedDates?.length} ${
                          bookSpaceDetails?.nightSelectedDates?.length === 1
                            ? "Night"
                            : "Night(s)"
                        } `}
                    </p>
                    <div>
                      <Link
                        to={`/userbooking/1/${match.params.propertyId}/${match.params.spaceId}`}
                      >
                        Edit
                      </Link>{" "}
                      <span className="text-primary">/</span>{" "}
                      <span
                        className="previewButton"
                        onClick={() => setModalOpen(true)}
                      >
                        Preview
                      </span>
                    </div>
                  </div>
                  <h6 className="desk-rate">
                    <span className="text-dark">Total Amount:</span> &#8356;
                    {appliedVoucherCode ? (
                      <>
                        {(
                          calculatedPrice *
                            bookSpaceDetails?.numberOfDesks.toLocaleString() -
                          (appliedVoucherCode &&
                            appliedVoucherCode.discount *
                              calculatedPrice *
                              bookSpaceDetails?.numberOfDesks.toLocaleString()) /
                            100
                        ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {calculatedPrice *
                          bookSpaceDetails?.numberOfDesks.toLocaleString()}
                      </>
                    )}{" "}
                    <span style={{ fontSize: "12px", fontWeight: "800" }}>
                      +VAT
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookingConfirmPopup
        open={isConfirmPopupOpened}
        onClose={() => setIsConfirmPopupOpened(false)}
        bookingDetails={bookingDetails}
      ></BookingConfirmPopup>
      <DatesModalPopup
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        bookingDetails={bookSpaceDetails}
      />
    </div>
  );
};

export default UserBookingSeven;
