import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageContent } from "../../store/homeAction";
import Loading from "../../components/loading/Loading";

const Faq = () => {
  const dispatch = useDispatch();
  const pageContent = useSelector((state) => state.home.pageContent);

  useEffect(() => {
    dispatch(
      getPageContent({
        slug: "faq",
      })
    );
  }, []);

  return pageContent ? (
    <div className="container py-5 fade-in-bottom">
      <h3 className="text-primary text-center mb-4 text-uppercase">
        {pageContent && pageContent.title}
      </h3>
      <p
        dangerouslySetInnerHTML={{
          __html: pageContent && pageContent.content,
        }}
      ></p>
    </div>
  ) : (
    <div className="py-5">
      <Loading />
    </div>
  );
};

export default Faq;
