import React from "react";
import { format } from "date-fns";

// react modal
import { Modal } from "react-responsive-modal";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAgreementFiles } from "../../store/homeAction";
import { SET_LANDLORD_AGREEMENT_FILES } from "../../store/types";

// Components
import Loading from "../../components/loading/Loading";

// Icons
import IconClock from "../../icons/icon_clock";
import IconFile from "../../icons/icon_file";

// SASS
import "react-responsive-modal/styles.css";
import "./../popup.scss";
import '../../pages/landlord/landlordAgreement/landlordAgreement.scss';
import './landlordStyle.scss';

const VersionHistory = ({
  onClose,
  open,
  data,
}) => {
  const dispatch = useDispatch();

  const landlordAgreementFiles = useSelector(
    (state) => state.home.landlordAgreementFiles
  );

  const isLoading = useSelector(
    (state) => state.home.isLoading
  );

  React.useEffect(() => {
    if (data?.id) {
      dispatch(getAgreementFiles({ landlord_id: data?.id }));
    }
    return () => {
      dispatch({
        type: SET_LANDLORD_AGREEMENT_FILES,
        payload: null,
      });
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "version-history-modal experience-modal" }}
    >
      <div className="la-modal__wrapper">
        <h5 className="text-primary">Version History</h5>

        <div className="la-modal__form">
          Agreement with the landlord, {`${data?.first_name || ''} ${data?.last_name || ''}`}
          {isLoading ? (
            <div className="py-5">
              <Loading />
            </div>
          ) : (
            <>
              <div className="la--list v-history">
                {landlordAgreementFiles?.data?.map((v, key) => (
                  <div key={key} className="la--list__card">
                    <div className="list-card--content version--history">
                      <div className="datecontent--field">
                        <div className="date--field">
                          <IconClock size={15} />
                          <span className="date-dl">{format(new Date(v.upload_date), 'dd MMM yyyy, hh:mm a')}</span>
                        </div>
                        {key === 0 && (
                          <span className="status-dl">Current</span>
                        )}
                      </div>
                      <a href={v.file_name} download target="_blank" rel="noreferrer">
                        <IconFile size={20} />
                        <span className="view-dl">View/Download</span>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="la--table la--modal_table">
                <table>
                  <colgroup>
                    <col span="1" style={{ width: '40%' }} />
                    <col span="1" style={{ width: '20%' }} />
                    <col span="1" style={{ width: '20%' }} />
                  </colgroup>
                  <thead className="d-none">
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {landlordAgreementFiles?.data?.map((v, key) => (
                      <tr key={key}>
                        <td>
                          <div className="date--field">
                            <IconClock size={15} />
                            <span className="date-dl">{format(new Date(v.upload_date), 'dd MMM yyyy, hh:mm a')}</span>
                          </div>
                        </td>
                        <td>
                          {key === 0 && (
                            <span className="status-dl">Current</span>
                          )}
                        </td>
                        <td>
                          <a href={v.file_name} download target="_blank" rel="noreferrer">
                            <IconFile size={20} />
                            <span className="view-dl">View/Download</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        
        <div className="la-modal__btn">
          <button type="button" className="la-modal__btn__cancel" onClick={onClose}>Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default VersionHistory;

