import React, { useEffect, useState, useRef } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import db from "../config";
import {
  landlordUserConnection,
  onChatNotification,
  sendEnquiryEmail,
} from "../store/homeAction";
import { useDispatch } from "react-redux";

const LandlordChatPopup = ({
  open,
  onClose,
  landlordDetails,
  propertyDetails,
}) => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const [chatUserIds, setChatUserIds] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    const fetchedUsers = {};

    [landlordDetails?.id, userDetails?.id].forEach(id => {
      db.ref(`users/${id}`).on("value", function (snapshot) {
        fetchedUsers[id] = snapshot.val()
      });
    });

    setUsers(fetchedUsers);
  };

  const sendRequest = (landlordDetails) => {
    // console.log("landlordDetails", landlordDetails);

    // const sendChatEmail = {
    //   user_id: landlordDetails.id.toString(),
    //   msg: "Hello I want to enquiry about your space!.",
    //   subject: "Hello I want to enquiry about your space!.",
    // };

    // dispatch(sendEnquiryEmail(sendChatEmail));

    const userConnection = {
      landlord_id: landlordDetails.id.toString(),
      user_id: userDetails.id.toString(),
    };
    dispatch(landlordUserConnection(userConnection));

    setIsLoading(true);
    let collection;
    if (parseInt(landlordDetails.id) < parseInt(userDetails.id)) {
      collection = `${landlordDetails.id}-${userDetails.id}`;
    } else {
      collection = `${userDetails.id}-${landlordDetails.id}`;
    }

    const rootRef = db.ref();
    const urlRef = rootRef.child(`messages/${collection}`);

    // Check if request already sent or not
    let exist = false;

    urlRef
      .orderByChild("senderid")
      .equalTo(userDetails.id.toString())
      .on("value", function (snapshot) {
        snapshot.forEach(function (child) {
          const chatDetails = child.val();

          if (
            chatDetails.senderid === userDetails.id.toString() &&
            chatDetails.receiverid === landlordDetails.id.toString()
          ) {
            exist = true;
            window.location.href = `#/chat/${userDetails?.id}`;
            return false;
          }
        });
      });

    const data = users[landlordDetails.id.toString()]?.chatRequestAcceptedUserIds;
    let chatUsers = [];
  
    if (data) {
      var keys = Object.values(data);
      for (var i = 0; i < keys.length; i++) {
        chatUsers.push(keys[i]);
      }
    }
    console.log("🚀 ~ file: LandlordChatPopup.js:134 ~ chatUsers", chatUsers)

    if (chatUsers.some((user) => user === userDetails.id.toString())) {
      exist = true;
      window.location.href = `#/chat/${userDetails?.id}`;
      return false;
    }

    setTimeout(() => {
      if (exist === false) {
        rootRef.child(`users/${userDetails.id}/pendingInviteUserIds`).push(landlordDetails.id.toString());
        rootRef.child(`users/${landlordDetails.id}/pendingInviteUserIds`).push(userDetails.id.toString());

        // insert into messages collection
        urlRef.push({
          propertyName: propertyDetails.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          text: "Hello \nI want to enquire about your space.",
          timestamp: Date.now(),
        });

        // insert into lastMessages collection
        const lastMessageRef = rootRef.child(`lastMessages/${collection}`);
        lastMessageRef.set({
          propertyName: propertyDetails.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          text: "Hello \nI want to enquiry about your space.",
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          timestamp: Date.now(),
        });

        window.location.href = `#/chat/${userDetails?.id}`;
      }

      setIsLoading(false);
    }, 1000);

    if (exist === false) {
      const pushData = {
        title: userDetails.name,
        message: "Hello I want to enquiry about your space!.",
        sender_id: userDetails.id.toString(),
        type: "chat",
        receiver_id: landlordDetails.id.toString(),
      };

      dispatch(onChatNotification(pushData));
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "landlord-chat-popup" }}
    >
      <div className="landlord-popup">
        <img
          className="landlord-profile"
          src={landlordDetails?.profile_pic}
          alt=""
        />
        <div className="landlord-details">
          <p className="text-primary mb-1">
            <img className="mr-1" src="assets/images/green-dot.png" alt="" />{" "}
            Sharer Online
          </p>
          <p className="m-0 landlordDetailsName">
            <span className="text-uppercase font-weight-bold mr-1">
              {landlordDetails?.name}
            </span>
            is available to chat
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {isLoading ? (
          <div className="chat-btn my-3 mx-auto">
            <span className="fa fa-spinner fa-spin px-3"></span>
          </div>
        ) : (
          <div
            className="chat-btn my-3 mx-auto"
            onClick={() => sendRequest(landlordDetails)}
          >
            Chat Now
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LandlordChatPopup;
