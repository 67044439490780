/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyAttributes,
  getPropertyById,
} from "../../store/homeAction";
import { SET_ADD_PROPERTY_DETAILS } from "../../store/types";
import {
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Map from "./Map";
import Loading from "../../components/loading/Loading";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import "./steps.scss";
import useGeocode from "../../hooks/useGeocode";
import { toast } from "react-toastify";
import { RequestType } from "react-geocode";
import KYCPopup from "../../popups/KYCPopup";

const libraries = ["places"];

const StepOne = ({ match }) => {
  const dispatch = useDispatch();

  const { getGeocode } = useGeocode();

  const addPropertyDetails = useSelector(
    (state) => state.home.addPropertyDetails
  );
  const propertyAttributeList = useSelector(
    (state) => state.home.propertyAttributeList
  );
  const propertyListById = useSelector((state) => state.home.propertyListById);

  const isLoading = useSelector((state) => state.home.isLoading);
  const [searchBox, setSearchBox] = React.useState(null);
  const [location, setLocation] = React.useState({
    lat: null,
    lng: null,
  });
  const [values, setValues] = useState({
    property_title: '',
    address_line_one: '',
    address_line_two: '',
    postcode: '',
    lang: null,
    long: null,
    city: '',
    state: '',
    country: '',
    disability_access: '',
    describe_your_space: '',
    how_to_find_us: '',
    facilities_values: [],
    insurance: '',
    covid_19_secure: '',
    user_id: '',
    property_id: '',
    isManuallyPinned: false,
    whats_not_included: ''
  });

  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    dispatch(getPropertyAttributes());
    dispatch(getPropertyById({ property_id: match.params.propertyId }));

    // Clear store data from adding property
    dispatch({
      type: SET_ADD_PROPERTY_DETAILS,
      payload: undefined,
    });

    if (localStorage.getItem("os-user")) {
      const user = JSON.parse(localStorage.getItem("os-user"));
      setUserDetails(user);
    }
  }, []);

  useEffect(() => {
    if (propertyAttributeList && addPropertyDetails === undefined) {
      for (let i = 0; i < propertyAttributeList.length; i++) {
        propertyAttributeList[i].selected = false;
      }
      setSelectedAttribute(propertyAttributeList);
    }
  }, [propertyAttributeList]);

  useEffect(() => {
    if (addPropertyDetails) {

      setValues(addPropertyDetails);
      setSelectedAttribute(addPropertyDetails.facilities_values);
    }

  }, [addPropertyDetails]);

  useEffect(() => {
    const propertyId = match.params.propertyId;

    if (
      propertyId
      && propertyListById
      // && propertyListById.Amenities.length > 0
    ) {
      const amenities = propertyListById?.Amenities;
      if (propertyAttributeList && addPropertyDetails === undefined) {
        for (let i = 0; i < propertyAttributeList.length; i++) {
          let exist = false;
          for (let j = 0; j < amenities.length; j++) {
            if (propertyAttributeList[i].value === amenities[j].value) {
              exist = true;
            }
          }

          propertyAttributeList[i].selected = exist;
        }

        setSelectedAttribute(propertyAttributeList);
        let lat = parseFloat(propertyListById.lang);
        let lng  = parseFloat(propertyListById.long);
        let location = {
          lat: lat !== null ? lat : 51.5073509,
          lng: lng !== null ? lng: -0.1277583,
        };
        setLocation(location);
      }
      propertyListById.how_to_find_us = propertyListById.about_sharer;
      setValues(propertyListById);

      const address_line_one = document.getElementById("address_line_one");
      if (address_line_one) {
        address_line_one.value = propertyListById.address_line_one;
      }
    }
  }, [propertyListById]);

  const onSelectAttribute = (value) => {
    for (let i = 0; i < selectedAttribute.length; i++) {
      if (value === selectedAttribute[i].id) {
        selectedAttribute[i].selected = !selectedAttribute[i].selected;
      }
    }

    setSelectedAttribute([...selectedAttribute]);
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleOnPostcodeBlur = async (e) => {
    // retrieve the coordinates using postcode
    if (!values.isManuallyPinned && e.target.value) {
      try {
        const result = await getGeocode(e.target.value);
        handleUpdateAddress(result);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleUpdateAddress = (result, isUserPinned = false) => {
    const address = result.address_components.reduce((acc, curr) => {
      if (curr.types.includes('street_number')) {
        acc.address_line_one = curr.long_name;
      } else if (curr.types.includes('route')) {
        acc.address_line_one = acc.address_line_one ? `${acc.address_line_one}${' ' + curr.long_name}` : curr.long_name;
      } else if (curr.types.includes('postal_town')) {
        acc.address_line_one = acc.address_line_one ? `${acc.address_line_one}${', ' + curr.long_name}` : curr.long_name;
      } else if (curr.types.includes('postal_code')) {
        acc.postal_code = curr.long_name;
      }

      return acc;
    }, {});

    document.getElementById("address_line_one").value = address.address_line_one;

    setLocation({
      lat: result.geometry.location.lat,
      lng: result.geometry.location.lng
    });
    setValues({
      ...values,
      isManuallyPinned: isUserPinned,
      lang: result.geometry.location.lat,
      long: result.geometry.location.lng,
      address_line_one: address.address_line_one,
      postcode: address.postal_code
    })
  }

  // Updates the coordinates in the values state when the marker is dragged
  const handleMarkerDragEnd = async (coordinates) => {
    try {
      const result = await getGeocode(`${coordinates.lat},${coordinates.lng}`, RequestType.LATLNG);

      handleUpdateAddress(result, true);
    } catch (error) {
      console.log(error);
    }
  }

  const onNext = (e) => {
    e.preventDefault();

    // Check if no coordinates assigned
    if (!values.lang || !values.long) {
      toast('Please pin the location in the map', {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      return;
    }

    const user = JSON.parse(localStorage.getItem("os-user"));
    setUserDetails(user);

    let data = {
      property_title: values?.property_title,
      address_line_one: document.getElementById("address_line_one").value, // Force the address value override as something replaces address_line_one even after onInputChange.
      address_line_two: values?.address_line_two,
      postcode: values?.postcode,
      lang: values?.lang,
      long: values?.long,
      city: "None",
      state: "None",
      country: "None",
      disability_access: values?.disability_access,
      describe_your_space: values?.describe_your_space,
      how_to_find_us: values?.how_to_find_us,
      whats_not_included: values?.whats_not_included,
      facilities_values: selectedAttribute,
      insurance: values?.insurance,
      covid_19_secure: values?.covid_19_secure,
      user_id: user.id,
      property_id: match.params.propertyId,
      isManuallyPinned: values?.isManuallyPinned
    };

    dispatch({
      type: SET_ADD_PROPERTY_DETAILS,
      payload: data,
    });

    if (match.params.propertyId) {
      window.location.href = `#/add-property/2/${match.params.propertyId}`;
    } else {
      window.location.href = "#/add-property/2";
    }
  };

  const [postalSearch, setPostalSearch] = React.useState('');
 
  const onPostalSearchLoad = React.useCallback((ref) => {
    setPostalSearch(ref);
  }, []);

  const handlePlacesChanged = React.useCallback(() => {
    if (values.isManuallyPinned) return;
    const places = postalSearch.getPlaces();

    if (places?.length > 0) {
      const place = places[0];

      let location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      setLocation(location);

      const findPostalCode = place?.address_components?.filter((v) => v.types.includes('postal_code'));
      
      let valueParams = {
        ...values,
        lang: location.lat,
        long: location.lng,
        address_line_one: place.name,
      };

      if (findPostalCode?.length) {
        valueParams = {
          ...valueParams,
          postcode: findPostalCode?.[0]?.long_name,
        }
      }

      setValues(valueParams);
    }
  }, [postalSearch, values]);

    return (
        <>
          <LandlordBar/>
          <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              libraries={libraries}
              language="en"
              region="uk"
          >
            {isLoading ? (
                <div className="py-5 my-5">
                  <Loading></Loading>
                </div>
            ) : (
                <div className="container mt-5">
                  <form onSubmit={onNext}>
                    <div className="add-property-step-wrapper">
                      <div className="step-progress">
                        <p className="text-text">
                          <b> Step 1 :</b> Location information{" "}
                        </p>
                        <div className="progress-bar">
                          <div className="fill" style={{width: "20%"}}></div>
                        </div>
                      </div>

                      <div className="step-detail">
                        <h5>
                          <b> Hi {userDetails && userDetails.first_name},</b>
                        </h5>
                        <h5>Thank you for choosing to become an OS verified Host.</h5>
                        <p className="instruction">
                          Before you turn your space into a hot spot, lets get
                          acquainted
                        </p>
                      </div>
                      <div className="address-details">
                        <div className="address-wrapper">
                          <p className="label">
                            Full Address of the space you'd like to list
                          </p>
                          <div className="address-line">
                            {location?.lat && location?.lng && values?.postcode ? (
                              <>
                                <div className="form-group">
                                  <input
                                    id="address_line_one"
                                    type="text"
                                    name="address_line_one"
                                    placeholder="Address Line 1"
                                    disabled={values?.isManuallyPinned}
                                    value={values?.address_line_one}
                                    onChange={onInputChange}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    placeholder="Address Line 2"
                                    name="address_line_two"
                                    disabled={values?.isManuallyPinned}
                                    value={values?.address_line_two}
                                    onChange={onInputChange}
                                    // required
                                  />
                                </div>
                              </>
                            ) : <></>}
                            <div className="form-group">
                            <StandaloneSearchBox
                              onLoad={onPostalSearchLoad}
                              onPlacesChanged={handlePlacesChanged}
                            >
                              <input
                                  type="text"
                                  placeholder="Postcode (E.g.LS1 HAR)"
                                  name="postcode"
                                  value={values?.postcode}
                                  disabled={values?.isManuallyPinned}
                                  onChange={onInputChange}
                                  onBlur={handleOnPostcodeBlur}
                                  required
                              />
                              </StandaloneSearchBox>
                            </div>
                            <div className="form-group">
                              <input
                                  type="text"
                                  placeholder="Property Title"
                                  name="property_title"
                                  value={values?.property_title}
                                  onChange={onInputChange}
                                  required
                              />
                            </div>
                            <div className="form-group">
                              <select
                                  name="disability_access"
                                  value={values?.disability_access}
                                  onChange={onInputChange}
                                  required
                              >
                                <option value="">Disability access</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </div>
                          </div>
                          <p className="label">Describe your space</p>
                          <div className="address-line">
                            <div className="form-group">
                        <textarea
                            rows="6"
                            name="describe_your_space"
                            value={values?.describe_your_space}
                            onChange={onInputChange}
                            placeholder="Please enter a description of your space, decor, facilies and what companies may be suited."
                            required
                        />
                            </div>
                          </div>

                          <p className="label">About the Host</p>
                          <div className="address-line">
                            <div className="form-group">
                        <textarea
                            rows="6"
                            name="how_to_find_us"
                            value={values?.how_to_find_us}
                            onChange={onInputChange}
                            placeholder="Please enter some information about the host"
                            required
                        />
                            </div>
                          </div>
                        </div>

                        <div className="map">
                          <Map
                            location={location}
                            isMarkerDraggable={true}
                            onChange={handleMarkerDragEnd}/>
                          <span className="small">Drag the pin to fine-tune your location</span>

                          <p className="label">Tips</p>
                        </div>
                      </div>

                      <div className="address-details">
                        <div className="w-100">
                          <p className="label">Select your facilities</p>
                          <div className="subtitle">
                            Tick that boxes are apply to your space
                          </div>
                          <div className="checkbox-wrapper">
                            {selectedAttribute &&
                                selectedAttribute.map((attribute, key) => (
                                    <div className="checkbox" key={key}>
                                      <input
                                          id={attribute.id}
                                          type="radio"
                                          onChange={() => onSelectAttribute(attribute.id)}
                                          checked={!!attribute.selected}
                                          required
                                      />
                                      <label htmlFor={attribute.id}>{attribute.value}</label>
                                    </div>
                                ))}
                          </div>

                          <div className="address-wrapper">
                            <p className="label">Insurance</p>
                            <div className="address-line">
                              <div className="form-group">
                                <textarea
                                    rows="6"
                                    placeholder="Please enter your insurance details."
                                    name="insurance"
                                    value={values?.insurance}
                                    onChange={onInputChange}
                                    required
                                />
                              </div>
                            </div>

                           <p className="label">What's not included?</p>
                           <div className="address-line">
                             <div className="form-group">
                                <textarea
                                  rows="6"
                                  placeholder="Please enter what's not included"
                                  name="whats_not_included"
                                  value={values?.whats_not_included}
                                  onChange={onInputChange}
                                />
                              </div>
                            </div>

                            <div className="btn-wrapper">
                              <button className="button" type="submit">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
            )}
          </LoadScript>

          <KYCPopup />
        </>
    );

};

export default StepOne;
