import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  sendOtp } from "../../../store/homeAction";
import { useHistory } from "react-router-dom";
import { SET_REGISTER_USER_DETAIL } from "../../../store/types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./landlordAll.scss";

const LandlordLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const registerUserDetail = useSelector(
    (state) => state.home.registerUserDetail
  );
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const regions = useSelector((state) => state.home.regions);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    if (
      (values.password && values.password.length === 0) ||
      (values.password && values.password.length < 7)
    ) {
      toast("Password length should be 8 characters", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    if (values.password !== values.cPassword) {
      toast("Password and Confirm Password does not match!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    onSendOtp();
  };

  const onSendOtp = () => {
    let selectedRegion = registerUserDetail.region;
    let phoneNumber = registerUserDetail.phone;

    if (selectedRegion === 0) {
      toast("Select Region", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    let data = {
      phone: selectedRegion + phoneNumber,
    };

    localStorage.setItem("os-phone-number", phoneNumber);
    localStorage.setItem("os-region", selectedRegion);

    let regionName;
    for (let i = 0; i < regions.length; i++) {
      if (selectedRegion === regions[i].phonecode) {
        localStorage.setItem("os-region-name", regions[i].name);
        regionName = regions[i].name;
      }
    }

    let registerDetails = registerUserDetail;
    registerDetails.password = values.password;
    registerDetails.region = regionName;

    dispatch({ type: SET_REGISTER_USER_DETAIL, payload: registerDetails });

    dispatch(sendOtp(data, "landlord", history));
  };

  return (
    <div className="register-main-wrapper">
      <div className="container">
        <div className="register-section">
          <div className="register-header">
            <h2>
              Sign up as a Host<span className="text-primary">:</span>
            </h2>
            <h3>
              Already registered?
              <Link to="/landlord-login">
                <span className="text-primary">Log in</span>
              </Link>
            </h3>
          </div>
          <h4 className="register-title">Please setup a password</h4>
          <form className="login-input-form" onSubmit={onSubmitForm}>
            <div className="input-group">
              <input
                type={showPwd ? "text" : "password"}
                placeholder="Password"
                name="password"
                minLength="8"
                value={values.password}
                onChange={onInputChange}
                required
              />
              {showPwd ? (
                <i
                  className="fa fa-eye text-secondary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowPwd(!showPwd)}
                ></i>
              ) : (
                <i
                  className="fa fa-eye-slash text-secondary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowPwd(!showPwd)}
                ></i>
              )}
            </div>
            <div className="input-group">
              <input
                type={showConfirmPwd ? "text" : "password"}
                placeholder="Confirm Password"
                name="cPassword"
                minLength="8"
                value={values.cPassword}
                onChange={onInputChange}
                required
              />
              {showConfirmPwd ? (
                <i
                  className="fa fa-eye text-secondary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                ></i>
              ) : (
                <i
                  className="fa fa-eye-slash text-secondary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                ></i>
              )}
            </div>
            <div className="input-group">
              <button type="submit" className="btn-submit">
                Create Host Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandlordLogin;
