import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertySpaces, getPropertyById } from "../../store/homeAction";
import { SET_BOOK_SPACE_DETAILS } from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import SwitchMap from "./SwitchMap";
import "./userBooking.scss";
import PropertyWithSpaceDetails from "./PropertyWithSpaceDetails";

const UserBookingTwo = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [isWeeklySpace, setIsWeeklySpace] = useState(false);

  useEffect(() => {
    getPropertyList();
    getSpaceList();
  }, [match]);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onNext = () => {
    dispatch({
      type: SET_BOOK_SPACE_DETAILS,
      payload: {
        ...bookSpaceDetails,
        isWeeklySpace,
      },
    });

    if (isWeeklySpace) {
      history.push(
        `/userbooking/3/${match.params.propertyId}/${match.params.spaceId}`
      );
    } else {
      history.push(
        `/userbooking/4/${match.params.propertyId}/${match.params.spaceId}`
      );
    }
  };

  return (
    <div className="user-detail-with-background">
      <div className="container mt-5">
        <div className="user-details-wrapper">
          <div className="user-booking">
            <div className="user-booking-main-wrapper">
              <div className="user-booking-main">
                <div className="user-left">
                  <Link
                    to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                    className="back-btn"
                  >
                    Back to space
                  </Link>
                  {propertyListById && spaceDetails && (
                    <PropertyWithSpaceDetails
                      propertyListById={propertyListById}
                      spaceDetails={spaceDetails}
                    />
                  )}
                  <hr />
                  <div className="user-middle">
                    <h6>
                      Are you interested in setting up a recurring booking?
                    </h6>
                    <div className="two-desc">
                      You can opt to pick a day/days of a week and keep that
                      slot week for up to 6 months.
                    </div>
                  </div>
                  <hr />
                  <div className="user-bottom">
                    <div className="buttons">
                      <div
                        onClick={() => setIsWeeklySpace(true)}
                        className={`ok-btn ${
                          isWeeklySpace == true && "selected"
                        } `}
                      >
                        Yes, I would like a weekly space
                      </div>
                      <div
                        onClick={() => setIsWeeklySpace(false)}
                        className={`ok-btn ${
                          isWeeklySpace == false && "selected"
                        }`}
                      >
                        No, just one off dates
                      </div>
                      <div>
                        <div onClick={onNext} className="one-btn btn">
                          Continue
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SwitchMap
                  propertyListById={propertyListById}
                  spaceDetails={spaceDetails}
                ></SwitchMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookingTwo;
