import React from "react";
import { Link } from "react-router-dom";
import './about.scss';

const content = {
  section1: {
    header: 'About us',
    description: `Welcome to OfficeShare, where the future of work is evolving. In a world where hybrid working models have transformed our office needs, we offer a solution that rethinks how office spaces are utilised. 

      OfficeShare sprang from a real need - the underuse of leased office spaces and its impact on business efficiency. OfficeShare is about flexibility and sustainability.
    `,
  },
  section2: {
    header: 'The OfficeShare platform',
    description: 'We provide a platform where businesses can optimise their space usage, cutting down on waste and costs. By fostering collaborations between landlords, tenants and renters, we build long-term relationships that benefit all.'
  },
  section3: {
    header: 'Our Goal',
    description: 'Our goal? To enable businesses to connect, grow, and thrive in an environment that values resourcefulness and community.'
  },
};

const About = () => {
  return (
    <div className="about--wrapper">
      <section className="container">
        <div className="text--wrapper">
          <h1 className="text-primary about--header">{content.section1.header}</h1>
          <p className="about--description">{content.section1.description}</p>
        </div>
      </section>
      <section className="container">
        <div className="image--wrapper">
          {[...Array(3)].map((_, key) => (
            <div key={key} className="image--container">
              <img src={`/assets/images/pages/about/about-img-${key + 1}.jpg`} alt="about people" />
            </div>
          ))}
        </div>
      </section>
      <section className="container">
        <div className="officeshare--wrapper">
            <div className="section">
              <h2 className="text-primary about--header-secondary">{content.section2.header}</h2>
              <p className="about--description officeshare--desc">{content.section2.description}</p>
              <Link
                to="/how-it-works"
                className="button primary-button how-it-works--cta how--cta"
              >
                See how it works
              </Link>
            </div>
            <div className="section">
              <div className="section-mid-image">
                <div className="section-mid-image--wrapper">
                  <img src="/assets/images/pages/about/mid-img-pc.png" alt="pc" />
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="section-goal">
        <div className="container">
          <div className="goal-section">
            <div className="goal-image">
              <img src="/assets/images/pages/about/about-img-4.jpg" alt="persons in meeting" />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h2 className="text-primary about--header-secondary">{content.section3.header}</h2>
              <p className="about--description">{content.section3.description}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default About;
