import React from "react";
import { useState } from "react";
import axios from "axios";

const FeaturedImageUpload = ({
  onUpload,
  uploadImageSrc,
  type,
  index,
  fixed,
}) => {
  const [file, setFile] = useState();

  const handleChange = (event) => {
    if (type === "featured") {
      onUpload(event.target.files[0], type);
    } else {
      onUpload(event.target.files[0], type, index, fixed);
    }

    setFile(URL.createObjectURL(event.target.files[0]));
  };

  return type === "featured" ? (
    <div className="upload-wrapper">
      <label htmlFor="featured">
        {uploadImageSrc ? (
          <img className="uploaded-image" src={uploadImageSrc} alt="" />
        ) : (
          <div className="upload-icon">
            <img
              src={
                uploadImageSrc !== null
                  ? uploadImageSrc
                  : "assets/images/add-icon.png"
              }
              alt=""
            />
          </div>
        )}
      </label>
      <input
        id="featured"
        type="file"
        className="invisible"
        onChange={handleChange}
      />
    </div>
  ) : (
    <div className="upload-wrapper">
      <label htmlFor={index ? index : "file"}>
        {(!fixed && file) || uploadImageSrc !== null ? (
          <img
            className="uploaded-image gallary-image"
            src={uploadImageSrc || "assets/images/add-icon.png"}
            alt=""
          />
        ) : (
          <div className="uploaded-image gallary-image upload-box">
            <img
              className="upload-icon"
              src={uploadImageSrc || "assets/images/add-icon.png"}
              alt=""
            />
          </div>
        )}
      </label>
      <input
        id={index ? index : "file"}
        type="file"
        className="invisible"
        onChange={handleChange}
      />
    </div>
  );
};

export default FeaturedImageUpload;
