

import React from 'react';

const IconArrow = ({ color = '#000000', size = 18, dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M16-0l12.685 12.685-1.528 1.529-10.076-10.076v27.863h-2.162v-27.863l-10.079 10.079-1.525-1.532 12.685-12.685z"></path>
    </svg>
  )
};

export default IconArrow;