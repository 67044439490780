import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpaceType,
  addSpace,
  getPropertySpaces,
} from "../../store/homeAction";
import {
  SET_SPACE_AVAILIBILITY_DATES,
  SET_ADD_SPACE_DETAILS,
} from "../../store/types";
import { useHistory, useLocation } from "react-router-dom";
import FeaturedImageUpload from "./FeaturedImageUpload";
import "./steps.scss";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import moment from "moment";
import Day from "./Day";
import Night from "./Night";
import DayNight from "./DayNight";
import { toast } from "react-toastify";

const StepFour = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useLocation().search;
  const [file, setFile] = useState();
  const [featuredImage, setFeaturedImage] = useState();
  const [spaceTypeId, setSpaceTypeId] = useState("");
  const [values, setValues] = useState({});
  const [spaceDetails, setSpaceDetails] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const spaceType = useSelector((state) => state.home.spaceType);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const isLoading = useSelector((state) => state.home.isLoading);
  const spaceAvailabilityDates = useSelector(
    (state) => state.home.spaceAvailabilityDates
  );
  const addSpaceDetails = useSelector((state) => state.home.addSpaceDetails);

  useEffect(() => {
    dispatch(getSpaceType());
    dispatch(getPropertySpaces({ property_id: match.params.propertyId }));

    if (match.params.spaceId === undefined) {
      dispatch({
        type: SET_ADD_SPACE_DETAILS,
        payload: null,
      });
    }
  }, []);

  useEffect(() => {
    if (addSpaceDetails) {
      setValues(addSpaceDetails.values);
      setFeaturedImage(addSpaceDetails.featuredImage);
      setSpaceTypeId(addSpaceDetails.spaceTypeId);
    }
  }, [addSpaceDetails]);

  useEffect(() => {
    if (propertySpaces) {
      for (let i = 0; i < propertySpaces.length; i++) {
        if (propertySpaces[i].id == match.params.spaceId) {
          setSpaceDetails(propertySpaces[i]);
          let spaceDetails = propertySpaces[i];

          spaceDetails.cost_type =
            propertySpaces[i].cost_type === "range" ? "range" : "single";

          spaceDetails.booking_payment_refund =
            propertySpaces[i].booking_payment_refund ===
            "100% refund if booking cancel in 24 hours"
              ? 1
              : propertySpaces[i].booking_payment_refund ===
                "100% refund if booking cancel in 48 hours "
              ? 2
              : propertySpaces[i].booking_payment_refund ===
                "Sorry, this booking amount is not refundable."
              ? 3
              : null;

          setSpaceTypeId(
            propertySpaces[i].space_type == "Dedicated"
              ? 1
              : propertySpaces[i].space_type == "Flexi desk"
              ? 2
              : propertySpaces[i].space_type == "Shared"
              ? 3
              : propertySpaces[i].space_type == "Short-Term"
              ? 4
              : propertySpaces[i].space_type == "Long-Term"
              ? 5
              : null
          );

          setFeaturedImage(propertySpaces[i].featured_image);

          if (
            propertySpaces[i].space_days_price_list &&
            propertySpaces[i].space_days_price_list.length > 0
          ) {
            let space_days_price_list = propertySpaces[i].space_days_price_list;
            for (let j = 0; j < space_days_price_list.length; j++) {
              let day = space_days_price_list[j].day;
              spaceDetails[day.toLowerCase()] = space_days_price_list[j].price;
              spaceDetails[`${day.toLowerCase()}_night`] =
                space_days_price_list[j].night_price;
            }
          }

          const availability = spaceDetails.availability;
          let availableDates = [];
          if (availability) {
            for (let i = 0; i < availability.length; i++) {
              let data = {
                type: availability[i].type,
                desk: availability[i].available_desk,
              };
              if (availability[i].type === "date") {
                data.date = {
                  startDate:
                    availability[i].from_date ||
                    moment(new Date()).format("YYYY-MM-DD"),
                };
              } else {
                data.date = {
                  startDate:
                    availability[i].from_date ||
                    moment(new Date()).format("YYYY-MM-DD"),
                  endDate:
                    availability[i].to_date ||
                    moment(new Date()).format("YYYY-MM-DD"),
                };
              }
              availableDates.push(data);
            }
          }

          if (!spaceAvailabilityDates) {
            dispatch({
              type: SET_SPACE_AVAILIBILITY_DATES,
              payload: availableDates,
            });
          }

          setValues(spaceDetails);
        }
      }
    }
  }, [propertySpaces]);

  const onUploadFeaturedImage = (image) => {
    setFeaturedImage(image);
  };

    const onAddSpace = (e) => {
    e.preventDefault();
    const userDetails = JSON.parse(localStorage.getItem("os-user"));
    let formData = new FormData();
    formData.append("space_title", values.space_title);
    formData.append("space_type_id", spaceTypeId);
    formData.append("available_desk", values.available_desk);
    formData.append("booking_approval", values.booking_approval);
    formData.append("price", values.price || "");
    formData.append("min_term", values.min_term);
    formData.append("max_term", values.max_term);
    formData.append("key_feature", values.key_feature);
    formData.append("things_not_included", values.things_not_included);
    formData.append("user_id", userDetails.id);
    formData.append("property_id", match.params.propertyId);
    formData.append("featured_image", featuredImage);
    formData.append(
      "availability_details",
      JSON.stringify(spaceAvailabilityDates)
    );

    formData.append("availability_type", values.availability_type || 1);
    formData.append("booking_payment_refund", values.booking_payment_refund);
    formData.append("cost_type", values.cost_type);

    formData.append(
      "mon",
      values.cost_type === "single" ? values.price || "" : values.mon
    );
    formData.append(
      "tue",
      values.cost_type === "single" ? values.price || "" : values.tue
    );
    formData.append(
      "wed",
      values.cost_type === "single" ? values.price || "" : values.wed
    );
    formData.append(
      "thu",
      values.cost_type === "single" ? values.price || "" : values.thu
    );
    formData.append(
      "fri",
      values.cost_type === "single" ? values.price || "" : values.fri
    );
    formData.append(
      "sat",
      values.cost_type === "single" ? values.price || "" : values.sat
    );
    formData.append(
      "sun",
      values.cost_type === "single" ? values.price || "" : values.sun
    );
    formData.append(
      "mon_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.mon_night
    );
    formData.append(
      "tue_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.tue_night
    );
    formData.append(
      "wed_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.wed_night
    );
    formData.append(
      "thu_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.thu_night
    );
    formData.append(
      "fri_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.fri_night
    );
    formData.append(
      "sat_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.sat_night
    );
    formData.append(
      "sun_night",
      values.cost_type === "single"
        ? values.night_price || ""
        : values.sun_night
    );

    if (values.max_term > 180) {
      toast("Max term field is 180", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else if (match.params.spaceId) {
      formData.append("space_id", match.params.spaceId);
      dispatch(addSpace(formData, "update"));
    } else {
      dispatch(addSpace(formData));
    }
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const checkHttpInUrl = (image) => {
    if (typeof image === "string") {
      return image;
    } else {
      return URL.createObjectURL(image);
    }
  };

  const goToAvailabilitySpace = () => {
    dispatch({
      type: SET_ADD_SPACE_DETAILS,
      payload: { values, spaceTypeId, featuredImage },
    });
    history.push(
      match.params.spaceId
        ? `/available-space/${match.params.propertyId}/${match.params.spaceId}${path || ''}`
        : `/available-space/${match.params.propertyId}${path || ''}`
    );
  };

  const handleImageChange = (e) => {
    setFeaturedImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };

    return (
    <>
      <LandlordBar />
      <div className="container mt-5">
        <form onSubmit={onAddSpace}>
          <div className="add-property-step-wrapper">
            <div className="enquiryType-space">
              {spaceDetails && spaceDetails.booking_approval === true ? (
                <span>Enquiry Type</span>
              ) : spaceDetails && spaceDetails.booking_approval === false ? (
                <span>Payment Type</span>
              ) : null}
            </div>
            <div className="step-progress">
              <p className="text-text">
                <b> Step 4 :</b> Renting Information
              </p>
              <div className="progress-bar">
                <div className="fill" style={{ width: "40%" }}></div>
              </div>
            </div>

            <div className="step-detail">
              <div className="top-section">
                <div className="space-image">
                  <FeaturedImageUpload
                    uploadImageSrc={
                      file ||
                      (featuredImage && checkHttpInUrl(featuredImage)) ||
                      null
                    }
                    type="featured"
                    onUpload={onUploadFeaturedImage}
                  ></FeaturedImageUpload>
                </div>
                <div className="first-section">
                  <h5 className="space-detail">
                    <b>Space Details</b>
                  </h5>
                  <p className="instruction">
                    Please upload one image of your property that you think
                    makes your space look great!
                  </p>
                  <p>
                    <b>
                      Drag your JPEG's or PNG here or Click the button below.
                    </b>
                  </p>
                  <div className="pt-4 stepFour-imageUpload">
                    <label className="button" htmlFor="file">
                      <input
                        type="file"
                        id="file"
                        onChange={handleImageChange}
                      />
                      Upload an Image
                      <img
                        className="camera-icon"
                        width="30px"
                        src="assets/images/camera.png"
                       alt="Upload"/>
                    </label>
                  </div>
                </div>
              </div>
              <h5 className="space-breakdown">
                <b> Space Breakdown</b>
              </h5>
              <p className="instruction">
                Add as much detail as you can about the configuration and
                duration of space
              </p>
            </div>
            <div className="rent-details">
              <div className="label">Please select type of office:</div>
              <div className="office-type">
                {spaceType &&
                    spaceType.map((item, key) => (
                        <div className="office-item" key={key}>
                          <div className="checkbox">
                            <input
                                id={item.id}
                                type="radio"
                                onChange={() => setSpaceTypeId(item.id)}
                                checked={spaceTypeId === item.id}
                                required
                            />
                            <label htmlFor={item.id}>
                              <b>{item.title}</b>
                            </label>
                          </div>
                        </div>
                    ))}
              </div>


              <div
                onClick={goToAvailabilitySpace}
                className="availability-button"
              >
                <img src="assets/images/add-icon.png" alt="" />
                <h6>Add Available Space</h6>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="input-wrapper">
                    <div className="label mb-3">Space Type</div>
                    <select
                      name="booking_approval"
                      value={values.booking_approval}
                      onChange={onInputChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="true">Enquiry Type</option>
                      <option value="false">Payment Type</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-wrapper">
                    <div className="label mb-3">Space Title</div>
                    <input
                      type="text"
                      name="space_title"
                      value={values.space_title}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-wrapper">
                    <div className="label">Available Desks</div>
                    <p className="label-subtitle">
                      How many desks would you like to rent out?
                    </p>
                    <input
                      type="number"
                      name="available_desk"
                      value={values.available_desk}
                      onChange={onInputChange}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-wrapper">
                    <div className="label mb-2">Price Type</div>
                    <select
                      type="number"
                      name="cost_type"
                      value={values.cost_type}
                      onChange={onInputChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="single">Single Price</option>
                      <option value="range">Range Price</option>
                    </select>
                  </div>
                </div>

                {values.cost_type === "single" ? (
                  <div className="col-md-8">
                    <div className="input-wrapper">
                      <div className="label">Price</div>
                      <p className="label-subtitle">
                        How much is the price per desk per day?
                      </p>
                      <input
                        type="number"
                        name="price"
                        value={values.price}
                        onChange={onInputChange}
                        onWheel={(e) => e.target.blur()}
                        required
                      />
                    </div>
                  </div>
                ) : values.cost_type === "range" ? (
                  <div className="col-md-8">
                    <div className="input-wrapper">
                      <div className="label mb-2">Availability Type</div>
                      <select
                        type="number"
                        name="availability_type"
                        value={values.availability_type}
                        onChange={onInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1">Day</option>
                        <option value="2">Night</option>
                        <option value="3">Day & Night</option>
                      </select>
                    </div>
                  </div>
                ) : null}

                {values.cost_type === "range" && (
                  <div className="col-md-8">
                    {parseInt(values.availability_type) === 1 ? (
                      <Day onInputChange={onInputChange} values={values} />
                    ) : parseInt(values.availability_type) === 2 ? (
                      <Night onInputChange={onInputChange} values={values} />
                    ) : parseInt(values.availability_type) === 3 ? (
                      <DayNight onInputChange={onInputChange} values={values} />
                    ) : null}
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="input-wrapper minimum">
                    <div className="label">Minimum Term</div>
                    <p className="label-subtitle">If applicable </p>
                    <input
                      type="number"
                      name="min_term"
                      value={values.min_term}
                      onChange={onInputChange}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-wrapper maximum">
                    <div className="label">Maximum Term</div>
                    <p className="label-subtitle">If applicable</p>
                    <input
                      type="number"
                      name="max_term"
                      value={values.max_term}
                      onChange={onInputChange}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="input-wrapper">
                  <div className="label mb-2">Booking Payment Refund</div>
                  {/* <p className="label-subtitle">
                    How much is the price per desk per day?
                  </p> */}
                  <select
                    name="booking_payment_refund"
                    value={values.booking_payment_refund}
                    onChange={onInputChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="1">Refund in 24 hours</option>
                    <option value="2">Refund in 48 hours</option>
                    <option value="3">Not refundable</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="input-wrapper">
                    <div className="label">Key Features</div>

                    <textarea
                      rows={5}
                      placeholder="Please complete."
                      name="key_feature"
                      value={values.key_feature}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tips-wrapper">
                    <div className="label">
                      Key Features <br /> Tips
                    </div>
                    <p>
                      To make your listing effective, please add as much detail
                      as possible. Think about the features that make your space
                      interesting and desirable.
                    </p>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="input-wrapper">
                    <div className="label">Things not included</div>

                    <textarea
                      rows={5}
                      placeholder="Please complete."
                      name="things_not_included"
                      value={values.things_not_included}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tips-wrapper">
                    <div className="label">
                      Things Not Included <br /> Tips
                    </div>
                    <p>
                      It's helpful to flag things might not be covered in the
                      rental fee, but available at an extra cost or request.​
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-wrapper"></div>
            {isLoading ? (
              <div className="button">
                <span className="fa fa-spinner fa-spin px-2"></span>
              </div>
            ) : (
              <button className="button" type="submit">
                Next
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default StepFour;

const AnyReactComponent = () => {
  return (
    <img
      width="30px"
      src="assets/images/marker.png"
      style={{ transform: "translate(-50%, -50%)" }}
    />
  );
};
