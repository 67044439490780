import React from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const VideoPopup = ({ open, onClose, src }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "video-popup" }}
    >
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src={src}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: "40px",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          title="OS POV Filmv4"
        ></iframe>
      </div>
    </Modal>
  );
};

export default VideoPopup;
