import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertySpaces, getPropertyById } from "../../store/homeAction";
import { SET_BOOK_SPACE_DETAILS } from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import SwitchMap from "./SwitchMap";
import "./userBooking.scss";
import PropertyWithSpaceDetails from "./PropertyWithSpaceDetails";

const UserBookingThree = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);

  useEffect(() => {
    getPropertyList();
    getSpaceList();
  }, [match]);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onNext = () => {
    dispatch({
      type: SET_BOOK_SPACE_DETAILS,
      payload: { ...bookSpaceDetails, numberOfWeeks: numberOfWeeks },
    });

    history.push(
      `/userbooking/4/${match.params.propertyId}/${match.params.spaceId}`
    );
  };

  return (
    <div className="user-detail-with-background">
      <div className="container mt-5">
        <div className="user-details-wrapper">
          <div className="user-booking">
            <div className="user-booking-main-wrapper">
              <div className="user-booking-main">
                <div className="user-left">
                  <Link
                    to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                    className="back-btn"
                  >
                    Back to space
                  </Link>
                  {propertyListById && spaceDetails && (
                    <PropertyWithSpaceDetails
                      propertyListById={propertyListById}
                      spaceDetails={spaceDetails}
                    />
                  )}
                  <hr />
                  <div className="user-middle">
                    <h6>How many desks do you need?</h6>
                    <div className="one-desc">
                      We will only show you available dates with the amount of
                      desks you require available and next to each other. If you
                      do not require desks next to each other, please uncheck
                      the box below.
                    </div>
                  </div>
                  <hr />
                  <div className="user-bottom">
                    <div className="user-select">
                      <h6>Select number of weeks</h6>
                      <div>
                        <i
                          className="fas fa-chevron-left"
                          onClick={() =>
                            setNumberOfWeeks(
                              numberOfWeeks > 1 ? numberOfWeeks - 1 : 1
                            )
                          }
                        ></i>
                        <span>{numberOfWeeks}</span>
                        <i
                          className="fas fa-chevron-right"
                          onClick={() => setNumberOfWeeks(numberOfWeeks + 1)}
                        ></i>
                      </div>
                    </div>

                    <div className="buttons">
                      <div onClick={onNext} className="one-btn btn">
                        Continue
                      </div>
                    </div>
                  </div>
                </div>
                <SwitchMap
                  propertyListById={propertyListById}
                  spaceDetails={spaceDetails}
                ></SwitchMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookingThree;
