import React from "react";
import "./loading.scss";

const Loading = () => {
  return (
    <div>
      <div className="loading-wrapper">
        <img className="loading-gif" src="assets/gif/loading.gif" alt="" />
      </div>
    </div>
  );
};

export default Loading;
