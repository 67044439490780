import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { toast } from "react-toastify";
import { getUserDetails, onSearch } from "../../store/homeAction";
import firebase from "firebase";
import IconChevron from '../../icons/icon_chevron';
import IconSharer from '../../icons/icon-sharer';
import IconDashboard from '../../icons/icon-dashboard';
import IconLogout from '../../icons/icon-logout';
import './header.scss';

const navigations = [
  {
    name: 'Home',
    path: '/',
    active: false,
  },
  {
    name: 'About Us',
    path: '/about',
    active: false,
  },
  {
    name: 'How It Works',
    path: '/how-it-works',
    active: false,
  },
  {
    name: 'Explore',
    path: '/search',
    active: false,
  },
]

const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userDetail = JSON.parse(localStorage.getItem("os-user"));

  const [navList, setNavList] = React.useState(navigations);
  const [show, handleShow] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [mobileNavOpen, setMobileNavOpen] = React.useState();

  const userInfo = useSelector((state) => state.home.userDetails);

  const goToListASpace = () => {
    if (userInfo?.id) {
      if (userInfo?.user_type === "User") {
        if (!userInfo?.is_verified) {
          toast("Please verify your email ", {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
        } else {
          history.push("/onboarding/2");
        }
      } else {
        history.push("/add-property/3");
      }
    } else {
      history.push("/landlord-login");
    }
    if (mobileNavOpen) {
      setMobileNavOpen(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    firebase.auth().signOut();
  };

  const transitionNavBar = () => {
    handleShow(window.scrollY > 70)
  };
  
  React.useEffect(() => {
    if (userDetail) {
      dispatch(getUserDetails({ user_id: userDetail.id }));
    }
  }, []);

  React.useEffect(() => {
    if (location.pathname) {
      setNavList((nav) => nav.map((v) => ({
          ...v,
          active: v.path === location.pathname
        })),
      );
    }

    return () => {
      setNavList(navigations);
    }
  }, [location.pathname]);


  React.useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);

  React.useEffect(() => {
    if (location.pathname && props?.callbackHeader) {
      props?.callbackHeader(location.pathname);
    }
  }, [location.pathname])

  React.useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (mobileNavOpen) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    }
  }, [mobileNavOpen]);

  return (
    <header className={clsx('header', { down: show, 'header--hero': location.pathname === '/' })}>
      <div className="header--logo">
        <Link to="/">
          <img src="/assets/images/img_app_logo.png" alt="office share logo" />
        </Link>
      </div>
      <div className="header--nav">
        <ul className="header--nav__list">
          {navList.map((v, key) => (
            <li key={key}>
              <Link
                to={v.path}
                className={clsx('nav--items', { active: v.active, 'header--hero': location.pathname === '/' && !show })}
                onClick={v.name === 'Explore' ? () => {
                  const data = {
                    search: "",
                    selectedRating: [],
                    selectedAmenities: [],
                    addPropertyDetails: null,
                    isAdminUserAdded: false,
                  };
                  setMobileNavOpen(false);
                  dispatch(onSearch(data));
                } : null}
              >
                {v.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {!userInfo?.id ? (
        <div className="header--cta">
          <Link to="/onboarding" className="header--login">
            <div className="header--login__icon">
              <img src="/assets/icons/profile-icon.svg" alt="profile" />
            </div>
            <p className={clsx({ 'text-white': location.pathname === '/' && !show })}>Login / Register</p>
          </Link>
          
            <button type="button" className="button header--list" onClick={goToListASpace}>
              List A Space
              <div className="header--list--icon">
                <IconChevron color="#fff" size={12} />
              </div>
            </button>
            <button type="button" className={clsx('btn--hamburger', { open: mobileNavOpen })} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </button>
        </div>
      ) : (
        <div className="header--cta">
          <div className="header--login">
            <div className="header--log  in__icon">
              <img src="/assets/icons/profile-icon.svg" alt="profile" />
            </div>
            <div className="d-flex flex-column position-relative">
              <p className={clsx('logged', { 'text-white': location.pathname === '/' && !show })}>{`Hi ${userInfo?.first_name || userInfo?.name}!`}</p>
              <button type="button" className="profile--btn" onClick={() => setShowDropdown(!showDropdown)}>
                My Dashboard
                <IconChevron size={10} />
              </button>
              {showDropdown && (
                <div className="nav--dropdown">
                  <Link to="/mydashboard/0" className="nav--dropdown__item" onClick={() => setShowDropdown(false)}>
                    <IconSharer size={24} />
                    <p className="dp--text">My Sharer Dashboard</p>
                  </Link>
                  <button type="button" className="nav--dropdown__item" onClick={() => {
                    goToListASpace();
                    setShowDropdown();
                  }}>
                    <IconDashboard size={22} />
                    <p className="dp--text">My Host Dashboard</p>
                  </button>
                  <div className="nav--dropdown__divider" />
                  <button type="button" className="nav--dropdown__item">
                    <IconLogout size={18} />
                    <p className="dp--text" onClick={handleLogout}>Logout</p>
                  </button>
                </div>
              )}
            </div>
          </div>
          <button type="button" className={clsx('btn--hamburger', { open: mobileNavOpen })} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </button>
        </div>
      )}
      <div className={clsx('mobile--nav', { open: mobileNavOpen, close: !mobileNavOpen && mobileNavOpen !== undefined })}>
        <ul className={clsx('header--nav__list', 'for-mobile')}>
          {navList.map((v, key) => (
            <li key={key}>
              <Link
                to={v.path}
                className={clsx('nav--items for-mobile', { active: v.active })}
                onClick={() => {
                  if (v.name === 'Explore') {
                    const data = {
                      search: "",
                      selectedRating: [],
                      selectedAmenities: [],
                      addPropertyDetails: null,
                      isAdminUserAdded: false,
                    };
                    dispatch(onSearch(data));
                  }
                  setMobileNavOpen(false);
                }}
              >
                {v.name}
              </Link>
            </li>
          ))}
          <li className="mobile--nav--login">
            <div className="mobile--nav--divider" />
            {!userInfo?.id ? (
              <Link to="/onboarding" className="header--login for-mobile" onClick={() => setMobileNavOpen(false)}>
                <div className="header--login__icon">
                  <img src="/assets/icons/profile-icon.svg" alt="profile" />
                </div>
                <p className={clsx({ 'text-white': location.pathname === '/' && !show })}>Login / Register</p>
              </Link>
            ) : (
              <div className="mobile--logged">
                <Link to="/mydashboard/0" className="header--login for-mobile p-0 logged" onClick={() => setMobileNavOpen(false)}>
                  <IconSharer size={24} />
                  <p className="dp--text">My Sharer Dashboard</p>
                </Link>
                <button
                  type="button"
                  className="header--login for-mobile p-0 logged clear--btn--border"
                  onClick={() => {
                    goToListASpace();
                    setMobileNavOpen();
                  }}
                >
                  <IconDashboard size={22} />
                  <p className="dp--text">My Host Dashboard</p>
                </button>
              </div>
            )}
            <div className="mobile--nav--divider" />
            {userInfo?.id && (
              <>
                <button type="button" className="nav--dropdown__item logout--mobile">
                  <IconLogout size={18} />
                  <p className="dp--text m-0" onClick={handleLogout}>Logout</p>
                </button>
                <div className="mobile--nav--divider" />
              </>
            )}
          </li>
          <li>
            <button type="button" className="button header--list for-mobile" onClick={goToListASpace}>
              List A Space
              <div className="header--list--icon">
                <IconChevron color="#fff" size={12} />
              </div>
            </button>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
