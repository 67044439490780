import React, { useEffect, useState } from "react";
import "./login.scss";
import { useSelector, useDispatch } from "react-redux";
import { getRegions, onLogin } from "../../store/homeAction";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import ForgotPasswordPopup from "../../popups/ForgotPasswordPopup";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoginLoading = useSelector((state) => state.home.isLoginLoading);
  const [values, setValues] = useState({});
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const fcmToken = localStorage.getItem("fcm_token");
  const userDetail = JSON.parse(localStorage.getItem("os-user"));

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();

    let data = {
      email: values.email,
      password: values.password,
      firebase_token: fcmToken,
    };

    dispatch(onLogin(data));
  };

  useEffect(() => {
    let isMounted = true; // Step 1: Track the mounted status
    const phone = localStorage.getItem("os-phone-number");
    if (isMounted) {
      setValues({...values, phone: phone});
    }
    dispatch(getRegions());
    return () => {
      isMounted = false; // Step 3: Set it to false on unmount
    };
  }, []);

  if (userDetail?.id) {
    history.replace("/");
  }

  return (
    <div className="onboarding-main pt-5 onboarding-height">
      <div className="left"></div>
      <div className="right">
        <div className="download-section">
          <h5>
            <b>Download</b>
          </h5>
          <h5>
            The OfficeShare app<span className="text-primary">...</span>
          </h5>
          <div className="image-div">
            <img src="./assets/images/playstore.png" alt="" />
            <img src="./assets/images/appstore.png" alt="" />
          </div>
        </div>
      </div>
      <div className="onboarding-background"></div>
      <div className="container onboarding--fields">
        <div className="onboarding-wrapper">
          <div className="left-section">
            <h2>
              Let's get started<span className="text-primary">!</span>
            </h2>
            <h4 className="mt-3">
              <Link to="/onboarding" className="text-secondary">
                Sign up
              </Link>
              &nbsp;&nbsp;&nbsp;
              <span className="text-primary">|</span>
              &nbsp;&nbsp;&nbsp;
              <span className="text-dark">
                Log in <small className="user-small"> (Existing user?)</small>
              </span>
            </h4>
            <div className="input-group-wrapper">
              <form onSubmit={onSubmitForm}>
                <div className="input-wrapper">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={onInputChange}
                    className="login-page-input"
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">Password:</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={onInputChange}
                    className="login-page-input"
                    required
                  />
                  {showPassword ? (
                    <i
                      className="fa fa-eye text-secondary cursor-pointer"
                      aria-hidden="true"
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye-slash text-secondary cursor-pointer"
                      aria-hidden="true"
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  )}
                </div>
                <div className="d-flex">
                  <div className="input-group justify-content-between align-items-center mb-4">
                    <p
                      className="btn-link cursor-pointer m-0"
                      onClick={() => setOpenForgotPassword(true)}
                    >
                      Forgot Password?
                    </p>
                    {isLoginLoading ? (
                      <button type="submit" className="btn-submit">
                        <span className="fa fa-spinner fa-spin px-3"></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn-submit">
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="right-section d-md-none">
            <div className="download-section">
              <h5>
                <b>Download</b>
              </h5>
              <h5>
                The OfficeShare app<span className="text-primary">...</span>
              </h5>
              <div className="image-div">
                <img src="./assets/images/playstore.png" alt="" />
                <img src="./assets/images/appstore.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ForgotPasswordPopup
        open={openForgotPassword}
        onClose={() => setOpenForgotPassword(false)}
      ></ForgotPasswordPopup>
    </div>
  );
};

export default Login;
