import React from "react";
import clsx from 'clsx';
import "./../popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useSelector } from "react-redux";
import './landlordStyle.scss';

const NewLandlord = ({
  onClose,
  open,
  edit,
}) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);

  const isLoading = useSelector(
    (state) => state.home.isLoading
  );

  const submitLandlord = () => {
    if (!firstName.trim().length && !lastName.trim().length) {
      setFirstNameError(true);
      setLastNameError(true);
      setFirstName('');
      setLastName('')
      return;
    }
    if (!firstName.trim().length) {
      setFirstNameError(true);
      setFirstName('');
      return;
    }
    if (!lastName.trim().length) {
      setLastNameError(true);
      setLastName('')
      return;
    } 
    onClose('submit', { firstName, lastName }, edit);
    setFirstName('');
    setLastName('')
  };

  React.useEffect(() => {
    if (edit?.id) {
      setFirstName(edit?.first_name);
      setLastName(edit?.last_name);
    }
    return () => {
      setFirstName('');
      setLastName('');
    }
  }, [edit]);

  React.useEffect(() => {
    if (open) {
      setFirstNameError(false);
      setLastNameError(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={(e) => {
        onClose(e);
        setFirstName('');
        setLastName('');
        setFirstNameError(false);
        setLastNameError(false);
      }}
      classNames={{ modal: "amenities-popup-modal experience-modal" }}
    >
      <div className="la-modal__wrapper">
        <h5 className="text-primary">{edit?.id ? 'Edit ' : 'New '}Landlord</h5>

        <div className="la-form--wrapper">
          <div className="la-modal__form">
            <label htmlFor="name">First name of Landlord *</label>
            <input
              type="text"
              name="name"
              placeholder=""
              className={clsx('field', { 'input--error': firstNameError })}
              value={firstName}
              onInput={(e) => {
                setFirstNameError(firstNameError && !firstName);
                setFirstName(e.target.value)
              }}
            />
            <p className={clsx('field--error', { show: firstNameError })}>First name field is required</p>
          </div>
          <div className="la-modal__form">
            <label htmlFor="name">Last name of Landlord *</label>
            <input
              type="text"
              name="name"
              placeholder=""
              className={clsx('field', { 'input--error': lastNameError })}
              value={lastName}
              onInput={(e) => {
                setLastNameError(lastNameError && !lastName);
                setLastName(e.target.value)
              }}
            />
            <p className={clsx('field--error', { show: lastNameError })}>Last name field is required</p>
          </div>
        </div>
        
        <div className="la-modal__btn">
          {!isLoading && (
            <button type="button" className="la-modal__btn__cancel" onClick={onClose}>Cancel</button>
          )}
          <button
            type="button"
            className="la-modal__btn__submit"
            onClick={() => submitLandlord()}
          >
            {!isLoading ? 'Submit' : (
              <div className="btns d-flex justify-content-center">
                <span className="fa fa-spinner fa-spin px-3"></span>
              </div>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewLandlord;

