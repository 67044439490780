import React from 'react';

const IconEmptyState = ({ size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill="#f3f2f1" d="M12.983 17.614l-5.969 3.992-5.879-8.788 5.969-3.992 5.879 8.788z"></path>
      <path fill="#dadbd8" d="M9.899 17.197l-0.068 0.419-2.967-0.427 0.068-0.419 2.967 0.427z"></path>
      <path fill="#dadbd8" d="M8.405 15.702l0.426 0.061-0.472 2.921-0.426-0.061 0.472-2.921z"></path>
      <path fill="#dadbd8" d="M5.671 11.989c0.004 0.006-0.594 0.414-1.336 0.91s-1.347 0.894-1.351 0.887c-0.004-0.006 0.594-0.414 1.336-0.91s1.347-0.893 1.351-0.887z"></path>
      <path fill="#dadbd8" d="M7.867 11.438c0.004 0.006-0.99 0.679-2.221 1.502s-2.233 1.486-2.237 1.479 0.99-0.679 2.221-1.502c1.231-0.823 2.233-1.486 2.237-1.479z"></path>
      <path fill="#dadbd8" d="M8.307 12.096c0.004 0.006-0.99 0.679-2.221 1.502s-2.233 1.486-2.237 1.48c-0.004-0.006 0.99-0.679 2.221-1.502s2.233-1.486 2.237-1.479z"></path>
      <path fill="#dadbd8" d="M8.747 12.753c0.004 0.006-0.99 0.679-2.221 1.502s-2.233 1.486-2.237 1.479 0.99-0.679 2.221-1.502c1.231-0.823 2.233-1.486 2.237-1.479z"></path>
      <path fill="#dadbd8" d="M9.187 13.412c0.004 0.006-0.99 0.679-2.221 1.502s-2.233 1.486-2.237 1.479 0.99-0.679 2.221-1.502c1.231-0.823 2.233-1.486 2.237-1.479z"></path>
      <path fill="#f3f2f1" d="M32 22.096l-5.013 3.353-4.937-7.38 5.013-3.353 4.937 7.38z"></path>
      <path fill="#dadbd8" d="M29.41 21.746l-0.057 0.352-2.492-0.359 0.057-0.352 2.492 0.359z"></path>
      <path fill="#dadbd8" d="M28.155 20.49l0.357 0.052-0.396 2.453-0.358-0.052 0.396-2.453z"></path>
      <path fill="#dadbd8" d="M25.86 17.372c0.004 0.006-0.498 0.349-1.121 0.766s-1.132 0.75-1.136 0.743c-0.004-0.006 0.498-0.349 1.121-0.766s1.132-0.75 1.136-0.743z"></path>
      <path fill="#dadbd8" d="M27.703 16.909c0.004 0.006-0.831 0.572-1.864 1.263s-1.876 1.247-1.88 1.241 0.83-0.572 1.865-1.263c1.034-0.691 1.876-1.247 1.88-1.241z"></path>
      <path fill="#dadbd8" d="M28.073 17.462c0.004 0.006-0.831 0.572-1.864 1.263s-1.876 1.247-1.88 1.241 0.83-0.572 1.865-1.264c1.034-0.691 1.876-1.247 1.88-1.241z"></path>
      <path fill="#dadbd8" d="M28.442 18.014c0.004 0.006-0.831 0.572-1.864 1.263s-1.876 1.247-1.88 1.241c-0.004-0.006 0.83-0.572 1.865-1.264s1.876-1.247 1.88-1.241z"></path>
      <path fill="#dadbd8" d="M28.812 18.566c0.004 0.006-0.831 0.572-1.864 1.263s-1.876 1.247-1.88 1.241c-0.004-0.006 0.83-0.572 1.865-1.264s1.876-1.247 1.88-1.24z"></path>
      <path fill="#f3f2f1" d="M14.825 17.201l-5.469-2.925 4.307-8.053 5.469 2.925-4.307 8.053z"></path>
      <path fill="#dadbd8" d="M13.889 14.682l-0.347 0.119-0.881-2.435 0.347-0.119 0.881 2.435z"></path>
      <path fill="#dadbd8" d="M14.421 12.935l0.126 0.349-2.418 0.828-0.126-0.349 2.418-0.828z"></path>
      <path fill="#dadbd8" d="M16.139 9.343c-0.004 0.008-0.559-0.28-1.239-0.644s-1.228-0.665-1.224-0.673c0.004-0.008 0.559 0.28 1.239 0.644s1.228 0.665 1.224 0.673z"></path>
      <path fill="#dadbd8" d="M17.452 10.791c-0.004 0.008-0.922-0.474-2.050-1.078s-2.039-1.099-2.035-1.107c0.004-0.008 0.922 0.474 2.050 1.078s2.039 1.099 2.035 1.107z"></path>
      <path fill="#dadbd8" d="M17.129 11.394c-0.004 0.008-0.922-0.474-2.050-1.078s-2.039-1.099-2.035-1.107c0.004-0.008 0.922 0.474 2.050 1.078s2.039 1.099 2.035 1.107z"></path>
      <path fill="#dadbd8" d="M16.807 11.997c-0.004 0.008-0.922-0.474-2.050-1.078s-2.039-1.099-2.035-1.107c0.004-0.008 0.922 0.474 2.050 1.078s2.039 1.099 2.035 1.107z"></path>
      <path fill="#dadbd8" d="M16.485 12.599c-0.004 0.008-0.922-0.474-2.050-1.078s-2.039-1.099-2.035-1.107c0.004-0.008 0.922 0.474 2.050 1.078s2.039 1.099 2.035 1.107z"></path>
      <path fill="#17282f" d="M30.712 25.716c0 0.008-6.876 0.014-15.355 0.014-8.482 0-15.357-0.006-15.357-0.014s6.874-0.014 15.357-0.014c8.479 0 15.355 0.006 15.355 0.014z"></path>
      <path fill="#e35447" d="M1.7 22.385c0.18 0.006 0.349 0.104 0.467 0.239s0.191 0.305 0.238 0.479c0.090 0.336 0.035 0.733-0.061 1.068-0.364-0.13-0.589-0.518-0.69-0.723-0.16-0.323-0.254-1.003 0.046-1.064z"></path>
      <path fill="#e35447" d="M2.868 24.579c-0.115-0.165-0.118-0.394-0.027-0.573s0.265-0.309 0.457-0.368c0.089-0.027 0.192-0.039 0.269 0.013 0.072 0.049 0.104 0.143 0.094 0.229s-0.053 0.165-0.105 0.235c-0.177 0.24-0.393 0.439-0.69 0.464z"></path>
      <path fill="#17282f" d="M2.908 25.703c-0.002 0-0.007-0.025-0.016-0.070-0.010-0.050-0.022-0.114-0.038-0.192-0.029-0.161-0.074-0.39-0.040-0.644 0.033-0.252 0.134-0.468 0.239-0.597 0.052-0.065 0.101-0.111 0.138-0.14 0.018-0.015 0.033-0.024 0.043-0.031s0.016-0.010 0.016-0.009c0.004 0.005-0.085 0.059-0.184 0.191-0.1 0.129-0.195 0.341-0.228 0.589s0.008 0.475 0.032 0.638c0.013 0.081 0.024 0.147 0.030 0.193s0.008 0.071 0.006 0.071z"></path>
      <path fill="#17282f" d="M1.817 22.897c0.001-0 0.006 0.009 0.015 0.028 0.010 0.021 0.022 0.048 0.038 0.081 0.032 0.071 0.077 0.173 0.132 0.301 0.11 0.255 0.256 0.609 0.41 1.003s0.283 0.755 0.374 1.017c0.046 0.131 0.081 0.237 0.105 0.311 0.011 0.035 0.020 0.063 0.027 0.085 0.006 0.019 0.009 0.030 0.008 0.030s-0.005-0.009-0.013-0.028c-0.008-0.022-0.019-0.049-0.031-0.084-0.028-0.076-0.066-0.18-0.113-0.308-0.095-0.261-0.228-0.62-0.381-1.014s-0.297-0.749-0.402-1.006c-0.052-0.126-0.094-0.229-0.125-0.304-0.014-0.034-0.025-0.061-0.033-0.083-0.007-0.019-0.011-0.029-0.010-0.030z"></path>
      <path fill="#e35447" d="M2.475 24.96c-0.215-0.365-0.616-0.614-1.038-0.645-0.108-0.008-0.237 0.010-0.285 0.107s0.016 0.212 0.089 0.291c0.305 0.33 0.821 0.446 1.238 0.276z"></path>
      <path fill="#17282f" d="M1.601 24.603c0-0.002 0.024-0.001 0.068 0.003 0.022 0.002 0.048 0.003 0.079 0.009s0.066 0.009 0.103 0.019c0.038 0.009 0.080 0.019 0.123 0.034 0.044 0.013 0.089 0.032 0.137 0.052 0.094 0.043 0.193 0.099 0.288 0.171 0.094 0.073 0.174 0.155 0.238 0.236 0.031 0.041 0.061 0.080 0.084 0.12 0.025 0.038 0.045 0.076 0.063 0.111 0.019 0.034 0.030 0.068 0.043 0.096s0.020 0.054 0.027 0.075c0.013 0.042 0.019 0.064 0.017 0.065-0.005 0.002-0.031-0.091-0.103-0.227-0.019-0.034-0.038-0.071-0.064-0.108-0.024-0.039-0.053-0.077-0.085-0.117-0.064-0.078-0.143-0.158-0.236-0.23s-0.19-0.127-0.282-0.17c-0.047-0.020-0.091-0.040-0.134-0.053-0.042-0.016-0.083-0.027-0.12-0.037-0.149-0.038-0.245-0.043-0.245-0.048z"></path>
      <path fill="#17282f" d="M26.698 25.638c0.002 0.002-0.005 0.013-0.011 0.027s-0.009 0.026-0.012 0.026c-0.003 0-0.005-0.015 0.002-0.031s0.019-0.025 0.021-0.022z"></path>
      <path fill="#17282f" d="M26.767 25.552c0.002 0.002-0.004 0.012-0.013 0.022s-0.019 0.016-0.021 0.014c-0.002-0.002 0.004-0.012 0.013-0.022s0.019-0.016 0.022-0.014z"></path>
      <path fill="#17282f" d="M26.865 25.512c-0.001 0.003-0.013 0.003-0.027 0.008s-0.024 0.012-0.026 0.010c-0.003-0.002 0.005-0.015 0.022-0.020s0.031-0 0.030 0.003z"></path>
      <path fill="#e35447" d="M11.311 14.797l-2.127 10.936 12.558-0.062c0.564-0.003 1.063-0.503 1.244-1.246l2.227-9.129c0.138-0.564-0.164-1.151-0.592-1.151l-12.71 0.013c-0.281 0-0.526 0.262-0.599 0.639z"></path>
      <path fill="#7e1c2c" d="M21.902 25.657c0.090-0.022 0.167-0.046 0.233-0.071-0.075 0.033-0.153 0.057-0.233 0.071z"></path>
      <path fill="#7e1c2c" d="M22.030 25.372c-1.362 0-1.494-1.559-1.494-1.56l-1.199-9.662 5.283-0.006c0 0 0 0 0 0 0.357 0 0.625 0.408 0.625 0.871-0 0.092-0.011 0.186-0.034 0.28l-2.227 9.129c-0.094 0.383-0.272 0.702-0.499 0.921 0.011-0.018 0.015-0.029 0.015-0.029-0.171 0.038-0.328 0.055-0.471 0.055z"></path>
      <path fill="#e35447" d="M20.537 23.812l-1.33-10.713c-0.055-0.439-0.404-0.766-0.817-0.765l-2.916 0.009c-0.216 0.001-0.423 0.093-0.576 0.255l-1.384 1.471-8.108 0.042c-0.495 0.003-0.876 0.471-0.811 0.998l1.235 9.901c0.055 0.438 0.404 0.765 0.816 0.765l14.131-0.028c1.574 0.033 1.724-0.432 1.724-0.432-1.804 0.4-1.964-1.504-1.965-1.505z"></path>
      <path fill="#fff" d="M13.966 22.18l-1.572-1.414 0.477-0.512 1.572 1.414-0.477 0.512zM11.868 20.292l-1.572-1.414 0.477-0.512 1.572 1.414-0.476 0.512z"></path>
      <path fill="#fff" d="M10.972 22.293l-0.527-0.474 3.321-3.566 0.527 0.474-3.321 3.567z"></path>
    </svg>
  );
};

export default IconEmptyState;