import { useState } from "react";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./stripePayment.scss";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Wrapper = (props) => (
  <Elements stripe={stripePromise}>
    <MyComponent {...props} />
  </Elements>
);

const MyComponent = ({ onBookSpace, type, detailsAreCorrect }) => {
  const addCardLoading = useSelector((state) => state.home.addCardLoading);

  const [isLoading, setIsLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (detailsAreCorrect) {
      setIsLoading(true);

      const cardElement = elements.getElement("card");

      stripe.createToken(cardElement).then((payload) => {

        if (payload.error) {
          toast(payload.error.message, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
          return;
        }

        if (type === "book") {
          onBookSpace(payload.token, "newCard");
        }
      });
    } else {
      toast("Please confirm all the above details are correct", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  };

  const onInputChange = (empty, complete, error) => {
    setComplete(complete);
  };

  return (
    <div className="stripe-wrapper">
      <div className="d-flex pb-3 align-items-start">
        <h4 className="text-primary">Add New Card Details</h4>{" "}
        <div className="stripeLogo">
          <img src="/assets/icons/stripe_logo.png" alt="" />
          <h6>(Secured by Stripe)</h6>
        </div>
      </div>

      <form className="card card-body" onSubmit={handleSubmit}>
        <label>Card details</label>
        <div className="py-4">
          <CardElement
            onChange={({ empty, complete, error }) =>
              onInputChange(empty, complete, error)
            }
          />
        </div>

        <div className="mt-3">
          {addCardLoading ? (
            <div className="button-payment">
              <span className="fa fa-spinner fa-spin px-3"></span>
            </div>
          ) : complete ? (
            <button className="button-payment" disabled={!stripe}>
              Go to Payment
            </button>
          ) : (
            <div className="mt-1">
              <div className="button-payment button-disabled">
                Go to Payment
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Wrapper;
