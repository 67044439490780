import React, { useState } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Calendar from "react-calendar";
import "./calendarPopup.scss";

const ConfirmPopup = ({ open, onClose, onApply }) => {
  const [dates, setDates] = useState();
  const [firstDate, setFirstDate] = useState();
  const [bothDate, setBothDate] = useState();

  const onSetFirstDate = (date) => {
    setFirstDate([date, date]);
    setDates([date, date]);
  };

  const onSetBothDate = (dates) => {
    setBothDate(dates);
    setDates(dates);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "confirm-popup-modal calendar-popup-modal" }}
    >
      <div className="user-calender">
        <Calendar
          selectRange={firstDate ? true : false}
          onChange={(dates) =>
            firstDate ? onSetBothDate(dates) : onSetFirstDate(dates)
          }
        ></Calendar>
      </div>
      <hr />
      <div className="d-flex justify-content-end">
        <div
          className="apply-btn"
          onClick={() => {
            onClose();
            onApply(dates);
            setFirstDate(false);
          }}
        >
          Apply
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPopup;
