import React from 'react';
import Header from '../../components/header/Header';
import { Link, useHistory } from 'react-router-dom';

const StepFive = () => {

	const history = useHistory();

	function gotoBackStep() {
		history.push('/add-property/4')
	}

	return(
		<div className="container">
			<div className="add-property-step-wrapper">

				<div className="step-detail">
					<h5>
						<b> Your Properties </b>
					</h5>
					<div className="row">
						<p className="instruction">Your properties are listed below.</p>
					</div>
				</div>
				<div className="property-wrapper">
					<div className="property-item">
					    <div className="add-property-btn">
					        <p>
						        <img className="camera-icon" width="45px" src="assets/images/add-icon.png"  alt="Ad New Property"/> Add new property
					        </p>
				        </div>
						<div className="details">
							<p className="title"> Property Name</p>
							<div className="subtitle">
								31 London Drive <br />
								London <br />
								SE4 6HJ
							</div>
						</div>
						<div className="action">
						<div className="button">Add A Space</div>
							<div className="button">Edit Property</div>
							<div className="button">Delete</div>
						</div>
					</div>
				</div>
            </div>

			<div className="add-property-step-wrapper">
				<div className="property-wrapper">
					<div className="property-item">
					    <div className="add-property-btn">
						    <img className="property-image" width="120px" height="100px" src="assets/images/office1.png"  alt="Property Image"/>
				        </div>
						<div className="details">
							<p className="title"> Top Floor Space</p>
							<div className="subtitle">
								31 London Drive
							</div>
						</div>
						<div className="action">
							<div className="button">Edit Space</div>
							<div className="button">Delete</div>
						</div>
					</div>
				</div>
            </div>
		</div>
	)

};

export default StepFive;
