import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyByUser,
  getPropertySpaces,
  deleteProperty,
  deleteSpace,
  getUserDetails,
} from "../../store/homeAction";
import {
  SET_ADD_PROPERTY_DETAILS,
  SET_PROPERTY_BY_ID,
  SET_SPACE_AVAILIBILITY_DATES,
} from "../../store/types";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./steps.scss";
import Loading from "../../components/loading/Loading";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import EarningWithdrawPopup from "../../popups/EarningWithdrawPopup";
import AddBankAccountPopup from "../../popups/AddBankAccountPopup";
import ManageAvailabilityPopup from "../../popups/ManageAvailabilityPopup";
import KYCPopup from "../../popups/KYCPopup";
import { EventEmitter } from "../../utils/EventEmitter";

const StepThree = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const propertyListByUser = useSelector(
    (state) => state.home.propertyListByUser
  );
  const userDetails = useSelector((state) => state.home.userDetails);
  const isLoading = useSelector((state) => state.home.isLoading);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const userDetail = JSON.parse(localStorage.getItem("os-user"));
  const [propertySpaceActiveId, setPropertySpaceActiveId] = useState();
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);

  const [manageAvailabilityPopup, setManageAvailabilityPopup] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [spaceId, setSpaceId] = useState(null);
  const handleManageAvailabilityPopup = (propertyId, spaceId) => {
    setManageAvailabilityPopup(true);
    setPropertyId(propertyId);
    setSpaceId(spaceId);
  };

  const handleClosePopup = () => {
    setManageAvailabilityPopup(false);
    setPropertyId(null);
    setSpaceId(null);
  };

  useEffect(() => {
    let data = {
      user_id: userDetail && userDetail.id,
    };
    dispatch(getPropertyByUser(data, history));
  }, []);

  useEffect(() => {
    dispatch(getUserDetails({ user_id: userDetail && userDetail.id }));
  }, []);


  const onDeleteProperty = (id) => {
    const formData = new FormData();
    formData.append("property_id", id);
    dispatch(deleteProperty(formData, history));
  };

  const onDeleteSpace = (id) => {
    const formData = new FormData();
    formData.append("space_id", id);
    dispatch(deleteSpace(formData, history));
  };

  function gotoBackStep() {
    history.push("/add-property/3");
  }

  function gotoNextStep() {
    history.push("/add-property/5");
  }

  function gotoNewPropertyAdd() {
    dispatch({
      type: SET_PROPERTY_BY_ID,
      payload: null,
    });
    dispatch({
      type: SET_ADD_PROPERTY_DETAILS,
      payload: {},
    });
    dispatch({
      type: SET_SPACE_AVAILIBILITY_DATES,
      payload: null,
    });
    history.push("/add-property/1");
  }

  const goToEditProperty = (propertyId) => {
    history.push(`/add-property/1/${propertyId}`);
  };

  const goToAddProperty = () => {
    if (userDetail.kyc_status) {
      history.push('/add-property/1');
    } else {
      EventEmitter.dispatch(EventEmitter.events.KycRequired, {
        isModalOpen: true
      });
    }
  }

  return (
    <>
      <LandlordBar />
      {userDetail && userDetail.strip_kyc_status === "verified" ? null : (
        <div className="complete-kycWrapper">
          <h6>KYC in not completed yet. Please complete Your KYC</h6>
          <Link
              to={"#"}
              className="ml-3" onClick={() => setOpenAddBankAccountModal(true)}>
            Add Bank Account
          </Link>
        </div>
      )}
      <div className="container mt-5">
        <div className="add-property-step-wrapper">
          {propertyListByUser && propertyListByUser.length <= 1 ? (
            <>
              <div className="step-progress">
                <p className="text-text">
                  <b> Step 3 :</b> Your Properties
                </p>
                <div className="progress-bar">
                  <div className="fill" style={{ width: "80%" }}></div>
                </div>
              </div>
              <div className="step-detail">
                <h5>
                  <b>More properties to add?</b>
                </h5>
                <div className="row">
                  <p className="instruction">
                    The next step is to add a space or spaces within your
                    property.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <p className="instruction">Your properties are listed below.</p>
              </div>
            </>
          )}

          <div className="add-property-btn" onClick={goToAddProperty}>
            <h6>
              <img
                className="camera-icon"
                width="45px"
                src="assets/images/add-icon.png"
               alt="Add property"/>
              Add new property
            </h6>
          </div>

          {isLoading ? (
            <Loading></Loading>
          ) : (
            <div className="property-wrapper fade-in-bottom">
              {propertyListByUser && propertyListByUser.length > 0 ? (
                propertyListByUser.map((item, key) => (
                  <div key={key} className="property-item">
                    <div className="property-div">
                      <div className="details">
                        <p className="title"> {item.property_title}</p>
                        <div className="subtitle">
                          {item.address_line_one} <br />
                          {item.address_line_two === undefined
                            ? null
                            : item.address_line_two}
                        </div>
                      </div>
                      <div className="action">
                        <Link
                          to={`/add-space/${item.id}`}
                          className="button"
                          onClick={gotoNewPropertyAdd}
                        >
                          Add A Space
                        </Link>
                        <Link
                          to="#"
                          onClick={() => goToEditProperty(item.id)}
                          className="button button-outline"
                        >
                          Edit Property
                        </Link>
                        <Link
                          to={`/property/${item.id}`}
                          className="button button-outline"
                        >
                          View Property
                        </Link>
                        <div
                          className="button button-outline"
                          onClick={() => onDeleteProperty(item.id)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="property-space-wrapper">
                      {propertySpaceActiveId === item.id ? (
                        <div
                          className="toggle-button"
                          onClick={() => setPropertySpaceActiveId(0)}
                        >
                          <i className="fas fa-chevron-up"></i> View Property
                          Spaces
                        </div>
                      ) : (
                        <div
                          className="toggle-button"
                          onClick={() => {
                            setPropertySpaceActiveId(item.id);
                            // dispatch(
                            //   getPropertySpaces({ property_id: item.id })
                            // );
                          }}
                        >
                          <i className="fas fa-chevron-down"></i> Close Property
                          Spaces
                        </div>
                      )}
                    </div>
                    {propertySpaceActiveId !== item.id && (
                      <div className="swing-in-top-bck">
                        <hr />
                        {item.space && item.space.length > 0 ? (
                          item.space.map((space, key) => (
                            <div key={key} className="space-div py-2">
                              <div className="details">
                                <img
                                  className="space-image"
                                  src={space.thumb}
                                  alt=""
                                />
                                <div style={{ width: "65%" }}>
                                  <p className="title mb-1">
                                    {" "}
                                    {space.space_title}
                                  </p>
                                  <div className="subtitle">
                                    Available desks : {space.total_desk}
                                  </div>
                                </div>
                              </div>
                              <div className="action">
                                <Link
                                  to={`/space/${item.id}/${space.id}`}
                                  className="button"
                                >
                                  Open Workspace
                                </Link>

                                <Link
                                  to={`/add-space/${item.id}/${space.id}`}
                                  className="button"
                                  onClick={() => {
                                    dispatch({
                                      type: SET_SPACE_AVAILIBILITY_DATES,
                                      payload: null,
                                    });
                                  }}
                                >
                                  Edit Space
                                </Link>
                                <div
                                  className="button"
                                  onClick={() => onDeleteSpace(space.id)}
                                >
                                  Delete
                                </div>
                                <Link
                                  to={`/available-space/${item.id}/${space.id}`}
                                  className="button"
                                  onClick={() => {
                                    dispatch({
                                      type: SET_SPACE_AVAILIBILITY_DATES,
                                      payload: null,
                                    });
                                  }}
                                >
                                  Manage Availability
                                </Link>
                              </div>
                              {/*<Link*/}
                              {/*    to="#"*/}
                              {/*    className="ml-3"*/}
                              {/*    onClick={() => handleManageAvailabilityPopup(item.id, space.id)}*/}
                              {/*>*/}
                              {/*  Edit Spaces*/}
                              {/*</Link>*/}
                            </div>
                          ))
                        ) : (
                          <div className="d-flex align-items-center justify-content-center py-3">
                            <b>No Space Available</b>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="d-flex align-items-center justify-content-center py-5">
                  <b>No Property Found</b>
                </div>
              )}
            </div>
          )}

          {/* <div className="btn-wrapper d-flex justify-content-between">
            <div className="button" onClick={gotoBackStep}>
              Back
            </div>
          </div> */}
        </div>
      </div>
      <EarningWithdrawPopup
        open={openWithdrawModal}
        onClose={() => setOpenWithdrawModal(false)}
      />
      <AddBankAccountPopup onClose={() => setOpenAddBankAccountModal(false)} open={openAddBankAccountModal} />

      {manageAvailabilityPopup && (
          <ManageAvailabilityPopup
              open={manageAvailabilityPopup}
              onClose={handleClosePopup}
              propertyId={propertyId}
              spaceId={spaceId}
          />
      )}

      <KYCPopup />
    </>
  );
};

export default StepThree;
