



import React from 'react';

const IconDashboard = ({ color = '#E35447', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M2.234 30h1.299v-28.5c0-0.829 0.698-1.5 1.558-1.5h21.818c0.861 0 1.559 0.671 1.559 1.5v28.5h1.299c0.43 0 0.779 0.336 0.779 0.75v1.25h-29.091v-1.25c0-0.414 0.349-0.75 0.779-0.75zM22.234 4.75c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v2.5c0 0.414 0.349 0.75 0.779 0.75h2.598c0.43 0 0.779-0.336 0.779-0.75v-2.5zM22.234 10.75c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v2.5c0 0.414 0.349 0.75 0.779 0.75h2.598c0.43 0 0.779-0.336 0.779-0.75v-2.5zM18.857 20h2.598c0.43 0 0.779-0.336 0.779-0.75v-2.5c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v2.5c0 0.414 0.349 0.75 0.779 0.75zM13.922 30h4.156v-5.25c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v5.25zM9.766 19.25c0 0.414 0.349 0.75 0.779 0.75h2.597c0.43 0 0.779-0.336 0.779-0.75v-2.5c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v2.5zM9.766 13.25c0 0.414 0.349 0.75 0.779 0.75h2.597c0.43 0 0.779-0.336 0.779-0.75v-2.5c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v2.5zM9.766 7.25c0 0.414 0.349 0.75 0.779 0.75h2.597c0.43 0 0.779-0.336 0.779-0.75v-2.5c0-0.414-0.349-0.75-0.779-0.75h-2.598c-0.43 0-0.779 0.336-0.779 0.75v2.5z"></path>
    </svg>
  )
};

export default IconDashboard;