import React from 'react';

const IconSharer = ({ color = '#E35447', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M28.16 1.306c-1.194-0.595-2.536-0.832-3.862-0.681s-2.58 0.682-3.611 1.529c-1.164-0.686-2.522-0.966-3.862-0.798s-2.587 0.775-3.546 1.726l-1.136 1.136 8.656 8.656 1.136-1.136c0.936-0.943 1.539-2.165 1.718-3.482s-0.076-2.655-0.726-3.814c0.561-0.388 1.218-0.615 1.899-0.657s1.36 0.103 1.965 0.42 1.111 0.792 1.465 1.375c0.354 0.583 0.542 1.252 0.543 1.934v7.904h-28.8v3.2h3.2v12.8h3.2v-12.8h19.2v12.8h3.2v-12.8h3.2v-11.104c0.003-1.29-0.355-2.555-1.034-3.652s-1.651-1.982-2.806-2.556z"></path>
    </svg>
  );
};

export default IconSharer;