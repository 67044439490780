import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const content = {
  desc1: `OfficeShare redefines the meaning of flexible working. 

  It enables any space – anytime, anywhere, any day, any desk – to be used equitably. It enables any business to pay for the space it needs, whilst sharing the space it leaves.`,
  allRights: `All Rights Reserved ${format(new Date(), 'yyyy')}`,
  contact: {
    title: 'CONTACT',
    description: `OfficeShare Limited. Da Vinci House44 Saffron Hill London EC1N 8FH

    +44(0)20 8049 6971
    enquiries@office-share.io`,
  },
  links: {
    title: 'Links',
    cta: [
      {
        title: 'Terms of Use',
        path: '/terms',
      },
      {
        title: 'Privacy Policy',
        path: '/terms',
      },
      {
        title: 'Cookie Policy',
        path: null,
      },
      {
        title: 'Help',
        path: '/help',
      },
      {
        title: 'FAQs',
        path: '/faq',
      },
    ],
  },
  social: {
    title: 'Socials',
    lists: [
      {
        logo: '/assets/icons/linkedin.svg',
        title: 'Linkedin',
        url: 'https://www.linkedin.com/company/officeshare-io'
      },
      {
        logo: '/assets/icons/instagram.svg',
        title: 'Instagram',
        url: 'https://www.instagram.com/officeshare.io'
      },
    ]
  }
}

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer--desc">
        <div className="footer--desc__image">
          <img src="/assets/images/office-share-logo-light.png" alt="official logo" />
        </div>
        <p className="footer--text">{content.desc1}</p>
        <p className="footer--text">{content.allRights}</p>
      </div>
      <div className="footer--lists">
        <h3 className="footer--title">{content.contact.title}</h3>
        <p className="footer--text">{content.contact.description}</p>
      </div>
      <div className="footer--lists">
        <h3 className="footer--title">{content.links.title}</h3>
        {content.links.cta.map((v, key) => !v.path ? (
          <p key={key} className="footer--text">{v.title}</p>
        ) : (
          <Link key={key} to={v.path} className="footer--text">
            {v.title}
          </Link>
        ))}
      </div>
      <div className="footer--lists">
        <h3 className="footer--title">{content.social.title}</h3>
        {content.social.lists.map((v, key) => (
          <div key={key} className="footer--social">
            <div className="footer--social__logo">
              <img src={v.logo} alt={v.title} />
            </div>
            <a href={v.url} className="footer--text" target="_blank" rel="noreferrer">{v.title}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
