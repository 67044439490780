import React from 'react';

const IconHistory = ({ color = '#BCBCBC', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M13.714 26.286l1.143 1.143h16l1.143-1.143v-20.571l-1.143-1.143h-16l-1.143 1.143v20.571zM18.286 9.143h9.143v13.714h-9.143v-13.714zM9.143 18.286v-9.143h2.286v-2.286h-3.429l-1.143 1.143v16l1.143 1.143h3.429v-2.286h-2.286v-4.571zM2.286 16v-4.571h2.286v-2.286h-3.429l-1.143 1.143v11.429l1.143 1.143h3.429v-2.286h-2.286v-4.571z"></path>
    </svg>
  );
};

export default IconHistory;