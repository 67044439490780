import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSearch, onLike, onUnlike } from "../../store/homeAction";
import SearchSection from "../../components/searchSection/SearchSection";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import SearchPageMap from "../../components/searchPageMap/SearchPageMap";
import Rating from "../../components/rating/Rating";
import "./searchPage.scss";
import useDynamicRefs from "use-dynamic-refs";

const SearchPage = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hideTrending, setHideTrending] = useState(false);
  const searchResult = useSelector((state) => state.home.searchResult);
  const searchLatLng = useSelector((state) => state.home.searchLatLng);
  const selectedFilters = useSelector((state) => state.home.selectedFilters);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [bookingType, setBookingType] = useState("");
  const [getRef, setRef] = useDynamicRefs();
  const userInfo = useSelector((state) => state.home.userDetails);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  let defaultProps = {
    center: {
      lat:
        (selectedProperty.location && selectedProperty.location.latitude) ||
        (searchLatLng && searchLatLng.lat) ||
        51.5074,
      lng:
        (selectedProperty.location && selectedProperty.location.longitude) ||
        (searchLatLng && searchLatLng.long) ||
        -0.1278,
    },
    zoom: 12,
  };

  useEffect(() => {
    const data = selectedFilters;
    if (localStorage.getItem("os-user")) {
      data.user_id = userDetails.id;
    }
    dispatch(onSearch(data));
  }, []);

  const onSearchByType = (type) => {
    const data = selectedFilters;
    if (localStorage.getItem("os-user")) {
      data.user_id = userDetails.id;
    }
    data.availability_type = type;
    dispatch(onSearch(data));
  };

  const onSelectProperty = (property) => {
    setSelectedProperty(property);
  };

  const onLikeClick = (id) => {
    const searchData = selectedFilters;
    let data = {};
    if (localStorage.getItem("os-user")) {
      if (!userInfo?.is_verified) {
        toast("Please verify your email", {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      } else {
        searchData.user_id = userDetails.id;
        data.user_id = userDetails.id;
        data.property_id = id;
      }
    } else {
      toast("Please Login!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      history.push("/login");
      return false;
    }
    dispatch(onLike(data, searchData));
  };
  const onUnlikeClick = (id) => {
    const searchData = selectedFilters;
    let data = {};
    if (localStorage.getItem("os-user")) {
      const userDetails = JSON.parse(localStorage.getItem("os-user"));
      searchData.user_id = userDetails.id;
      data.user_id = userDetails.id;
      data.property_id = id;
    } else {
      toast("Please Login!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });

      return false;
    }

    dispatch(onUnlike(data, searchData));
  };

  const onSetCurrentSelectedProperty = (property) => {
    let propertyId;
    for (let i = 0; i < searchResult.length; i++) {
      if (property?.id === searchResult?.[i]?.id) {
        propertyId = getRef(searchResult[i - 1]?.id);
      }
    }

    if (propertyId) {
      propertyId.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }


    setSelectedProperty(property);
  };

  const onHideTrending = () => {
    setHideTrending(!hideTrending);
  };

  return (
    <div className="search-page-wrapper">
      <div className="left-side pt-5">
        <div className="search-filter-wrapper">
          <SearchSection onHideTrending={onHideTrending}></SearchSection>
        </div>

        <div className="office-list-wrapper">
          <p className="heading text-primary font-weight-bold mb-1">
            Select Booking Type
          </p>
          <p className="filter-btn">
            <span
              onClick={() => {
                setBookingType("3");
                onSearchByType("3");
              }}
              className={`${bookingType === "3" && "selected"}`}
            >
              By Desk
            </span>
            <span
              onClick={() => {
                setBookingType("1");
                onSearchByType("1");
              }}
              className={`${bookingType === "1" && "selected"}`}
            >
              <img src="assets/images/day.svg" alt="" /> By Day
            </span>
            <span
              onClick={() => {
                setBookingType("2");
                onSearchByType("2");
              }}
              className={`${bookingType === "2" && "selected"}`}
            >
              <img src="assets/images/night.svg" alt="" /> By Night
            </span>
          </p>
          <div className="main-title">
            <h5>
              Results
              {selectedFilters &&
                selectedFilters.search &&
                ` in ${selectedFilters.search}`}
            </h5>
            {/* <p>
              Sort by: <span className="text-primary">User rating</span> /
              Available Space
            </p> */}
          </div>
          <p className="tagline my-1">
            <b> {searchResult && searchResult.length} </b> Available office
            spaces{" "}
            {selectedFilters &&
              selectedFilters.search &&
              `in ${selectedFilters.search}`}
          </p>
          <div className="rating-certification">
            <div className="rating d-flex align-items-center">
              <h3 className="text-primary m-0">*</h3>
              <span>pdpd = Per Desk, Per Day</span>
            </div>
            <div className="certification">
              <img src="assets/images/check-icon.png" alt="" />{" "}
              <span>OS Certified</span>
            </div>
          </div>
          <div className="office-list">
            {isLoading ? (
              <Loading></Loading>
            ) : searchResult && searchResult.length > 0 ? (
              searchResult.map((office, key) => (
                <div
                  id={key}
                  ref={setRef(office.id)}
                  className={`office-list-item fade-in-bottom ${
                    selectedProperty.id === office.id && "selected"
                  }`}
                  onMouseOver={() => onSelectProperty(office)}
                >
                  <div
                    className="office-image"
                    onClick={() => onSelectProperty(office)}
                  >
                    <Link to={`/property/${office.id}`}>
                      <img src={office.featured_image} alt="" />
                    </Link>

                    {office.is_liked ? (
                      <div
                        className="middle-screen-heart"
                        onClick={() => onUnlikeClick(office.id)}
                      >
                        <img
                          className="jello-horizontal"
                          src="assets/images/heart.png"
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        className="middle-screen-heart"
                        onClick={() => onLikeClick(office.id)}
                      >
                        <span>
                          <img
                            className="jello-horizontal"
                            src="assets/images/heart-blank.png"
                            alt=""
                          />
                        </span>
                      </div>
                    )}
                    {/* <div className="middle-screen-heart text-primary">
                      <img src="assets/images/heart.png" alt="" />
                    </div> */}
                  </div>

                  <div className="office-text">
                    <h5>
                      <Link to={`/property/${office.id}`}>
                        {" "}
                        {office.property_title}
                      </Link>

                      <img
                        className="dice"
                        src="assets/images/check-icon.png"
                        alt=""
                      />
                      <Rating rating={office.avg_rating}></Rating>
                    </h5>
                    <p className="address">{office?.location?.address}</p>
                    <div className="desk-availibility">
                      <div className="mr-2">
                        Desks:{" "}
                        <b className="ml-1">{office.number_of_desk}</b>
                      </div>
                      {office.availability_type === 1 ? (
                        <span>
                          <img src="assets/images/day.svg" alt="" /> Day
                        </span>
                      ) : office.availability_type === 2 ? (
                        <span>
                          <img src="assets/images/night.svg" alt="" /> Night
                        </span>
                      ) : office.availability_type === 3 ? (
                        <>
                          <span>
                            <img src="assets/images/day.svg" alt="" /> Day
                          </span>
                          <span className="light-text">and</span>
                          <span>
                            <img src="assets/images/night.svg" alt="" /> Night
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <p className="price-availibility">
                      <span>Price per desk: </span>
                      <span className="light-text mx-1">
                        <span className="text-primary font-weight-bold ml-1">
                          &#8356;{office?.price_from}
                        </span>
                      </span>
                      -
                      <span className="light-text">
                        <span className="text-primary font-weight-bold mx-1">
                          &#8356;{office?.price_to}
                        </span>
                      </span>
                    </p>
                    {office.cost_type === "range" && (
                      <div className="light-text">
                        Discount available on off-peak booking
                      </div>
                    )}

                    <div className="btn-wrapper">
                      {office.property_type &&
                        office.property_type.map((type, key) => (
                          <div id={key} className="property-btn">
                            {type}
                          </div>
                        ))}
                    </div>
                  </div>

                  {office.is_liked ? (
                    <div
                      className="favourite-wrapper"
                      onClick={() => onUnlikeClick(office.id)}
                    >
                      <img
                        className="jello-horizontal"
                        src="assets/images/heart.png"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div
                      className="favourite-wrapper"
                      onClick={() => onLikeClick(office.id)}
                    >
                      <span>
                        <img
                          className="jello-horizontal"
                          src="assets/images/heart-blank.png"
                          alt=""
                        />
                      </span>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center p-5">
                <b> No Result Found</b>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="right-side-map">
          <SearchPageMap
            searchResult={searchResult}
            selectedProperty={selectedProperty}
            defaultProps={defaultProps}
            onSetCurrentSelectedProperty={onSetCurrentSelectedProperty}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
