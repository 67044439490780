import React, { useEffect, useState } from "react";
import moment from "moment";

const PropertyWithSpaceDetails = ({ propertyListById, spaceDetails }) => {
  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    if (spaceDetails && spaceDetails.space_days_price_list) {
      // Set current price
      // console.log(spaceDetails);
      const todayDayName = moment(new Date()).format("dddd").slice(0, 3);
      const space_days_price_list = spaceDetails.space_days_price_list;
      for (let j = 0; j < space_days_price_list.length; j++) {
        if (todayDayName == space_days_price_list[j].day) {
          setCurrentPrice(space_days_price_list[j].price);
        }
      }
    }
  }, [spaceDetails]);

  return (
    <div className="user-top">
      <h3 className="user-booking-heading">
        {propertyListById && propertyListById.property_title}
      </h3>
      <h1 className="user-booking-title">
        {spaceDetails && spaceDetails.space_title}
      </h1>
      <div className="mt-2">
        <div className="type">{spaceDetails && spaceDetails.space_type}</div>
      </div>
      <div className="desk-available">
        <p>
          Desks:{" "}
          <span className="font-weight-bold mr-1">
            {spaceDetails && spaceDetails.available_desk}
          </span>
          <span className="text-primary"> | </span>
          {spaceDetails && spaceDetails.cost_type === "range" ? (
            <>
              <span className="light-text mx-1">
                <span className="text-primary font-weight-bold ml-1">
                  &#8356;
                  {spaceDetails && spaceDetails.price_from}
                </span>
              </span>
              -
              <span className="light-text">
                <span className="text-primary font-weight-bold mx-1">
                  &#8356;
                  {spaceDetails && spaceDetails.price_to}
                </span>
              </span>
            </>
          ) : (
            <span className="font-weight-bold ml-1">
              &#8356;{spaceDetails && spaceDetails.price}
            </span>
          )}{" "}
          pdpd
          {/* <span className="font-weight-bold ml-1">
            &#8356;{currentPrice} pdpd
          </span> */}
        </p>
      </div>
    </div>
  );
};

export default PropertyWithSpaceDetails;
