import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import moment from "moment";

const DatesModalPopup = ({ open, onClose, bookingDetails }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "price-grid-popup" }}
    >
      <h5 className="title text-primary font-weight-bold mb-2">
        Booking Dates:
      </h5>

      <div className="list-group">
        <p className="text-primary font-weight-bold text-center text-uppercase mb-1"></p>

        <div className="list-group-item">
          <span className="day">Dates</span>
          <span className="price text-dark">Day </span>
          <span className="price text-dark">Desks</span>
        </div>
        {bookingDetails?.daySelectedDates?.length > 0 ? (
          bookingDetails?.daySelectedDates?.map((item, key) => (
            <div className="list-group-item" key={key}>
              <span className="day">{item}</span>
              <span className="price">
                {moment(item).format("dddd").slice(0, 3)}{" "}
              </span>
              <span className="price">
                {bookingDetails && bookingDetails.numberOfDesks}
              </span>
            </div>
          ))
        ) : (
          <div className="text-center py-3"> No bookings for day</div>
        )}
      </div>

      <div className="list-group">
        <p className="text-primary font-weight-bold text-center text-uppercase mb-1">
          Night Dates
        </p>

        {bookingDetails?.nightSelectedDates?.length > 0 ? (
          bookingDetails?.nightSelectedDates?.map((item, key) => (
            <div className="list-group-item" key={key}>
              <span className="day">{item}</span>
              <span className="price">
                {moment(item).format("dddd").slice(0, 3)}{" "}
              </span>
              <span className="price">
                {bookingDetails && bookingDetails.numberOfDesks}
              </span>
            </div>
          ))
        ) : (
          <div className="text-center py-3"> No bookings for night</div>
        )}
      </div>

      <p
        className="text-center text-underline close-btn"
        onClick={() => onClose(false)}
      >
        Close
      </p>
    </Modal>
  );
};

export default DatesModalPopup;
