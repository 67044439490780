import { geocode, RequestType } from 'react-geocode';

const useGeocode = () => {
    /**
     * 
     * @param location either an address or coordinates (lat, lng)
     * 
     * @returns 
     */
    const getGeocode = async (location, requestType = RequestType.ADDRESS ) => {
        try {
            const response = await geocode(requestType, location, {
                key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                language: "en",
                region: "eu",
              });
            return response.results[0];
        } catch (error) {
            console.error(error);
        }
    }

    return { getGeocode };
}

export default useGeocode;