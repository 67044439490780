import React, { useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./css/config.scss";
import "./css/style.scss";
import HomePage from "./pages/HomePage";
import Onboarding from "./pages/onBoarding/Onboarding";
import OnboardingStepOne from "./pages/onBoarding/OnboardingStepOne";
import OnboardingStepThree from "./pages/onBoarding/OnboardingStepThree";
import OnboardingStepTwo from "./pages/onBoarding/OnboardingStepTwo";
import PlanList from "./pages/planList/PlanList";
import PlanBuy from "./pages/planList/PlanBuy";
import PlanBuySuccess from "./pages/planList/PlanBuySuccess";
import LandlordDashboard from "./pages/landlord/LandlordDashboard";
import LandlordLogin from "./pages/landlord/landlordLogin/LandlordLogin";
import LandlordRegister from "./pages/landlord/landlordLogin/LandlordRegister";
import LandlordVerification from "./pages/landlord/landlordLogin/LandlordVerification";
import LandlordSignUp from "./pages/landlord/landlordLogin/LandlordSignUp";
import Register from "./pages/register/Register";
import RegisterSuccess from "./pages/register/RegisterSuccess";
import SearchPage from "./pages/searchPage/SearchPage";
import StepFive from "./pages/spaceRegisterStep/StepFive";
import StepFour from "./pages/spaceRegisterStep/StepFour";
import StepOne from "./pages/spaceRegisterStep/StepOne";
import StepThree from "./pages/spaceRegisterStep/StepThree";
import StepTwo from "./pages/spaceRegisterStep/StepTwo";
import Verfication from "./pages/verification/Verfication";
import PropertyDetails from "./pages/property/PropertyDetails";
import SpaceDetails from "./pages/property/SpaceDetails";
import UserBookingOne from "./pages/userBooking/UserBookingOne";
import UserBookingTwo from "./pages/userBooking/UserBookingTwo";
import UserBookingThree from "./pages/userBooking/UserBookingThree";
import UserBookingFour from "./pages/userBooking/UserBookingFour";
import UserBookingFive from "./pages/userBooking/UserBookingFive";
import UserBookingSix from "./pages/userBooking/UserBookingSix";
import UserBookingSeven from "./pages/userBooking/UserBookingSeven";
import Login from "./pages/login/Login";
import MyDashboard from "./pages/myDashboard/MyDashboard";
import BookingConfirm from "./pages/myDashboard/bookingConfirm/BookingConfirm";
import ManageAdminUser from "./pages/landlord/manageAdminUser/ManageAdminUser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTop from "react-router-scroll-top";
import MessageChat from "./pages/landlord/messageChat/MessageChat";
import ArticleDetails from "./pages/articleDetails/ArticleDetails";
import BlogDetails from "./pages/blogDetails/BlogDetails";
import Chat from "./components/chat/Chat";
import CookieBar from "./components/cookieBar/CookieBar";
import SpaceAvailableDates from "./pages/spaceAvailableDates/SpaceAvailableDates";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import NotFound from "./pages/notFound/NotFound";
import PrivateRoute from "./pages/notFound/PrivateRoute";
import Faq from "./pages/faq/Faq";
import Help from "./pages/help/Help";
import About from "./pages/about/About";
import HowItWorks from "./pages/howitworks/Howitworks";
import Privacy from "./pages/privacy/Privacy";
import Verify from "./components/verify/Verify";
import EnquiryPayment from "./pages/myDashboard/enquiryPayment/EnquiryPayment";
// import BetaEarlyAccess from "./pages/betaEarlyAccess/BetaEarlyAccess";
import MyEarning from "./pages/myDashboard/myEarnings/MyEarning";
import firebase from "firebase";
import Vouchers from "./pages/myDashboard/vouchers/Vouchers";
import { EventEmitter } from "./utils/EventEmitter";
import { getToken, registerOnMessageListerner } from "./config";
import TagManager from 'react-gtm-module';

// Landlord Agreement
import LandlordAgreement from "./pages/landlord/landlordAgreement/LandlordAgreement";

import clsx from 'clsx';

registerOnMessageListerner();

const smallpages = [
  '/help',
  '/onboarding',
  '/login',
  '/landlord-login',
]

function App() {
  // localStorage.setItem("beta-access", true);
  const [active, setActive] = useState(false);
  const [urlPath, setUrlPath] = useState('/');
  const wrapperRef = useRef(null);
  const wrapperRefnew = useRef(null);
  const tagManagerArgs = {
    gtmId: 'G-VE6V83R0FM'
  }

  TagManager.initialize(tagManagerArgs);

  useOutsideAlerter(wrapperRef, wrapperRefnew);

  function useOutsideAlerter(ref, refnew) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      function handleClickOutside(event) {
        if (
            ref.current &&
            !ref.current.contains(event.target) &&
            !refnew.current.contains(event.target)
        ) {
          setActive(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (firebase.messaging.isSupported() && Notification.permission === "granted") {
      getToken();
    }

    EventEmitter.subscribe("onRequestNotificationPermission", getToken);

    return () => {
      EventEmitter.unsubscribe("onRequestNotificationPermission");
    };
  }, []);

  const callbackHeader = (path) => {
    setUrlPath(path);
  };

  const fullPages = smallpages.filter((v) => v === urlPath).length > 0;

  return (
    // <div>
    <div className={clsx({ 'main--wrapper': fullPages })}>
      <ToastContainer position="top-center"></ToastContainer>
      <Router baseName="/">
        {/*{ localStorage.getItem("beta-access") ? (*/}
        {(
            <>
              <ScrollToTop>
                <CookieBar/>
                {/* <Sidebar
                    wrapperRef={wrapperRef}
                    wrapperRefnew={wrapperRefnew}
                    setActive={setActive}
                    active={active}
                ></Sidebar> */}
                <Header callbackHeader={callbackHeader} />
                <div className={clsx('route-wrapper', { index: urlPath === '/',  'full-page': fullPages })}>
                  <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/search/:inputs?" component={SearchPage}/>
                    <Route
                        exact
                        path="/property/:id"
                        component={PropertyDetails}
                    />
                    <Route
                        path="/space/:propertyId/:spaceId"
                        component={SpaceDetails}
                    />
                    <Route
                        exact
                        path="/landlord-register"
                        component={LandlordRegister}
                    />
                    <Route
                        exact
                        path="/landlord-login"
                        component={LandlordLogin}
                    />
                    <Route
                        exact
                        path="/landlord-signup"
                        component={LandlordSignUp}
                    />
                    <Route
                        exact
                        path="/landlord-verification"
                        component={LandlordVerification}
                    />
                    <PrivateRoute
                        exact
                        path="/landlord-dashboard"
                        component={LandlordDashboard}
                    />
                    <Route exact path="/register" component={Register}/>
                    <Route
                        exact
                        path="/register-success"
                        component={RegisterSuccess}
                    />
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/verification" component={Verfication}/>
                    <PrivateRoute exact path="/plan-list" component={PlanList}/>
                    <PrivateRoute
                        exact
                        path="/plan-list/:id"
                        component={PlanBuy}
                    />
                    <PrivateRoute
                        exact
                        path="/plan-success"
                        component={PlanBuySuccess}
                    />

                    <PrivateRoute
                        exact
                        path="/add-property/1/:propertyId?"
                        component={StepOne}
                    />
                    <PrivateRoute
                        exact
                        path="/add-property/2/:propertyId?"
                        component={StepTwo}
                    />
                    <PrivateRoute
                        exact
                        path="/add-property/3"
                        component={StepThree}
                    />
                    <PrivateRoute exact path="/vouchers" component={Vouchers}/>
                    <PrivateRoute
                        exact
                        path="/add-space/:propertyId/:spaceId?"
                        component={StepFour}
                    />
                    <PrivateRoute
                        exact
                        path="/add-property/5"
                        component={StepFive}
                    />
                    <Route exact path="/onboarding" component={Onboarding}/>
                    <Route
                        exact
                        path="/onboarding/1"
                        component={OnboardingStepOne}
                    />
                    <PrivateRoute
                        exact
                        path="/onboarding/2"
                        component={OnboardingStepTwo}
                    />
                    <PrivateRoute
                        exact
                        path="/landlord-agreement"
                        component={LandlordAgreement}
                    />
                    <Route
                        exact
                        path="/onboarding/3"
                        component={OnboardingStepThree}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/1/:propertyId/:spaceId"
                        component={UserBookingOne}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/2/:propertyId/:spaceId"
                        component={UserBookingTwo}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/3/:propertyId/:spaceId"
                        component={UserBookingThree}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/4/:propertyId/:spaceId"
                        component={UserBookingFour}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/5/:propertyId/:spaceId"
                        component={UserBookingFive}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/6/:propertyId/:spaceId"
                        component={UserBookingSix}
                    />
                    <PrivateRoute
                        exact
                        path="/userbooking/7/:propertyId/:spaceId"
                        component={UserBookingSeven}
                    />
                    <PrivateRoute
                        exact
                        path="/enquiry-payment/:bookingId"
                        component={EnquiryPayment}
                    />
                    <PrivateRoute
                        exact
                        path="/mydashboard/:id?"
                        component={MyDashboard}
                    />
                    <PrivateRoute
                        exact
                        path="/myearning/:id?"
                        component={MyEarning}
                    />
                    <PrivateRoute
                        exact
                        path="/booking-confirm/:propertyId/:spaceId"
                        component={BookingConfirm}
                    />
                    <PrivateRoute
                        exact
                        path="/manage-admin-user"
                        component={ManageAdminUser}
                    />
                    <PrivateRoute
                        exact
                        path="/message-chat"
                        component={MessageChat}
                    />
                    <Route
                        exact
                        path="/article-details"
                        component={ArticleDetails}
                    />
                    <Route
                        exact
                        path="/blog-details/:blogId"
                        component={BlogDetails}
                    />
                    <PrivateRoute exact path="/chat/:userId" component={Chat}/>
                    <PrivateRoute
                        exact
                        path="/available-space/:propertyId/:spaceId?"
                        component={SpaceAvailableDates}
                    />
                    <Route exact path="/terms" component={TermsAndConditions}/>
                    <Route exact path="/faq" component={Faq}/>
                    <Route exact path="/privacy" component={Privacy}/>
                    <Route exact path="/help" component={Help}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/how-it-works" component={HowItWorks}/>
                    <Route exact path="/verify/:token" component={Verify}/>
                    <Route path="*" component={NotFound}/>
                  </Switch>
                </div>
              </ScrollToTop>
              <Footer />
            </>
        )}
      </Router>
    </div>
  );
}

export default App;
