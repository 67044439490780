import react, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAmenities, onSearch } from "../../store/homeAction";
import { SET_SELECTED_FILTERS } from "../../store/types";
import SearchInput from "../searchInput/SearchInput";
import { Link } from "react-router-dom";
import AmenitiesPopup from "../../popups/AmenitiesPopup";
import ExperiencePopup from "../../popups/ExperiencePopup";
import DesksPopup from "../../popups/DesksPopup";
import DaysPopup from "../../popups/DaysPopup";
import { useHistory } from "react-router-dom";
import "./searchSection.scss";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const SearchSection = ({ onHideTrending }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = useLocation().pathname;
  const filters = useSelector((state) => state.home.filters);
  const selectedFilters = useSelector((state) => state.home.selectedFilters);
  const [amenities, setAmenities] = useState([]);
  const [rating, setRating] = useState([false, false, false, false, false]);
  const [experienceRating, setExperienceRating] = useState([]);
  const [numberOfDesks, setNumberOfDesks] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState([]);
  const [isAmenitiesPopupOpened, setIsAmenitiesPopupOpened] = useState(false);
  const [isExperiencePopupOpened, setIsExperiencePopupOpened] = useState(false);
  const [isDesksPopupOpened, setIsDesksPopupOpened] = useState(false);
  const [isDaysPopupOpened, setIsDaysPopupOpened] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [deskValue, setDeskValue] = useState({
    no_of_desk_min: "",
    no_of_desk_max: "",
    no_of_desk_greater: "",
    no_of_desk_below: "",
  });
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  const [daysValue, setDaysValue] = useState({
    no_of_day_min: 0,
    no_of_day_max: 5,
  });

  useEffect(() => {
    onGetAmenities();

    if (path === "/search") {
      setSearchInput("");
    }
  }, []);

  useEffect(() => {
    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].filer_title === "Amenities") {
          if (
            selectedFilters &&
            selectedFilters.selectedAmenities &&
            selectedFilters.selectedAmenities.length > 0
          ) {
            setAmenities(selectedFilters.selectedAmenities);
          } else {
            setAmenities(filters[i].filter_list);
          }
        }
        if (filters[i].filer_title === "Experience rating") {
          if (
            selectedFilters &&
            selectedFilters.selectedRating &&
            selectedFilters.selectedRating.length > 0
          ) {
            setRating(selectedFilters.selectedRating);
          }
          setExperienceRating(filters[i].filter_list);
        }
        if (filters[i].filer_title === "Number of desks") {
          setNumberOfDesks(filters[i].filter_list);
        }
        if (filters[i].filer_title === "Number of days per week") {
          setNumberOfDays(filters[i].filter_list);
        }
      }
    }
  }, [filters, selectedFilters]);

  const onGetAmenities = () => {
    dispatch(getAmenities());
  };

  const onSelectAmenities = (id) => {
    for (let j = 0; j < amenities.length; j++) {
      if (id === amenities[j].id) {
        if (amenities[j].is_selected) {
          amenities[j].is_selected = false;
        } else {
          amenities[j].is_selected = true;
        }
      }
    }
    setAmenities([...amenities]);
  };
  const onSelectExperienceRating = (rating) => {
    setRating(rating);
  };

  const onApplySearch = (value, location) => {
    if (!value) {
      toast("Please Enter Something to search", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else {
      let data = {
        search: value || searchInput,
        lat: location.lat,
        long: location.lng,
        radius: 30,
        // user_id:1,
        // attr_id: getAttribute(),
        // rating: getRatingCount(),
        // no_of_desk_min: 100,
        // no_of_desk_max: 1000,
      };
      let dispatchData = {
        search: value || searchInput,
        lat: location.lat,
        long: location.lng,
        radius: 30,
        // user_id:1,
        // attr_id: getAttribute(),
        // rating: getRatingCount(),
        // no_of_desk_min: 100,
        // no_of_desk_max: 1000,
        // selectedAmenities: amenities,
        // selectedRating: rating,
      };

      if (localStorage.getItem("os-user")) {
        const userDetails = JSON.parse(localStorage.getItem("os-user"));
        data.user_id = userDetails.id;
        dispatchData.user_id = userDetails.id;
      }
      dispatch({
        type: SET_SELECTED_FILTERS,
        payload: dispatchData,
      });
      dispatch(onSearch(data));
      history.push(`/search`);
    }
  };

  const onButtonApplySearch = () => {
    const data = {
      radius: 30,
      attr_id: getAttribute(),
      rating: getRatingCount(),
      no_of_desk_min: deskValue.no_of_desk_min,
      no_of_desk_max: deskValue.no_of_desk_max,
      no_of_desk_greater: deskValue.no_of_desk_greater,
      no_of_desk_below: deskValue.no_of_desk_below,
    };

    if (localStorage.getItem("os-user")) {
      const userDetails = JSON.parse(localStorage.getItem("os-user"));
      data.user_id = userDetails.id;
    }
    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: data,
    });
    dispatch(onSearch(data));
    history.push(`/search`);
  };

  const onSearchInput = (value) => {
    let data = {
      search: searchInput,
      // user_id:1,
      // attr_id: getAttribute(),
      rating: getRatingCount(),
      no_of_desk_min: 100,
      no_of_desk_max: "",
      selectedAmenities: amenities,
      selectedRating: rating,
    };

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: data,
    });

    setSearchInput(value);
  };

  const getRatingCount = () => {
    let ratingCount = 0;
    for (let i = 0; i < rating.length; i++) {
      if (rating[i]) {
        ratingCount = ratingCount + 1;
      }
    }
    return ratingCount;
  };

  const getAttribute = () => {
    let attributesValue = "";
    for (let j = 0; j < amenities.length; j++) {
      if (amenities[j].is_selected) {
        attributesValue = `${attributesValue}${attributesValue !== "" ? "," : ""
          }${amenities[j].id}`;
      }
    }
    return attributesValue;
  };

  const onOpenAmenitiesPopup = () => {
    setIsAmenitiesPopupOpened(true);
  };
  const onCloseAmenitiesPopup = () => {
    setIsAmenitiesPopupOpened(false);
  };
  const onOpenExperiencePopup = () => {
    setIsExperiencePopupOpened(true);
  };
  const onCloseExperiencePopup = () => {
    setIsExperiencePopupOpened(false);
  };
  const onOpenDaysPopup = () => {
    setIsDaysPopupOpened(true);
  };
  const onCloseDaysPopup = () => {
    setIsDaysPopupOpened(false);
  };
  const onOpenDesksPopup = () => {
    setIsDesksPopupOpened(true);
  };
  const onCloseDesksPopup = () => {
    setIsDesksPopupOpened(false);
  };

  const clearFilter = () => {
    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].filer_title === "Amenities") {
          let filterList = filters[i].filter_list;
          filterList = filterList.map((item, key) => ({
            ...item,
            is_selected: false,
          }));

          setAmenities(filterList);
        }
        if (filters[i].filer_title === "Experience rating") {
          setRating([false, false, false, false, false]);
        }
        if (filters[i].filer_title === "Number of desks") {
          setDeskValue({
            no_of_desk_min: 0,
            no_of_desk_max: 1,
            no_of_desk_greater: "",
            no_of_desk_below: "",
          });
        }
      }
    }

    dispatch(onSearch());
  };

  return (
    <div className="search-section-wrapper">
      <AmenitiesPopup
        amenities={amenities}
        onSelectAmenities={onSelectAmenities}
        open={isAmenitiesPopupOpened}
        onClose={onCloseAmenitiesPopup}
        filters={filters}
        setAmenities={setAmenities}
      ></AmenitiesPopup>
      <ExperiencePopup
        experienceRating={experienceRating}
        selectedRating={rating}
        onSelectExperienceRating={onSelectExperienceRating}
        open={isExperiencePopupOpened}
        onClose={onCloseExperiencePopup}
        filters={filters}
        setRating={setRating}
        rating={rating}
      ></ExperiencePopup>
      <DesksPopup
        selectDeskValue={(value) => setDeskValue(value)}
        open={isDesksPopupOpened}
        onClose={onCloseDesksPopup}
      ></DesksPopup>
      <DaysPopup
        selectDaysValue={(value) => setDaysValue(value)}
        open={isDaysPopupOpened}
        onClose={onCloseDaysPopup}
      ></DaysPopup>
      <div>
        <div className="search-input-box">
          <SearchInput
            value={
              searchInput !== ""
                ? searchInput
                : selectedFilters.search
                  ? selectedFilters.search
                  : ""
            }
            onApplySearch={onApplySearch}
            onSearchInput={onSearchInput}
          ></SearchInput>
          <div
            className="filter-icon"
            onClick={() => {
              setShowFilter(!showFilter);
              onHideTrending();
            }}
          >
            <i className="fas fa-filter text-primary"></i> <a> Filter</a>
          </div>
        </div>

        {showFilter && (
          <div
            className={`amenities-text ${showFilter ? "swing-in-top-bck" : "swing-out-top-bck"
              }`}
          >
            <p className="heading text-primary">Amenities</p>
            <p>
              {amenities.map(
                (item, index) =>
                  item.is_selected && <span key={index}>{item.title}, </span>
              )}
              <Link onClick={onOpenAmenitiesPopup} className="link">
                edit
              </Link>
            </p>

            <p className="heading text-primary mt-3">Experience Rating</p>
            <p>
              <div className="rating-wrapper">
                {rating.map((star, key) =>
                  star ? (
                    <img src="assets/icons/star.png" key={key} />
                  ) : (
                    <img src="assets/icons/inactive-star.png" key={key} />
                  )
                )}
              </div>
              {rating}{" "}
              <Link onClick={onOpenExperiencePopup} className="link">
                edit
              </Link>
            </p>

            <p className="heading text-primary mt-3">Number of Desks</p>
            <p>
              {deskValue.no_of_desk_min && deskValue.no_of_desk_max ? (
                <>
                  {deskValue.no_of_desk_min}
                  {" to "}
                  {deskValue.no_of_desk_max}
                </>
              ) : deskValue.no_of_desk_greater ? (
                <>{deskValue.no_of_desk_greater} </>
              ) : deskValue.no_of_desk_below ? (
                <>{deskValue.no_of_desk_below} </>
              ) : null}

              <Link onClick={onOpenDesksPopup} className="link">
                edit
              </Link>
            </p>
            {/* 
            <p className="heading text-primary mt-3">Number of days per week</p>
            <p>
              {daysValue.no_of_day_min}
              {" to "}
              {daysValue.no_of_day_max}{" "}
              <Link onClick={onOpenDaysPopup} className="link">
                edit
              </Link>
            </p> */}

            <div className="btn-wrapper">
              <div
                className="close-btn"
                onClick={() => {
                  setShowFilter(false);
                  onHideTrending();

                  dispatch(onSearch());
                }}
              >
                {" "}
                <img src="assets/icons/close.png"></img> Close
              </div>
              <div
                className="apply-btn text-primary"
                onClick={onButtonApplySearch}
              >
                {" "}
                <img src="assets/images/done.png"></img> Apply
              </div>

              <div
                className="apply-btn text-primary ml-4 "
                onClick={() => clearFilter()}
              >
                Reset
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchSection;
