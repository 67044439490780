import React from "react";
import LandlordBar from "../../../components/landlordBar/LandlordBar";
import { Link } from "react-router-dom";
import "./messageChat.scss";

const MessageChat = () => {
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  return (
    <>
      <LandlordBar />
      <div className="message-main-wrapper">
        <div className="container">
          <h2 className="message-title">Messages</h2>
          <div className="message-wrapper">
            <h6 className="message-header">
              <img src="assets/images/background-red-error.png" alt="" />
              You have an ongoing chat with at least one OfficeShare user.
            </h6>
            <hr />
            <div className="chat-wrapper">
              <h2 className="font-weight-bold">
                Open Live Chats&nbsp;
                <span className="font-weight-normal">(3)</span>
              </h2>
              <div className="chat-buttons">
                <span className="chat-active">
                  <Link to={`/chat/${userDetails.id}`}>
                    <h6>
                      <img
                        src="assets/images/background-red-error.png"
                        alt=""
                      />
                      &nbsp; OS User 1
                    </h6>
                  </Link>
                  <small className="mt-1">New Message!</small>
                </span>
                <span className="chat-button">OS User 2</span>
                <span className="chat-button">OS User 3</span>
              </div>
            </div>
          </div>

          <div className="message-list-wrapper">
            <div className="message-list">
              <div className="message-list-text">
                <h6>06.08.2021</h6>
                <b>
                  <img src="assets/images/background-red-error.png" alt="" />
                  An OfficeShare User has made an enquiry regarding your space.
                  Please review and confirm.
                </b>
              </div>
              <div className="active-button">Read Message</div>
            </div>
            <div className="message-list">
              <div className="message-list-text">
                <h6>06.08.2021</h6>
                <span>
                  Feedback on your OfficeShare Landlord expierience so far.
                </span>
              </div>
              <div className="button">Read Message</div>
            </div>
            <div className="message-list">
              <div className="message-list-text">
                <h6>06.08.2021</h6>
                <span>Welcome to OfficeShare! Let's get sharing!</span>
              </div>
              <div className="button">Read Message</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageChat;
