import React, { useState } from "react";
import "./popup.scss";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  sendForgotPasswordOtp,
  verifyForgotPasswordOtp,
  createNewPassword,
} from "../store/homeAction";
import {
  SET_FORGOT_PASSWORD_OTP_SENT,
  SET_FORGOT_PASSWORD_OTP_VERIFY,
} from "../store/types";
// react modal
import "react-responsive-modal/styles.css";
import OtpInput from "react-otp-input";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";

const ForgotPasswordPopup = ({ open, onClose, bookingDetails }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const regions = useSelector((state) => state.home.regions);

  const forgotPasswordOtpSent = useSelector(
    (state) => state.home.forgotPasswordOtpSent
  );
  const forgotPasswordOtpVerified = useSelector(
    (state) => state.home.forgotPasswordOtpVerified
  );

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSendOtp = () => {
    if (!mobileNumber) {
      toast("Please enter mobile number!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("mobile_email", `${selectedRegion}${mobileNumber}`);
    dispatch(sendForgotPasswordOtp(formData));
  };

  const onVerify = () => {
    if (!otp) {
      toast("Please enter otp", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("otp", otp);
    formData.append(
      "user_id",
      localStorage.getItem("os_forgot_password_user_id")
    );
    formData.append(
        'phone',
        localStorage.getItem("os_forgot_password_phone")
    );

    dispatch(verifyForgotPasswordOtp(formData));
  };

  const handleOtp = (value) => {
    setOtp(value);
  };

  const onClosePopup = () => {
    dispatch({ type: SET_FORGOT_PASSWORD_OTP_SENT, payload: false });
    dispatch({ type: SET_FORGOT_PASSWORD_OTP_VERIFY, payload: false });

    onClose();
  };

  const onCreateNewPassword = () => {
    // createNewPassword
    if (!newPassword || !confirmPassword) {
      toast("Please enter password", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return;
    }

    if (newPassword != confirmPassword) {
      toast("Password should be same!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("mobile_email", `${selectedRegion}${mobileNumber}`);
    formData.append("password", newPassword);

    dispatch(createNewPassword(formData));
  };
  return (
    <Modal
      open={open}
      onClose={onClosePopup}
      classNames={{ modal: "forgot-password-popup-modal text-center mt-8" }}
    >
      {forgotPasswordOtpSent && forgotPasswordOtpVerified ? (
        <>
          <div className="details py-4">
            <h5 className="text-primary font-weight-bold">
              Create New Password
            </h5>
          </div>
          <input
            type="password"
            className="form-control mb-2"
            placeholder="Enter New Password"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="btns d-flex justify-content-center">
            <div className="button-submit mt-4" onClick={onCreateNewPassword}>
              Submit
            </div>
          </div>
        </>
      ) : forgotPasswordOtpSent ? (
        <>
          <h5 className="text-primary font-weight-bold">
            Verify Otp<span className="text-primary">!</span>
          </h5>
          <div className="details py-4">Enter Otp</div>
          <div className="pin-wrapper">
            <OtpInput
              value={otp}
              onChange={handleOtp}
              numInputs={6}
              disabledStyle
            />
          </div>
          <div className="btns d-flex justify-content-center">
            <div className="button-submit mt-4" onClick={onVerify}>
              Verify Otp
            </div>
          </div>
        </>
      ) : (
        <>
          <h5 className="text-primary font-weight-bold">
            Forgot Password<span className="text-primary">!</span>
          </h5>
          <div className="details py-4">
            Please enter your registered mobile number to reset your password.
          </div>
          <select
            className="form-control mb-2"
            onChange={(e) => setSelectedRegion(e.target.value)}
            required
          >
            <option value="">Select Region</option>
            {regions &&
              regions.map((region, i) =>
                selectedRegion === region.name ? (
                  <option value={region.phonecode} key={i} selected>
                    {region.name}
                  </option>
                ) : (
                  <option value={region.phonecode} key={i}>
                    {region.name}
                  </option>
                )
              )}
          </select>
          <input
            type="text"
            className="form-control"
            placeholder="Enter mobile number"
            name="mobile"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
          <div className="btns d-flex justify-content-center">
            <div className="button-submit mt-4" onClick={onSendOtp}>
              Send OTP
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ForgotPasswordPopup;
