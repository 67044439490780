import React from 'react';
import clsx from 'clsx';
import { format } from "date-fns";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { SET_NEW_LANDLORD_DATA, SET_EDIT_LANDLORD_DATA } from '../../../store/types';

// Components
import LandlordBar from "../../../components/landlordBar/LandlordBar";

// Popups
import UpdateAgreement from '../../../popups/AgreementLandlord/UpdateAgreement';
import NewLandlord from '../../../popups/AgreementLandlord/NewLandlord';
import SuccessNewLandlord from '../../../popups/AgreementLandlord/SuccessNewLandlord';
import VersionHistory from '../../../popups/AgreementLandlord/VersionHistory';
import AgreementSuccess from '../../../popups/AgreementLandlord/AgreementSuccess';

// Icons
import IconAdd from '../../../icons/icon_add';
import IconDownload from '../../../icons/icon_download';
import IconFile from '../../../icons/icon_file';
import IconUpload from '../../../icons/icon_upload';
import IconMenu from '../../../icons/icon_menu';
import IconHistory from '../../../icons/icon_history';
import IconEdit from '../../../icons/icon_edit';
import IconEmptyState from '../../../icons/icon_emptystate';

// SCSS
import './landlordAgreement.scss';

// Utils
import Axios from '../../../store/axios';

import { getAllAgreementLandlordData, createLandlord, editLandlord, config } from "../../../store/homeAction";

const LandlordAgreement = () => {
  const dispatch = useDispatch();
  const [onMenuActive, setOnMenuActive] = React.useState(null);
  const [versionHistory, setVersionHistory] = React.useState(null);
  const [successCreatedLandlord, setSuccessCreatedLandlord] = React.useState(false);
  const [newLandlordOpen, setNewLandlordOpen] = React.useState(false);
  const [landLordEdit, setLandlordEdit] = React.useState(null);
  const [updateAgreement, setUpdateAgreement] = React.useState(null);
  const [downloading, setDownloading] = React.useState(undefined);
  const [uploadSuccess, setUploadSuccess] = React.useState(null);

  const getAgreementLandlordList = useSelector(
    (state) => state.home.getAgreementLandlordList
  );

  const newLandlordData = useSelector(
    (state) => state.home.newLandlordData
  );

  const editLandlordData = useSelector(
    (state) => state.home.editLandlordData
  );

  const isLoading = useSelector(
    (state) => state.home.isLoading
  );

  const downloadFile = async (data) => {
    setDownloading(data?.landlord_id);
    Axios.post("/agreements/files", data, config).then((res) => {
      if (res?.data?.status && res?.data?.data?.length) {
        const responseData = res?.data?.data?.[0]?.file_name;
    
        const downloadLink = document.createElement('a');
        downloadLink.href = responseData;
        downloadLink.target = "_blank";
        downloadLink.download = `${format(new Date(), 'mm-dd-yyyy')}.pdf`;
        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
      }
      setDownloading(undefined);
    });
  };

  const onCloseNewLandlord = (type, name, edit) => {
    if (type === 'submit' && name?.firstName && name?.lastName) {
      const payload = {
        first_name: name?.firstName || '',
        last_name: name?.lastName || '',
      };
      if (edit?.id) {
        dispatch(editLandlord({ landlord_id: edit?.id, ...payload }))
      } else {
        dispatch(createLandlord(payload))
      }
      if (!isLoading) {
        setNewLandlordOpen(false);
      }
    } else {
      setNewLandlordOpen(false);
    }
  };

  const onCloseSuccessNewLandlord = (type, name) => {
    setSuccessCreatedLandlord(false);
    dispatch(getAllAgreementLandlordData());
    dispatch({
      type: SET_NEW_LANDLORD_DATA,
      payload: undefined,
    });
    dispatch({
      type: SET_EDIT_LANDLORD_DATA,
      payload: undefined,
    });
    setLandlordEdit(null)
  };

  const onCloseUpdateAgreement = (type, data = null) => {
    if (type === 'submit') {
      setUploadSuccess(data);
    }
    setUpdateAgreement(false);
  }

  React.useEffect(() => {
    dispatch(getAllAgreementLandlordData());
  }, []);

  React.useEffect(() => {
    if (newLandlordData?.id) {
      setSuccessCreatedLandlord(true)
    }
  }, [newLandlordData]);

  React.useEffect(() => {
    if (editLandlordData?.status) {
      setSuccessCreatedLandlord(true)
    }
  }, [editLandlordData]);

  return (
    <>
      <div className="la">
        <LandlordBar />
        <div className="container la--container">
          <div className="la--wrapper">
            <div className="la--wrapper__title">
              <h4 className="la--title">Landlord License Agreement</h4>
              <p className="la--desc">This could assist you in ensuring that your landlords are completely fine with you subleasing their property if you are renting it out from them.</p>
              <div className="la--download">
                <button type="button" className='la--download--btn'>
                  <IconDownload size={20} />
                  <p className="la--download--btn__label">Landlord Licensing Agreement template</p>
                </button>
                <button
                  type="button"
                  className='la--download--btn'
                  onClick={() => {
                    setNewLandlordOpen(true);
                    setLandlordEdit(null);
                    setOnMenuActive(null);
                  }}
                >
                  <IconAdd size={14} />
                  <p className="la--download--btn__label">New Landlord</p>
                </button>
              </div>
            </div>
            <div className="la--list">
              {getAgreementLandlordList?.length ? getAgreementLandlordList?.map((v, key) => (
                <div key={key} className="la--list__card">
                  <div className="list-card--content">
                    <div className="">
                      <h3 className="title text-overflow-ellipsis">{v.first_name === v.last_name ? v?.first_name : `${v?.first_name} ${v.last_name}`}</h3>
                      <p className="subtitle">Landlord</p>
                    </div>
                    <div className="">
                      <h3 className="agreement--date">
                        {v?.last_date_uploaded
                          ? (
                            <span className="with--agreement">{format(new Date(v.last_date_uploaded), 'dd MMM yyyy')}</span>
                          )
                          : (
                          <span className="no--agreement">No agreement uploaded yet</span>
                        )}
                      </h3>
                      {v?.last_date_uploaded && (
                        <p className="subtitle">Last updated</p>
                      )}
                    </div>
                    <div className="actions">
                      {v?.last_date_uploaded && (
                        <button
                          type="button"
                          disabled={downloading !== undefined && downloading === v?.id}
                          className="table--btn download"
                          onClick={() => !(downloading !== undefined && downloading === v?.id) && downloadFile({ landlord_id: v?.id})}
                        >
                          {(downloading !== undefined && downloading === v?.id) ? (
                            <div className="btns d-flex justify-content-center">
                              <span className="fa fa-spinner fa-spin px-3"></span>
                            </div>
                          ) : (
                            <>
                              <IconFile size={18} />
                              View/Download
                            </>
                          )}
                        </button>
                      )}
                      <button
                        type="button"
                        className="table--btn"
                        onClick={() => {
                          setUpdateAgreement(v);
                          setOnMenuActive(null);
                        }}
                      >
                        <IconUpload size={18} />
                        {v?.last_date_uploaded ? 'Replace' : 'Upload'}
                      </button>
                    </div>
                  </div>
                  <div className="position-relative">
                    <button
                      type="button"
                      className={clsx('la--btn_menu', { active: onMenuActive === key })}
                      onClick={() => onMenuActive === key ? setOnMenuActive(null) : setOnMenuActive(key)}
                    >
                      <IconMenu size={20} />
                    </button>
                    {key === onMenuActive && (
                      <ul className="la-menu_list">
                        {v?.last_date_uploaded && (
                          <li onClick={() => {
                            setVersionHistory(v);
                            setOnMenuActive(null);
                          }}>
                            <IconHistory size={21} />
                            Version History
                          </li>
                        )}
                        <li onClick={() => {
                          setLandlordEdit(v);
                          setNewLandlordOpen(true);
                          setOnMenuActive(null)
                        }}>
                          <IconEdit size={18} />
                          Edit Landlord
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              )) : (<></>)}
            </div>
            <div className="la--table">
              <table>
                <colgroup>
                  <col span="1" style={{ width: '30%' }} />
                  <col span="1" style={{ width: '30%' }} />
                  <col span="1" style={{ width: '20%' }} />
                  <col span="1" style={{ width: '10%' }} />
                  <col span="1" style={{ width: '10%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Landlord</th>
                    <th>Last updated</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                {getAgreementLandlordList?.length ? (
                  <tbody>
                    {getAgreementLandlordList?.map((v, key) => (
                      <tr key={key}>
                        <td>{v.first_name === v.last_name ? v?.first_name : `${v?.first_name} ${v.last_name}`}</td>
                        <td>
                          {v?.last_date_uploaded
                            ? (
                              <span className="with--agreement">{format(new Date(v.last_date_uploaded), 'dd MMM yyyy')}</span>
                            )
                            : (
                            <span className="no--agreement">No agreement uploaded yet</span>
                          )}
                        </td>
                        <td>
                          {v?.last_date_uploaded && (
                            <button
                              type="button"
                              disabled={downloading !== undefined && downloading === v?.id}
                              className="table--btn download"
                              onClick={() => !(downloading !== undefined && downloading === v?.id) && downloadFile({ landlord_id: v?.id})}
                            >
                              {(downloading !== undefined && downloading === v?.id) ? (
                                <div className="btns d-flex justify-content-center">
                                  <span className="fa fa-spinner fa-spin px-3"></span>
                                </div>
                              ) : (
                                <>
                                  <IconFile size={18} />
                                  View/Download
                                </>
                              )}
                            </button>
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="table--btn"
                            onClick={() => {
                              setUpdateAgreement(v);
                              setOnMenuActive(null);
                            }}
                          >
                            <IconUpload size={18} />
                            {v?.last_date_uploaded ? 'Replace' : 'Upload'}
                          </button>
                        </td>
                        <td align="right">
                          <button
                            type="button"
                            className={clsx('la--btn_menu', { active: onMenuActive === key })}
                            onClick={() => onMenuActive === key ? setOnMenuActive(null) : setOnMenuActive(key)}
                          >
                            <IconMenu size={20} />
                          </button>
                          {key === onMenuActive && (
                            <ul className="la-menu_list">
                              {v?.last_date_uploaded && (
                                <li onClick={() => {
                                  setVersionHistory(v);
                                  setOnMenuActive(null);
                                }}>
                                  <IconHistory size={21} />
                                  Version History
                                </li>
                              )}
                              <li onClick={() => {
                                setLandlordEdit(v);
                                setNewLandlordOpen(true);
                                setOnMenuActive(null)
                              }}>
                                <IconEdit size={18} />
                                Edit Landlord
                              </li>
                            </ul>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (<></>)}
              </table>
            </div>
            {!getAgreementLandlordList?.length && (
              <div className="empty--state">
                <IconEmptyState size={258} />
                <p className="empty-msg">No agreements have been added as of yet.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewLandlord
        open={newLandlordOpen}
        edit={landLordEdit}
        onClose={onCloseNewLandlord}
      />
      <SuccessNewLandlord
        open={successCreatedLandlord}
        edit={landLordEdit}
        onClose={onCloseSuccessNewLandlord}
      />
      <UpdateAgreement
        open={updateAgreement?.id}
        data={updateAgreement}
        onClose={onCloseUpdateAgreement}
      />
      <VersionHistory
        open={versionHistory?.id}
        data={versionHistory}
        onClose={() => setVersionHistory(null)}
      />
      <AgreementSuccess
        open={uploadSuccess?.id}
        data={uploadSuccess}
        onClose={() => {
          setUploadSuccess(null);
          dispatch(getAllAgreementLandlordData());
        }}
      />
    </>
  );
};

export default LandlordAgreement;