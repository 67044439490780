import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLikedProperty, onLike, onUnlike } from "../../../store/homeAction";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import Rating from "../../../components/rating/Rating";
import "./myLikedLocations.scss";

const MyLikedLocations = () => {
  const dispatch = useDispatch();
  const likedProperty = useSelector((state) => state.home.likedProperty);
  const isLoading = useSelector((state) => state.home.isLoading);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("os-user"));

    let data = {
      user_id: userDetails && userDetails.id,
    };
    dispatch(getLikedProperty(data));
  }, []);

  const onLikeClick = (id) => {
    const userDetails = JSON.parse(localStorage.getItem("os-user"));
    const data = { user_id: userDetails.id, property_id: id };
    dispatch(onLike(data, {}, "likedLocation"));
  };

  const onUnlikeClick = (id) => {
    const userDetails = JSON.parse(localStorage.getItem("os-user"));
    const data = { user_id: userDetails.id, property_id: id };
    dispatch(onUnlike(data, {}, "likedLocation"));
  };
  return (
    <div className="liked-wrapper">
      {isLoading ? (
        <div className="py-5">
          <Loading></Loading>
        </div>
      ) : likedProperty && likedProperty.length > 0 ? (
        likedProperty.map((property) => (
          <div className="liked fade-in-bottom">
            <div className="liked-left">
              <div className="liked-images">
                <img
                  src={property && property.featured_image}
                  alt=""
                  className="liked-image"
                />
                {property.is_liked ? (
                  <img
                    src="assets/images/heart.png"
                    onClick={() => onUnlikeClick(property.id)}
                    alt=""
                    className="heart"
                  />
                ) : (
                  <img
                    src="assets/images/heart-blank.png"
                    alt=""
                    onClick={() => onLikeClick(property.id)}
                    className="heart"
                  />
                )}
              </div>
              <div className="liked-text">
                <Link to={`/property/${property.id}`}>
                  <h5>{property && property.property_title}</h5>
                </Link>
                {/* <img src="assets/images/inactive-stars.png" alt="" /> */}

                <Rating rating={property && property.avg_rating}></Rating>
                <div className="liked-desk">
                  Desks: <b>{property && property.number_of_desk}</b>
                </div>
              </div>
            </div>

            {property.is_liked ? (
              <div
                className="liked-right"
                onClick={() => onUnlikeClick(property.id)}
              >
                <img
                  className="jello-horizontal"
                  src="assets/images/heart.png"
                  alt=""
                />
                <small>Liked</small>
              </div>
            ) : (
              <div
                className="liked-right"
                onClick={() => onLikeClick(property.id)}
              >
                <span>
                  <img
                    className="jello-horizontal"
                    src="assets/images/heart-blank.png"
                    alt=""
                  />
                </span>

                <small>Liked</small>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="p-5 my-5 text-center w-100">No Data Available!</div>
      )}
    </div>
  );
};

export default MyLikedLocations;
