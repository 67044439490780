import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieBar = () => {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="OS_CookieConsent2"
        style={{ background: "#0f344e", fontSize: "13px" }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          backgroundColor: "#f56565",
        }}
        enableDeclineButton
        declineButtonText="Reject All"
        flipButtons={true}
        onDecline={() => {
          alert("Declined!");
        }}
        expires={150}
      >
        This website uses cookies. To consent to all, simply hit ‘Accept’ here.
        If you would like to manage your cookie preferences, hit ‘Manage’.
      </CookieConsent>
    </div>
  );
};

export default CookieBar;
