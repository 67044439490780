import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPlanLists, getUserDetails } from "../../store/homeAction";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/loading/Loading";
import "./planList.scss";

const PlanList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const planLists = useSelector((state) => state.home.planLists);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [selectedPack, setSelectedPack] = useState();
  const userDetail = JSON.parse(localStorage.getItem("os-user"));
  const userDetails = useSelector((state) => state.home.userDetails);

  useEffect(() => {
    dispatch(getPlanLists());
  }, []);

  useEffect(() => {
    dispatch(getUserDetails({ user_id: userDetail && userDetail.id }));
  }, []);

  const goToBuy = () => {
    if (!selectedPack) {
      toast("Please select any plan!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }
    history.push(`/plan-list/${selectedPack}`);
  };

  return (
    <>
      {isLoading ? (
        <div
          className="container"
          style={{
            height: "450px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading></Loading>
        </div>
      ) : (
        <>
          <div className="kyc-progressbarWrapper">
            {userDetails && userDetails.kyc_status === false ? (
              <div className="kyc-progressbar">
                <h6>verification in progress</h6>
                <button className="kyc-button">in-progress</button>
              </div>
            ) : null}
          </div>
          <div className="container py-5">
            <div className="plan-list-wrapper fade-in-bottom">
              <h3>Select your plan</h3>
              <div className="plan-list pb-4">
                {planLists &&
                  planLists.map((plan, key) => (
                    <div
                      className={`plan-item py-5 ${
                        selectedPack === plan.id && "active"
                      }`}
                      key={key}
                      onClick={() => setSelectedPack(plan.id)}
                    >
                      <h5>
                        <b>{plan.title}</b>
                      </h5>
                      <h5 className="mt-2">{plan.validity}</h5>
                      <br />
                      <h3>&#8356;{plan.price}</h3>
                      <br />
                      <div className="plan-description">
                        {plan.features.map((feature, key) => (
                          <p key={key}>
                            {feature.title}
                            <br />
                          </p>
                        ))}
                      </div>
                      <p>
                        <b
                          dangerouslySetInnerHTML={{
                            __html: plan.description,
                          }}
                        ></b>
                      </p>
                    </div>
                  ))}
              </div>
              <div onClick={goToBuy} className="button">
                Next
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlanList;
