import React from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const PriceGridPopup = ({ open, onClose, priceList, spaceDetails }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "price-grid-popup" }}
    >
      <h5 className="title text-primary font-weight-bold mb-2">
        OS Smart Pricing:
      </h5>
      <p>Delivering value and helping keep our spaces great, 24/7.</p>
      <div className="list-group">
        <div className="list-group-item">
          <span className="day"></span>
          {spaceDetails.availability_type == 1 ? (
            <span className="price text-dark">Day</span>
          ) : spaceDetails.availability_type == 2 ? (
            <span className="price text-dark">Night</span>
          ) : spaceDetails.availability_type == 3 ? (
            <>
              <span className="price text-dark">Day</span>
              <span className="price text-dark">Night</span>
            </>
          ) : null}
        </div>
        {priceList &&
          priceList.map((item, key) => (
            <div className="list-group-item" key={key}>
              <span className="day">{item.day}</span>
              {spaceDetails.availability_type == 1 ? (
                <span className="price text-primary">&#8356;{item.price}</span>
              ) : spaceDetails.availability_type == 2 ? (
                <span className="price text-primary">
                  &#8356;{item.night_price}
                </span>
              ) : spaceDetails.availability_type == 3 ? (
                <>
                  <span className="price text-primary">
                    &#8356;{item.price}
                  </span>
                  <span className="price text-primary">
                    &#8356;{item.night_price}
                  </span>
                </>
              ) : null}
            </div>
          ))}
      </div>
      <p className="discount-text">
        {spaceDetails.availability_type == 2 ||
        spaceDetails.availability_type == 3
          ? spaceDetails.night_discount_msg
          : null}
      </p>
      <p
        className="text-center text-underline close-btn"
        onClick={() => onClose(false)}
      >
        Close
      </p>
    </Modal>
  );
};

export default PriceGridPopup;
