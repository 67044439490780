import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertySpaces,
  getPropertyById,
  getAvailableDates,
} from "../../store/homeAction";
import { SET_BOOK_SPACE_DETAILS } from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import SingleCalendar from "./SingleCalendar";
import RangeCalendar from "./RangeCalendar";
import SwitchMap from "./SwitchMap";
import "./userBooking.scss";
import PropertyWithSpaceDetails from "./PropertyWithSpaceDetails";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/loading/Loading";

const UserBookingFour = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const isLoading = useSelector((state) => state.home.isLoading);
  const dayAvailableDates = useSelector(
    (state) => state.home.dayAvailableDates
  );
  const nightAvailableDates = useSelector(
    (state) => state.home.nightAvailableDates
  );

  const [spaceDetails, setSpaceDetails] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [availabilityType, setAvailabilityType] = useState(1);
  const [daySelectedDates, setDaySelectedDates] = useState([]);
  const [nightSelectedDates, setNightSelectedDates] = useState([]);

  useEffect(() => {
    onGetPropertyList();
    onGetSpaceList();
  }, [match]);

  //console.log(bookSpaceDetails);

  useEffect(() => {
    if (bookSpaceDetails) {
      if (bookSpaceDetails.daySelectedDates) {
        setDaySelectedDates(bookSpaceDetails.daySelectedDates);
      }
      if (bookSpaceDetails.nightSelectedDates) {
        setNightSelectedDates(bookSpaceDetails.nightSelectedDates);
      }
    }
  }, [bookSpaceDetails]);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
          setAvailabilityType(spaceList[i].availability_type);
          onGetAvailableDates(spaceList[i].availability_type);
        }
      }
    }
  }, [propertySpaces]);

  useEffect(() => {
    if (spaceDetails.availability_type == 2 && availabilityType == 1) {
      toast("Sorry! This space is not available for day booking", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      setAvailabilityType(2);
    } else if (spaceDetails.availability_type == 1 && availabilityType == 2) {
      toast("Sorry! This space is not available for night booking", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      setAvailabilityType(1);
    } else {
      setAvailabilityType(availabilityType);
    }
  }, [availabilityType]);

  useEffect(() => {
    if (bookSpaceDetails === undefined) {
      history.push(`/space/${match.params.propertyId}/${match.params.spaceId}`);
    }
  }, []);

  const onGetPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };

  const onGetSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onGetAvailableDates = (availabilityType) => {
    const data = {
      space_id: match.params.spaceId,
      no_of_desk: (bookSpaceDetails && bookSpaceDetails.number_of_desk) || 1,
      day_availability: availabilityType,
    };
    dispatch(getAvailableDates(data));
  };

  const onSelectDayDate = (dates) => {
    setDaySelectedDates(dates);
    // console.log(dates);
  };
  const onSelectNightDate = (dates) => {
    setNightSelectedDates(dates);
    // console.log(dates);
  };

  const onNext = () => {
    if (daySelectedDates.length === 0 && nightSelectedDates.length === 0) {
      toast("Please select dates", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else {
      dispatch({
        type: SET_BOOK_SPACE_DETAILS,
        payload: {
          ...bookSpaceDetails,
          daySelectedDates,
          nightSelectedDates,
        },
      });
      history.push(
        `/userbooking/5/${match.params.propertyId}/${match.params.spaceId}`
      );
    }
  };

  return (
    <div
      className={`user-detail-with-background ${
        availabilityType === 2 ? "" : null
      }`}
    >
      <div className="container pt-5">
        <div className="user-details-wrapper">
          <div className="user-booking">
            <div className="user-booking-main-wrapper">
              <div className="user-booking-main">
                <div className="user-left">
                  <Link
                    to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                    className="back-btn"
                  >
                    Back to space
                  </Link>
                  {propertyListById && spaceDetails && (
                    <PropertyWithSpaceDetails
                      propertyListById={propertyListById}
                      spaceDetails={spaceDetails}
                    />
                  )}
                  <hr />
                  <div className="user-middle">
                    <h6>When would you like to work here?</h6>
                    <div className="two-desc">
                      Top & hold a date for more options
                    </div>
                  </div>
                  <hr />
                  <div className="day-night-wrapper pt-2">
                    <div
                      className={`daytime ${
                        availabilityType == 1 || availabilityType == 3
                          ? "selected"
                          : "low-opacity"
                      }`}
                      onClick={() => setAvailabilityType(1)}
                    >
                      <img src="assets/images/day.svg" alt="" />
                      <span>
                        <p>
                          <b>Daytime</b> <br />
                          8am-6pm
                        </p>
                      </span>
                    </div>
                    <div
                      className={`nighttime ${
                        availabilityType == 2 ? "selected" : "low-opacity"
                      }`}
                      onClick={() => setAvailabilityType(2)}
                    >
                      <img src="assets/images/night.svg" alt="" />
                      <span>
                        <p>
                          <b>Nighttime</b> <br />
                          7pm-5am
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="user-bottom">
                    {isLoading === false ? (
                      bookSpaceDetails?.isWeeklySpace ? (
                        <RangeCalendar
                          availableDates={
                            availabilityType == 1 || availabilityType == 3
                              ? dayAvailableDates
                              : nightAvailableDates
                          }
                          selectedDatesArray={
                            availabilityType == 1 || availabilityType == 3
                              ? daySelectedDates
                              : nightSelectedDates
                          }
                          isWeeklySpace={bookSpaceDetails?.isWeeklySpace}
                          numberOfWeeks={bookSpaceDetails?.numberOfWeeks}
                          onSelectDayDate={onSelectDayDate}
                          onSelectNightDate={onSelectNightDate}
                          availabilityType={availabilityType}
                        />
                      ) : (
                        <SingleCalendar
                          availableDates={
                            availabilityType == 1 || availabilityType == 3
                              ? dayAvailableDates
                              : nightAvailableDates
                          }
                          selectedDatesArray={
                            availabilityType == 1 || availabilityType == 3
                              ? daySelectedDates
                              : nightSelectedDates
                          }
                          numberOfWeeks={
                            bookSpaceDetails && bookSpaceDetails.numberOfWeeks
                          }
                          onSelectDayDate={onSelectDayDate}
                          onSelectNightDate={onSelectNightDate}
                          availabilityType={availabilityType}
                        />
                      )
                    ) : (
                      <div className="py-4 pr-5 mr-5">
                        <Loading />
                      </div>
                    )}

                    <div className="buttons">
                      <div onClick={onNext} className="one-btn btn">
                        Continue
                      </div>
                    </div>
                  </div>
                </div>
                <SwitchMap
                  propertyListById={propertyListById}
                  spaceDetails={spaceDetails}
                ></SwitchMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookingFour;
