import firebase from "firebase";
import "firebase/firestore";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBCVWhxGiVB5-X8zFEVPyRJkHG0aw_bHo4",
  authDomain: "office-share-e1756.firebaseapp.com",
  databaseURL: process.env.REACT_APP_FIREABASE_DATABASE_URL,
  projectId: "office-share-e1756",
  storageBucket: "office-share-e1756.appspot.com",
  messagingSenderId: "584323513081",
  appId: "1:584323513081:web:b5d3a9b71b59dfb0450028",
  measurementId: "G-KF1PDDKLJC",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();

export const getToken = (setTokenFound) => {
  if (!firebase.messaging.isSupported()) return;

  const messaging = firebase.messaging();

  return messaging
    .getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("fcm_token", currentToken);
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const registerOnMessageListerner = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: process.env.REACT_APP_BASE_URL + "/assets/gif/loading.gif",
        tag: payload.notification.body
      };

      if (!("Notification" in window)) {
      } else if (Notification.permission === "granted") {
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        notification.onclick = function (event) {
          event.preventDefault();
          window.open(
              process.env.REACT_APP_BASE_URL + `/#/chat/${payload.data.receiver_id}`,
            "_blank"
          );
          notification.close();
        };
      }
    });
  }
}

export default db;
