import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  addAccountInStripe,
  getCountryList,
  getStateList,
  createFileLink,
  getUserDetails,
} from "../../store/homeAction";
import { SET_EMPTY_VERIFICATION_FILES } from "../../store/types";
import Datetime from "react-datetime";
import ForCompany from "../onBoarding/ForCompany";
import ForIndividual from "../onBoarding/ForIndividual";
import moment from "moment";

let inputProps = {
  placeholder: "DOB",
};

const OnboardingStepTwo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ip, setIP] = useState("");
  const [phoneCode, setPhoneCode] = useState();
  const countryList = useSelector((state) => state.home.countryList);
  const stateList = useSelector((state) => state.home.stateList);
  const verificationFiles = useSelector(
    (state) => state.home.verificationFiles
  );
  const addStripeAccountLoading = useSelector(
    (state) => state.home.addStripeAccountLoading
  );

  const [selectedType, setSelectedType] = useState("company");
  const [values, setValues] = useState({});
  const [companyFrontUploading, setCompanyFrontUploading] = useState(false);
  const [companyBackUploading, setCompanyBackUploading] = useState(false);
  const [personFrontUploading, setPersonFrontUploading] = useState(false);
  const [personBackUploading, setPersonBackUploading] = useState(false);
  const [frontUploading, setFrontUploading] = useState(false);
  const [backUploading, setBackUploading] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("os-user"));
  const userDetails = useSelector((state) => state.home.userDetails);

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getStateList({ country_id: 230 }));
    getIpData();
  }, []);

  useEffect(() => {
    dispatch(getUserDetails({ user_id: userDetail?.id }));
  }, [selectedType]);

  useEffect(() => {
    if (userDetails) {
      setValues({
        ...userDetails,
        dob: moment(userDetails?.dob, "DD-MMM-YYYY").format("DD-MMM-YYYY"),
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      verificationFiles &&
      verificationFiles.some((file) => file.type === "front")
    ) {
      setFrontUploading(false);
    }

    if (
      verificationFiles &&
      verificationFiles.some((file) => file.type === "back")
    ) {
      setBackUploading(false);
    }
  }, [verificationFiles]);

  //creating function to load ip address from the API
  const getIpData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const gotoNextStep = () => {
    history.push("/onboarding/3");
  };

  const onChangeSelectedType = (type) => {
    // setValues({});
    setSelectedType(type);
    dispatch({
      type: SET_EMPTY_VERIFICATION_FILES,
      payload: null,
    });
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    if (e.target.name === "country") {
      dispatch(getStateList({ country_id: e.target.value }));
    }

    if (countryList) {
      for (let i = 0; i < countryList?.length; i++) {
        if (countryList[i].id == e.target.value) {
          setPhoneCode(countryList[i].phonecode);
        }
      }
    }
  };

  const onAddBankAccount = async (e) => {
    e.preventDefault();

    // For company
    if (selectedType === "company") {
      const companyParams = new URLSearchParams();
      const personParams = new URLSearchParams();

      companyParams.append("type", "custom");
      companyParams.append("capabilities[card_payments][requested]", true);
      companyParams.append("capabilities[transfers][requested]", true);
      companyParams.append("business_type", selectedType);

      companyParams.append("business_profile[mcc]", "6513");
      companyParams.append(
        "tos_acceptance[date]",
        Math.round(new Date().getTime() / 1000)
      );
      // companyParams.append("tos_acceptance[ip]", "104.236.225.186");
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;
        companyParams.append("tos_acceptance[ip]", ipAddress);

        // Continue with the rest of your logic here
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }

      companyParams.append("company[name]", values.company_name);
      companyParams.append(
        "business_profile[url]",
        values.business_profile_url
      );
      companyParams.append("company[phone]", values.company_phone);
      companyParams.append("company[tax_id]", values.company_tax_id);
      companyParams.append("company[address][city]", values.city);
      companyParams.append("company[address][state]", values.state);
      companyParams.append("company[address][country]", getCountry());
      companyParams.append("company[address][line1]", values.line_one);
      companyParams.append("company[address][line2]", values.line_two);
      companyParams.append(
        "company[address][postal_code]",
        values.domestic_postcode
      );
      companyParams.append(
        "company[verification][document][front]",
        verificationFiles.filter((file) => file.type === "company_front")[0].id
      );
      companyParams.append(
        "company[verification][document][back]",
        verificationFiles.filter((file) => file.type === "company_back")[0].id
      );
      companyParams.append("company[directors_provided]", true);
      companyParams.append("company[executives_provided]", true);

      personParams.append("first_name", values.first_name);
      personParams.append("last_name", values.last_name);
      personParams.append("email", values.email);
      personParams.append("phone", values.phone);
      personParams.append("dob[day]", new Date(values.dob).getUTCDate());
      personParams.append("dob[month]", new Date(values.dob).getUTCMonth() + 1);
      personParams.append("dob[year]", new Date(values.dob).getUTCFullYear());
      // personParams.append("ssn_last_4", values.owner_ssn_last_4);
      personParams.append("address[line1]", values.line_one);
      personParams.append("address[line2]", values.line_two);
      personParams.append("address[postal_code]", values.domestic_postcode);
      personParams.append("address[city]", values.city);
      personParams.append("address[state]", values.state);
      personParams.append("relationship[owner]", true);
      personParams.append("relationship[representative]", true);
      personParams.append("relationship[executive]", false);
      personParams.append("relationship[director]", false);
      personParams.append("relationship[percent_ownership]", 100);
      personParams.append("relationship[title]", "Founder");

      personParams.append(
        "verification[additional_document][front]",
        verificationFiles.filter((file) => file.type === "person_front")[0].id
      );
      personParams.append(
        "verification[additional_document][back]",
        verificationFiles.filter((file) => file.type === "person_back")[0].id
      );
      personParams.append(
        "verification[document][front]",
        verificationFiles.filter((file) => file.type === "person_front")[0].id
      );
      personParams.append(
        "verification[document][back]",
        verificationFiles.filter((file) => file.type === "person_back")[0].id
      );

      dispatch(addAccountInStripe(companyParams, history, personParams));
    } else {
      // For individual

      const individualParams = new URLSearchParams();
      individualParams.append("type", "custom");
      individualParams.append("business_profile[mcc]", "6513");

      individualParams.append(
        "tos_acceptance[date]",
        Math.round(new Date().getTime() / 1000)
      );

      // companyParams.append("tos_acceptance[ip]", "104.236.225.186");
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;
        individualParams.append("tos_acceptance[ip]", ipAddress);

        console.log(ipAddress);
        // Continue with the rest of your logic here
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
      // individualParams.append("tos_acceptance[ip]", "104.236.225.186");

      individualParams.append("capabilities[card_payments][requested]", true);
      individualParams.append("capabilities[transfers][requested]", true);
      individualParams.append("business_type", selectedType);
      individualParams.append("individual[first_name]", values.first_name);
      individualParams.append("individual[last_name]", values.last_name);
      individualParams.append("individual[email]", values.email);
      individualParams.append(
          "individual[phone]",
          `${values.phone.startsWith("+") ? '' : `+${phoneCode || "44"}`}${values.phone}`
      );

      individualParams.append("individual[address][city]", values.city);
      individualParams.append("individual[address][state]", values.state);
      individualParams.append(
        "individual[address][country]",
        getCountry() || "GB"
      );
      individualParams.append("individual[address][line1]", values.line_one);
      individualParams.append("individual[address][line2]", values.line_two);
      individualParams.append(
        "individual[address][postal_code]",
        values.domestic_postcode
      );

      individualParams.append(
        "business_profile[url]",
        values.business_profile_url
      );

      individualParams.append(
        "individual[dob][day]",
        new Date(values.dob).getUTCDate()
      );
      individualParams.append(
        "individual[dob][month]",
        new Date(values.dob).getUTCMonth() + 1
      );
      individualParams.append(
        "individual[dob][year]",
        new Date(values.dob).getUTCFullYear()
      );

      individualParams.append(
        "individual[verification][document][front]",
        verificationFiles.filter((file) => file.type === "person_front")[0].id
      );
      individualParams.append(
        "individual[verification][document][back]",
        verificationFiles.filter((file) => file.type === "person_back")[0].id
      );
      individualParams.append(
        "individual[verification][additional_document][front]",
        verificationFiles.filter((file) => file.type === "person_front")[0].id
      );
      individualParams.append(
        "individual[verification][additional_document][back]",
        verificationFiles.filter((file) => file.type === "person_back")[0].id
      );

      dispatch(addAccountInStripe(individualParams, history));
    }
  };

  const getCountry = () => {
    const country = countryList.filter(
      (country) => values.country == country.id
    );
    return country[0]?.sortname;
  };

  const onUploadFile = (e, type, purpose) => {
    if (type === "company_front") {
      setCompanyFrontUploading(true);
    }
    if (type === "company_back") {
      setCompanyBackUploading(true);
    }
    if (type === "person_front") {
      setPersonFrontUploading(true);
    }
    if (type === "person_back") {
      setPersonBackUploading(true);
    }
    if (type === "front") {
      setFrontUploading(true);
    }
    if (type === "back") {
      setBackUploading(true);
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("purpose", purpose);

    console.log('onboarding_step_two createFileLnk')
    console.log(e.target.files[0]);
    dispatch(createFileLink(formData, type));
  };

  return (
    <div className="onboarding-main step-two">
      <div className="left"></div>
      <div className="right">
        <div className="tips-wrapper">
          <div className="tips-description">
            <p className="title">
              Tips<span className="text-primary">:</span>
            </p>
            <p className="description">
              In order to verify you as a person or company who is going to
              charge another OS member to share a space we need to validate your
              identity. The below lists the forms of ID you can use to do this.
              But one must be photographic and you may be taken to a third party
              web url to do this. Please stick with the process and have the
              verification documents at hand. It’s really easy to do.{" "}
            </p>
            <i className="fa fa-chevron-down text-primary down-arrow-icon"></i>
          </div>
          <div className="identification-div">
            <p className="title">Acceptable forms of identification:</p>
            <p className="identity">Passport</p>
            <p className="identity">Driver Licence</p>
            <p className="identity">Resident Permit</p>
            <p className="identity">Citizen Card</p>
            <p className="identity">Electoral ID</p>
            <p className="identity">Validate UK</p>
          </div>

          <div className="identification-div border-bottom-none">
            <p className="title">Acceptable forms of address verification:</p>

            <p className="identity">Driver Licence</p>
            <p className="identity">Utility Bills</p>
            <p className="identity">Statement from a financial institution</p>
            <p className="identity">Government issued letter</p>
            <p className="identity">Solicitors letter</p>
            <p className="identity">
              Council or housing association documentation
            </p>
            <p className="identity">Benefits Agency documentation</p>
            <p className="identity">Electoral Register entry</p>
            <p className="identity">NHS Medical Card</p>
            <p className="identity">
              GP letter of registration with the surgery
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="onboarding-wrapper">
          <div className="left-section">
            <div className="step-progress">
              <p className="text-text">
                <b> Step 2:</b> Are you listing as a company or an individual?{" "}
              </p>
              <div className="progress-bar">
                <div className="fill" style={{ width: "40%" }}></div>
              </div>
            </div>

            <div className="company-type-wrapper">
              <div
                className={`option ${selectedType === "company" && "selected"}`}
                onClick={() => onChangeSelectedType("company")}
              >
                {selectedType === "company" ? (
                  <img src="./assets/images/public-white.png" alt="" />
                ) : (
                  <img src="./assets/images/public.png" alt="" />
                )}
                As a company
              </div>
              <div
                className={`option ${
                  selectedType === "individual" && "selected"
                }`}
                onClick={() => onChangeSelectedType("individual")}
              >
                {selectedType === "individual" ? (
                  <img src="./assets/images/female-white.png" alt="" />
                ) : (
                  <img src="./assets/images/female.png" alt="" />
                )}
                As a individual
              </div>
            </div>
            {selectedType === "company" ? (
              <ForCompany
                onInputChange={onInputChange}
                values={values}
                onAddBankAccount={onAddBankAccount}
                stateList={stateList}
                countryList={countryList}
                verificationFiles={verificationFiles}
                companyFrontUploading={companyFrontUploading}
                onUploadFile={onUploadFile}
                companyBackUploading={companyBackUploading}
                Datetime={Datetime}
                setValues={setValues}
                inputProps={inputProps}
                personFrontUploading={personFrontUploading}
                personBackUploading={personBackUploading}
                addStripeAccountLoading={addStripeAccountLoading}
              />
            ) : selectedType === "individual" ? (
              <ForIndividual
                onInputChange={onInputChange}
                values={values}
                onAddBankAccount={onAddBankAccount}
                stateList={stateList}
                countryList={countryList}
                verificationFiles={verificationFiles}
                onUploadFile={onUploadFile}
                Datetime={Datetime}
                setValues={setValues}
                inputProps={inputProps}
                personFrontUploading={personFrontUploading}
                personBackUploading={personBackUploading}
                addStripeAccountLoading={addStripeAccountLoading}
              />
            ) : null}
          </div>
          <div className="right-section">
            <div className="tips-wrapper">
              <div className="tips-description">
                <p className="title">
                  Tips<span className="text-primary">:</span>
                </p>
                <p className="description">
                  In order to verify you as a person or company who is going to
                  charge another OS member to share a space we need to validate
                  your identity. The below lists the forms of ID you can use to
                  do this. But one must be photographic and you may be taken to
                  a third party web url to do this. Please stick with the
                  process and have the verification documents at hand. It’s
                  really easy to do.{" "}
                </p>
                <i className="fa fa-chevron-down text-primary down-arrow-icon"></i>
              </div>
              <div className="identification-div">
                <p className="title">Acceptable forms of identification:</p>
                <p className="identity">Passport</p>
                <p className="identity">Driver Licence</p>
                <p className="identity">Resident Permit</p>
                <p className="identity">Citizen Card</p>
                <p className="identity">Electoral ID</p>
                <p className="identity">Validate UK</p>
              </div>

              <div className="identification-div border-bottom-none">
                <p className="title">
                  Acceptable forms of address verification:
                </p>

                <p className="identity">Driver Licence</p>
                <p className="identity">Utility Bills</p>
                <p className="identity">
                  Statement from a financial institution
                </p>
                <p className="identity">Government issued letter</p>
                <p className="identity">Solicitors letter</p>
                <p className="identity">
                  Council or housing association documentation
                </p>
                <p className="identity">Benefits Agency documentation</p>
                <p className="identity">Electoral Register entry</p>
                <p className="identity">NHS Medical Card</p>
                <p className="identity">
                  GP letter of registration with the surgery
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStepTwo;
