import React from "react";
import "./../popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import './landlordStyle.scss';

const AgreementSuccess = ({
  onClose,
  open,
  data,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "amenities-popup-modal experience-modal" }}
    >
      <div className="la-modal__wrapper">
        <h5 className="text-primary">Agreement successfully updated</h5>

        <div className="la-modal__form">
          The agreement between you and {`${data?.first_name || ''} ${data?.last_name || ''}`} has been updated. You can upload an updated version of the document if you discover any errors in the existing one.
        </div>
        
        <div className="la-modal__btn">
          <button type="button" className="la-modal__btn__cancel" onClick={onClose}>Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default AgreementSuccess;

