import moment from "moment";
import React from "react";

const ForCompany = ({
  onInputChange,
  values,
  onAddBankAccount,
  stateList,
  countryList,
  verificationFiles,
  companyFrontUploading,
  onUploadFile,
  companyBackUploading,
  Datetime,
  setValues,
  inputProps,
  personFrontUploading,
  personBackUploading,
  addStripeAccountLoading,
}) => {
  // disable future dates
  const today = moment();
  const disableFutureDt = (current) => {
    return current.isBefore(today);
  };

  return (
    <div className="row add-bank-form">
      <div className="col-md-8 my-5">
        <form onSubmit={onAddBankAccount}>
          <h5 className="mb-3 text-primary">Company Details</h5>
          <div className="form-group">
            <label htmlFor="company_name">Company Name</label>
            <input
              type="text"
              name="company_name"
              value={values.company_name}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="company_tax_id">Company Tax ID</label>
            <input
              type="text"
              name="company_tax_id"
              value={values.company_tax_id}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="company_phone">Company Phone</label>
            <input
              type="text"
              name="company_phone"
              value={values.company_phone}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="business_profile_url">Business Profile URL</label>
            <input
              type="text"
              name="business_profile_url"
              value={values.business_profile_url}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="line_one">Address Line 1</label>
            <input
              type="text"
              name="line_one"
              value={values.line_one}
              onChange={onInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="line_two">Address Line 2</label>
            <input
              type="text"
              name="line_two"
              value={values.line_two}
              onChange={onInputChange}
              // required
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>

            <select
              name="country"
              value={values.country}
              onChange={onInputChange}
              required
            >
              <option value="" hidden>
                Select
              </option>
              {countryList &&
                countryList.map((country, key) => (
                  <option
                    key={key}
                    value={country.id}
                    // selected={country.id === 230 ? true : false}
                  >
                    {country.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="state">State</label>
            <select
              name="state"
              value={values.state}
              onChange={onInputChange}
              required
            >
              <option value="">Select</option>
              {stateList &&
                stateList.map((state, key) => (
                  <option key={key} value={state.sortname}>
                    {state.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              name="city"
              value={values.city}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="domestic_postcode">Postal Code</label>
            <input
              type="text"
              name="domestic_postcode"
              value={values.domestic_postcode}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="postal_code">
              Company Verification (Front){" "}
              <span>
                {verificationFiles &&
                verificationFiles.some(
                  (file) => file.type === "company_front"
                ) ? (
                  <span className="text-success font-weight-bold">
                    <i className="fa fa-check"></i> Uploaded
                  </span>
                ) : companyFrontUploading ? (
                  <span className="text-warning font-weight-bold">
                    <i className="fa fa-spinner fa-spin"></i> Uploading
                  </span>
                ) : null}
              </span>
            </label>
            <input
              type="file"
              onChange={(e) =>
                onUploadFile(e, "company_front", "account_requirement")
              }
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="postal_code">
              Company Verification (Back){" "}
              <span>
                {verificationFiles &&
                verificationFiles.some(
                  (file) => file.type === "company_back"
                ) ? (
                  <span className="text-success font-weight-bold">
                    <i className="fa fa-check"></i> Uploaded
                  </span>
                ) : companyBackUploading ? (
                  <span className="text-warning font-weight-bold">
                    <i className="fa fa-spinner fa-spin"></i> Uploading
                  </span>
                ) : null}
              </span>
            </label>
            <input
              type="file"
              onChange={(e) =>
                onUploadFile(e, "company_back", "account_requirement")
              }
              required
            />
          </div>

          <h5 className="py-3 text-primary">Business Owner Details</h5>
          <div className="form-group">
            <label htmlFor="first_name">Owner First Name</label>
            <input
              type="text"
              name="first_name"
              value={values.first_name}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="last_name">Owner Last Name</label>
            <input
              type="text"
              name="last_name"
              value={values.last_name}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Owner Email</label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Owner Phone</label>
            <input
              type="text"
              name="phone"
              value={values.phone}
              onChange={onInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="dob">DOB</label>
            <Datetime
              id="date-input"
              dateFormat={"DD-MMM-YYYY"}
              timeFormat={false}
              value={values.dob}
              onChange={(date) => {
                setValues({
                  ...values,
                  dob: moment(date._d, "DD-MMM-YYYY").format("DD-MMM-YYYY"),
                });
              }}
              inputProps={inputProps}
              isValidDate={disableFutureDt}
            />
          </div>

          <div className="form-group">
            <label htmlFor="postal_code">
              Owner Verification (Front){" "}
              <span>
                {verificationFiles &&
                verificationFiles.some(
                  (file) => file.type === "person_front"
                ) ? (
                  <span className="text-success font-weight-bold">
                    <i className="fa fa-check"></i> Uploaded
                  </span>
                ) : personFrontUploading ? (
                  <span className="text-warning font-weight-bold">
                    <i className="fa fa-spinner fa-spin"></i> Uploading
                  </span>
                ) : null}
              </span>
            </label>
            <input
              type="file"
              onChange={(e) =>
                onUploadFile(e, "person_front", "identity_document")
              }
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="postal_code">
              Owner Verification (Back){" "}
              <span>
                {verificationFiles &&
                verificationFiles.some(
                  (file) => file.type === "person_back"
                ) ? (
                  <span className="text-success font-weight-bold">
                    <i className="fa fa-check"></i> Uploaded
                  </span>
                ) : personBackUploading ? (
                  <span className="text-warning font-weight-bold">
                    <i className="fa fa-spinner fa-spin"></i> Uploading
                  </span>
                ) : null}
              </span>
            </label>
            <input
              type="file"
              onChange={(e) =>
                onUploadFile(e, "person_back", "identity_document")
              }
              required
            />
          </div>

          <div className="btn-wrapper pt-3">
            {addStripeAccountLoading ? (
              <button className="button">
                <span className="fa fa-spinner fa-spin px-4"></span>
              </button>
            ) : (
              <button type="submit" className="button">
                Continue
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForCompany;
