import React from 'react';

const IconArrow = ({ color = '#BCBCBC', size = 18, dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (

    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M6.222 1.94l2.108-1.94 17.447 16-17.447 16-2.108-1.93 15.329-14.070-15.329-14.060z"></path>
    </svg>

  )
};

export default IconArrow;