import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import db from "../../config";
import { onLogout } from "../../store/homeAction";
import "./landlordBar.scss";

const LandlordBar = () => {
  const path = useLocation().pathname;
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const details = JSON.parse(localStorage.getItem("os-user"));
  const [userDetails, setUserDetails] = useState(null);
  const [messagesLength, setMessagesLength] = useState();

  useEffect(() => {
    let messageList = [];
    db.ref(`messages`).on("value", function (snapshot) {
      const message = snapshot.val();
      const rootRef = db.ref();

      if (message && Object.keys(message) && Object.keys(message).length > 0) {
        Object.keys(message).map((msg) => {
          if (msg.includes(details.id)) {
            const urlRef = rootRef.child(`messages/${msg}`);
            urlRef.once("value").then((snapshot) => {
              snapshot.forEach((childSnapshot) => {
                let snap = childSnapshot.val();
                if (snap) {
                  messageList.push(snap);
                  const messageFilter = messageList.filter(
                    (m) => m.isread === false
                  );
                  if (messageFilter) {
                    setMessagesLength(messageFilter.length);
                  }
                }
              });
            });
          }
        });
      }
    });
  }, []);

  const handleOpen = () => {
    setActive(!active);
  };

  useEffect(() => {
    if (localStorage.getItem("os-user")) {
      setUserDetails(details);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    // const formData = new FormData();
    // formData.append("user_id", userDetails.id);
    // formData.append("firebase_token", "fdfgdfgd");
    // formData.append("device_id", 123);

    // dispatch(onLogout(formData));
  };

  return (
    userDetails &&
    userDetails.user_role_type === "Landlord" && (
      <>
        <div className="loadload-bar-wrapper">
          <div className="landlord-bar">
            <div className="landlord-header">
              <div className="landlord-menu">
                <i className="fas fa-bars" onClick={handleOpen}></i>
                <h6>Landlord Manager</h6>
              </div>
              <h6 className="landlord-user">
                Hi <b>{userDetails?.first_name}!</b>
              </h6>
            </div>

            <div className="landlord-left">
              <span>
                Hi{" "}
                <span className="font-weight-bold">
                  {userDetails?.first_name}!
                </span>
              </span>
              <span className="ml-3">
                Logged in as{" "}
                <span className="text-primary font-weight-bold">Host</span>
              </span>
              <span className="landlord-logout" onClick={handleLogout}>
                Log out
              </span>
            </div>
            <div className="landlord-right">
              <Link
                to="/landlord-agreement"
                className={
                  path === "/landlord-agreement" ? "landlord-btnActive" : "landlord-btn"
                }
              >
                Landlord Agreement
              </Link>
              <Link
                to="/vouchers"
                className={
                  path === "/vouchers" ? "landlord-btnActive" : "landlord-btn"
                }
              >
                Vouchers
              </Link>
              <Link
                to="/add-property/3"
                className={
                  path === "/add-property/3"
                    ? "landlord-btnActive"
                    : "landlord-btn"
                }
              >
                Dashboard
              </Link>
              <Link
                to="/landlord-dashboard"
                className={
                  path === "/landlord-dashboard"
                    ? "landlord-btnActive"
                    : "landlord-btn"
                }
              >
                Manage Bookings
              </Link>
              <Link
                to={`/chat/${userDetails?.id}`}
                className={
                  path === `/chat/${userDetails?.id}`
                    ? "landlord-btnActive"
                    : "landlord-btn"
                }
              >
                {/* <img
                  src="assets/images/background-red-error.png"
                  alt=""
                  className="message-image"
                /> */}
                Message and Chat
                {path === `/chat/${userDetails?.id}` ? null : (
                  <>
                    {messagesLength === 0 ||
                    messagesLength === undefined ||
                    messagesLength === null ? null : (
                      <span className="messageCounter">{messagesLength}</span>
                    )}
                  </>
                )}
              </Link>
              <Link
                to="/manage-admin-user"
                className={
                  path === "/manage-admin-user"
                    ? "landlord-btnActive"
                    : "landlord-btn"
                }
              >
                Manage Admin Users
              </Link>
              <Link
                to="/myearning"
                className={
                  path === "/myearning" ? "landlord-btnActive" : "landlord-btn"
                }
              >
                My Earnings
              </Link>
              {/* <Link to="/manage-admin-user" className="landlord-btn">
                <img
                  src="assets/images/adding-icon.png"
                  alt=""
                  className="admin-image"
                />
                <span className="ml-3">Add Admin User</span>
              </Link> */}
            </div>
          </div>
        </div>
        <div
          className={`landlord-wrapper swing-in-top-bck ${
            active && "active-bar"
          }`}
        >
          <div className="landlord-top">
            <span>
              Logged in as{" "}
              <span className="text-primary font-weight-bold">Landlord</span>
            </span>
            <span className="landlord-logout">Log out</span>
          </div>
          <div className="landlord-bottom">
            <Link
              to="/landlord-agreement"
              className={
                path === "/landlord-agreement" ? "landlord-btnActive" : "landlord-btn"
              }
            >
              Landlord Agreement
            </Link>
            <Link
              to="/vouchers"
              className={
                path === "/vouchers" ? "landlord-btnActive" : "landlord-btn"
              }
            >
              Vouchers
            </Link>
            <Link
              to="/add-property/3"
              className={
                path === "/add-property/3"
                  ? "landlord-btnActive"
                  : "landlord-btn"
              }
            >
              Dashboard
            </Link>
            <Link
              to="/landlord-dashboard"
              className={
                path === "/landlord-dashboard"
                  ? "landlord-btnActive"
                  : "landlord-btn"
              }
            >
              Manage Bookings
            </Link>
            <Link
              to={`/chat/${userDetails?.id}`}
              className={
                path === `/chat/${userDetails?.id}`
                  ? "landlord-btnActive"
                  : "landlord-btn"
              }
            >
              {/* <img
                src="assets/images/background-red-error.png"
                alt=""
                className="message-image"
              /> */}
              Message and Chat
            </Link>
            <Link
              to="/manage-admin-user"
              className={
                path === "/manage-admin-user"
                  ? "landlord-btnActive"
                  : "landlord-btn"
              }
            >
              Manage Admin Users
            </Link>
            <Link
              to="/myearning"
              className={
                path === "/myearning" ? "landlord-btnActive" : "landlord-btn"
              }
            >
              My Earnings
            </Link>
            {/* <Link className="landlord-btn">
              <img src="assets/images/add.png" alt="" className="admin-image" />
              <span className="ml-3">Add Admin User</span>
            </Link> */}
          </div>
        </div>
      </>
    )
  );
};

export default LandlordBar;
