import React, { useState, useEffect } from "react";
import "./popup.scss";
import { updateBankAccountInStripe } from "../store/homeAction";
import { SET_CLOSE_BANK_ACCOUNT_MODAL } from "../store/types";
import {Link, useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const AddBankAccountPopup = ({ open, onClose, bookingDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const addBankAccountLoading = useSelector(
      (state) => state.home.addBankAccountLoading
  );
  const backAccountModalClosed = useSelector(
      (state) => state.home.backAccountModalClosed
  );

  const [values, setValues] = useState({
    account_number: "",
    routing_number: "",
    account_holder_name: "",
    toc: false,
  });

  useEffect(() => {
    if (backAccountModalClosed) {
      onClose();
      dispatch({ type: SET_CLOSE_BANK_ACCOUNT_MODAL, payload: false });
    }
  }, [backAccountModalClosed]);

  const onAddBankAccount = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("bank_account[account_number]", values.account_number);
    params.append("bank_account[routing_number]", values.routing_number);
    params.append("bank_account[country]", "GB");
    params.append(
        "bank_account[account_holder_name]",
        values.account_holder_name
    );
    params.append("bank_account[currency]", "GBP");

    dispatch(updateBankAccountInStripe(params));
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      routing_number:
          name === "routing_number" ? value.replace(/-/g, "").match(/.{1,2}/g)?.join("-") : prevValues.routing_number,
    }));
  };

  return (
      <Modal
          open={open}
          classNames={{ modal: "add-bank-account-modal" }}
          onClose={onClose}
      >
        <h5 className="font-weight-bold m-0 pb-4">Add New Bank Details</h5>
        <form onSubmit={onAddBankAccount}>
          <div className="form-group">
            <label htmlFor="">Bank Account Number</label>
            <input
                type="text"
                className="form-control"
                name="account_number"
                value={values.account_number}
                onChange={onInputChange}
                required
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Sort Code</label>
            <input
                type="text"
                className="form-control"
                name="routing_number"
                value={values.routing_number}
                onChange={onInputChange}
                maxLength={8}
                required
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Holder Name</label>
            <input
                type="text"
                className="form-control"
                name="account_holder_name"
                value={values.account_holder_name}
                onChange={onInputChange}
                required
            />
          </div>
          <div className="form-group">
            <input type={"checkbox"} id={"toc"} name={"toc"} value={"toc"} required/>
            <label className="pl-2"><p>I confirm I agree to the
              <Link to="/terms" className="link pl-1" target="_blank">
               Terms of Use
            </Link>
            </p>
            </label>
          </div>
          <div className="text-center py-3">
            {addBankAccountLoading ? (
                <button className="button-submit btn">
                  <span className="fa fa-spinner fa-spin px-4"></span>
                </button>
            ) : (
                <button type="submit" className="button-submit btn">
                  Submit
                </button>
            )}
          </div>
        </form>
      </Modal>
  );
};

export default AddBankAccountPopup;
