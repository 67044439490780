import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRegions,
  onAppleLogin,
  onSocialLogin,
  sendOtp,
} from "../../store/homeAction";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./onboarding.scss";
import { LoginSocialGoogle } from "reactjs-social-login";
import firebase from "firebase";

const Onboarding = () => {
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.home.regions);
  const isRegisterLoading = useSelector(
    (state) => state.home.isRegisterLoading
  );
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState(0);
  const googleRef = useRef(null);
  const [profile, setProfile] = useState();
  const [provider, setProvider] = useState("");

  useEffect(() => {
    dispatch(getRegions());
  }, []);

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const onLogoutFailure = useCallback(() => {
    // alert("logout fail");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const onSendOtp = (e) => {
    e.preventDefault();
    if (selectedRegion === 0) {
      toast("Select Region", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    let data = {
      phone: selectedRegion + phoneNumber,
    };

    localStorage.setItem("os-phone-number", phoneNumber);
    localStorage.setItem("os-region", selectedRegion);

    for (let i = 0; i < regions.length; i++) {
      if (selectedRegion === regions[i].phonecode) {
        localStorage.setItem("os-region-name", regions[i].name);
      }
    }

    dispatch(sendOtp(data));
  };

  const onLoginDetails = (provider, loginDetails) => {
    const data = {
      first_name: loginDetails.firstName,
      last_name: loginDetails.lastName,
      social_id: loginDetails.id,
      social_type: provider,
      firebase_token: loginDetails.id_token,
      email: loginDetails.email,
      mobile: loginDetails.phone,
      platform_type: "web",
      device_id: loginDetails.id,
    };

    // console.log(data);
    dispatch(onSocialLogin(data));
  };

  const handleAppleLogin = () => {
    const appleProvider = new firebase.auth.OAuthProvider("apple.com");
    appleProvider.addScope("email");
    appleProvider.addScope("name");
    appleProvider.setCustomParameters({
      locale: "us",
    });

    firebase
      .auth()
      .signInWithPopup(appleProvider)
      .then((result) => {
        const data = {
          email: result.user.email,
          mobile: result.user.phoneNumber,
          firebase_token: result.credential.idToken,
          platform_type: "web",
          social_type: "apple",
          social_id: result.user.uid,
          device_id: result.user.uid,
        };

        dispatch(onAppleLogin(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="onboarding-main pt-5 onboarding-height">
      <div className="left"></div>
      <div className="right">
        <div className="download-section">
          <h5>
            <b>Download</b>
          </h5>
          <h5>
            The OfficeShare app<span className="text-primary">...</span>
          </h5>
          <div className="image-div">
            <img src="./assets/images/playstore.png" alt="" />
            <img src="./assets/images/appstore.png" alt="" />
          </div>
        </div>
      </div>
      <div className="onboarding-background"></div>
      <div className="container onboarding--fields">
        <div className="onboarding-wrapper">
          <div className="left-section">
            <h2>
              Let's get Sharing<span className="text-primary">!</span>
            </h2>
            <h4 className="mt-3">
              <span>Sign up</span>
              &nbsp;&nbsp;&nbsp;
              <span className="text-primary">|</span>
              &nbsp;&nbsp;&nbsp;
              <Link to="/login" className="text-secondary">
                Log in <small> (Existing user?)</small>
              </Link>
            </h4>
            <div className="input-group-wrapper">
              <form onSubmit={onSendOtp}>
                <div className="input-wrapper select-wrapper">
                  <label htmlFor="">Region:</label>
                  <select
                    onChange={(e) => setSelectedRegion(e.target.value)}
                    required
                  >
                    <option>Select</option>
                    {regions &&
                      regions.map((region, i) => (
                        <option value={region.phonecode} key={i}>
                          {region.name}
                        </option>
                      ))}
                  </select>
                  <div className="select-icon">
                    <img src="./assets/images/down-arrow-select.png" alt="" />
                  </div>
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">Phone Number:</label>{" "}
                  <span className="divider"> |</span>
                  <input
                    type="number"
                    placeholder="e.g. 07624444555"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="register-page-input"
                    required
                  />
                </div>

                <div className="input-wrapper-submit float-right">
                  {isRegisterLoading ? (
                    <div className="button">
                      <span className="fa fa-spinner fa-spin px-4"></span>
                    </div>
                  ) : (
                    <button type="submit" className="button">
                      Continue
                    </button>
                  )}
                </div>
              </form>

              {/* <div className="or-section login">
                <span className="border-div"></span>
                <span className="or">or</span>
                <span className="border-div"></span>
              </div> */}

              {/* <div className="social-handles-wrapper"> */}
                {/* <div className="social-item">
                  <img src="./assets/images/email.png" alt="" />
                  <p>Continue with email</p>
                </div> */}
                {/* <div
                  className="social-item right-div"
                  onClick={handleAppleLogin}
                >
                  <img src="./assets/images/apple.png" alt="" />
                  <p>Continue with Apple</p>
                </div>
                <div className="social-item">
                  <LoginSocialGoogle
                    ref={googleRef}
                    client_id={
                      "252546605518-49apkc7emf40dlqjnfmd80cgacfe2ion.apps.googleusercontent.com"
                    }
                    onLogoutFailure={onLogoutFailure}
                    onLoginStart={onLoginStart}
                    onLogoutSuccess={onLogoutSuccess}
                    onResolve={({ provider, data }) => {
                      setProvider(provider);
                      setProfile(data);
                      onLoginDetails(provider, data);
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <div className="d-flex">
                      <img src="./assets/images/google.png" alt="" />
                      <p>Continue with Google</p>
                    </div>
                  </LoginSocialGoogle>
                </div> */}
                {/* <div className="social-item right-div">
                  <img src="./assets/images/facebook.png" alt="" />
                  <p>Continue with Facebook</p>
                </div> */}
              {/* </div> */}
            </div>
          </div>
          <div className="right-section">
            <div className="download-section">
              <h5>
                <b>Download</b>
              </h5>
              <h5>
                The OfficeShare app<span className="text-primary">...</span>
              </h5>
              <div className="image-div">
                <img src="./assets/images/playstore.png" alt="" />
                <img src="./assets/images/appstore.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
