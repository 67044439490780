import React from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ImageGallery from "react-image-gallery";

const ViewAllImages = ({ open, onClose, images }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "image-popup" }}
    >
      <ImageGallery items={images} />
    </Modal>
  );
};

export default ViewAllImages;
