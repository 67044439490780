import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyById,
  landlordUserConnection,
  onChatNotification,
} from "../../store/homeAction";
import Loading from "../../components/loading/Loading";
import { Link } from "react-router-dom";
import Rating from "../../components/rating/Rating";
import Favourite from "../../components/favourite/Favourite";
import LandlordChatPopup from "../../popups/LandlordChatPopup";
import ViewAllImages from "../../popups/ViewAllImages";
import Map from "../../components/map/Map";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import "./propertyDetails.scss";
import db from "../../config";
import ReactGA from '../../utils/gaInit';

const libraries = ["places"];

const PropertyDetails = ({ match }) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 51.5074,
      lng: -0.1278,
    },
    zoom: 12,
  });
  const [landlordPopup, setLandlordPopup] = useState(false);
  const [imagesPopup, setImagesPopup] = useState(false);
  const [gallaryImages, setGallaryImages] = useState([]);
  const [isLoadingTrue, setIsLoadingTrue] = useState(false);
  const [newDisplayImage, setNewDisplayImage] = useState(null);
  const [currentPropertyId, setCurrentPropertyId] = useState(false);
  const [users, setUsers] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    const data = {
      property_id: match.params.id,
      user_id: userDetails && userDetails.id,
    };
    dispatch(getPropertyById(data));
  }, []);

  useEffect(() => {
    if (propertyListById?.property_title) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: `Property | ${propertyListById?.property_title}`
      });
    }
  }, [propertyListById])

  useEffect(() => {
    if (propertyListById) {
      const data = {
        center: {
          lat: parseFloat(propertyListById && propertyListById.lang) || 0,
          lng: parseFloat(propertyListById && propertyListById.long) || 0,
        },
        zoom: 12,
      };
      setDefaultProps(data);

      let images = [];
      let gallery_image = propertyListById.gallery_image;
      for (let i = 0; i < gallery_image.length; i++) {
        images.push({
          original: gallery_image[i],
          thumbnail: gallery_image[i],
        });
      }
      setGallaryImages(images);
    }

    if (currentPropertyId == propertyListById?.id) {
      return false;
    }

    if (propertyListById && propertyListById.user && userDetails) {
      if (userDetails.id !== propertyListById.user.id) {
        setLandlordPopup(true);
        setCurrentPropertyId(propertyListById.id);
      }
    }
  }, [propertyListById]);

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const sendRequest = (landlordDetails) => {
    // console.log("landlordDetails", landlordDetails);

    // const sendChatEmail = {
    //   user_id: landlordDetails.id.toString(),
    //   msg: "Hello I want to enquiry about your space!.",
    //   subject: "Hello I want to enquiry about your space!.",
    // };

    // dispatch(sendEnquiryEmail(sendChatEmail));

    const userConnection = {
      landlord_id: landlordDetails.id.toString(),
      user_id: userDetails.id.toString(),
    };
    dispatch(landlordUserConnection(userConnection));

    setIsLoadingTrue(true);
    let collection;
    if (parseInt(landlordDetails.id) < parseInt(userDetails.id)) {
      collection = `${landlordDetails.id}-${userDetails.id}`;
    } else {
      collection = `${userDetails.id}-${landlordDetails.id}`;
    }

    const rootRef = db.ref();
    const urlRef = rootRef.child(`messages/${collection}`);

    // Check if request already sent or not
    let exist = false;

    urlRef
      .orderByChild("senderid")
      .equalTo(userDetails.id.toString())
      .on("value", function (snapshot) {
        snapshot.forEach(function (child) {
          const chatDetails = child.val();
          // console.log(
          //   "chatDetails",
          //   chatDetails,
          //   chatDetails.senderid,
          //   userDetails.id.toString(),
          //   chatDetails.receiverid,
          //   landlordDetails.id.toString()
          // );
          if (
            chatDetails.senderid == userDetails.id.toString() &&
            chatDetails.receiverid == landlordDetails.id.toString()
          ) {
            exist = true;
            window.location.href = `#/chat/${userDetails?.id}`;
            return false;
          }
        });
      });

    const data =
      users[landlordDetails.id.toString()]?.chatRequestAcceptedUserIds;
    let chatUsers = [];
    if (data) {
      var keys = Object.values(data);
      for (var i = 0; i < keys.length; i++) {
        chatUsers.push(keys[i]);
      }
    }

    if (chatUsers.some((user) => user == userDetails.id.toString())) {
      exist = true;
      window.location.href = `#/chat/${userDetails?.id}`;
      return false;
    }

    setTimeout(() => {
      if (exist == false) {
        urlRef.push({
          propertyName: propertyListById.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          text: "Hello \nI want to enquire about your space.",
          timestamp: Date.now(),
        });

        const lastMessageRef = rootRef.child(`lastMessages/${collection}`);
        lastMessageRef.set({
          propertyName: propertyListById.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          // text: "Hello \nI want to enquiry about your space.",
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          timestamp: Date.now(),
        });

        window.location.href = `#/chat/${userDetails?.id}`;
      }

      setIsLoadingTrue(false);
    }, 1000);

    if (exist === false) {
      const pushData = {
        title: userDetails.name,
        message: "Hello I want to enquiry about your space!.",
        sender_id: userDetails.id.toString(),
        type: "chat",
        receiver_id: landlordDetails.id.toString(),
      };

      dispatch(onChatNotification(pushData));
    }
  };

  return (
    <div>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        libraries={libraries}
        language="en"
        region="uk"
      >
        {isLoading ? (
          <div className="py-5 my-5">
            <Loading></Loading>
          </div>
        ) : (
          <div className="property-detail-with-background">
            <div className="container mt-5">
              <div className="property-details-wrapper fade-in-bottom">
                <div className="property-booking">
                  <div className="property-booking-main-wrapper">
                    <div className="property-booking-main">
                      <div className="left-section">
                        <Link to="/search" className="back-btn">
                          Back to search
                        </Link>
                        <h3 className="property-booking-heading">
                          {propertyListById && propertyListById.property_title}

                          {propertyListById && userDetails && (
                            <Favourite
                              office={propertyListById}
                              className="float-right"
                            />
                          )}
                        </h3>
                        <div className="property-booking-icons">
                          <span className="check">
                            <img src="assets/images/check-icon.png" />
                            OS Verified
                          </span>
                          <Rating rating={propertyListById?.rating} />
                          <br />

                          <a
                            href={`http://maps.google.com/maps?&q=${
                              propertyListById && propertyListById.lang
                            }+${propertyListById && propertyListById.long}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="details-address"
                          >
                            <span className="address">
                              {propertyListById &&
                                propertyListById.address_line_one}
                            </span>
                          </a>
                        </div>
                        <div className="office-type-wapper">
                          {propertyListById &&
                            propertyListById.property_type.map(
                              (propertytype, key) => (
                                <div className="type" key={key}>
                                  {propertytype}
                                </div>
                              )
                            )}
                        </div>
                        <div className="desk-availibility">
                          <div className="mr-2">
                            Desks:{" "}
                            <b className="ml-1">
                              {propertyListById &&
                                propertyListById.number_of_desk}
                            </b>
                          </div>
                          {propertyListById &&
                          propertyListById.availability_type === 1 ? (
                            <span>
                              <img src="assets/images/day.svg" alt="" /> Day
                            </span>
                          ) : propertyListById &&
                            propertyListById.availability_type === 2 ? (
                            <span>
                              <img src="assets/images/night.svg" alt="" /> Night
                            </span>
                          ) : propertyListById &&
                            propertyListById.availability_type === 3 ? (
                            <>
                              <span>
                                <img src="assets/images/day.svg" alt="" /> Day
                              </span>
                              <span className="light-text">and</span>
                              <span>
                                <img src="assets/images/night.svg" alt="" />{" "}
                                Night
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="desk-available">
                          {/* <p>
                            Desk available:{" "}
                            <span className="font-weight-bold">
                              {propertyListById &&
                                propertyListById.number_of_desk}
                            </span>
                          </p> */}
                          <div onClick={executeScroll} className="button btn">
                            Available Spaces
                          </div>
                        </div>

                        <p className="price-availibility">
                          <span>Price per desk: </span>

                          <span className="light-text mx-1">
                            <span className="text-primary font-weight-bold ml-1">
                              &#8356;{propertyListById?.price_from}
                            </span>
                          </span>
                          <span className="light-text">
                            -
                            <span className="text-primary font-weight-bold mx-1">
                              &#8356;{propertyListById?.price_to}
                            </span>
                          </span>
                        </p>
                        {(propertyListById &&
                          propertyListById?.availability_type === 2) ||
                        propertyListById?.availability_type === 3 ? (
                          <div className="light-text">
                            Discount available on off-peak times (nights and
                            weekends)
                          </div>
                        ) : null}

                        <hr />
                        <div className="description">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                propertyListById &&
                                propertyListById.describe_your_space,
                            }}
                          ></p>
                        </div>
                        <div className="amenities-main">
                          <div className="amenities-item">
                            {propertyListById &&
                              propertyListById.Amenities.map(
                                (amenitie, key) => (
                                  <div className="amenities-list" key={key}>
                                    <div className="">
                                      <img src={amenitie.icon} alt="" />
                                    </div>
                                    <span>{amenitie.value}</span>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                        <hr />
                        <div ref={myRef} className="about-sharer">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6>About the sharer</h6>

                            <Link
                              className="open-chat-btn"
                              to={`/chat/${userDetails?.id}`}
                              onClick={() =>
                                sendRequest(
                                  propertyListById && propertyListById.user
                                )
                              }
                            >
                              Open a Chat
                            </Link>
                          </div>

                          {propertyListById && propertyListById.rating != 0 && (
                            <span className="sharer-rating">
                              {propertyListById && propertyListById.rating}{" "}
                              Rating{" "}
                            </span>
                          )}
                          <div className="sharer-para">
                            <textarea
                              className="sharer-paraTextArea"
                              disabled="disabled"
                              placeholder={
                                propertyListById &&
                                propertyListById.about_sharer
                              }
                            ></textarea>
                          </div>
                        </div>
                        <hr />
                        <div className="spaces-box">
                          <h6>Available Spaces</h6>
                          <b>
                            (
                            {propertyListById &&
                              propertyListById.space &&
                              propertyListById.space.length}
                            )
                          </b>
                          <div className="spaces-main">
                            {propertyListById &&
                            propertyListById.space &&
                            propertyListById.space.length > 0 ? (
                              propertyListById.space.map((space, key) => (
                                <div className="spaces-item" key={key}>
                                  <Link
                                    to={`/space/${match.params.id}/${space.id}`}
                                  >
                                    <img src={space.thumb} alt="" />
                                  </Link>
                                  <div className="spaces-right">
                                    <Link
                                      to={`/space/${match.params.id}/${space.id}`}
                                    >
                                      {space.space_title}
                                    </Link>
                                    <span>{space.no_of_desk} Desks </span>
                                    {space.booking_approval === false ? (
                                      <span>
                                        Availability:{" "}
                                        <span className="text-primary">
                                          Book & Pay Now
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        Availability:{" "}
                                        <span className="text-primary">
                                          Enquire Only
                                        </span>
                                      </span>
                                    )}
                                    <div className="button">
                                      {space.space_type}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="d-flex py-3 align-items-center">
                                No space available
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="right-section">
                        <div className="right-images pb-5">
                          <img
                            src={
                              newDisplayImage ||
                              (propertyListById &&
                                propertyListById.featured_image)
                            }
                            alt=""
                            className="property-right-image"
                          />
                          <div className="property-booking-bottom mb-1">
                            {propertyListById &&
                              propertyListById.gallery_image.map(
                                (image, key) =>
                                  key < 3 && (
                                    <img
                                      className="cursor-pointer"
                                      key={key}
                                      src={image}
                                      alt=""
                                      // onClick={() => setNewDisplayImage(image)}
                                    />
                                  )
                              )}
                          </div>
                          {/* {propertyListById &&
                            propertyListById.gallery_image.length > 3 && ( */}
                          <div
                            className="button"
                            onClick={() => setImagesPopup(true)}
                          >
                            View all images
                          </div>
                          {/* )} */}
                        </div>
                        <div className="right-map">
                          {propertyListById && (
                            <Map
                              location={{
                                lat: parseFloat(propertyListById?.lang),
                                lng: parseFloat(propertyListById?.long)
                              }}
                              propertyListById={propertyListById}
                            ></Map>
                          )}

                          <span className="address">
                            {propertyListById &&
                              propertyListById.address_line_one}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {propertyListById && (
          <LandlordChatPopup
            open={landlordPopup}
            onClose={() => setLandlordPopup(false)}
            landlordDetails={propertyListById.user}
            propertyDetails={propertyListById}
          />
        )}

        <ViewAllImages
          open={imagesPopup}
          onClose={() => setImagesPopup(false)}
          images={gallaryImages}
        />
      </LoadScript>
    </div>
  );
};

export default PropertyDetails;
