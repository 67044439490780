import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTermsConditions } from "../../store/homeAction";
import Loading from "../../components/loading/Loading";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const termsConditions = useSelector((state) => state.home.termsConditions);

  useEffect(() => {
    dispatch(getTermsConditions({}));
  }, []);

  return termsConditions ? (
    <div className="container py-5 fade-in-bottom">
      <h3 className="text-primary text-center mb-4">Terms and Conditions</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: termsConditions && termsConditions.value,
        }}
      ></p>
    </div>
  ) : (
    <div className="py-5">
      <Loading />
    </div>
  );
};

export default TermsAndConditions;
