import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyUser } from "../../store/homeAction";
import {toast} from "react-toastify";

const Verify = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const formData = new FormData();
    formData.append("token", match.params.token);
    // check if the user has been logged in and email_verified_at
    const userDetails = JSON.parse(localStorage.getItem("os-user"));
    console.log(userDetails);
    if (userDetails && userDetails.email_verified_at === null) {
      dispatch(verifyUser(formData));
    } else {
      toast('Email verified, Please login.', {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
    }
  }, []);

  return (
    <div className="register-wrapper pt-4 register-page">
      <div className="container success-wrapper p-5">
        <h2 className="mb-3">
          Welcome to OfficeShare<span className="text-primary">!</span>{" "}
        </h2>
        <p className="mb-4">Thank you for joining! Explore now</p>

        <Link to="/Login" className="btn-submit">
          Start exploring OfficeShare
        </Link>
      </div>
    </div>
  );
};

export default Verify;
