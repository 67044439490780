import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  MarkerClusterer,
  InfoBox,
} from "@react-google-maps/api";
import { Link } from 'react-router-dom';
import mapStyle from "../../pages/spaceRegisterStep/MapStyle";
import mapStyleLanding from "../../pages/spaceRegisterStep/MapStyleLanding";
import "./searchPageMap.css";
const libraries = ["places"];

const styles = {
  input: "px-3 py-3 h-12 placeholder-indigo-500 w-full",
  h2: "py-4 text-3xl text-gray-600 font-normal text-left",
  map: "mt-6 rounded-md select-none overflow-hidden sm:w-1/2",
  mainDiv: "sm:flex justify-between -mt-6",
};

const containerStyle = {
  width: "100%",
  height: "88vh",
};

//COMPONENT
const Map = ({
  defaultProps,
  searchResult,
  selectedProperty,
  onSetCurrentSelectedProperty,
  isMarkerCircle = false,
  customStyle = undefined,
  zoom = 12,
}) => {
  const options = isMarkerCircle ? {
    styles: mapStyleLanding,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
  } : { styles: mapStyle }
  
  const [map, setMap] = useState(null);
  const [currentSelectedProperty, setCurrentSelectedProperty] = useState("");

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds); // Omit this line
    setMap(map);
  }, []);

  useEffect(() => {
    setCurrentSelectedProperty(selectedProperty.id);
  }, [selectedProperty]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      libraries={libraries}
      language="en"
      region="uk"
    >
      <div className={styles.mainDiv}>
        <div className={styles.map}>
          <GoogleMap
            mapContainerStyle={{ ...containerStyle, ...customStyle}}
            center={defaultProps.center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={options}
            zoom={zoom}
          >
            {searchResult &&
              searchResult.map((office, key) => (
                <React.Fragment key={key}>
                    {currentSelectedProperty === office.id ? (
                      <Marker
                        icon={isMarkerCircle ? "assets/images/marker-landing.png" : "assets/images/selected-marker.png"}
                        position={{
                          lat: office.location.latitude,
                          lng: office.location.longitude,
                        }}
                        title={office.property_title}
                        // animation={window.google.maps.Animation.BOUNCE}
                      >
                        <InfoBox
                          options={{
                            // pane: "overlayLayer",
                            maxWidth: 100,
                            pixelOffset: new window.google.maps.Size(-100, -70),
                            alignBottom: true,
                            boxStyle: {
                              width: "289px",
                            },
                            closeBoxURL: "",
                          }}
                          anchor={true}
                        >
                          <div
                            style={{
                              width: "289px",
                              height: "100px",
                              position: "relative",
                            }}
                            className="popup-bubble-anchor"
                          >
                            <div className="popup-bubble">
                              <div className="popup-bubble--wrapper">
                                <div className="popup-bubble--image">
                                  <img src={office?.featured_image} alt="property"/>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                  <div className="popup-bubble--title">
                                    {office?.property_title}{" "}
                                  </div>
                                  <div className="popup-bubble--description">
                                    {office?.location?.address}
                                  </div>
                                </div>
                              </div>
                              <div className="popup-bubble--nav">
                                <Link to={`/property/${office?.id}`}>View Property</Link>
                                <div className="separator" />
                                <Link to="/search">Explore Properties around this area</Link>
                              </div>
                            </div>
                          </div>
                        </InfoBox>
                      </Marker>
                    ) : (
                      <Marker
                        onClick={() => {
                          setCurrentSelectedProperty(office?.id);
                          onSetCurrentSelectedProperty(office);
                        }}
                        icon={isMarkerCircle ? "assets/images/marker-landing.png" : "assets/images/marker.png"}
                        position={{
                          lat: office.location.latitude,
                          lng: office.location.longitude,
                        }}
                        // label={office.property_title}
                        title={office.property_title}
                      />
                    )}
                </React.Fragment>
              ))}
          </GoogleMap>
        </div>
      </div>
    </LoadScript>
  );
};

export default Map;
