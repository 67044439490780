import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertySpaces, getPropertyById } from "../../store/homeAction";
import { SET_BOOK_SPACE_DETAILS } from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import SwitchMap from "./SwitchMap";
import "./userBooking.scss";
import PropertyWithSpaceDetails from "./PropertyWithSpaceDetails";
import moment from "moment";
import DatesModalPopup from "../../popups/DatesModalPopup";

const UserBookingSix = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const bookSpaceLoading = useSelector((state) => state.home.bookSpaceLoading);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (bookSpaceDetails) {
      const { daySelectedDates, nightSelectedDates } = bookSpaceDetails;
      const spaceList = propertySpaces;

      let price = 0;
      const spaceDetail = getSpaceDetail(spaceList);
      const { space_days_price_list } = spaceDetail;
      if (spaceDetail && space_days_price_list) {
        if (spaceDetail.availability_type === 1) {
          // for day
          for (let i = 0; i < daySelectedDates.length; i++) {
            const todayDayName = moment(daySelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].price);
              }
            }
          }
        } else if (spaceDetail.availability_type === 2) {
          // for night
          for (let i = 0; i < nightSelectedDates.length; i++) {
            const todayDayName = moment(nightSelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].night_price);
              }
            }
          }
        } else {
          // for day and night
          for (let i = 0; i < daySelectedDates.length; i++) {
            const todayDayName = moment(daySelectedDates[i])
              .format("dddd")
              .slice(0, 3);
            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].price);
              }
            }
          }

          for (let i = 0; i < nightSelectedDates.length; i++) {
            const todayDayName = moment(nightSelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].night_price);
              }
            }
          }
        }
      }

      setCalculatedPrice(price);
    }
  }, []);

  useEffect(() => {
    getPropertyList();
    getSpaceList();
  }, [match]);

  useEffect(() => {
    if (bookSpaceDetails === undefined) {
      history.push(`/space/${match.params.propertyId}/${match.params.spaceId}`);
    }
  }, []);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  const getSpaceDetail = () => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          return spaceList[i];
        }
      }
    }
  };

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onNext = () => {
    dispatch({
      type: SET_BOOK_SPACE_DETAILS,
      payload: {
        ...bookSpaceDetails,
        calculatedPrice,
      },
    });

    history.push(
      `/userbooking/7/${match.params.propertyId}/${match.params.spaceId}`
    );
  };

  return (
    <>
      <DatesModalPopup
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        bookingDetails={bookSpaceDetails}
      />
      <div className="user-detail-with-background">
        <div className="container mt-5">
          <div className="user-details-wrapper">
            <div className="user-booking">
              <div className="user-booking-main-wrapper">
                <div className="user-booking-main">
                  <div className="user-left">
                    <Link
                      to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                      className="back-btn"
                    >
                      Back to space
                    </Link>
                    {propertyListById && spaceDetails && (
                      <PropertyWithSpaceDetails
                        propertyListById={propertyListById}
                        spaceDetails={spaceDetails}
                      />
                    )}
                    <hr />
                    <div className="user-middle">
                      <h6>Confirm & Pay</h6>
                      <div className="user-edit">
                        <p>
                          {bookSpaceDetails?.numberOfDesks}{" "}
                          {bookSpaceDetails?.numberOfDesks === 1
                            ? "Desk"
                            : "Desks"}{" "}
                          <span>for</span>{" "}
                          {bookSpaceDetails?.daySelectedDates?.length > 0 &&
                            `${bookSpaceDetails?.daySelectedDates?.length} ${
                              bookSpaceDetails?.daySelectedDates?.length === 1
                                ? "Day"
                                : "Day(s)"
                            }`}
                          {bookSpaceDetails?.daySelectedDates?.length > 0 &&
                            bookSpaceDetails?.nightSelectedDates?.length > 0 &&
                            " and "}
                          {bookSpaceDetails?.nightSelectedDates?.length > 0 &&
                            `${bookSpaceDetails?.nightSelectedDates?.length} ${
                              bookSpaceDetails?.nightSelectedDates?.length === 1
                                ? "Night"
                                : "Night(s)"
                            } `}
                        </p>
                        <div>
                          <Link
                            to={`/userbooking/4/${match.params.propertyId}/${match.params.spaceId}`}
                          >
                            Edit
                          </Link>{" "}
                          <span className="text-primary">/</span>{" "}
                          <span
                            className="previewButton"
                            onClick={() => setModalOpen(true)}
                          >
                            Preview
                          </span>
                        </div>
                      </div>

                      <h6 className="desk-rate">
                        <span className="text-dark">Total Amount:</span> &#8356;
                        {(
                          calculatedPrice * bookSpaceDetails?.numberOfDesks
                        ).toLocaleString()}{" "}
                        <span style={{ fontSize: "12px", fontWeight: "800" }}>
                          +VAT
                        </span>
                      </h6>
                    </div>
                    <hr />
                    <div className="user-bottom">
                      <div className="buttons">
                        <div onClick={onNext} className="one-btn btn mt-4">
                          Continue
                        </div>
                      </div>
                    </div>
                  </div>
                  <SwitchMap
                    propertyListById={propertyListById}
                    spaceDetails={spaceDetails}
                  ></SwitchMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBookingSix;
