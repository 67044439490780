import React from "react";
import clsx from 'clsx';
import Axios from "../../store/axios";
import "./../popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import './landlordStyle.scss';
import { config } from "../../store/homeAction";

export const validateFileSizeLimit = (inputSize, limitMac = 10054736, limitWin = 11143552) => {
  const osLimit = window.navigator.userAgent.includes('Macintosh') ? limitMac : limitWin;
  let isExceeded = false;
  if (inputSize >= osLimit) {
    isExceeded = true;
  }

  return isExceeded;
};

const UpdateAgreement = ({
  onClose,
  open,
  data,
}) => {
  const inputFileRef = React.useRef(null);
  const [uploading, setUploading] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const [fileValue, setFileValue] = React.useState(null);
  const [fileExceed, setFileExceed] = React.useState(false);

  const submitFile = async () => {
    if (inputFileRef.current?.value && data?.id && fileValue) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', fileValue);
      formData.append('landlord_id', data.id);

      Axios.post("/agreements/upload-contract", formData, config).then((res) => {
        setUploading(false);
        closeModal('submit', data);
      });
    }
  };

  const closeModal = (type = undefined, data = undefined) => {
    setFileName('');
    setFileValue('');
    onClose(type, data);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{ modal: "amenities-popup-modal experience-modal" }}
    >
      <div className="la-modal__wrapper">
        <h5 className="text-primary">Update Agreement</h5>

        <div className={clsx('la-modal__form', { uploading })}>
          <p className="title--agreement">Select the document from your machine</p>
          <p className="secondary--agreement">Please upload a PDF file with a maximum file size of 2MB</p>
        
          <input
            ref={inputFileRef}
            id="file"
            type="file"
            accept="application/pdf, application/msword"
            className="d-none"
            name="file"
            onChange={(e) => {
              e.persist();
              if (e?.target?.files?.[0]?.name) {
                const isFileExceeded = validateFileSizeLimit(e?.target?.files?.[0]?.size);
                setFileExceed(isFileExceeded);
                if (!isFileExceeded) {
                  setFileValue(e?.target?.files?.[0]);
                  setFileName(e?.target?.files?.[0]?.name)
                }
              }
            }}
          />
          <div className={clsx('file--container', { fileExceed })}>
            <button type="button" className="select--file" onClick={() => inputFileRef.current.click()}>Select a file</button>
            <p className="file-label">{fileName || 'No file chosen'}</p>
          </div>
          <p className={clsx('field--error', { show: fileExceed })}>File exceeds 10mb limit</p>
        </div>
        
        <div className="la-modal__btn">
          {!uploading && (
            <button type="button" className="la-modal__btn__cancel" onClick={closeModal}>Cancel</button>
          )}
          <button
            type="button"
            disabled={uploading || !fileValue}
            className="la-modal__btn__submit"
            onClick={submitFile}
          >
            {uploading ? (
              <div className="d-flex align-items-center">
                <div className="btns d-flex justify-content-center">
                  <span className="fa fa-spinner fa-spin px-3"></span>
                </div>
                Submitting update...
              </div>
            ) : 'Submit'}
          </button>
        </div>
        {uploading && (
          <div className="important--notice">
            <h3>IMPORTANT</h3>
            <p>Please do not close or reload this page while the update process is ongoing...</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UpdateAgreement;

