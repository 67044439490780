import React from 'react';

const IconEdit = ({ color = '#BCBCBC', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M26.944 0l5.056 5.056-3.854 3.856-5.056-5.056 3.854-3.856zM8.426 23.574h5.056l12.281-12.281-5.056-5.056-12.281 12.281v5.056z"></path>
      <path fill={color} d="M26.964 28.629h-18.272c-0.044 0-0.089 0.017-0.133 0.017-0.056 0-0.111-0.015-0.169-0.017h-5.020v-23.594h11.539l3.371-3.371h-14.91c-1.859 0-3.371 1.51-3.371 3.371v23.594c0 1.861 1.512 3.371 3.371 3.371h23.594c0.894 0 1.751-0.355 2.383-0.987s0.987-1.489 0.987-2.383v-14.608l-3.371 3.371v11.237z"></path>
    </svg>
  );
};

export default IconEdit;