import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import IconArrow from '../../icons/icon_arrow';
import IconPoint from '../../icons/icon_point';
import smoothscroll from "smoothscroll-polyfill";
import { toast } from "react-toastify";
import './howitworks.scss';

const content = {
  section1: {
    header: 'How It Works',
    description: `Think of OfficeShare as a marketplace akin to Airbnb or Uber, but for office spaces. We connect businesses with spare office capacity ('Hosts') with those in need of such spaces ('Sharers'). `,
  },
  section2: {
    header: 'Security and Ease of Use',
    card: [
      {
        title: 'Two-Factor Authentication',
        description: 'Registration involves Two-Factor Authentication, work email, and password setup.',
        path: '/assets/images/pages/howitworks/svg/calendar-globe.svg',
      },
      {
        title: 'Intuitive Dashboard',
        description: 'A user dashboard is available for managing bookings and listings. ',
        path: '/assets/images/pages/howitworks/svg/pc-chart.svg',
      },
      {
        title: 'Secured Transactions',
        description: 'We partner with Stripe Payments for secure financial transactions. ',
        path: '/assets/images/pages/howitworks/svg/cc-card.svg',
      },
      {
        title: 'Simplified Booking',
        description: 'Features include easy booking, reviewing past bookings, repeat bookings, and invoice downloads.',
        path: '/assets/images/pages/howitworks/svg/calendar-note.svg',
      },
    ]
  },
  section3: {
    header: 'How To Become A Host', 
  },
  section4: {
    header: 'Make the most out of your space',
    pricing: [
      {
        title: 'Trial',
        price: 1,
        label: 'for 3 months',
        inclusions: [
          '24 hours Zendesk support',
          'List up to 5 properties',
          'Unlimited spaces per property',
          'Unlimited messaging',
          'Flexible cancellation with 30 days notice',
        ],
        note: '* This nominal fee is to set up your Stripe account for receiving OfficeShare earnings.',
      },
      {
        title: 'OS Premium',
        price: 20,
        label: null,
        inclusions: [
          '24 hours Zendesk support',
          'List up to 5 properties',
          'Unlimited spaces per property',
          'Unlimited messaging',
          'Flexible cancellation with 30 days notice',
        ],
        note: null,
      },
      {
        title: 'OS Institutional',
        price: 50,
        label: 'Ideal for larger properties',
        inclusions: [
          '24 hours Zendesk support',
          'Unlimited property listing',
          'Unlimited spaces per property',
          'Unlimited messaging',
          'Flexible cancellation with 30 days notice',
        ],
        note: null,
      },
    ],
  },
  section5: {
    subtitle: 'Vouchers',
    title: 'Create your own discount vouchers for your sharers',
    description: 'As a Host, you can create discount vouchers for Sharers. These can be used for offering bulk discounts on long-term bookings and attracting Sharers to last-minute availabilities. ',
  }
};

const SecurityCard = ({ title, description, path }) => (
  <div className="security-ease--card">
    <div className="security-ease--card-icon">
      <img src={path || ''} alt="icon card" />
    </div>
    <div className="security-ease--card-text">
      <p className="security-ease--card-title">{title}</p>
      <p className="security-ease--card-desc">{description}</p>
    </div>
  </div>
);

const PricingCard = ({ value }) => {
  const { title, price, label, inclusions, note } = value;
  return (
    <ul className="pricing--card">
      <li className="pricing--card-rate">
        <p className="trial">{title}</p>
        <strong className="price">{`₤${price} `}<span>/ mo</span></strong>
        {label && <p className="date">{label}</p>}
      </li>
      {inclusions.map((v, key) => (
        <li key={key} className="pricing--card-inclusions">
          <div className="pricing--card-inclusions--icon">
            <img src="/assets/images/pages/howitworks/svg/success-icon.svg" alt="success" />
          </div>
          <p className="pricing--card-inclusions--text">{v}</p>
        </li>
      ))}
      {note && <li className="how--note">{note}</li>}
    </ul>
  )
};

const redirect = (id) => {
  const layoutElement = document.getElementById(id);
  layoutElement.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "start",
  });
};

const HowItWorks = () => {
  const [show, handleShow] = React.useState(false);
  const history = useHistory();
  const userInfo = useSelector((state) => state.home.userDetails);

  const transitionNavBar = () => {
    handleShow(window.scrollY > 70)
  };

  const goToListASpace = () => {
    if (userInfo?.id) {
      // const user = JSON.parse(localStorage.getItem("os-user"));
      if (userInfo?.user_type === "User") {
        if (!userInfo?.is_verified) {
          toast("Please verify your email ", {
            type: toast.TYPE.ERROR,
            autoClose: 10000,
          });
        } else {
          history.push("/onboarding/2");
        }
      } else {
        history.push("/add-property/3");
      }
    } else {
      history.push("/landlord-login");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);

  React.useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div id="how--wrapper" className="how--wrapper">
      <section className="container">
        <div className="how--text_wrapper">
          <h1 className="text-primary how--header">{content.section1.header}</h1>
          <p className="how--description">{content.section1.description}</p>
        </div>
        <div className="for-hosts">
          <div className="for-hosts--image">
            <img src="/assets/images/pages/howitworks/how-it-works-img-1.png" alt="persons in meeting" />
          </div>
          <div className="for-hosts--description">
            <h2 className="how--header-secondary">For <span className="text-primary ">Hosts</span></h2>
            <p className="how--description">
              Hosts are businesses with leased or owned office spaces and spare desks who may have permanent or temporary excess capacity, adaptable for sharing. They are prepared to share their space, ensuring accessibility and providing essentials like WiFi.
            </p>
            <button
              type="button"
              className="button primary-button cta--btn"
              onClick={() => redirect('become-host')}
            >
              How to become a Host
            </button>
          </div>
        </div>
        <div className="for-sharers">
          <div className="for-sharers--description">
            <h2 className="how--header-secondary">For <span className="text-primary ">Sharers</span></h2>
            <p className="how--description">
              {`Sharers can browse properties, communicate with Hosts, and leave feedback on their experiences. 

Our community thrives on trust and collaboration, ensuring quality experiences for all. `}
            </p>
            <Link
              to="/mydashboard/0"
              className="button primary-button cta--btn"
            >
              Create a Sharer account
            </Link>
          </div>
          <div className="for-sharers--image">
            <img src="/assets/images/pages/howitworks/how-it-works-img-2.jpeg" alt="persons in meeting" />
          </div>
        </div>
      </section>
      <section className="security-ease">
        <div className="container">
          <h2 className="secondary--title">{content.section2.header}</h2>
          <div className="security--guard">
            <div className="security-ease--wrapper">
              <SecurityCard {...content.section2.card[0]} />
              <SecurityCard {...content.section2.card[1]} />
            </div>
            <div className="security-ease--wrapper">
              <SecurityCard {...content.section2.card[2]} />
              <SecurityCard {...content.section2.card[3]}/>
            </div>
          </div>
        </div>
      </section>
      <section id="become-host" className="become-host">
        <h2 className="secondary--title">{content.section3.header}</h2>
        <div className="become-host--content">
          <div className="kyc-verification">
            <div className="kyc-verification--image">
              <img src="/assets/images/pages/howitworks/how-it-works-img-3.png" alt="kyc verification" />
            </div>
            <div className="kyc-verification--desc">
              <h2 className="subtitle--sec">KYC verification</h2>
              <p className="description--sec">Hosts undergo a verification process including KYC (Know Your Customer) checks via Stripe, ensuring a secure and trusted environment. </p>
            </div>
          </div>
          <div className="proof-business">
            <div className="proof-business--desc">
              <h2 className="subtitle--sec">Proof of business</h2>
              <p className="description--sec">We require proof of identification for your business. </p>
            </div>
            <div className="proof-business--image">
              <img src="/assets/images/pages/howitworks/proof-business.png" alt="form fields example"  />
            </div>
          </div>
          <div className="listings">
            <div className="listings--image">
              <img src="/assets/images/pages/howitworks/listings.png" alt="listings example"  />
            </div>
            <div className="listings--desc">
              <h2 className="subtitle--sec">Listings</h2>
              <p className="description--sec">We recommend quality listings and good reaction to any enquiries from customers. Our system will send you an email when an enquiry or booking is received.  </p>
            </div>
          </div>
        </div>
        <div className="sbs-progress">
          <IconPoint size="21" />
          <IconPoint size="21" />
          <IconPoint size="21" />
          <hr className="sbs--line" />
        </div>
      </section>
      <section className="pricing">
        <div className="container">
          <div className="pricing--desc">
            <h2 className="secondary--title">{content.section4.header}</h2>
            <p className="description--sec text-center mt-3">You can try OfficeShare for only <span className="text-primary">₤1</span> per month for <span className="text-primary">3 months</span></p>
          </div>
          <div className="pricing--rates">
            {content.section4.pricing.map((v, key) => <PricingCard key={key} value={v} />)}
          </div>
        </div>
      </section>
      <section className="container">
        <div className="vouchers">
          <div className="vouchers--image">
            <img src="/assets/images/pages/howitworks/person-on-meeting.png" alt="person on meeting" />
          </div>
          <div className="vouchers--desc">
            <p className="subtitle">{content.section5.subtitle}</p>
            <h2 className="secondary--title text-left">{content.section5.title}</h2>
            <p className="description--sec desc">{content.section5.description}</p>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="cta">
          <button
            type="button"
            className="button primary-button cta--btn cta--btn__bottom"
            onClick={goToListASpace}
          >
            Try OfficeShare now
          </button>
          <p className="how--note">* OfficeShare applies a 12% fee on each booking, deducted <br />from the day rate paid by the Sharer at the time of transaction.</p>
        </div>
      </section>
      {show && (
        <button type="button" className="scroll-to-top" onClick={() => redirect('how--wrapper')}>
          <IconArrow />
          <p>Scroll to top</p>
        </button>
      )}
    </div>
  );
};

export default HowItWorks;
