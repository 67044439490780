
import React from 'react';

const IconMenu = ({ color = '#000000', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M20 4c0-2.212-1.789-4-4-4s-4 1.788-4 4c0 2.212 1.789 4 4 4s4-1.788 4-4zM20 16c0-2.212-1.789-4-4-4s-4 1.787-4 4c0 2.213 1.789 4 4 4s4-1.787 4-4zM20 28c0-2.212-1.789-4-4-4s-4 1.788-4 4c0 2.212 1.789 4 4 4s4-1.787 4-4z"></path>
    </svg>
  );
};

export default IconMenu;