import React from 'react';

const IconMap = ({ color = '#000000', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M16 32c0 0 12-11.164 12-20s-5.372-12-12-12-12 3.164-12 12c0 8.836 12 20 12 20zM10 10c0-3.314 2.686-6 6-6s6 2.686 6 6c0 3.314-2.686 6-6 6s-6-2.686-6-6z"></path>
    </svg>
  )
};

export default IconMap;