import React, { useEffect, useState } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ExperiencePopup = ({
                           open,
                           onClose,
                           onSelectExperienceRating,
                           selectedRating,
                           filters,
                           setRating,
                           rating,
                         }) => {
  useEffect(() => {
    setRating(selectedRating);
  }, [selectedRating]);

  const selectRating = (key) => {
    let count = 0;
    for (let i = 0; i < rating.length; i++) {
      rating[i] = i <= key;

      if (rating[i]) {
        count = count + 1;
      }
    }
    onSelectExperienceRating([...rating]);
    setRating([...rating]);
  };

  return (
      <Modal
          open={open}
          onClose={onClose}
          classNames={{ modal: "amenities-popup-modal experience-modal" }}
      >
        <i
            className="fa fa-times removeIcon"
            onClick={() => {
              if (filters) {
                for (let i = 0; i < filters.length; i++) {
                  if (filters[i].filer_title === "Experience rating") {
                    setRating([false, false, false, false, false]);
                  }
                }
              }
              onClose();
            }}
            aria-hidden="true"
        ></i>
        <h5 className="title text-primary">Filter by experience rating</h5>
        <div className="rating-wrapper">
          {rating.map((star, key) =>
              star ? (
                  <img
                      key={key} // Add the unique key prop here
                      alt=""
                      src="assets/icons/star.png"
                      onClick={selectRating.bind(this, key)}
                  />
              ) : (
                  <img
                      key={key} // Add the unique key prop here
                      alt=""
                      src="assets/icons/inactive-star.png"
                      onClick={selectRating.bind(this, key)}
                  />
              )
          )}
        </div>
        <div className="done-btn" onClick={onClose}>
          <img src="assets/images/done.png" alt=""></img> Done
        </div>
      </Modal>
  );
};

export default ExperiencePopup;

