import React, {useState, memo} from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import mapStyle from "./MapStyle";
import { is } from "date-fns/locale";

const styles = {
  input: "px-3 py-3 h-12 placeholder-indigo-500 w-full",
  h2: "py-4 text-3xl text-gray-600 font-normal text-left",
  map: "mt-6 rounded-md select-none overflow-hidden sm:w-1/2",
  mainDiv: "sm:flex justify-between -mt-6",
};

const containerStyle = {
  width: "100%",
  height: "350px",
};

const options = {
  disableDefaultUI: true,
  styles: mapStyle,
  draggableCursor: "pointer",
  draggingCursor: 'grabbing'
};

const DEFAULT_CENTER = {
  lat: 51.50735091,
  lng: -0.1277583,
}

//COMPONENT
const Map = ({ location, isMarkerDraggable, onChange }) => {  
  const [center, setCenter] = useState(DEFAULT_CENTER || location);

  const [map, setMap] = React.useState(null);

  React.useEffect(() => {
    if (!location.lat && !location.lng) return;

    setCenter(location)
  }, [location]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerUpdate = (event) => {
    if (!isMarkerDraggable) return;

    const coordinates = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }

    setCenter(coordinates);
    onChange(coordinates);
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.map}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={8}
          onUnmount={onUnmount}
          options={options}
          onClick={handleMarkerUpdate}
        >
          <Marker
            position={center}
            draggable={isMarkerDraggable}
            onDragEnd={handleMarkerUpdate} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default memo(Map);
