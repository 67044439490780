import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendOtp, verifyOtp } from "../../../store/homeAction";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandlordVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const otpLoading = useSelector((state) => state.home.otpLoading);
  const isRegisterLoading = useSelector(
    (state) => state.home.isRegisterLoading
  );
  const registerUserDetail = useSelector(
    (state) => state.home.registerUserDetail
  );

  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);
  const [isVerifying, setIsVerifying] = useState("");
  const [countdown, setCountDown] = useState("1:00");

  let time = "1:00";

  useEffect(() => {
    startCountdown();
  }, []);

  const handleOtp = (value) => {
    setOtp(value);
  };
  const onVerify = () => {
    if (otp === "") {
      toast("Select enter otp", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    setIsVerifying(true);

    const phoneNumber = localStorage.getItem("os-phone-number");
    const selectedRegion = localStorage.getItem("os-region");

    const data = {
      otp: otp,
      phone: selectedRegion + phoneNumber,
    };
    registerUserDetail.phone = data.phone;
    dispatch(verifyOtp(data, "landlord", registerUserDetail));
  };

  const onSendOtp = () => {
    const phoneNumber = localStorage.getItem("os-phone-number");
    const selectedRegion = localStorage.getItem("os-region");

    let data = {
      phone: selectedRegion + phoneNumber,
    };

    dispatch(sendOtp(data, "landlord"));
    setResend(false);
    time = "1:00";
    startCountdown();
  };

  let interval;
  const startCountdown = () => {
    clearInterval(interval);
    interval = setInterval(function () {
      var timer = time;
      timer = timer.split(":");
      var minutes = timer[0];
      var seconds = timer[1];
      seconds -= 1;
      if (minutes < 0) {
        return false;
      } else if (seconds < 0 && minutes != 0) {
        minutes -= 1;
        seconds = 59;
      } else if (seconds < 10 && seconds.length != 2) seconds = "0" + seconds;

      time = minutes + ":" + seconds;
      setCountDown(time);

      if (minutes == 0 && seconds == 0) {
        clearInterval(interval);
        setResend(true);
      }
    }, 1000);
  };

  return (
    <div className="register-main-wrapper">
      <div className="container">
        <div className="register-section">
          <div className="register-header">
            <h2>
              Sign up as a Host<span className="text-primary">:</span>
            </h2>
          </div>
          <h4 className="register-title">Verify Account</h4>
          <h4 className="mt-1">
            Before you continue, please verify your account by inputting the 6
            digit code sent to your mobile.
          </h4>
          <div className="input-group-wrapper">
            <p className="title text-primary">
              <b>Enter PIN:</b>
            </p>
            <div className="link" onClick={onSendOtp}>
              I haven't received a PIN
            </div>
            <div className="pin-wrapper">
              <OtpInput
                value={otp}
                onChange={handleOtp}
                numInputs={6}
                disabledStyle
              />
            </div>
            <br />
            <br />

            {(otp.length === 6 && otpLoading) ||
            (otp.length === 6 && isRegisterLoading) ? (
              <div className="button float-left">
                <span className="fa fa-spinner fa-spin px-3"></span>
              </div>
            ) : (
              <div className="button float-left" onClick={onVerify}>
                Continue to start listing your space!
              </div>
            )}
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandlordVerification;
