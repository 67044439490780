

import React from 'react';

const IconPoint = ({ size = 21, dimentions = undefined, className = "" }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 32 32">
      <path fill="#ec9d99" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z"></path>
      <path fill="#fff" d="M24.381 16c0 4.629-3.752 8.381-8.381 8.381s-8.381-3.752-8.381-8.381c0-4.629 3.752-8.381 8.381-8.381s8.381 3.752 8.381 8.381z"></path>
    </svg>
  );
};

export default IconPoint;