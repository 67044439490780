import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRegions, onLogin } from "../../../store/homeAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import "./landlordAll.scss";

const LandlordLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regions = useSelector((state) => state.home.regions);
  const isLoginLoading = useSelector((state) => state.home.isLoginLoading);
  const [values, setValues] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    const phone = localStorage.getItem("os-phone-number");
    const region = localStorage.getItem("os-region");
    setValues({ ...values, phone: phone });
    setSelectedRegion(region);
    dispatch(getRegions());
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();

    let data = {
      email: values.email,
      password: values.password,
      firebase_token: "123456",
    };

    dispatch(onLogin(data));
  };

  if (userDetail?.id) {
    history.replace("/");
  }

  return (
    <div className="register-main-wrapper landlord-login">
      <div className="container">
        <div className="register-section">
          <div className="register-header">
            <h2>
              Host Sign in<span className="text-primary">:</span>
            </h2>
            <h3>
              Not Already registered?{" "}
              <Link to="/landlord-register">
                <span className="text-primary">Sign Up as a Host</span>
              </Link>
            </h3>
          </div>
          <form className="login-input-form" onSubmit={onSubmitForm}>
            <div className="input-group">
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={onInputChange}
                placeholder="Email"
                required
              />
            </div>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={onInputChange}
                placeholder="Password"
                required
              />
              {showPassword ? (
                <i
                  className="fa fa-eye text-secondary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              ) : (
                <i
                  className="fa fa-eye-slash text-secondary cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              )}
            </div>
            <div className="input-group">
              {isLoginLoading ? (
                <button type="submit" className="btn-submit">
                  <span className="fa fa-spinner fa-spin px-3"></span>
                </button>
              ) : (
                <button type="submit" className="btn-submit">
                  Login
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandlordLogin;
