import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getLandlordBookingList,
  landlordBookingAction,
} from "../../store/homeAction";
import "./landlordDashboard.scss";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import moment from "moment";
import CalendarPopup from "../../popups/CalendarPopup";
import Loading from "../../components/loading/Loading";

const LandlordDashboard = () => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const landlordBookingList = useSelector(
    (state) => state.home.landlordBookingList
  );
  const [selectedBookingList, setSelectedBookingList] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [activeTab, setActiveTab] = useState("today");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [filteredDate, setFilteredDate] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onGetLandlordBookingList();
    setFilteredDate([moment(new Date()).format("YYYY-MM-DD")]);
  }, []);
  
  const onGetLandlordBookingList = (type) => {
    setSelectedBookingList([]);
    if (type === 'calendar') return;
  
    const formData = new FormData();
    formData.append("landlord_id", userDetails.id);
  
    if (type !== 'history') {
      formData.append("from_date", moment(new Date()).format("YYYY-MM-DD"));
      formData.append("to_date", moment(new Date()).format("YYYY-MM-DD"));
    }

    dispatch(getLandlordBookingList(formData));
  };

  useEffect(() => {
    if (landlordBookingList) {
      switch (activeTab) {
        case 'today':
          let newBookingList = [];
          let exist;
          for (let i = 0; i < landlordBookingList.length; i++) {
            exist = false;
            const booked_dates =
              landlordBookingList[i].booking_details.booked_dates;
            for (let j = 0; j < booked_dates.length; j++) {
              if (booked_dates[j] === moment(new Date()).format("YYYY-MM-DD")) {
                exist = true;
              }
            }

            if (exist) {
              newBookingList.push(landlordBookingList[i]);
            }
          }
          setSelectedBookingList([...newBookingList]);
          if (newBookingList && newBookingList.length > 0) {
            onSelectBooking(newBookingList[0], 0);
          } else {
            setLoading(false);
            setSelectedBooking(null, 0);
          }
          break;
        case 'calendar':
          if (filteredDate.length > 1) {
            setSelectedBookingList([...landlordBookingList]);
  
            if (landlordBookingList && landlordBookingList.length > 0) {
              onSelectBooking(landlordBookingList[0], 0);
            } else {
              setLoading(false);
            }
          } else {
            setSelectedBookingList([]);
          }
          break;
        case 'history':
          setSelectedBookingList([...landlordBookingList]);
          if (landlordBookingList && landlordBookingList.length > 0) {
            onSelectBooking(landlordBookingList[0], 0);
          } else {
            setLoading(false);
          }
          break;
        default:
          return;
      }
    }
  }, [landlordBookingList]);

  const onSelectBooking = (item, key) => {
    item.key = key;
    setSelectedBooking(item);
    // setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const onLandlordBookingAction = (type, id) => {
    const data = {
      status_update: type,
      booking_id: id,
      user_id: userDetails.id,
    };

    const formData = new FormData();
    formData.append("landlord_id", userDetails.id);
    formData.append("from_date", filteredDate[0]);
    formData.append("to_date", filteredDate[1]);

    console.log({ data, formData})
    dispatch(landlordBookingAction(data, formData));
  };

  const onCloseCalendar = () => {
    setIsCalendarOpen(false);
  };

  const onApplyCalendarDates = (dates) => {
    const formData = new FormData();
    formData.append("landlord_id", userDetails.id);
    formData.append(
      "from_date",
      moment(dates && dates[0]).format("YYYY-MM-DD")
    );
    formData.append("to_date", moment(dates && dates[1]).format("YYYY-MM-DD"));
    dispatch(getLandlordBookingList(formData));

    setFilteredDate([
      moment(dates && dates[0]).format("YYYY-MM-DD"),
      moment(dates && dates[1]).format("YYYY-MM-DD"),
    ]);
  };

  return (
    <div className="reception-board">
      <LandlordBar />
      <div className="container">
        <div className="reception-dashboard-wrapper">
          <div className="tab-section">
            <div className="tab-list">
              {activeTab === "today" ? (
                <div className="tab-item active">Today</div>
              ) : (
                <div
                  className="tab-item"
                  onClick={() => {
                    setActiveTab("today");
                    onGetLandlordBookingList('today');
                    setFilteredDate([moment(new Date()).format("YYYY-MM-DD")]);
                  }}
                >
                  Today
                </div>
              )}

              {activeTab === "calendar" ? (
                <div
                  className="tab-item active"
                  onClick={() => {
                    setActiveTab("calendar");
                    onGetLandlordBookingList('calendar');
                    setIsCalendarOpen(true);
                  }}
                >
                  Calendar
                </div>
              ) : (
                <div
                  className="tab-item"
                  onClick={() => {
                    setActiveTab("calendar");
                    onGetLandlordBookingList('calendar');
                    setIsCalendarOpen(true);
                  }}
                >
                  Calendar
                </div>
              )}

              {activeTab === "history" ? (
                <div className="tab-item active">All Booking</div>
              ) : (
                <div
                  className="tab-item"
                  onClick={() => {
                    setActiveTab("history");
                    onGetLandlordBookingList('history');
                    setFilteredDate([]);
                  }}
                >
                  All Booking
                </div>
              )}

              <div className="dates px-5 mt-3">
                Dates :{" "}
                <span>
                  {filteredDate.length > 0 ? (
                    <>
                      <b>{filteredDate[0]} </b>{" "}
                      {filteredDate.length > 1 ? (
                        <>
                          {" "}
                          - <b>{filteredDate[1]} </b>{" "}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </>
                  ) : (
                    <b>All Dates</b>
                  )}
                </span>
              </div>
            </div>

            <div className="date">
              {selectedBooking && selectedBooking.booking_details.start_date}
            </div>
          </div>

          <div className="record-wrapper">
            <div className="record-list">
              {selectedBookingList && selectedBookingList.length > 0 ? (
                selectedBookingList.map((item, key) => (
                  <div
                    key={key}
                    className={`record-item-wrapper ${
                      selectedBooking &&
                      selectedBooking.key === key &&
                      "selected"
                    }`}
                  >
                    <div className="record-title">
                      <div
                        className="record-item"
                        onClick={() => onSelectBooking(item, key)}
                      >
                        <div className="date">
                          {item?.booking_details?.start_date}
                        </div>
                        <div className="devider">|</div>
                        <div className="time">
                          {item?.booking_details?.booking_time}
                        </div>
                        <div className="devider">|</div>
                        <div className="name">{item?.user?.name}</div>
                        <div className="devider">|</div>
                        {item?.booking_details?.booking_status === "hold" ? (
                          <b className="status text-warning">
                            {item?.booking_details?.booking_status}{" "}
                          </b>
                        ) : item?.booking_details?.booking_status ===
                          "enquiry_pending" ? (
                          <b className="status text-danger">Pending</b>
                        ) : item?.booking_details?.booking_status ===
                          "enquiry_cancelled" ? (
                          <b className="status text-danger">Cancelled</b>
                        ) : item?.booking_details?.booking_status ===
                          "booked" ? (
                          <b className="status text-success">Booked</b>
                        ) : item?.booking_details?.booking_status ===
                          "enquiry_approved" ? (
                          <b className="status text-success">Approved</b>
                        ) : null}
                        <div className="devider">|</div>
                      </div>
                    </div>
                    {item?.booking_details?.booking_status === "hold" ? (
                      <b className="text-warning">
                        {item?.booking_details?.booking_status}{" "}
                      </b>
                    ) : null}
                    <div className="btn-group">
                      {item?.booking_details?.booking_status ===
                      "enquiry_pending" ? (
                        <>
                          <div
                            className="btn btn-approve"
                            onClick={() =>
                              onLandlordBookingAction(
                                "enquiry_approved",
                                item?.booking_details?.booking_id
                              )
                            }
                          >
                            <i className="fa fa-check"></i>
                          </div>
                          <div
                            className="btn btn-reject"
                            onClick={() =>
                              onLandlordBookingAction(
                                "enquiry_cancelled",
                                item?.booking_details?.booking_id
                              )
                            }
                          >
                            <i className="fa fa-times"></i>
                          </div>{" "}
                        </>
                      ) : null}
                      <div className="right-icon">
                        <div
                          className={`btn button ${
                            selectedBooking &&
                            selectedBooking.key === key &&
                            "bg-dark text-white"
                          }`}
                          onClick={() => onSelectBooking(item, key)}
                        >
                          <i className="fa fa-eye"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : activeTab === "today" ? (
                <div className="d-flex justify-content-center mt-5">
                  No booking for today!
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-5">
                  No Data Found
                </div>
              )}
            </div>

            <div className="record-detail">
              {loading ? (
                <div className="p-5">
                  <Loading />
                </div>
              ) : (
                selectedBooking && (
                  <div className="fade-in-bottom">
                    <div className="record-block bottom-border">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="title">Booking Details</div>
                          <p>
                            Property:{" "}
                            <b> {selectedBooking?.property?.property_title}</b>
                          </p>
                          <p>
                            Space: <b> {selectedBooking?.space?.space_title}</b>
                          </p>
                          <p>Type: {selectedBooking?.space?.space_type}</p>
                          <p>
                            Desk:{" "}
                            {selectedBooking?.booking_details?.booked_desk}
                          </p>
                        </div>
                        <div>
                          <div className="booking-id">
                            Booking ID:{" "}
                            {selectedBooking?.booking_details?.booking_id}
                          </div>

                          <p className="d-flex justify-content-end align-items-center">
                            Verified{" "}
                            <img
                              className="check-icon"
                              src="assets/images/check.png"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="record-block bottom-border">
                      <div className="title">Contact</div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p>{selectedBooking?.user?.email}</p>
                          <p>{selectedBooking?.user?.phone}</p>
                        </div>
                        <Link to="/chat/1" className="btn button">
                          Chat
                        </Link>
                      </div>
                    </div>

                    <div className="record-block bottom-border">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="title">Payment</div>
                          <p>
                            Total Price:{" "}
                            <b>
                              £
                              {selectedBooking?.booking_details?.desk_price *
                                selectedBooking?.booking_details?.booked_desk}
                            </b>{" "}
                            incl. Tax
                          </p>
                          <p>
                            Status:{" "}
                            {selectedBooking?.booking_details
                              ?.booking_status === "hold" ? (
                              <b className="status text-warning">
                                {
                                  selectedBooking?.booking_details
                                    ?.booking_status
                                }{" "}
                              </b>
                            ) : selectedBooking?.booking_details
                                ?.booking_status === "enquiry_pending" ? (
                              <b className="status text-danger">Pending</b>
                            ) : selectedBooking?.booking_details
                                ?.booking_status === "enquiry_cancelled" ? (
                              <b className="status text-danger">Cancelled</b>
                            ) : selectedBooking?.booking_details
                                ?.booking_status === "booked" ? (
                              <b className="status text-success">Booked</b>
                            ) : selectedBooking?.booking_details
                                ?.booking_status === "enquiry_approved" ? (
                              <b className="status text-success">Approved</b>
                            ) : null}
                          </p>
                          <a
                            href={
                              selectedBooking?.booking_details?.download_pdf
                            }
                            className="print-box d-flex"
                            download
                            rel="noreferrer"
                          >
                            <img
                              className="print-icon"
                              src="assets/images/print.png"
                              alt=""
                            />
                            <div className="print-text">Print Invoice</div>
                          </a>
                        </div>

                        <div>
                          <div className="payment-tabs">
                            <div
                              className={
                                currentTab === 0
                                  ? "tab-item active"
                                  : "tab-item"
                              }
                              onClick={() => setCurrentTab(0)}
                            >
                              <p>Invoice</p>
                            </div>
                            <div
                              className={
                                currentTab === 1
                                  ? "tab-item active"
                                  : "tab-item"
                              }
                              onClick={() => setCurrentTab(1)}
                            >
                              <p>Card</p>
                            </div>
                            {/* <div className="tab-item">
                        <p> E-pay</p>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="record-block pb-5">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="title">Special Requests</div>
                          <p>Booking Note</p>
                        </div>

                        {/* <div className="btn button">Edit Booking</div> */}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <CalendarPopup
        open={isCalendarOpen}
        onClose={onCloseCalendar}
        onApply={onApplyCalendarDates}
      ></CalendarPopup>
    </div>
  );
};

export default LandlordDashboard;
