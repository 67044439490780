import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRegions, onRegister } from "../../store/homeAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./register.scss";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";

let inputProps = {
  placeholder: "DOB",
};

const Register = () => {
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.home.regions);
  const isRegisterLoading = useSelector(
    (state) => state.home.isRegisterLoading
  );
  const [values, setValues] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [terms, setTerms] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const phone = localStorage.getItem("os-phone-number");
  const regionName = localStorage.getItem("os-region-name");
  const regionPhoneCode = localStorage.getItem("os-region");
  // const osUser = localStorage.getItem('os-user')

  // useEffect(() => {
  //   if (osUser) {
  //     history.push("/")
  //   }
  // }, [])

  useEffect(() => {
    setValues({ ...values, phone: phone });
    setSelectedRegion(regionName);
    dispatch(getRegions());
  }, []);

  const onInputChange = (e) => {
    if (
      e.target.value.length > 0 &&
      e.target.name === "first_name" &&
      /^[A-Za-z ]+$/.test(e.target.value) === false
    ) {
      return;
    }

    if (
      e.target.value.length > 0 &&
      e.target.name === "last_name" &&
      /^[A-Za-z ]+$/.test(e.target.value) === false
    ) {
      return;
    }

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    if (values.password && values.password.length === 0) {
      toast("Password length should be 6 characters", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    if (values.password !== values.cPassword) {
      toast("Password and Confirm Password does not match!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    if (
      values.dob == "" ||
      values.dob == null ||
      values.dob == undefined ||
      values.dob === ""
    ) {
      toast("DOB is required!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    if (terms === false) {
      toast("Please accept terms & conditions", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    let data = {
      first_name: values.first_name,
      last_name: values.last_name,
      dob: moment(values.dob).format("DD-MM-YYYY"),
      email: values.email,
      password: values.password,
      region: selectedRegion,
      phone: regionPhoneCode + values.phone,
      domestic_postcode: values.domestic_postcode,
      newsletter: false,
      social_type: "manual", //social login => google,facebook,apple
      social_id: "0", //social id
    };

    dispatch(onRegister(data));
  };

  // disable future dates
  const today = moment();
  const disableFutureDt = (current) => {
    return current.isBefore(today);
  };

  return (
    <div className="register-wrapper pt-4 register-page">
      <div className="container">
        <div>
          <div className="register-header">
            <h2>
              Create an account
              <br /> and get started today.
            </h2>
          </div>
          <form onSubmit={onSubmitForm}>
            <div className="register-section">
              <div className="input-group">
                <select
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  required
                >
                  <option value="">Select Region</option>
                  {regions &&
                    regions.map((region, i) =>
                      selectedRegion === region.name ? (
                        <option value={region.name} key={i} selected>
                          {region.name}
                        </option>
                      ) : (
                        <option value={region.name} key={i}>
                          {region.name}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Your Mobile Number"
                  name="phone"
                  value={values.phone}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Postcode"
                  name="domestic_postcode"
                  minlength="6"
                  value={values.domestic_postcode}
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type={showPwd ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  minLength="6"
                  value={values.password}
                  onChange={onInputChange}
                  required
                />

                {showPwd ? (
                  <i
                    className="fa fa-eye text-secondary cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setShowPwd(!showPwd)}
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash text-secondary cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setShowPwd(!showPwd)}
                  ></i>
                )}
              </div>
              <div className="input-group">
                <input
                  type={showConfirmPwd ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="cPassword"
                  minLength="4"
                  value={values.cPassword}
                  onChange={onInputChange}
                  required
                />

                {showConfirmPwd ? (
                  <i
                    className="fa fa-eye text-secondary cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash text-secondary cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                  ></i>
                )}
              </div>
              <div className="input-group">
                <Datetime
                  id="date-input"
                  dateFormat={"DD/MM/YYYY"}
                  timeFormat={false}
                  value={values.dob}
                  onChange={(date) => setValues({ ...values, dob: date._d })}
                  inputProps={inputProps}
                  isValidDate={disableFutureDt}
                />
              </div>
            </div>
            <div className="register-submit">
              <div className="d-flex align-items-center m-0">
                <input
                  id="terms"
                  type="radio"
                  className="form-control-md"
                  onClick={() => setTerms(!terms)}
                  checked={terms}
                />
                <label htmlFor="terms" className="four-title mx-1 my-0">
                  Agree to{" "}
                  <Link to="/terms" target="_blank">
                    {" "}
                    Terms and Conditions
                  </Link>
                </label>
              </div>

              <div className="input-group">
                {isRegisterLoading ? (
                  <button type="submit" className="btn-submit">
                    <span className="fa fa-spinner fa-spin px-4"></span>
                  </button>
                ) : (
                  <button type="submit" className="btn-submit">
                    Register
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
