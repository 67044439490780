import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { SET_USER_DETAILS } from "../../store/types";

const MULTIPLIER = 1000;

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const userInfo = useSelector((state) => state.home.userDetails);

  const dispatch = useDispatch();

  React.useEffect (() => {
    const token = userDetails?.gf_token ? jwtDecode(userDetails.gf_token) : {};
    if (token?.exp) {
      if (Date.now() >= token?.exp * MULTIPLIER) {
        localStorage.clear()
        dispatch({
          type: SET_USER_DETAILS,
          payload: null,
        });
      }
    }
  }, [userDetails]);

  return (
    <Route
      {...rest}
      render={(props) =>
        (userDetails || userInfo?.id) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
