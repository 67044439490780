import React, { useEffect, useState } from "react";
import ReactCalendar from "react-calendar";
import moment from "moment";
import "./calendar.scss";
import "react-calendar/dist/Calendar.css";
import "./userBooking.scss";
import * as Popover from "@radix-ui/react-popover";
import { styled, keyframes } from "@stitches/react";
import useLongPress from "./UseLongPress";

const RangeCalendar = ({
  availableDates,
  isWeeklySpace,
  numberOfWeeks,
  onSelectDayDate,
  onSelectNightDate,
  availabilityType,
  selectedDatesArray,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [deletedDate, setDeletedDate] = useState("");
  const [longPress, setLongPress] = useState(false);

  useEffect(() => {
    if (selectedDatesArray) {
      setSelectedDates([...selectedDatesArray]);
    }
    //console.log(selectedDatesArray);
  }, [selectedDatesArray, availabilityType]);

  const addMonths = (date, months) => {
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };

  const setDisableDates = (data) => {
    const { activeStartDate, date, view } = data;

    if (availableDates && availableDates.length > 0) {
      for (let i = 0; i < availableDates.length; i++) {
        const finalMonth = date.getMonth() + 1;
        if (finalMonth === parseInt(availableDates[i].month_no)) {
          const days = availableDates[i].days;
          for (let j = 0; j < days.length; j++) {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            if (formattedDate === days[j].date) {
              if (days[j].is_available === true) {
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }
    }
  };

  const onAddDateWithWeeks = (date, isRecurring) => {
    let noOfWeeks = numberOfWeeks;
    let dates = selectedDates;
    let exist = false;
    let allDates = [];

    if (!isRecurring) {
      for (let i = 0; i < dates.length; i++) {
        if (dates[i] === moment(date).format("YYYY-MM-DD")) {
          exist = true;
          dates.splice(i, 1);
        }
      }
    }

    if (!exist) {
      dates.push(moment(date).format("YYYY-MM-DD"));

      let weekDates = [];
      let actualDateNumber = noOfWeeks / 1;

      if (date) {
        for (let i = 0; i < actualDateNumber; i++) {
          if (i !== 0) {
            let weekDays = i * 7;
            let startDate = new Date(date);
            const newDate = startDate.setDate(startDate.getDate() + weekDays);
            weekDates.push(moment(newDate).format("YYYY-MM-DD"));
          }
        }
      }
      allDates = [...dates, ...weekDates];

      allDates = allDates.filter(function (item, pos) {
        return allDates.indexOf(item) == pos;
      });

      setSelectedDates(allDates);
    } else {
      allDates = [...dates];
      setSelectedDates([...dates]);
    }

    if (availabilityType === 1) {
      onSelectDayDate(allDates);
    } else if (availabilityType === 2) {
      onSelectNightDate(allDates);
    } else {
      onSelectDayDate(allDates);
    }
  };

  const onRemoveDateWithWeeks = (date) => {
    let noOfWeeks = numberOfWeeks;
    let dates = selectedDates;
    let exist = false;
    let allDates = [];

    let weekDates = [];
    let actualDateNumber = noOfWeeks / 1;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === moment(date).format("YYYY-MM-DD")) {
        exist = true;
        dates.splice(i, 1);

        if (date) {
          for (let j = 0; j < actualDateNumber; j++) {
            if (j !== 0) {
              let weekDays = j * 7;
              let startDate = new Date(date);
              const newDate = startDate.setDate(startDate.getDate() + weekDays);

              if (dates[i] === moment(newDate).format("YYYY-MM-DD")) {
                dates.splice(i, 1);
              }
            }
          }
        }
      }
    }

    dates = dates.filter(function (item, pos) {
      return dates.indexOf(item) == pos;
    });

    allDates = [...dates];
    setSelectedDates(allDates);

    if (availabilityType === 1) {
      onSelectDayDate(allDates);
    } else if (availabilityType === 2) {
      onSelectNightDate(allDates);
    } else {
      onSelectDayDate(allDates);
    }
  };

  const checkDate = (date) => {
    let dates = selectedDates;
    let exist = false;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === moment(date).format("YYYY-MM-DD")) {
        exist = true;
      }
    }
    return exist;
  };

  const onChangeDate = (date) => {
    let dates = selectedDates;
    let exist = false;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === moment(date).format("YYYY-MM-DD")) {
        exist = true;
        dates.splice(i, 1);
        setDeletedDate(moment(date).format("YYYY-MM-DD"));
      }
    }

    if (!exist) {
      dates.push(moment(date).format("YYYY-MM-DD"));
    }
    setSelectedDates(dates);

    if (availabilityType === 1) {
      onSelectDayDate(dates);
    } else if (availabilityType === 2) {
      onSelectNightDate(dates);
    } else {
      onSelectDayDate(dates);
    }
  };

  const tileClassNameRange = ({ date, view }) => {
    for (let j = 0; j < selectedDates.length; j++) {
      if (moment(date).format("YYYY-MM-DD") === selectedDates[j]) {
        return `react-calendar__tile react-calendar__tile--selected react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day`;
      }
    }

    if (moment(date).format("YYYY-MM-DD") === deletedDate) {
      // console.log(moment(date).format("YYYY-MM-DD"), deletedDate);
      return `react-calendar__tile react-calendar__month-view__days__day date-unselected`;
    }
  };

  const StyledContent = styled(Popover.Content, {
    transformOrigin: "var(--radix-popover-content-transform-origin)",
  });

  const onLongPress = () => {
    setLongPress(true);
  };

  const onClick = () => {
    setLongPress(false);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  const tileContent = ({ date, view }) =>
    view === "month" && (
      <Popover.Root>
        <Popover.Trigger
          onClick={() => !longPress && onAddDateWithWeeks(date)}
          className="long-press-button"
          {...longPressEvent}
        ></Popover.Trigger>
        {longPress ? (
          <StyledContent>
            <div
              style={{
                width: "200px",
                height: "0px",
                position: "relative",
              }}
              className="popover-bubble-anchor"
            >
              <div
                style={{
                  fontSize: `10px`,
                  fontColor: `#000`,
                  fontFamily: "Poppins",
                  textAlign: "center",
                  padding: "10px 0",
                }}
                className="popover-bubble"
              >
                <h6>Date Options:</h6>
                <br />
                {checkDate(date) ? (
                  <div className="button" onClick={() => onChangeDate(date)}>
                    Remove Date
                  </div>
                ) : (
                  <div className="button" onClick={() => onChangeDate(date)}>
                    Add Date
                  </div>
                )}

                <div
                  className={`button active`}
                  onClick={() => onAddDateWithWeeks(date, "recurring")}
                >
                  Make recurring booking
                </div>
                <div
                  className={`button active`}
                  onClick={() => onRemoveDateWithWeeks(date)}
                >
                  Remove recurring booking
                </div>
              </div>
            </div>
          </StyledContent>
        ) : null}
      </Popover.Root>
    );
  return (
    <div className={`user-calender ${availabilityType === 2 ? "" : null}`}>
      <ReactCalendar
        tileDisabled={(date) => setDisableDates(date)}
        view={"month"}
        minDate={new Date()}
        maxDate={addMonths(new Date(), 2)}
        minDetail={"month"}
        tileClassName={tileClassNameRange}
        tileContent={tileContent}
      />
    </div>
  );
};

export default RangeCalendar;
