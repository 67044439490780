import React from 'react';

const IconChevron = ({ color = '#E35447', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M1.768 7.31c-0.955 1.026-1.748 1.904-1.768 1.952-0.020 0.054 2.85 3.171 7.965 8.642 3.319 3.569 5.939 6.355 8.571 9.129l-0.539-0.572c0.054 0 15.963-17.034 16.003-17.13 0.017-0.051-0.674-0.836-1.768-2l-1.799-1.912-0.187 0.213c-0.547 0.626-12.212 13.064-12.249 13.064-0.025 0-2.83-2.978-6.233-6.616-2.612-2.805-4.641-4.962-6.68-7.111l0.458 0.487c-0.596 0.592-1.174 1.197-1.738 1.816l-0.036 0.040z"></path>
    </svg>
  )
};

export default IconChevron;