import React, { useState } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const re = /^[0-9\b]+$/;

const DesksPopup = ({ open, onClose, selectDeskValue }) => {
  const [inBetween, setInBetween] = useState(true);
  const [inGreaterThan, setInGreaterThan] = useState(false);
  const [inBelowThan, setInBelowThan] = useState(false);
  const [inBetweenMinValue, setInBetweenMinValue] = useState("");
  const [inBetweenMaxValue, setInBetweenMaxValue] = useState("");
  const [greaterThen, setGreaterThen] = useState("");
  const [belowThen, setBelowThen] = useState("");

  const onSelectDeskValue = () => {
    let data = {
      no_of_desk_min: inBetweenMinValue,
      no_of_desk_max: inBetweenMaxValue,
      no_of_desk_greater: greaterThen,
      no_of_desk_below: belowThen,
    };
    selectDeskValue(data);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "amenities-popup-modal desk-popup-modal" }}
    >
      <h5 className="title text-primary">Filter by number of desks</h5>
      <div className="desks-wrapper">
        <div className="top-input-wrapper">
          <label>
            <input
              type="checkbox"
              className="css-checkbox"
              checked={inBetween}
              onClick={() => {
                setInBetween(!inBetween);
                setInGreaterThan(false);
                setInBelowThan(false);
              }}
              onChange={() => {
                setGreaterThen("");
                setBelowThen("");
              }}
            />
            <i></i> In between:
          </label>
        </div>
        <div className="top-input-wrapper">
          <input
            id="number-input"
            name="greaterThen"
            value={inBetweenMinValue}
            disabled={inGreaterThan || inBelowThan}
            onChange={(e) =>
              setInBetweenMinValue(
                re.test(e.target.value) ? e.target.value : ""
              )
            }
          />
          <span className="text-primary">and</span>
          <input
            id="number-input"
            name="greaterThen"
            value={inBetweenMaxValue}
            disabled={inGreaterThan || inBelowThan}
            onChange={(e) =>
              setInBetweenMaxValue(
                re.test(e.target.value) ? e.target.value : ""
              )
            }
          />
        </div>
        <div className="bottom-input-wrapper">
          <div className="text">
            <label>
              <input
                type="checkbox"
                className="css-checkbox"
                checked={inGreaterThan}
                onClick={() => {
                  setInBetween(false);
                  setInGreaterThan(!inGreaterThan);
                  setInBelowThan(false);
                }}
                onChange={() => {
                  setInBetweenMinValue("");
                  setInBetweenMaxValue("");
                  setBelowThen("");
                }}
              />
              <i></i>
            </label>{" "}
            Greater Then:
          </div>
          <div>
            <input
              id="number-input"
              name="greaterThen"
              value={greaterThen}
              disabled={inBetween || inBelowThan}
              onChange={(e) =>
                setGreaterThen(re.test(e.target.value) ? e.target.value : "")
              }
            />
          </div>
          <div className="text">
            <label>
              <input
                type="checkbox"
                className="css-checkbox"
                checked={inBelowThan}
                onClick={() => {
                  setInBetween(false);
                  setInGreaterThan(false);
                  setInBelowThan(!inBelowThan);
                }}
                onChange={() => {
                  setInBetweenMinValue("");
                  setInBetweenMaxValue("");
                  setGreaterThen("");
                }}
              />
              <i></i>
            </label>{" "}
            Below:
          </div>
          <div>
            <input
              id="number-input"
              name="greaterThen"
              value={belowThen}
              disabled={inBetween || inGreaterThan}
              onChange={(e) =>
                setBelowThen(re.test(e.target.value) ? e.target.value : "")
              }
            />
          </div>
        </div>
      </div>
      <div className="done-btn" onClick={onSelectDeskValue}>
        <img src="assets/images/done.png"></img>
        Done
      </div>
    </Modal>
  );
};

export default DesksPopup;
