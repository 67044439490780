import React from "react";

const DayNight = ({ onInputChange, values }) => {
  return (
    <div className="input-wrapper">
      <div className="label">Price</div>
      <p className="label-subtitle">How much is the price per desk per day?</p>
      {/* {values.cost_type != "range" ? (
        <input
          type="number"
          name="price"
          value={values.price}
          onChange={onInputChange}
          onWheel={(e) => e.target.blur()}
          required
        />
      ) : ( */}
      <div>
        <div className="row mb-2">
          <div className="col-md-2"></div>
          <div className="col-md-4 ml-3 text-center">Night</div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Monday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="mon_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.mon_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Tuesday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="tue_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.tue_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Wednesday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="wed_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.wed_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Thursday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="thu_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.thu_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Friday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="fri_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.fri_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Saturday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="sat_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.sat_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-center">Sunday</div>
          <div className="col-md-4 ml-3">
            <input
              type="number"
              name="sun_night"
              className="py-1 my-1"
              placeholder="Enter Price"
              value={values.sun_night}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default DayNight;
