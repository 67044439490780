import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import KYCPopup from "../../popups/KYCPopup";
import { getUserInfo } from "../../api/user";
import { toast } from "react-toastify";
import { EventEmitter } from "../../utils/EventEmitter";

const PlanBuySuccess = () => {
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const buyPlanDetails = useSelector((state) => state.home.buyPlanDetails);

  useEffect(() => {
    localStorage.setItem("firstProperty", true);
  }, [buyPlanDetails]);

  const handleProcessKYC = (triggeredFrom) => {
    return async () => {
      try {
        const { data } = await getUserInfo({ user_id: userDetails.id });
  
        if (!data.data.kyc_status) {
          EventEmitter.dispatch(EventEmitter.events.TriggerKycProcess);
          return;
        }
  
        window.location.href = "#/add-property/1";
      } catch (err) {
        toast("Failed to proceed with the KYC process", {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    }
  }

  return (
    <div className="plan-success-wrapper container">
      <h2>
        Success<span className="text-primary">!</span>
      </h2>
      <h5>
        You are on your journey to sharing a space with the OfficeShare
        community.
      </h5>

      <KYCPopup isManualTrigger={true} />

      <div className="discription">
        We understand that you want to get going as fast as possible, but we
        need you to provide us a few more details about you and your business
        (if indeed you are a business) so as to make sure you are fully
        validated and able to receive guests and of course income. Your guests
        will also be required to do this. Validation is simple, but you will
        need a few things for identification purposes (Government issued ID and
        address verification).
      </div>
      <button onClick={handleProcessKYC('')} className="button">
        Get Started
      </button>
      <br />
      <br />
      <hr />
      <br />
      <div className="plan-success-details">
        <div className="left-section">
          <p>
            Receipt:{" "}
            <span className="italic">
              <i>
                (This payment will show as <b>OfficeShare Ltd</b> on your bank
                statement).
              </i>
            </span>
          </p>
          <div className="details">
            <p>
              <b> Package Ordered:</b> {buyPlanDetails?.package_details?.title}
            </p>
            <p>
              <b>Start Date:</b>{" "}
              {moment(buyPlanDetails?.from_date).format("DD-MM-YYYY")}{" "}
            </p>
            <p>
              <b>End date:</b>{" "}
              {moment(buyPlanDetails?.to_date).format("DD-MM-YYYY")}{" "}
            </p>
            <p className="price">
              <b>Price: </b>£{buyPlanDetails?.price}
            </p>
          </div>
          <div className="details-buttons">
            <a
              href={buyPlanDetails?.invoice_url}
              download
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              <div className="button">Download Invoice</div>
            </a>
            {/* <a href={buyPlanDetails?.invoice_url} download>
              
            </a> */}

            <Link to="/terms" className="button" target="_blank">
              View Terms & Conditions
            </Link>
          </div>
        </div>
        <div className="right-section">
          <p>
            <b> Auto Renewal:</b>
            <br /> After three months we will email you to let you know that
            your package will automatically be upgraded to Premium unless you
            want to cancel – which you can do in a single click.
          </p>
          <br />
          <p>
            <b>Our Role:</b> <br /> OfficeShare is a marketplace. We are a
            technology company with a sprinkling of property expertise and as
            such our role is as facilitator. Almost everything we do is
            standardised and process driven. Once on board you will be able to
            list locations and spaces quickly and seamlessly, showcasing them to
            our global community.
          </p>
        </div>
      </div>
      <hr />

      <div className="text-center py-3">
        <button
          onClick={handleProcessKYC('CONTINUE')}
          className="button continue-button px-5">
          Continue
        </button>
      </div>
    </div>
  );
};

export default PlanBuySuccess;
