import React from 'react';

const IconAdd = ({ color = '#BCBCBC', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path stroke={color} strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="10" strokeWidth="2.9091" d="M0 16h32M16 0v32"></path>
    </svg>
  );
};

export default IconAdd;