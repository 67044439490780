import React from 'react';

const IconUpload = ({ color = '#E35447', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M15.551 0.045c-0.171 0.063-0.32 0.144-0.453 0.245l0.004-0.003c-0.123 0.095-2.089 2.047-4.368 4.338-4.475 4.498-4.307 4.302-4.302 5.003 0.008 0.846 0.673 1.508 1.521 1.516 0.683 0.005 0.614 0.063 3.637-2.953l2.804-2.796 0.018 7.885c0.016 7.433 0.024 7.898 0.133 8.14 0.248 0.559 0.798 0.943 1.438 0.943 0.006 0 0.012-0 0.019-0h-0.001c0.641 0 1.191-0.356 1.455-0.943 0.109-0.242 0.117-0.707 0.133-8.14l0.018-7.885 2.804 2.796c3.026 3.018 2.953 2.957 3.642 2.953 0.629-0.009 1.167-0.389 1.405-0.931l0.004-0.010c0.065-0.18 0.102-0.388 0.102-0.605 0-0.23-0.042-0.45-0.119-0.653l0.004 0.013c-0.146-0.325-8.449-8.666-8.791-8.827-0.205-0.082-0.443-0.13-0.693-0.13-0.147 0-0.289 0.017-0.426 0.048l0.013-0.002zM1.274 19.277c-0.352 0.056-0.654 0.236-0.865 0.493l-0.002 0.002c-0.412 0.455-0.404 0.373-0.404 4.405 0 3.273 0.011 3.704 0.114 4.139 0.441 1.766 1.804 3.129 3.536 3.563l0.033 0.007c0.454 0.107 1.215 0.114 12.314 0.114s11.86-0.006 12.314-0.114c1.766-0.441 3.129-1.804 3.563-3.536l0.007-0.033c0.103-0.434 0.114-0.866 0.114-4.139 0-4.036 0.008-3.948-0.409-4.411-0.758-0.842-2.146-0.611-2.618 0.436-0.106 0.234-0.119 0.571-0.147 3.911l-0.032 3.655-0.149 0.239c-0.2 0.322-0.625 0.678-0.904 0.755-0.147 0.040-4.355 0.064-11.788 0.064-12.844 0-11.793 0.037-12.282-0.436-0.486-0.47-0.457-0.208-0.492-4.277-0.030-3.536-0.037-3.664-0.167-3.943-0.26-0.548-0.809-0.92-1.445-0.92-0.103 0-0.204 0.010-0.301 0.028l0.010-0.002z"></path>
    </svg>
  )
};

export default IconUpload;