import React, { useState } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const re = /^[0-9\b]+$/;

const DaysPopup = ({ open, onClose, selectDaysValue }) => {
  const [inBetween, setInBetween] = useState(true);
  const [inBetweenMinValue, setInBetweenMinValue] = useState("");
  const [inBetweenMaxValue, setInBetweenMaxValue] = useState("");
  const [greaterThen, setGreaterThen] = useState("");
  const [belowThen, setBelowThen] = useState("");

  const onSelectDaysValue = () => {
    let data = {
      no_of_day_min: inBetweenMinValue,
      no_of_day_max: inBetweenMaxValue,
    };
    selectDaysValue(data);
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "amenities-popup-modal desk-popup-modal" }}
    >
      <h5 className="title text-primary">Filter by number of days per week</h5>
      <div className="desks-wrapper">
        <div className="top-input-wrapper">
          <label>
            <input
              type="checkbox"
              className="css-checkbox"
              checked={inBetween}
              onClick={() => setInBetween(!inBetween)}
            />
            <i></i> In between:
          </label>
        </div>
        <div className="top-input-wrapper">
          <input
            id="number-input"
            name="greaterThen"
            value={inBetweenMinValue}
            onChange={(e) =>
              setInBetweenMinValue(
                re.test(e.target.value) ? e.target.value : ""
              )
            }
          />
          <span className="text-primary">and</span>
          <input
            id="number-input"
            name="greaterThen"
            value={inBetweenMaxValue}
            onChange={(e) =>
              setInBetweenMaxValue(
                re.test(e.target.value) ? e.target.value : ""
              )
            }
          />
        </div>
        <div className="bottom-input-wrapper">
          <div className="text">
            <label>
              <input type="checkbox" className="css-checkbox" />
              <i></i>
            </label>{" "}
            Greater Then:
          </div>
          <div>
            <input
              id="number-input"
              name="greaterThen"
              value={greaterThen}
              disabled={inBetween}
              onChange={(e) =>
                setGreaterThen(re.test(e.target.value) ? e.target.value : "")
              }
            />
          </div>
          <div className="text">
            <label>
              <input type="checkbox" className="css-checkbox" />
              <i></i>
            </label>{" "}
            Below:
          </div>
          <div>
            <input
              id="number-input"
              name="greaterThen"
              value={belowThen}
              disabled={inBetween}
              onChange={(e) =>
                setBelowThen(re.test(e.target.value) ? e.target.value : "")
              }
            />
          </div>
        </div>
      </div>
      <div className="done-btn" onClick={onSelectDaysValue}>
        <img src="assets/images/done.png"></img>
        Done
      </div>
    </Modal>
  );
};

export default DaysPopup;
