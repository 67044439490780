import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../pages/myDashboard/vouchers/vouchers.scss";
import { useSelector, useDispatch } from "react-redux";
import { createVoucherCode, getAllUsersData } from "../store/homeAction";
import { toast } from "react-toastify";
import db from "../config";

const CreateVoucherPopup = ({ open, onCloseModel }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [chatUserIds, setChatUserIds] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [voucherCode, setVoucherCode] = useState();
  const getAllUsersList = useSelector((state) => state.home.getAllUsersList);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  const validity = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200,
  ];

  useEffect(() => {
    const data = {
      landlord_id: userDetails && userDetails.id,
    };
    dispatch(getAllUsersData(data));
  }, []);

  useEffect(() => {
    getUsers(userDetails.id);
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [chatUserIds]);

  const makeid = () => {
    var length = 6;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setVoucherCode(result.toUpperCase());
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onUserChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    makeid();
  };

  const handleCreateVoucher = (e) => {
    e.preventDefault();

    const data = {
      user_id: userDetails && userDetails.id,
      user_to: values.userId,
      code: voucherCode,
      validity: values.voucherValidity,
      discount: values.voucherDiscount,
    };

    if (!values.userId) {
      toast("Select User", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else if (!values.voucherValidity) {
      toast("Select Your Validity", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else if (!values.voucherDiscount) {
      toast("Enter Your Discount", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    } else {
      setVoucherCode();
      setValues();
      dispatch(createVoucherCode(data, onCloseModel));
    }
  };

  const getUsers = (userId) => {
    db.ref(`users`).on("value", function (snapshot) {
      const users = snapshot.val();

      if (users[userId]?.chatRequestAcceptedUserIds) {
        const data = users[userId].chatRequestAcceptedUserIds;
        let chatUsers = [];
        if (data) {
          var keys = Object.values(data);
          for (var i = 0; i < keys.length; i++) {
            chatUsers.push(keys[i]);
          }
          setChatUserIds(chatUsers);
        }
      } else {
        setChatUserIds([]);
      }
    });
  };

  const getData = () => {
    db.ref(`messages`).on("value", function (snapshot) {
      const message = snapshot.val();
      const rootRef = db.ref();
      let messageList = [];
      let exist = false;

      if (message && Object.keys(message) && Object.keys(message).length > 0) {
        Object.keys(message).map((msg) => {
          if (msg.includes(userDetails.id)) {
            exist = true;
            const urlRef = rootRef.child(`messages/${msg}`);
            urlRef
              .limitToLast(1)
              .once("value")
              .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                  let snap = childSnapshot.val();
                  snap.collection = msg;
                  messageList.push(checkInvitation(snap, chatUserIds));

                  messageList = messageList.sort((x, y) => {
                    return x.timestamp - y.timestamp;
                  });
                  setUsersList([...messageList.reverse()]);

                  // First chat default selected
                });
              });
          }
        });
      }

      if (exist === false) {
        setUsersList([]);
      }
    });
  };

  const checkInvitation = (chat, userIds) => {
    if (chat) {
      if (chat.senderid == userDetails.id) {
        return chat;
      }

      if (userIds.some((user) => user == chat.senderid)) {
        chat.invite = false;
      } else {
        chat.invite = true;
      }
      return chat;
    }
  };

  return (
    <Modal
      open={open}
      classNames={{ modal: "voucher-withdraw-modal text-center" }}
      onClose={onCloseModel}
    >
      <h5 className="font-weight-bold m-0 text-center">Create Voucher</h5>
      <form className="voucher-wrapper" onSubmit={handleCreateVoucher}>
        <div className="voucher-left">
          <div className="voucher-left-item">
            <span>To User:</span>{" "}
            <select
              className="voucher-input"
              value={values?.userId}
              onChange={onUserChange}
              name="userId"
            >
              <option>Select User</option>
              {getAllUsersList &&
                getAllUsersList.map((data, key) => (
                  <option value={data.user_id} key={key}>
                    {data.name}
                  </option>
                ))}
              {/* {getAllUsersList &&
                getAllUsersList.map((data, key) => (
                  <option
                    value={
                      data.senderid == userDetails.id
                        ? data.receiverid
                        : data.senderid
                    }
                    key={key}
                  >
                    {data.senderid == userDetails.id
                      ? data.receivername
                      : data.sendername}
                  </option>
                ))} */}
            </select>
          </div>
          <div className="voucher-left-item">
            <span>Voucher Code:</span>
            <input
              type="text"
              placeholder="Input Voucher Code"
              className="voucher-input"
              value={voucherCode}
              disabled
            />
          </div>
          <div className="voucher-left-item">
            <span>Valid Until:</span>
            <select
              className="voucher-input"
              value={values?.voucherValidity}
              onChange={onInputChange}
              name="voucherValidity"
            >
              <option>Select Validity</option>
              {validity.map((data, key) => (
                <option key={key} value={`${data} Day`}>
                  {data} Day
                </option>
              ))}
            </select>
          </div>
          <div className="voucher-left-item">
            <span>Discount(%):</span>
            <input
              type="number"
              placeholder="Enter Discount"
              className="voucher-input"
              name="voucherDiscount"
              value={values?.voucherDiscount}
              onChange={onInputChange}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        </div>
        <button className="voucherCreate" type="submit">
          Create Voucher
        </button>
      </form>
    </Modal>
  );
};

export default CreateVoucherPopup;
