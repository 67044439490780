import React, { useState } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ConfirmPopup = ({ title, description, open, onClose, onDelete }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "confirm-popup-modal" }}
    >
      <h5 className="text-primary">{title}</h5>
      <div className="details py-4">{description}</div>
      <div className="btns d-flex justify-content-end">
        <span>
          <span className="btns text-primary mx-2" onClick={() => onClose()}>
            Cancel
          </span>
          <span
            className="btns text-primary mx-2"
            onClick={() => {
              onDelete();
              onClose();
            }}
          >
            OK
          </span>
        </span>
      </div>
    </Modal>
  );
};

export default ConfirmPopup;
