import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./bookingConfirm.scss";
import BookingDatesPopup from "../../../popups/BookingDatesPopup";
import UnverifiedPopup from "../../../popups/UnverifiedPopup";
import {getPropertyById, onVerificationSession} from "../../../store/homeAction";
import { useParams } from 'react-router-dom';

const BookingConfirm = ({ match }) => {
  // Fetch the prop and spaceID from the router
  const { propertyId, spaceId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [isOpenBookingDatesPopup, setIsOpenBookingDatesPopup] = useState(false);
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const bookingDetails = useSelector((state) => state.home.bookingDetails);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  // const kycDetails = useSelector((state) => state.home.kycDetails);
  const bookingConfirmDetails = useSelector(
    (state) => state.home.bookingConfirmDetails
  );
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  // useEffect(() => {
  //   kycVerified();
  // }, []);
  //
  // const kycVerified = () => {
  //   const kycParams = new URLSearchParams();
  //
  //   kycParams.append("type", "document");
  //   kycParams.append("options[document][require_live_capture]", true);
  //   kycParams.append("options[document][require_matching_selfie]", true);
  //   kycParams.append("metadata[user_id]", userDetails?.id);
  //
  //   // dispatch(onVerificationSession(kycParams));
  // };

  useEffect(() => {
    const data = {
      property_id: propertyId,
      user_id: userDetails && userDetails.id,
    };
    dispatch(getPropertyById(data));

  }, []);

  const goToSearch = () => {
    history.push("/search");
    window.location.reload();
  };

  return (
    <div className="booking-confirm-wrapper">
      <div className="booking-wrapper">
        <h1 className="text-primary mb-4">
          ...and relax! Your booking has been confirmed.
        </h1>
        {!propertyListById ? (
            <b>No property details available.</b>
        ) : (
            <b>
              We are delighted to confirm your booking at:{" "}
              {propertyListById?.property_title && (
                  <>
                    {propertyListById.property_title},{" "}
                  </>
              )}
              {propertyListById?.address_line_one && (
                  <>
                    {propertyListById.address_line_one},{" "}
                  </>
              )}
              {propertyListById?.city && (
                  <>
                    {propertyListById.city},{" "}
                  </>
              )}
              {propertyListById?.state && (
                  <>
                    {propertyListById.state},{" "}
                  </>
              )}
              {propertyListById?.country && (
                  <>
                    {propertyListById.country}
                  </>
              )}
            </b>
        )}

        <h6 className="my-4">
          {/*You are expected on{" "}*/}
          {/*<span*/}
          {/*  className="text-primary font-weight-bold mr-2 btn-link cursor-pointer"*/}
          {/*  onClick={() => setIsOpenBookingDatesPopup(true)}*/}
          {/*>*/}
          {/*  View Dates*/}
          {/*</span>*/}
          {" "}
          <Link to="/mydashboard/2" className="booking-confirm-link">
            Click here to view/manage your bookings
          </Link>
        </h6>
        <h6 className="mb-5">
          if you have any enquires you can contact the location via email on:{" "}
          <a className="booking-confirm-link" href={`mailto:${propertyListById?.user?.email}`}>
            {propertyListById?.user?.email}
          </a>

        </h6>
        <hr />
        <div className="booking-confirm-buttons">
          <div onClick={goToSearch} style={{ textDecoration: "none" }}>
            <div className="booking-confirm-explore cursor-pointer">
              Back to Explore
            </div>
          </div>
          {/*<a*/}
          {/*  href={bookingConfirmDetails?.data?.download_pdf}*/}
          {/*  className="booking-confirm-download"*/}
          {/*  download*/}
          {/*  rel="noreferrer"*/}
          {/*  to={}*/}
          {/*>*/}
          {/*  Download Receipt*/}
          {/*</a>*/}
          {/*<a*/}
          {/*  href={kycDetails?.url}*/}
          {/*  className="booking-confirm-explore"*/}
          {/*  rel="noreferrer"*/}
          {/*  style={{ textDecoration: "none", marginLeft: "30px" }}*/}
          {/*>*/}
          {/*  Complete KYC*/}
          {/*</a>*/}
        </div>
      </div>
      <BookingDatesPopup
        open={isOpenBookingDatesPopup}
        onClose={() => setIsOpenBookingDatesPopup(false)}
        bookingDetails={bookingConfirmDetails && bookingConfirmDetails.data}
      />
      {/*{bookingConfirmDetails && bookingConfirmDetails.status === true && (*/}
      {/*  <UnverifiedPopup*/}
      {/*    open={open}*/}
      {/*    onCloseModal={() => setOpen(false)}*/}
      {/*    kycDetails={kycDetails}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default BookingConfirm;
