import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onLike, onUnlike } from "../../store/homeAction";
import { ToastContainer, toast } from "react-toastify";
import "./favourite.scss";

const Favourite = ({ office, history, selectedFilters, className }) => {
  const dispatch = useDispatch();

  const onLikeClick = (id) => {
    let data = {};
    if (localStorage.getItem("os-user")) {
      const userDetails = JSON.parse(localStorage.getItem("os-user"));

      data.user_id = userDetails.id;
      data.property_id = id;
    } else {
      toast("Please Login!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      history.push("/login");
      return false;
    }
    dispatch(onLike(data, null, "property-details"));
  };

  const onUnlikeClick = (id) => {
    const searchData = selectedFilters;
    let data = {};
    if (localStorage.getItem("os-user")) {
      const userDetails = JSON.parse(localStorage.getItem("os-user"));

      data.user_id = userDetails.id;
      data.property_id = id;
    } else {
      toast("Please Login!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });

      return false;
    }

    dispatch(onUnlike(data, null, "property-details"));
  };

  return office.is_liked ? (
    <div
      className={`favourite-wrapper ${className}`}
      onClick={() => onUnlikeClick(office.id)}
    >
      <img className="jello-horizontal" src="assets/images/heart.png" alt="" />
    </div>
  ) : (
    <div
      className={`favourite-wrapper ${className}`}
      onClick={() => onLikeClick(office.id)}
    >
      <span>
        <img
          className="jello-horizontal"
          src="assets/images/heart-blank.png"
          alt=""
        />
      </span>
    </div>
  );
};

export default Favourite;
