/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SET_INITIALIZE_CHAT_DETAILS } from "../../store/types";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import "./chat.css";
import db from "../../config";
import moment from "moment";
// import ChatInvitation from "./ChatInvitation";
import { onChatNotification } from "../../store/homeAction";

const Chat = ({ match }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [receiverDetails, setReceiverDetails] = useState({});
  const [activeChat, setActiveChat] = useState();
  const [chatUserIds, setChatUserIds] = useState([]);
  const [pendingUserIds, setPendingUserIds] = useState([]);
  const [firstMessage, setFirstMessage] = useState({});
  const fcmToken = useSelector((state) => state.home.fcmToken);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  let initalizeChat = useSelector((state) => state.home.initalizeChat);

  if (initalizeChat === undefined) {
    initalizeChat = JSON.parse(localStorage.getItem("initialize-chat"));
  }

  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    getUsers(userDetails.id);
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [chatUserIds]);

  useEffect(() => {
    if (firstMessage) {
      const iAmSender = userDetails.id.toString() == firstMessage.senderid;
      const iAmReceiver = userDetails.id.toString() == firstMessage.receiverid;
      findUserFromUserIds(firstMessage.receiverid);

      let requestReceivedFromSender = false;
      let requestAcceptedByReceiver = false;

      if (
        iAmReceiver &&
        findUserFromUserIds(firstMessage.receiverid).some(
          (user) => user == firstMessage.senderid
        )
      ) {
        requestReceivedFromSender = false;
      } else {
        requestReceivedFromSender = true;
      }

      if (
        iAmSender &&
        findUserFromUserIds(firstMessage.senderid).some(
          (user) => user == firstMessage.receiverid
        )
      ) {
        requestAcceptedByReceiver = true;
      } else {
        requestAcceptedByReceiver = false;
      }

      let receiverDetail = {
        collection: currentCollection,
      };
      if (firstMessage.senderid == userDetails.id) {
        receiverDetail.id = firstMessage.receiverid;
        receiverDetail.photo = firstMessage.receiverphoto;
        receiverDetail.name = firstMessage.receivername;
      } else {
        receiverDetail.id = firstMessage.senderid;
        receiverDetail.photo = firstMessage.senderphoto;
        receiverDetail.name = firstMessage.sendername;
      }

      setTimeout(() => {
        if (firstMessage.senderid) {
          setReceiverDetails({
            ...receiverDetail,
            requestReceivedFromSender: requestReceivedFromSender,
            requestAcceptedByReceiver: requestAcceptedByReceiver,
            iAmSender: iAmSender,
            iAmReceiver: iAmReceiver,
          });

          const chatMessages = document.querySelector(".chat-container");
          chatMessages.scrollTop = chatMessages.scrollHeight;
        } else {
          setReceiverDetails({});
        }
      }, 100);
    } else {
      setTimeout(() => {
        setReceiverDetails({});
      }, 100);
    }
  }, [firstMessage, users]);

  const getUsers = (userId) => {
    db.ref(`users/${userId}`).on("value", function (snapshot) {
      const user = snapshot.val();
      let chatUsers = {[userId]: user };
      let acceptedAndPendingUserIds = [];

      if (user?.chatRequestAcceptedUserIds) {
        const requestUserIds = user.chatRequestAcceptedUserIds.filter(v => Boolean(v));
        acceptedAndPendingUserIds.push(...requestUserIds);
        requestUserIds.forEach(id => {
          db.ref(`users/${id}`).on("value", function (snapshot) {
            chatUsers[id] = snapshot.val()
          });
        });
      }

      let pendingIds = [];

      if (user?.pendingInviteUserIds) {
        pendingIds = Object.keys(user.pendingInviteUserIds)?.map(v => user.pendingInviteUserIds[v]);
        acceptedAndPendingUserIds.push(...pendingIds);
        pendingIds.forEach(id => {
          db.ref(`users/${id}`).on("value", function (snapshot) {
            chatUsers[id] = snapshot.val()
          });
        });

      }
      
      setPendingUserIds(pendingIds);
      setChatUserIds(acceptedAndPendingUserIds);
      setUsers(chatUsers);
    });
  };

  const retrieveMessageIds = () => {
    return chatUserIds.map(id => {
      if (id > userDetails.id) {
        return `${userDetails.id}-${id}`;
      }
      return `${id}-${userDetails.id}`;
    })
  }

  const getData = () => {
    const messageIds = retrieveMessageIds();
    let messageList = [];

    messageIds.forEach(messageId => {
      db.ref(`lastMessages/${messageId}`).on("value", function (snapshot) {
        const snap = snapshot.val();

        if (!snap) return;

        const key = Object.keys(snap)[0];
        const index = messageList.findIndex((msg) => msg.collection === messageId);

        if (index !== -1) {
          messageList[index] = checkInvitation({
            ...snap,
            collection: messageId,
          }, chatUserIds);
        } else {
          messageList.push(checkInvitation({
            ...snap,
            collection: messageId,
          }, chatUserIds));
        }

        messageList = messageList.sort((x, y) => {
          return x.timestamp - y.timestamp;
        });

        setMessages([...messageList.reverse()]);
      });
    })
  };

  const getChatData = (msg, key, collection) => {
    const rootRef = db.ref();

    const lastRef = rootRef.child(`messages/${currentCollection}`);
    lastRef.off("value");

    let user;
    const urlRef = rootRef.child(`messages/${collection}`);
    urlRef.on("value", function (snapshot) {
      if (snapshot.val() === null) return;

      let chatList = [];
      snapshot.forEach(function (childSnapshot) {
        chatList.push(childSnapshot.val());
        setChats(chatList);
        setCurrentCollection(collection);
        setFirstMessage(chatList[0]);
      });
    });

    setActiveChat(key);

    // const messagesRef = rootRef.child(`messages/${msg.collection}`);
    // messagesRef.push({
    //   receiverid: msg.receiverid.toString(),
    //   receivername: msg.receivername,
    //   receiverphoto: msg.receiverphoto,
    //   text: msg.text,
    //   senderid: msg.senderid.toString(),
    //   sendername: msg.sendername,
    //   senderphoto: msg.senderphoto,
    //   timestamp: msg.timestamp,
    //   isread: true,
    // });

    const messageRef = rootRef.child(`messages/${msg.collection}`);

    messageRef
      .orderByChild("timestamp")
      .equalTo(msg.timestamp)
      .once("value", function (snapshot) {
        if (snapshot.val() === null) return;
        snapshot.forEach(function (child) {
          child.ref.update({ isread: true });
        });
      });

    const lastMessageRef = rootRef.child(`lastMessages/${msg.collection}`);
    lastMessageRef.set({
      receiverid: msg.receiverid.toString(),
      receivername: msg.receivername,
      receiverphoto: msg.receiverphoto,
      text: msg.text,
      senderid: msg.senderid.toString(),
      sendername: msg.sendername,
      senderphoto: msg.senderphoto,
      timestamp: msg.timestamp,
      isread: true,
    });
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    if (!text) {
      return false;
    }
    const rootRef = db.ref();
    const urlRef = rootRef.child(`messages/${receiverDetails.collection}`);
    const newMessage = {
      receiverid: receiverDetails.id.toString(),
      receivername: receiverDetails.name,
      receiverphoto: receiverDetails.photo,
      text: text,
      senderid: userDetails.id.toString(),
      sendername: userDetails.name,
      senderphoto: userDetails.profile_pic,
      timestamp: Date.now().toString(),
      isread: false,
    };

    urlRef.push(newMessage);

    const lastMessageRef = rootRef.child(
      `lastMessages/${receiverDetails.collection}`
    );
    lastMessageRef.set(newMessage);

    setText("");
    dispatch({
      type: SET_INITIALIZE_CHAT_DETAILS,
      payload: null,
    });
    localStorage.removeItem("initialize-chat");

    const data = {
      title: userDetails.name,
      message: text,
      sender_id: userDetails.id.toString(),
      type: "chat",
      receiver_id: receiverDetails.id.toString(),
    };

    dispatch(onChatNotification(data));
  };

  const checkInvitation = (chat, userIds) => {
    if (chat) {
      if (chat.senderid === userDetails.id) {
        return chat;
      }

      if (!pendingUserIds.includes(chat.senderid)) {
        chat.invite = false;
      } else {
        chat.invite = true;
      }
      return chat;
    }
  };

  const findUserFromUserIds = (oppositeUser) => {
    const data = users[oppositeUser]?.chatRequestAcceptedUserIds;
    let chatUsers = [];
    if (data) {
      var keys = Object.values(data);
      for (var i = 0; i < keys.length; i++) {
        chatUsers.push(keys[i]);
      }
    }

    return chatUsers;
  };

  const onAccept = (inviteDetails) => {
    try {
      const rootRef = db.ref();
      const urlRef = rootRef.child(`messages/${inviteDetails.collection}`);
  
      urlRef
        .orderByChild("timestamp")
        .equalTo(inviteDetails.timestamp)
        .once("value", function (snapshot) {
          snapshot.forEach(function (child) {
            child.ref.update({ isread: true });
          });
        });
  
      const landlordRef = rootRef.child(`users/${userDetails.id}`);
  
      landlordRef.once("value").then((snapshot) => {
        const landlordData = snapshot.val();
        let numChildren = landlordData.chatRequestAcceptedUserIds?.length || 0;
    
        landlordRef.child('chatRequestAcceptedUserIds').child(`${(numChildren + 1)}`).set(inviteDetails.senderid.toString());
  
        const index = Object.keys(landlordData.pendingInviteUserIds).find((key) => landlordData.pendingInviteUserIds[key] === inviteDetails.senderid);
        
        delete landlordData.pendingInviteUserIds[index];
        landlordRef.child(`pendingInviteUserIds`).set(landlordData.pendingInviteUserIds);
      });
  
      const senderRef = rootRef.child(`users/${inviteDetails.senderid}`);
  
      senderRef.once("value").then((snapshot) => {
        const senderData = snapshot.val();
        let numChildren = senderData.chatRequestAcceptedUserIds?.length || 0;
  
        senderRef.child(`chatRequestAcceptedUserIds`).child(`${(numChildren + 1)}`).set(`${userDetails.id}`);
  
        const index = Object.keys(senderData.pendingInviteUserIds || {}).find((key) => {
          return senderData.pendingInviteUserIds[key] === `${userDetails.id}`
        });
  
        delete senderData.pendingInviteUserIds[index];
        senderRef.child(`pendingInviteUserIds`).set(senderData.pendingInviteUserIds);
      });

      const updatedPendingUsers = pendingUserIds.filter((id) => id !== inviteDetails.senderid);

      setPendingUserIds([...updatedPendingUsers]);
    } catch (error) {
      console.log(error);
    }
  };

  const onDecline = (inviteDetails) => {
    try {
      const rootRef = db.ref();
  
      // Remove pending user id
      const landlordRef = rootRef.child(`users/${userDetails.id}`);
      landlordRef.once("value").then((snapshot) => {
        const landlordData = snapshot.val();
        const index = Object.keys(landlordData.pendingInviteUserIds).find((key) => landlordData.pendingInviteUserIds[key] === inviteDetails.senderid);
        delete landlordData.pendingInviteUserIds[index];
        landlordRef.child(`pendingInviteUserIds`).set(landlordData.pendingInviteUserIds);
      });
  
      const senderRef = rootRef.child(`users/${inviteDetails.senderid}`);
      senderRef.once("value").then((snapshot) => {
        const senderData = snapshot.val();
        const index = Object.keys(senderData.pendingInviteUserIds).find((key) => senderData.pendingInviteUserIds[key] === `${userDetails.id}`);
        delete senderData.pendingInviteUserIds[index];
        senderRef.child(`pendingInviteUserIds`).set(senderData.pendingInviteUserIds);
      });
  
      setPendingUserIds([...pendingUserIds.filter((id) => id !== inviteDetails.senderid)]);
      setChatUserIds([...chatUserIds.filter((id) => id !== inviteDetails.senderid)]);
      setUsers([...Object.keys(users).filter((id) => id !== inviteDetails.senderid)]);
  
      rootRef.child(`messages/${inviteDetails.collection}`).remove();
      rootRef.child(`lastMessages/${inviteDetails.collection}`).remove();
  
      const data = {
        title: "Request Declined",
        message:
          "Your request has been declined by Property host. Please try with another property or follow the procedure for booking.",
        sender_id: inviteDetails.receiverid,
        type: "chat",
        receiver_id: inviteDetails.senderid,
        image: userDetails.profile_pic,
      };
  
      dispatch(onChatNotification(data));
  
      if (inviteDetails.collection === currentCollection) {
        setReceiverDetails({});
        setChats([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const onInputSearch = (e) => {
    e.preventDefault();

    if (searchInput !== "") {
      const filteredData = messages.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(messages);
    }
  };

  return (
    <div>
      <LandlordBar />
      <div className="container">
        <div className="content-wrapper">
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card m-0">
                <div className="mob-search-box">
                  <form onSubmit={onInputSearch} className="input-group">
                    <input
                      className="form-control"
                      placeholder="Search"
                      onChange={onSearchChange}
                    />
                    <div className="input-group-btn">
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="row no-gutters">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3">
                    <div className="users-container">
                      <div className="chat-search-box">
                        <form onSubmit={onInputSearch} className="input-group">
                          <input
                            className="form-control"
                            placeholder="Search"
                            onChange={onSearchChange}
                          />
                          <div className="input-group-btn">
                            <button type="submit" className="btn btn-primary">
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                      <ul className="users">
                        {searchInput.length > 1
                          ? filteredResults.map((msg, key) => (
                              // active-user
                              <li
                                className={`person ${
                                  currentCollection == msg.collection &&
                                  "active-user"
                                }`}
                                data-chat="person1"
                                onClick={() =>
                                  getChatData(msg, key, msg.collection)
                                }
                              >
                                <div className="user">
                                  <img
                                    src={
                                      msg.senderid == userDetails.id
                                        ? msg.receiverphoto
                                        : msg.senderphoto
                                    }
                                    alt="image"
                                  />
                                  {/* <span
                                    className={`status ${checkStatus(
                                      msg.senderid == userDetails.id
                                        ? msg.receiverid
                                        : msg.senderid
                                    )}`}
                                  ></span> */}
                                </div>
                                <p className="name-time">
                                  <span className="name">
                                    {msg.senderid == userDetails.id
                                      ? msg.receivername
                                      : msg.sendername}
                                  </span>

                                  {msg.invite ? (
                                    <div className="actions">
                                      <div
                                        className="btn-accept"
                                        onClick={() => onAccept(msg)}
                                      >
                                        Accept
                                      </div>
                                      <div
                                        className="btn-decline"
                                        onClick={() => onDecline(msg)}
                                      >
                                        Decline
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {msg.isread === false ? (
                                        <span className="timeNotRead">
                                          {msg?.text?.length < 45
                                            ? msg?.text
                                            : `${msg.text.substring(0, 45)}...`}
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {msg?.text?.length < 45
                                            ? msg?.text
                                            : `${msg.text.substring(0, 45)}...`}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </p>
                              </li>
                            ))
                          : messages.map((msg, key) => (
                              // active-user
                              <li
                                key={key}
                                className={`person ${
                                  currentCollection == msg.collection &&
                                  "active-user"
                                }`}
                                data-chat="person1"
                                onClick={() =>
                                  getChatData(msg, key, msg.collection)
                                }
                              >
                                <div className="user">
                                  <img
                                    src={
                                      msg.senderid == userDetails.id
                                        ? msg.receiverphoto
                                        : msg.senderphoto
                                    }
                                    alt="image"
                                  />

                                  {/* <span
                                    className={`status ${checkStatus(
                                      msg.senderid == userDetails.id
                                        ? msg.receiverid
                                        : msg.senderid
                                    )}`}
                                  ></span> */}
                                </div>
                                <p className="name-time">
                                  <span className="name">
                                    {msg.senderid == userDetails.id
                                      ? msg.receivername
                                      : msg.sendername}
                                  </span>

                                  {msg.invite ? (
                                    <div className="actions">
                                      <div
                                        className="btn-accept"
                                        onClick={() => onAccept(msg)}
                                      >
                                        Accept
                                      </div>
                                      <div
                                        className="btn-decline"
                                        onClick={() => onDecline(msg)}
                                      >
                                        Decline
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {msg.isread === false ? (
                                        <span className="timeNotRead">
                                          {msg?.text?.length < 45
                                            ? msg?.text
                                            : `${msg.text.substring(0, 45)}...`}
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {msg?.text?.length < 45
                                            ? msg?.text
                                            : `${msg.text.substring(0, 45)}...`}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </p>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-9">
                    <div className="selected-user">
                      <span>
                        To: <span className="name">{receiverDetails.name}</span>
                      </span>
                    </div>
                    <div className="chat-container">
                      <ul className="chat-box chatContainerScroll">
                        {/* {checkInvitation(chats[0]) ? (
                          <ChatInvitation
                            chats={chats}
                            onAccept={onAccept}
                            onDecline={onDecline}
                          />
                        ) : ( */}
                        {chats &&
                          chats.map((msg, key) =>
                            msg.receiverid == userDetails.id ? (
                              <li
                                id={msg.receiverid}
                                className="chat-left"
                                key={key}
                              >
                                <div className="chat-avatar">
                                  <img src={msg.senderphoto} alt="image" />
                                  <div className="chat-name">
                                    {msg.sendername.split(" ")[0]}
                                  </div>
                                </div>
                                <div className="chat-text">
                                  <p>{msg.text}</p>
                                </div>
                                <div className="chatLeft-hour">
                                  {moment(parseInt(msg.timestamp)).format(
                                    "hh:mm A"
                                  )}
                                  &nbsp;
                                  <span className="fa fa-check-circle"></span>
                                  &nbsp;
                                  {moment(parseInt(msg.timestamp)).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </div>
                              </li>
                            ) : (
                              <li
                                key={key}
                                id={msg.senderid}
                                className="chat-right sender"
                              >
                                <div className="chatRight-hour">
                                  {moment(parseInt(msg.timestamp)).format(
                                    "hh:mm A"
                                  )}
                                  &nbsp;
                                  <span className="fa fa-check-circle"></span>
                                  &nbsp;
                                  {moment(parseInt(msg.timestamp)).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </div>
                                <div className="chat-text">
                                  <p>{msg.text}</p>
                                </div>
                                <div className="chat-avatar">
                                  <img src={msg.senderphoto} alt="image" />
                                  <div className="chat-name">
                                    {msg?.sendername?.split(" ")[0]}
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                    <div className="chat-input">
                      {receiverDetails.iAmReceiver === true ? (
                        receiverDetails.requestReceivedFromSender ===
                        true ? null : (
                          <form onSubmit={onSendMessage}>
                            <div className="form-group mt-3 mb-0 d-flex">
                              <input
                                className="form-control"
                                rows="1"
                                placeholder="Type your message here..."
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                              />

                              {!text ? (
                                <button className="btn btn-disabled">
                                  Send
                                </button>
                              ) : (
                                <input
                                  className="btn btn-primary mx-2"
                                  type="submit"
                                  value="Send"
                                />
                              )}
                            </div>
                          </form>
                        )
                      ) : null}

                      {receiverDetails.iAmSender === true ? (
                        receiverDetails.requestAcceptedByReceiver ===
                        false ? null : (
                          <form onSubmit={onSendMessage}>
                            <div className="form-group mt-3 mb-0 d-flex">
                              <input
                                className="form-control"
                                rows="1"
                                placeholder="Type your message here..."
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                              />

                              {!text ? (
                                <button className="btn btn-disabled">
                                  Send
                                </button>
                              ) : (
                                <input
                                  className="btn btn-primary mx-2"
                                  type="submit"
                                  value="Send"
                                />
                              )}
                            </div>
                          </form>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

// const createNewUserFirebase = () => {
//   const rootRef = db.ref();
//   const urlRef = rootRef.child(`users/${1111}`);
//   urlRef.set({
//     id: "134",
//     isOnline: true,
//     name: "Gunjan",
//     photo: "http://officeshare-cms.ewtlive.in/user-profile/...",
//   });
// };

// const setUserOnline = () => {
//   db.ref(`test`).on("value", function (snapshot) {
//     const rootRef = db.ref();
//     const urlRef = rootRef.child(`users/${userDetails.id}`);
//     urlRef.update({
//       isOnline: true,
//     });
//   });
// };

// const setUserOffline = () => {
//   const rootRef = db.ref();
//   const urlRef = rootRef.child(`users/${userDetails.id}`);
//   urlRef.onDisconnect("users").update({
//     isOnline: false,
//   });
// };

// const checkStatus = (id) => {
//   let lastStatus = "offline";
//   // if (timer) {
//   //   setTimeout(() => {
//   //     setTimer(false);
//   //   }, 10000);

//   //   db.ref(`users/${id}`).on("value", function (snapshot) {
//   //     const user = snapshot.val();
//   //     console.log(user);
//   //     if (user.isOnline) {
//   //       lastStatus = "online";
//   //     } else {
//   //       lastStatus = "offline";
//   //     }
//   //   });
//   // } else {
//   //   setTimer(true);
//   // }
//   return lastStatus;
// };
