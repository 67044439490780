import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEarningReport } from "../../../store/homeAction";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import EarningWithdrawPopup from "../../../popups/EarningWithdrawPopup";
import "./myEarning.scss";
import moment from "moment";
import LandlordBar from "../../../components/landlordBar/LandlordBar";

const MyEarning = () => {
  const dispatch = useDispatch();
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const earningReport = useSelector((state) => state.home.earningReport);
  const isLoading = useSelector((state) => state.home.isLoading);

  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    onGetEarningReport();
  }, []);

  useEffect(() => {
    setTotalPageCount(earningReport?.total_page_count);
  }, [earningReport]);

  const onGetEarningReport = () => {
    const formData = new FormData();

    formData.append("user_id", userDetails.id);
    formData.append("page", currentPage);
    formData.append("limit", 10);
    formData.append("status", "");
    formData.append("type", "earning");
    formData.append("date", "");

    dispatch(getEarningReport(formData));
  };

  useEffect(() => {
    onGetEarningReport();
  }, [currentPage]);

  const getPagination = () => {
    const pagination = [];
    for (let i = 1; i < totalPageCount; i++) {
      const element = totalPageCount[i];
      let page = {
        active: currentPage === i ? true : false,
        pageNumber: i,
      };
      pagination.push(page);
    }

    return pagination;
  };

  return (
    <div className="my-earning-main-wrapper">
      <LandlordBar />
      {!earningReport ? (
        <div className="py-5">
          <Loading />
        </div>
      ) : (
        <div className="container my-earning-wrapper">
          <div className="top-section">
            <div className="top-left-section">
              <div className="total">
                <h5 className="text-primary">
                  &#8356; {earningReport?.total_earning.toFixed(2)}
                </h5>
                <p className="m-0">Total Earnings</p>
              </div>
              <div className="total">
                <h5 className="text-primary">
                  &#8356; {earningReport?.amount_on_hold.toFixed(2)}
                </h5>
                <p className="m-0">Amount to Hold</p>
              </div>
              <div className="total">
                <h5 className="text-primary">
                  &#8356; {earningReport?.withdrawal_request}
                </h5>
                <p className="m-0">Total Pending Withdrawals</p>
              </div>
              <div className="total">
                <h5 className="text-primary">
                  &#8356; {earningReport?.to_be_withdrawal.toFixed(2)}
                </h5>
                <p className="m-0">Can be Withdrawn</p>
              </div>
            </div>
            <div className="top-right-section">
              <div
                className="button-withdrawal"
                onClick={() => setOpenWithdrawModal(true)}
              >
                Withdraw
              </div>
            </div>
          </div>
          <div className="bottom-section fade-in-bottom pb-5">
            {earningReport?.data?.length > 0 ? (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Title</th>
                      <th>Booking Date</th>
                      <th className="location-td">Location Name</th>
                      <th>Space Name</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {earningReport?.data?.map((item) => (
                      <tr
                      //  className="tr-hold"
                      >
                        <td>{item?.buyer_name}</td>
                        <td>{item?.meta?.title}</td>
                        <td>{moment(item?.created_at).format("YYYY-MM-DD")}</td>
                        <td>{item?.property_title}</td>
                        <td>{item?.space_title}</td>
                        <td> &#8356; {item?.amount}</td>
                        <td>
                          {item?.status === "success" ||
                          item?.status === "payment_success" ? (
                            <div className="btn-status status-success">
                              Success
                            </div>
                          ) : item?.status === "pending" ? (
                            <div className="btn-status status-pending">
                              Pending
                            </div>
                          ) : item?.status === "hold" ? (
                            <div className="btn-status status-hold">Hold</div>
                          ) : item?.status === "commission_charge" ? (
                            <div className="btn-status status-pending">
                              Commission
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))}

                    {/* <tr>
                <td>Jacky Smith</td>
                <td>07/02/2022</td>
                <td>
                  The industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled.
                </td>
                <td>The Exosy House</td>
                <td> &#8356; 700</td>
                <td>
                  <div className="btn-status status-pending">Pending</div>
                </td>
              </tr>
              <tr>
                <td>Jacky Smith</td>
                <td>07/02/2022</td>
                <td>
                  When an unknown printer took a galley of type and scrambled it
                  to make a type specimen book.
                </td>
                <td>The Property</td>
                <td> &#8356; 500</td>
                <td>
                  <div className="btn-status status-success">Success</div>
                </td>
              </tr> */}
                  </tbody>
                </table>

                <div
                  className="btn-toolbar py-3 float-right"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="First group"
                  >
                    {currentPage > 1 && (
                      <button
                        type="button"
                        className={`btn  btn-light`}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Prev
                      </button>
                    )}

                    {getPagination().map(
                      (page) =>
                        currentPage + 5 > page.pageNumber &&
                        currentPage - 5 < page.pageNumber && (
                          <button
                            type="button"
                            className={`btn ${
                              currentPage === page.pageNumber
                                ? "btn-primary"
                                : "btn-light"
                            }`}
                            onClick={() => setCurrentPage(page.pageNumber)}
                          >
                            {page.pageNumber}
                          </button>
                        )
                    )}

                    {totalPageCount > currentPage + 1 && (
                      <button
                        type="button"
                        className={`btn  btn-light`}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-5 my-5 text-center w-100">
                No Earning Available
              </div>
            )}
          </div>
        </div>
      )}
      <EarningWithdrawPopup
        open={openWithdrawModal}
        onClose={() => setOpenWithdrawModal(false)}
      />
    </div>
  );
};

export default MyEarning;
