import React from "react";
import "./verification.scss";

const Verfication = () => {
  return (
    <div className="verification-wrapper">
      <div className="container">
        <div className="verification-section">
          <h2>
            Enter your <br /> verfication code
          </h2>
          <form>
            <div className="input-group">
              <input type="text" placeholder="Enter Verification Code" />
            </div>
            <div className="input-group">
              <button type="submit" className="btn-submit">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verfication;
