import React, { useState, useEffect } from "react";
import { createFileLink } from "../../store/homeAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const OnboardingStepThree = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({});
  const [stripeAccountDetails, setStripeAccountDetails] = useState({});

  function gotoNextStep() {
    history.push("/add-property/1");
  }

  useEffect(() => {
    const stripeAccountDetailsLS = JSON.parse(
      localStorage.getItem("os-stripe-account-details")
    );
    setStripeAccountDetails(stripeAccountDetailsLS);
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onUploadFile = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("purpose", "account_requirement");

    console.log('StepThree formData CreateFileLink' . formData);

    dispatch(createFileLink(formData));
  };

  return (
    <div>
      <div className="onboarding-main step-three">
        <div className="left"></div>
        <div className="right">
          <div className="tips-wrapper">
            <div className="tips-description">
              <p className="title">
                Tips<span className="text-primary">:</span>
              </p>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                nesciunt id et earum, nobis tempore?
              </p>
              <i className="fa fa-chevron-down text-primary down-arrow-icon"></i>
            </div>
            <div className="identification-div">
              <p className="title">Acceptable forms of indentification:</p>
              <p className="identity">Passport</p>
              <p className="identity">Driver Licence</p>
              <p className="identity">Resident Permit</p>
              <p className="identity">Citizen Card</p>
              <p className="identity">Electoral ID</p>
              <p className="identity">Validate UK</p>
            </div>

            <div className="identification-div border-bottom-none">
              <p className="title">Acceptable forms of address verification:</p>

              <p className="identity">Driver Licence</p>
              <p className="identity">Utility Bills</p>
              <p className="identity">Statement from a financial institution</p>
              <p className="identity">Government issued letter</p>
              <p className="identity">Solicitors letter</p>
              <p className="identity">
                Council or housing association documentation
              </p>
              <p className="identity">Benefits Agency documentation</p>
              <p className="identity">Electoral Register entry</p>
              <p className="identity">NHS Medical Card</p>
              <p className="identity">
                GP letter of registration with the surgery
              </p>
            </div>
          </div>
        </div>
        <div className="onboarding-background no-background"></div>
        <div className="container">
          <div className="onboarding-wrapper">
            <div className="left-section">
              <div className="step-progress">
                <p className="text-text">
                  <b> Step 3:</b> Varifying your identity
                </p>
                <div className="progress-bar">
                  <div className="fill" style={{ width: "40%" }}></div>
                </div>
              </div>
              <div className="identity-wrapper">
                <p className="identity-title">
                  How would you like to varify your identity?
                </p>
              </div>

              <div className="row add-bank-form">
                <div className="col-md-8 my-5">
                  <form
                  //   onSubmit={onAddBankAccount}
                  >
                    {stripeAccountDetails &&
                    stripeAccountDetails.type === "business" ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="routing_number">Upload</label>
                          <input
                            type="file"
                            name="routing_number"
                            value={values.routing_number}
                            onChange={onUploadFile}
                            required
                          />
                        </div>

                        <div className="btn-wrapper pt-3">
                          <button type="submit" className="button">
                            Continue
                          </button>
                        </div>
                      </>
                    ) : null}
                  </form>
                </div>
              </div>
              {/* <div className="identity-wrapper">
                <p className="identity-title">
                  How would you like to varify your identity?
                </p>
                <div className="indentity-btn-wrapper">
                  <div className="btn-wrapper">
                    <div className="indentity-button">Scan Bank Card</div>
                    <div className="link">Enter Manually</div>
                  </div>

                  <span>or</span>
                  <div className="link-wrapper">
                    <div className="icon">
                      <img src="./assets/images/card.png" alt="" />
                    </div>
                    <div className="link">
                      Back to other forms of identification
                    </div>
                  </div>
                </div>
              </div>
              <div className="business-verification-wrapper">
                <p className="title">Business Verification:</p>
                <p className="detail">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Voluptas eveniet maxime in nesciunt dicta soluta alias ad
                  repellat optio doloremque fugiat voluptate velit autem facere
                  consequuntur quos maiores, consectetur iusto! Deleniti,
                  possimus sequi, modi officia quibusdam animi velit neque
                  accusantium quisquam reiciendis laboriosam magni tempore id
                  atque delectus maiores saepe!
                </p>
                <p className="title">Company Registration No.</p>
                <p className="detail">
                  ( Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Adipisci, repellat? )
                </p>
                <div className="pb-3">
                  <span className="title">VAT No.</span>{" "}
                  <span className="detail">(Check box - not register)</span>
                </div>

                <div>
                  <img src="./assets/images/authority.png" alt="" />{" "}
                  <span className="title mx-2">Charity exempt - </span>{" "}
                  <span className="detail">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Delectus, voluptatum.
                  </span>
                </div>
                
              </div> */}
            </div>
            <div className="right-section">
              <div className="tips-wrapper">
                <div className="tips-description">
                  <p className="title">
                    Tips<span className="text-primary">:</span>
                  </p>
                  <p className="description">
                    In order to verify you as a person or company who is going
                    to charge another OS member to share a space we need to
                    validate your identity. The below lists the forms of ID you
                    can use to do this. But one must be photographic and you may
                    be taken to a third party web url to do this. Please stick
                    with the process and have the verification documents at
                    hand. It’s really easy to do.
                  </p>
                  <i className="fa fa-chevron-down text-primary down-arrow-icon"></i>
                </div>
                <div className="identification-div">
                  <p className="title">Acceptable forms of indentification:</p>
                  <p className="identity">Passport</p>
                  <p className="identity">Driver Licence</p>
                  <p className="identity">Resident Permit</p>
                  <p className="identity">Citizen Card</p>
                  <p className="identity">Electoral ID</p>
                  <p className="identity">Validate UK</p>
                </div>

                <div className="identification-div border-bottom-none">
                  <p className="title">
                    Acceptable forms of address verification:
                  </p>

                  <p className="identity">Driver Licence</p>
                  <p className="identity">Utility Bills</p>
                  <p className="identity">
                    Statement from a financial institution
                  </p>
                  <p className="identity">Government issued letter</p>
                  <p className="identity">Solicitors letter</p>
                  <p className="identity">
                    Council or housing association documentation
                  </p>
                  <p className="identity">Benefits Agency documentation</p>
                  <p className="identity">Electoral Register entry</p>
                  <p className="identity">NHS Medical Card</p>
                  <p className="identity">
                    GP letter of registration with the surgery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer></Footer> */}
    </div>
  );
};

export default OnboardingStepThree;
