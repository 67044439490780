import React from "react";

const NotFound = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center flex-column"
      style={{ height: "446px" }}
    >
      <h1
        className="text-primary"
        style={{ fontSize: "60px", fontWeight: "bold" }}
      >
        404
      </h1>
      <h5 style={{ fontSize: "30px", fontWeight: "bold" }}>Page Not Found</h5>
    </div>
  );
};

export default NotFound;
