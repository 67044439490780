import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import smoothscroll from "smoothscroll-polyfill";
import IconDoubleArrowDown from '../../icons/icon-double-arrow-down';
import IconChevron from '../../icons/icon_chevron';
import FeatureSection from '../featuresSection/FeatureSection';
import { onSearch } from "../../store/homeAction";
import { useDispatch, useSelector } from "react-redux";
import './mainSection.scss';
import SearchPageMap from "../../components/searchPageMap/SearchPageMap";
import ReactGA from '../../utils/gaInit';

const content = {
  hero: {
    title: `Flex your space by day, 
    by desk, by night.`,
    welcome: 'Welcome to the OfficeShare revolution.',
    discover: 'Discover how it works',
  }
};

const redirect = (id) => {
  const layoutElement = document.getElementById(id);
  layoutElement.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "start",
  });
};

const MainSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const selectedFilters = useSelector((state) => state.home.selectedFilters);
  const searchResult = useSelector((state) => state.home.searchResult);
  const [selectedProperty, setSelectedProperty] = React.useState({});
  const searchLatLng = useSelector((state) => state.home.searchLatLng);

  let defaultProps = {
    center: {
      lat:
        (selectedProperty.location && selectedProperty.location.latitude) ||
        (searchLatLng && searchLatLng.lat) ||
        51.5074,
      lng:
        (selectedProperty.location && selectedProperty.location.longitude) ||
        (searchLatLng && searchLatLng.long) ||
        -0.1278,
    },
    zoom: 12,
  };

  const onSetCurrentSelectedProperty = (property) => {
    setSelectedProperty(property);
  };

  React.useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo(0, 0);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'OfficeShare | Homepage'
    });
  }, []);

  React.useEffect(() => {
    const data = selectedFilters;
    if (userDetails?.id) {
      data.user_id = userDetails.id;
    }
    dispatch(onSearch(data));
  }, [userDetails?.id]);

  return (
    <div>
      <section className="hero">
        <div className="hero--content">
          <h1 className="hero--title">{content.hero.title}</h1>
          <p className="hero--subtitle">{content.hero.welcome}</p>
          <p className="hero--discover" onClick={() => history.push("/how-it-works")}>{content.hero.discover}</p>
          <div className="hero--cta">
            <Link to={userDetails?.user_role_type === 'Landlord' ? '/add-property/1' : "/onboarding/2"} className="hero--cta__list btn-hero flex-column align-items-start">
              <p className="hovered--label text-white">Sign up as a host:</p>
              <div className="d-flex align-items-center">
                I want to <span>{` list a space`}</span> for rent
                <IconChevron size={12} color="#E35447" />
              </div>
            </Link>
            <Link to="/search" className="hero--cta__rent btn-hero flex-column align-items-start">
              <p className="hovered--label">Explore as a sharer</p>
              <div className="d-flex align-items-center">
                I am looking for a <span>{` space to rent`}</span>
                <IconChevron size={12} color="#E35447" />
              </div>
            </Link>
          </div>
        </div>
        <div className="cta--featured">
          <button type="button" className="cta--featured__btn" onClick={() => redirect('featured')}>
            <p>Featured Listing</p>
            <IconDoubleArrowDown />
          </button>
        </div>
      </section>
      <FeatureSection />
      <section className="explore">
        <div className="container explore--wrapper">
          <div className="d-flex flex-column align-items-center">
            <p className="landing--subtitle">Explore</p>
            <h2 className="landing--title explore--title">Explore available spaces at accessible spots</h2>
            <p className="landing--desc explore--desc">Find a rated, verified place (or places) for you and your colleagues - either on a desk only basis or book whole offices out exclusively on the days you want the team to be together. </p>
          </div>
          <div className="explore--map">
            <SearchPageMap
              isMarkerCircle
              customStyle={{ width: '100%', height: '577px'}}
              searchResult={searchResult}
              selectedProperty={selectedProperty}
              defaultProps={defaultProps}
              zoom={9}
              onSetCurrentSelectedProperty={onSetCurrentSelectedProperty}
            />
          </div>
          <div className="explore--spaces">
            <Link to="/search" className="explore--btn">
              Explore more spaces for rent
            </Link>
          </div>
        </div>
      </section>
      <section className="container landing-how">
        <div className="landing-how--group_img">
          <img src="/assets/images/pages/landing/circle-with-group-images.png" alt="group--img" />
        </div>
        <div className="landing-how--desc">
          <p className="landing--subtitle">How it works</p>
          <h2 className="landing--title">For Hosts & Sharers</h2>
          <p className="landing--desc">Think of OfficeShare as a marketplace akin to Airbnb or Uber, but for office spaces. We connect businesses with spare office capacity ('Hosts') with those in need of such spaces ('Sharers'). </p>
          <div className="learn-more--wrap">
            <Link to="/how-it-works" className="btn--learn-more">
              Learn More
            </Link>
          </div>
        </div>
      </section>
      <section className="calculator">
        <div className="container calculator">
          <div className="calculator--desc">
            <p className="landing--subtitle text-white">How it works</p>
            <h2 className="landing--title">Discover your earning potential</h2>
            <p className="landing--desc text-white">Use our OfficeShare calculator to learn how much extra income you could generate by using OfficeShare to optimise your vacant desks and spaces.</p>
            <div className="learn-more--wrap">
              <a href="https://officeshare.outgrow.us/OfficeSharePotentialRentalEarnings" target="_blank" rel="noopener noreferrer" className="btn--learn-more">
                Find Out More
              </a>
            </div>
          </div>
          <div className="calculator--calculator">
            <a href="https://officeshare.outgrow.us/OfficeSharePotentialRentalEarnings" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/pages/landing/highres/static-calculator.png" alt="static calculator" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainSection;
