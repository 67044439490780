import React from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const AmenitiesPopup = ({
  open,
  onClose,
  onSelectAmenities,
  amenities,
  filters,
  setAmenities,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "amenities-popup-modal" }}
    >
      <h5 className="title text-primary">Select Amenities</h5>
      <i
        className="fa fa-times removeIcon"
        onClick={() => {
          if (filters) {
            for (let i = 0; i < filters.length; i++) {
              if (filters[i].filer_title === "Amenities") {
                let filterList = filters[i].filter_list;
                filterList = filterList.map((item, key) => ({
                  ...item,
                  is_selected: false,
                }));

                setAmenities(filterList);
              }
            }
          }
          onClose();
        }}
        aria-hidden="true"
      ></i>
      <div className="amenities-wrapper">
        {amenities.map((item, index) => (
          <div
            key={index}
            className={`item ${item.is_selected ? "active" : ""}`}
            onClick={onSelectAmenities.bind(this, item.id)}
          >
            <img src={item.icon} alt={item.title+' Icon'}></img>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
      <div className="done-btn" onClick={onClose}>
        <img src="assets/images/done.png"></img> Done
      </div>
    </Modal>
  );
};

export default AmenitiesPopup;
