import React, { useState } from "react";
import "./popup.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendWithdrawRequest } from "../store/homeAction";
import { SET_CLOSE_WITHDRAW_MODAL } from "../store/types";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import AddBankAccountPopup from "./AddBankAccountPopup";
import { toast } from "react-toastify";
import { useEffect } from "react";

const EarningWithdrawPopup = ({ open, onClose, bookingDetails }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [amount, setAmount] = useState("");

  const withdrawLoading = useSelector((state) => state.home.withdrawLoading);
  const withdrawModalClosed = useSelector(
    (state) => state.home.withdrawModalClosed
  );

  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    if (withdrawModalClosed) {
      onClose();
      dispatch({ type: SET_CLOSE_WITHDRAW_MODAL, payload: false });
    }
  }, [withdrawModalClosed]);

  const onSendWithdrawRequest = () => {
    const formData = new FormData();

    formData.append("user_id", userDetails.id);
    formData.append("amount", amount);

    if (!amount) {
      toast("Amount required", {
        type: toast.TYPE.SUCCESS,
        autoClose: 10000,
      });
      return;
    }

    dispatch(sendWithdrawRequest(formData));
  };
  return (
    <Modal
      open={open}
      classNames={{ modal: "earning-withdraw-modal text-center" }}
      onClose={() => onClose()}
    >
      <div className="d-flex justify-content-between align-items-baseline pr-5">
        <h5 className="font-weight-bold m-0">Withdraw Funds</h5>
        {/* <p className="font-weight-bold text-primary m-0 cursor-pointer">
          Suggest Amount <i class="fa fa-caret-down"></i>
        </p> */}
      </div>
      <div className="pt-4 px-4 pb-2">
        <input
          type="number"
          className="form-control"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <div className="pt-4">
          {withdrawLoading ? (
            <div className="button-withdrawal">
              <span className="fa fa-spinner fa-spin px-4"></span>
            </div>
          ) : (
            <div className="button-withdrawal" onClick={onSendWithdrawRequest}>
              Withdraw
            </div>
          )}
        </div>
        <div
          className="add-new-bank-btn text-primary"
          onClick={() => setOpenAddBankAccountModal(true)}
        >
          Add New Bank
        </div>
      </div>
      <AddBankAccountPopup
        open={openAddBankAccountModal}
        onClose={() => setOpenAddBankAccountModal(false)}
      />
    </Modal>
  );
};

export default EarningWithdrawPopup;
