import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertySpaces,
  getPropertyById,
  getTermsConditions,
  landlordUserConnection,
  onChatNotification,
} from "../../store/homeAction";
import { SET_BOOK_SPACE_DETAILS } from "../../store/types";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import SwitchMap from "./SwitchMap";
import "./userBooking.scss";
import PropertyWithSpaceDetails from "./PropertyWithSpaceDetails";
import moment from "moment";
import SignatureCanvas from "./SignatureCanvas";
import DatesModalPopup from "../../popups/DatesModalPopup";
import TermsAndConditionsPopup from "../../popups/TermsAndConditionsPopup";
import db from "../../config";

const UserBookingFive = ({ match }) => {
  let signedPad = useRef({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const bookSpaceDetails = useSelector((state) => state.home.bookSpaceDetails);
  const termsConditions = useSelector((state) => state.home.termsConditions);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [terms, setTerms] = useState(false);
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  //console.log(spaceDetails);

  useEffect(() => {
    if (bookSpaceDetails) {
      const { daySelectedDates, nightSelectedDates } = bookSpaceDetails;
      const spaceList = propertySpaces;

      let price = 0;
      const spaceDetail = getSpaceDetail(spaceList);
      const { space_days_price_list } = spaceDetail;


      if (spaceDetail && space_days_price_list) {
        if (spaceDetail.availability_type === 1) {
          // for day
          for (let i = 0; i < daySelectedDates.length; i++) {
            const todayDayName = moment(daySelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].price);
              }
            }
          }
        } else if (spaceDetail.availability_type === 2) {

          // for night
          for (let i = 0; i < nightSelectedDates.length; i++) {
            const todayDayName = moment(nightSelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].night_price);
              }
            }
          }
        } else {

          // for day and night
          for (let i = 0; i < daySelectedDates.length; i++) {
            const todayDayName = moment(daySelectedDates[i])
              .format("dddd")
              .slice(0, 3);
            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].price);
              }
            }
          }

          for (let i = 0; i < nightSelectedDates.length; i++) {
            const todayDayName = moment(nightSelectedDates[i])
              .format("dddd")
              .slice(0, 3);

            for (let j = 0; j < space_days_price_list.length; j++) {
              if (todayDayName == space_days_price_list[j].day) {
                price = price + parseInt(space_days_price_list[j].night_price);
              }
            }
          }
        }
      }

      if(price <= 0) {
        price = parseInt(spaceDetail.price);
      }

      setCalculatedPrice(price);
    }
  }, []);

  useEffect(() => {
    getPropertyList();
    getSpaceList();
    dispatch(getTermsConditions({}));
  }, [match]);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);
        }
      }
    }
  }, [propertySpaces]);

  useEffect(() => {
    if (bookSpaceDetails === undefined) {
      history.push(`/space/${match.params.propertyId}/${match.params.spaceId}`);
    }
  }, []);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const getSpaceDetail = () => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          return spaceList[i];
        }
      }
    }
  };

  const onNext = () => {
    if (spaceDetails?.booking_approval === false) {
      if (!localStorage.getItem("signature")) {
        toast("Please Use the signature and continue", {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      } else if (terms === false) {
        toast("Please accept terms & conditions", {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
        return false;
      } else {
        history.push(
          `/userbooking/7/${match.params.propertyId}/${match.params.spaceId}`
        );
        dispatch({
          type: SET_BOOK_SPACE_DETAILS,
          payload: { ...bookSpaceDetails },
        });
      }
    } else {
      history.push(
        `/userbooking/7/${match.params.propertyId}/${match.params.spaceId}`
      );
      dispatch({
        type: SET_BOOK_SPACE_DETAILS,
        payload: { ...bookSpaceDetails },
      });
    }
  };

  const clearPad = () => {
    signedPad.current.clear();
    localStorage.removeItem("signature");
  };

  const savePad = () => {
    localStorage.setItem("signature", signedPad.current.toDataURL());
  };

  const sendRequest = (landlordDetails) => {
    // console.log("landlordDetails", landlordDetails);

    // const sendChatEmail = {
    //   user_id: landlordDetails.id.toString(),
    //   msg: "Hello I want to enquiry about your space!.",
    //   subject: "Hello I want to enquiry about your space!.",
    // };

    // dispatch(sendEnquiryEmail(sendChatEmail));

    const userConnection = {
      landlord_id: landlordDetails.id.toString(),
      user_id: userDetails.id.toString(),
    };
    dispatch(landlordUserConnection(userConnection));

    setIsLoading(true);
    let collection;
    if (parseInt(landlordDetails.id) < parseInt(userDetails.id)) {
      collection = `${landlordDetails.id}-${userDetails.id}`;
    } else {
      collection = `${userDetails.id}-${landlordDetails.id}`;
    }

    const rootRef = db.ref();
    const urlRef = rootRef.child(`messages/${collection}`);

    // Check if request already sent or not
    let exist = false;

    urlRef
      .orderByChild("senderid")
      .equalTo(userDetails.id.toString())
      .on("value", function (snapshot) {
        snapshot.forEach(function (child) {
          const chatDetails = child.val();
          // console.log(
          //   "chatDetails",
          //   chatDetails,
          //   chatDetails.senderid,
          //   userDetails.id.toString(),
          //   chatDetails.receiverid,
          //   landlordDetails.id.toString()
          // );
          if (
            chatDetails.senderid == userDetails.id.toString() &&
            chatDetails.receiverid == landlordDetails.id.toString()
          ) {
            exist = true;
            window.location.href = `#/chat/${userDetails?.id}`;
            return false;
          }
        });
      });

    const data =
      users[landlordDetails.id.toString()]?.chatRequestAcceptedUserIds;
    let chatUsers = [];
    if (data) {
      var keys = Object.values(data);
      for (var i = 0; i < keys.length; i++) {
        chatUsers.push(keys[i]);
      }
    }

    if (chatUsers.some((user) => user == userDetails.id.toString())) {
      exist = true;
      window.location.href = `#/chat/${userDetails?.id}`;
      return false;
    }

    setTimeout(() => {
      if (exist == false) {
        urlRef.push({
          propertyName: propertyListById.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          text: "Hello \nI want to enquire about your space.",
          timestamp: Date.now(),
        });

        const lastMessageRef = rootRef.child(`lastMessages/${collection}`);
        lastMessageRef.set({
          propertyName: propertyListById.property_title,
          isread: false,
          receiverid: landlordDetails.id.toString(),
          receivername: landlordDetails.name,
          receiverphoto: landlordDetails.profile_pic,
          // text: "Hello \nI want to enquiry about your space.",
          senderid: userDetails.id.toString(),
          sendername: userDetails.name,
          senderphoto: userDetails.profile_pic,
          timestamp: Date.now(),
        });

        window.location.href = `#/chat/${userDetails?.id}`;
      }

      setIsLoading(false);
    }, 1000);

    if (exist === false) {
      const pushData = {
        title: userDetails.name,
        message: "Hello I want to enquiry about your space!.",
        sender_id: userDetails.id.toString(),
        type: "chat",
        receiver_id: landlordDetails.id.toString(),
      };

      dispatch(onChatNotification(pushData));
    }
  };

  return (
    <>
      <DatesModalPopup
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        bookingDetails={bookSpaceDetails}
      />
      <TermsAndConditionsPopup
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        termsConditions={termsConditions}
      />
      <div className="user-detail-with-background">
        <div className="container mt-5">
          <div className="user-details-wrapper">
            <div className="user-booking">
              <div className="user-booking-main-wrapper">
                <div className="user-booking-main">
                  <div className="user-left">
                    <div className="user-left-top">
                      <Link
                        to={`/space/${match.params.propertyId}/${match.params.spaceId}`}
                        className="back-btn"
                      >
                        Back to space
                      </Link>

                      <div
                        className="Open-a-Chat"
                        onClick={() =>
                          sendRequest(propertyListById && propertyListById.user)
                        }
                      >
                        Open a Chat
                      </div>
                    </div>
                    {propertyListById && spaceDetails && (
                      <PropertyWithSpaceDetails
                        propertyListById={propertyListById}
                        spaceDetails={spaceDetails}
                      />
                    )}
                    <hr />

                    {/* <hr /> */}
                    <div className="user-middle">
                      {spaceDetails?.booking_approval === false ? (
                        <h6>Confirm & Pay</h6>
                      ) : (
                        <span className="d-flex align-items-center">
                          Availability Type:&nbsp;
                          <span className="text-primary">Enquire Only</span>
                        </span>
                      )}
                      <div className="user-edit">
                        <p>
                          {bookSpaceDetails?.numberOfDesks}{" "}
                          {bookSpaceDetails?.numberOfDesks === 1
                            ? "Desk"
                            : "Desks"}{" "}
                          <span>for</span>{" "}
                          {bookSpaceDetails?.daySelectedDates?.length > 0 &&
                            `${bookSpaceDetails?.daySelectedDates?.length} ${
                              bookSpaceDetails?.daySelectedDates?.length === 1
                                ? "Day"
                                : "Day(s)"
                            }`}
                          {bookSpaceDetails?.daySelectedDates?.length > 0 &&
                            bookSpaceDetails?.nightSelectedDates?.length > 0 &&
                            " and "}
                          {bookSpaceDetails?.nightSelectedDates?.length > 0 &&
                            `${bookSpaceDetails?.nightSelectedDates?.length} ${
                              bookSpaceDetails?.nightSelectedDates?.length === 1
                                ? "Night"
                                : "Night(s)"
                            } `}
                        </p>
                        <div>
                          <Link
                            to={`/userbooking/1/${match.params.propertyId}/${match.params.spaceId}`}
                          >
                            Edit
                          </Link>{" "}
                          <span className="text-primary">/</span>{" "}
                          <span
                            className="previewButton"
                            onClick={() => setModalOpen(true)}
                          >
                            Preview
                          </span>
                        </div>
                      </div>
                      <h6 className="desk-rate">
                        <span className="text-dark" data-calc_price={calculatedPrice} data-desks={bookSpaceDetails?.numberOfDesks}>Total Amount:</span> &#8356;
                        {(
                          calculatedPrice * bookSpaceDetails?.numberOfDesks
                        ).toLocaleString()}{" "}
                        <span style={{ fontSize: "12px", fontWeight: "800" }}>
                          +VAT
                        </span>
                      </h6>
                    </div>

                    {spaceDetails?.booking_approval === false ? (
                      <SignatureCanvas
                        clearPad={clearPad}
                        savePad={savePad}
                        signedPad={signedPad}
                      />
                    ) : null}

                    <div className="user-bottom">
                      {spaceDetails?.booking_approval === false ? (
                        <div className="form-group d-flex align-items-center">
                          <input
                            id="terms"
                            type="radio"
                            className="form-control-md"
                            onClick={() => setTerms(!terms)}
                            checked={terms}
                          />

                          <Link
                            className="four-title mx-1 my-0"
                            onClick={() => setTermsModalOpen(true)}
                            style={{ textDecoration: "underline" }}
                          >
                            {/* <label
                        htmlFor="terms"
                        className="four-title mx-1 my-0"
                      > */}
                            Accept Terms & Conditions
                            {/* </label> */}
                          </Link>
                        </div>
                      ) : null}

                      {spaceDetails?.booking_approval === false ? (
                        <div className="buttons">
                          <div onClick={onNext} className="one-btn btn">
                            Confirm & Pay
                          </div>
                        </div>
                      ) : (
                        <div className="buttons">
                          <div onClick={onNext} className="one-btn btn">
                            Submit Enquiry
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <SwitchMap
                    propertyListById={propertyListById}
                    spaceDetails={spaceDetails}
                  ></SwitchMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBookingFive;
