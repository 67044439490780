import React from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PrivateRoute from "../pages/notFound/PrivateRoute";
import SpaceAvailableDates from "../pages/spaceAvailableDates/SpaceAvailableDates";
import { Route } from 'react-router-dom';


const ManageAvailabilityPopup = ({ open, onClose, propertyId, spaceId }) => {
    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            classNames={{ modal: "manage-availability-popup" }}
        >
            <SpaceAvailableDates propertyId={propertyId} spaceId={spaceId} wrapper={false} onclose={onClose}/>
        </Modal>
    );
};

export default ManageAvailabilityPopup;
