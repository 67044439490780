import React from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  InfoBox,
} from "@react-google-maps/api";
import mapStyle from "./MapStyle";
const libraries = ["places"];

const styles = {
  input: "px-3 py-3 h-12 placeholder-indigo-500 w-full",
  h2: "py-4 text-3xl text-gray-600 font-normal text-left",
  map: "mt-6 rounded-md select-none overflow-hidden sm:w-1/2",
  mainDiv: "sm:flex justify-between -mt-6",
};

const containerStyle = {
  width: "100%",
  height: "350px",
};

const options = {
    disableDefaultUI: true,
    styles: mapStyle,
    draggable: true,
    draggableCursor: "pointer",
    draggingCursor: 'grabbing'
};

//COMPONENT
const Map = ({ location, propertyListById, customStyle = {}, zoom = 20 }) => {
  const [map, setMap] = React.useState(null);

  const handleOnLoad = () => {
    setMap(location);
  }

  return (
    <div className={styles.mainDiv}>
      <div className={styles.map}>
        <GoogleMap
          mapContainerStyle={{ ...containerStyle, ...customStyle }}
          center={location}
          zoom={zoom}
          onLoad={handleOnLoad}
          options={options}
        >
            <Marker
                icon={"assets/images/marker.png"}
                position={location}
                title={propertyListById?.property_title}
                draggable={true} // Add draggable property
                onDragEnd={(e) => {
                    const { latLng } = e;
                    const lat = latLng.lat();
                    const lng = latLng.lng();
                    // Handle the updated marker position here
                    console.log("New Marker Position:", lat, lng);
                }}
            >
            <InfoBox
              options={{
                maxWidth: 100,
                pixelOffset: new window.google.maps.Size(-100, -70),
                alignBottom: true,
                boxStyle: {
                  width: "200px",
                },
                closeBoxURL: "",
              }}
              anchor={true}
            >
              <div
                style={{
                  width: "200px",
                  height: "90px",
                  position: "relative",
                }}
                className="popup-bubble-anchor"
              >
                <div
                  style={{
                    fontSize: `10px`,
                    fontColor: `#000`,
                    fontFamily: "Poppins",
                    textAlign: "center",
                  }}
                  className="popup-bubble"
                >
                  <div className="text-primary font-weight-bold">
                    {propertyListById?.property_title}{" "}
                  </div>
                  {propertyListById?.address_line_one &&
                    `${propertyListById?.address_line_one}`}{" "}
                </div>
              </div>
            </InfoBox>
          </Marker>
        </GoogleMap>
      </div>
    </div>
  );
};

export default Map;
