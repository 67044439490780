import React, { useEffect, useRef, useState } from "react";
import "./blogDetails.scss";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBlogDetails } from "../../store/homeAction";
import VideoPopup from "../../popups/VideoPopup";
import Loading from "../../components/loading/Loading";

const BlogDetails = ({ match }) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const blogDetails = useSelector((state) => state.home.blogDetails);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  useEffect(() => {
    getBlogList();
  }, [match]);

  const getBlogList = () => {
    const data = {
      id: match.params.blogId,
    };
    dispatch(getBlogDetails(data));
  };

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <div className="article-details-wrapper" ref={myRef}>
          <div className="article-details">
            <Link to="/" className="back-home">
              Back to Home
            </Link>
            <h1 className="article-heading mb-2">
              {blogDetails && blogDetails.title}
            </h1>
            <h6 className="article-date">23.06.2021</h6>
            <hr />
            <img
              src={blogDetails && blogDetails.media}
              alt=""
              className="article-image1"
            />
            <p className="font-weight-bold">
              {blogDetails && blogDetails.short_description}
            </p>

            <p
              dangerouslySetInnerHTML={{
                __html: blogDetails && blogDetails.description,
              }}
            ></p>

            <div className="back-top" onClick={executeScroll}>
              Back to Top
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogDetails;
