import React from 'react';

const IconHamburger = ({ color = '#fff', size = 22, dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill={color}>
      <path d="M11.3231 11.605L18.1088 4.81252C18.2214 4.681 18.2802 4.51182 18.2736 4.33879C18.2669 4.16576 18.1951 4.00163 18.0727 3.87919C17.9503 3.75675 17.7861 3.68502 17.6131 3.67834C17.4401 3.67166 17.2709 3.73051 17.1394 3.84314L10.9106 10.0719L4.86063 4.02189C4.73181 3.89384 4.55757 3.82197 4.37594 3.82197C4.19431 3.82197 4.02006 3.89384 3.89125 4.02189C3.82681 4.0858 3.77567 4.16184 3.74076 4.24562C3.70586 4.3294 3.68789 4.41926 3.68789 4.51002C3.68789 4.60077 3.70586 4.69063 3.74076 4.77441C3.77567 4.85819 3.82681 4.93423 3.89125 4.99814L10.4981 11.605C10.6077 11.7142 10.756 11.7755 10.9106 11.7755C11.0653 11.7755 11.2136 11.7142 11.3231 11.605Z" fill="white"/>
      <path d="M11.3231 18.1913L18.1088 11.3988C18.2214 11.2672 18.2802 11.0981 18.2736 10.925C18.2669 10.752 18.1951 10.5879 18.0727 10.4654C17.9503 10.343 17.7861 10.2713 17.6131 10.2646C17.4401 10.2579 17.2709 10.3168 17.1394 10.4294L10.9106 16.6581L4.86063 10.6081C4.73181 10.4801 4.55756 10.4082 4.37594 10.4082C4.19431 10.4082 4.02006 10.4801 3.89125 10.6081C3.7632 10.7369 3.69133 10.9112 3.69133 11.0928C3.69133 11.2744 3.7632 11.4487 3.89125 11.5775L10.4981 18.1913C10.6077 18.3004 10.756 18.3617 10.9106 18.3617C11.0653 18.3617 11.2136 18.3004 11.3231 18.1913Z" fill="white"/>
    </svg>
  )
};

export default IconHamburger;