import Axios from '../store/axios';

const token = localStorage.getItem('access_token');
// const token_expiry = localStorage.getItem('access_token');

const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
};

export const postStripeVerificationSession = async (data) => {
    return Axios.post('/stripe-verification-session', data, config)
}