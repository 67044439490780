import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertySpaces, getPropertyById } from "../../store/homeAction";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { toast } from "react-toastify";
import PriceGridPopup from "../../popups/PriceGridPopup";
import Map from "../../components/map/Map";
import { SET_REDIRECT_URL_ON_ENQUIRE } from "../../store/types";
import NotFound from "../notFound/NotFound";
import moment from "moment";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import "./propertyDetails.scss";
import ReactGA from '../../utils/gaInit';

const libraries = ["places"];

const SpaceDetails = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const propertyListById = useSelector((state) => state.home.propertyListById);
  const propertySpaces = useSelector((state) => state.home.propertySpaces);
  const isLoading = useSelector((state) => state.home.isLoading);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [forSpaceTitle, setForSpaceTitle] = useState('');
  const [currentPrice, setCurrentPrice] = useState(0);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 51.5074,
      lng: -0.1278,
    },
    zoom: 12,
  });
  const userInfo = useSelector((state) => state.home.userDetails);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    getPropertyList();
    getSpaceList();
  }, [match]);

  useEffect(() => {
    if (spaceDetails?.space_title) {
      setForSpaceTitle(spaceDetails?.space_title)
    }
  }, [forSpaceTitle, spaceDetails]);

  // console.log({ outside: forSpaceTitle, location: location.pathname });

  useEffect(() => {
    if (forSpaceTitle) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: `Space | ${forSpaceTitle}`
      });
      console.log({ inside: forSpaceTitle });
    }
  }, [forSpaceTitle]);

  useEffect(() => {
    const spaceList = propertySpaces;
    if (spaceList) {
      for (let i = 0; i < spaceList.length; i++) {
        if (match.params.spaceId == spaceList[i].id) {
          setSpaceDetails(spaceList[i]);

          // Set current price
          const todayDayName = moment(new Date()).format("dddd").slice(0, 3);
          const space_days_price_list = spaceList[i].space_days_price_list;
          for (
            let j = 0;
            j < space_days_price_list && space_days_price_list.length;
            j++
          ) {
            if (todayDayName == space_days_price_list[j].day) {
              setCurrentPrice(space_days_price_list[j].price);
            }
          }
        }
      }
    }
  }, [propertySpaces]);

  useEffect(() => {
    if (propertyListById) {
      const data = {
        center: {
          lat: parseFloat(propertyListById.lang),
          lng: parseFloat(propertyListById.long),
        },
        zoom: 12,
      };
      setDefaultProps(data);
    }
  }, [propertyListById]);

  const getPropertyList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertyById(data));
  };
  const getSpaceList = () => {
    const data = {
      property_id: match.params.propertyId,
    };
    dispatch(getPropertySpaces(data));
  };

  const onNext = () => {
    if (!localStorage.getItem("os-user")) {
      toast("Please Login!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      dispatch({
        type: SET_REDIRECT_URL_ON_ENQUIRE,
        payload: `/space/${match.params.propertyId}/${match.params.spaceId}`,
      });
      history.push("/login");
      return false;
    } else {
      if (!userInfo?.is_verified) {
        toast("Please verify your email", {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      } else {
        history.push(
          `/userbooking/1/${match.params.propertyId}/${match.params.spaceId}`
        );
      }
    }
  };

  if (!isLoading && !propertyListById && !propertySpaces?.length) {
    return <NotFound />;
  }

  return (
    <div className="property-detail-with-background">
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        libraries={libraries}
        language="en"
        region="uk"
      >
        {isLoading ? (
          <div className="py-5 my-5">
            <Loading></Loading>
          </div>
        ) : (
          <div>
            <div className="container mt-5">
              <div className="property-details-wrapper fade-in-bottom">
                <div className="property-booking">
                  <div className="property-booking-main-wrapper">
                    <div className="property-booking-main">
                      <div className="left-section">
                        <Link
                          to={`/property/${match.params.propertyId}`}
                          className="back-btn"
                        >
                          Back to location
                        </Link>
                        <h3 className="property-booking-heading">
                          {propertyListById && propertyListById.property_title}
                        </h3>
                        <h1 className="space-title">
                          {spaceDetails && spaceDetails.space_title}
                        </h1>

                        <div className="office-type-wapper">
                          <div className="type">
                            {spaceDetails && spaceDetails.space_type}
                          </div>
                        </div>

                        <div className="desk-available">
                          <p>
                            Desks:{" "}
                            <span className="font-weight-bold mr-1">
                              {spaceDetails && spaceDetails.available_desk}
                            </span>
                            <span className="text-primary">|</span>
                            {spaceDetails &&
                            spaceDetails.cost_type === "range" ? (
                              <>
                                <span className="light-text mx-1">
                                  <span className="text-primary font-weight-bold ml-1">
                                    &#8356;
                                    {spaceDetails && spaceDetails.price_from}
                                  </span>
                                </span>
                                -
                                <span className="light-text">
                                  <span className="text-primary font-weight-bold mx-1">
                                    &#8356;
                                    {spaceDetails && spaceDetails.price_to}
                                  </span>
                                </span>
                              </>
                            ) : (
                              <span className="font-weight-bold ml-1">
                                &#8356;{spaceDetails && spaceDetails.price}
                              </span>
                            )}{" "}
                            pdpd
                          </p>
                        </div>

                        {/* <p className="price-availibility">
                          <span>Price per desk: </span>
                          <span className="light-text mx-1">
                            <span className="text-primary font-weight-bold ml-1">
                              &#8356;{spaceDetails && spaceDetails.price_from}
                            </span>
                          </span>
                          -
                          <span className="light-text">
                            <span className="text-primary font-weight-bold mx-1">
                              &#8356;{spaceDetails && spaceDetails.price_to}
                            </span>
                          </span>
                        </p> */}
                        {propertyListById &&
                        propertyListById?.availability_type === 2 ? (
                          <div className="light-text">
                            Discount available on off-peak times (nights and
                            weekends)
                          </div>
                        ) : propertyListById?.availability_type === 3 ? (
                          <div className="d-flex align-item-center space-smallSize">
                            <div className="light-text">
                              Nights 25% discount after 8pm
                            </div>
                            <span className="view-price-button-wrapper ml-3">
                              <span
                                className="view-price-button"
                                onClick={() => setOpen(true)}
                              >
                                View Price Grid
                              </span>
                            </span>
                          </div>
                        ) : null}

                        <div
                          className="property-button btn mt-4"
                          onClick={onNext}
                        >
                            {spaceDetails && spaceDetails.booking_approval === false ? (
                                <span>Book</span>
                            ) : (
                                <span>Enquire</span>
                            )}

                        </div>
                        <hr />
                        <div className="about-sharer">
                          <h6>Key Features</h6>
                          <div
                            className="sharer-para"
                            dangerouslySetInnerHTML={{
                              __html: spaceDetails && spaceDetails.key_feature,
                            }}
                          ></div>
                        </div>
                        <hr />
                        <div className="about-sharer">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6>About the Host</h6>

                            <Link
                              className="open-chat-btn"
                              to={`/chat/${userDetails?.id}`}
                              // onClick={() =>
                              //   sendRequest(bookingDetails?.landlord)
                              // }
                            >
                              Open a Chat
                            </Link>
                          </div>
                          {propertyListById && propertyListById.rating != 0 && (
                            <span className="sharer-rating">
                              {propertyListById && propertyListById.rating}{" "}
                              Rating{" "}
                            </span>
                          )}
                          <div className="sharer-para">
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  propertyListById &&
                                  propertyListById.about_sharer,
                              }}
                            ></p>
                          </div>
                        </div>
                        <hr />
                        <div className="about-sharer">
                          <h6>Refund Policy</h6>
                          <div className="sharer-para">
                            {spaceDetails?.booking_payment_refund}
                          </div>
                        </div>
                        <hr />
                        <div className="spaces-box">
                          <h6>Other Available Spaces</h6>
                          <b>
                            ({" "}
                            {propertyListById &&
                              propertyListById.property_title}{" "}
                            )
                          </b>
                          <div className="spaces-main">
                            {propertySpaces && propertySpaces.length > 1 ? (
                              propertySpaces.map(
                                (space, key) =>
                                  space.id != match.params.spaceId && (
                                    <div key={key} className="spaces-item">
                                      {" "}
                                      <Link
                                        to={`/space/${match.params.propertyId}/${space.id}`}
                                      >
                                        <img
                                          src={space && space.featured_image}
                                          alt=""
                                        />{" "}
                                      </Link>
                                      <div className="spaces-right">
                                        <Link
                                          to={`/space/${match.params.propertyId}/${space.id}`}
                                        >
                                          {space && space.space_title}
                                        </Link>
                                        <span>
                                          {space && space.available_desk} Desks
                                        </span>
                                        {space.booking_approval === false ? (
                                          <span>
                                            Availability:{" "}
                                            <span className="text-primary">
                                              Book & Pay Now
                                            </span>
                                          </span>
                                        ) : (
                                          <span>
                                            Availability:{" "}
                                            <span className="text-primary">
                                              Enquire Only
                                            </span>
                                          </span>
                                        )}
                                        <div className="button">
                                          {space && space.space_type}
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )
                            ) : (
                              <div className="d-flex py-3 align-items-center">
                                No more space available
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="property-button btn" onClick={onNext}>
                          Enquire
                        </div> */}
                      </div>

                      <div className="space-section">
                        <img
                          src={spaceDetails && spaceDetails.featured_image}
                          alt={spaceDetails && spaceDetails.space_title}
                          className="space-right-image"
                        />
                        <div className="right-map">
                          {propertyListById && (
                            <Map
                              location={{ lat: parseFloat(propertyListById?.lang), lng: parseFloat(propertyListById?.long) }}
                              propertyListById={propertyListById}/>
                          )}

                          <span className="address space-details text-right">
                            Location:{" "}
                            <span className="text-primary">
                              {propertyListById &&
                                propertyListById.property_title}
                            </span>
                            <br />
                            Space:{" "}
                            <span className="text-primary">
                              {spaceDetails && spaceDetails.space_title}
                            </span>
                            <br />
                            {propertyListById &&
                              propertyListById.address_line_one}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PriceGridPopup
              open={open}
              onClose={() => setOpen(!open)}
              priceList={spaceDetails && spaceDetails.space_days_price_list}
              spaceDetails={spaceDetails}
            />
          </div>
        )}
      </LoadScript>
    </div>
  );
};

export default SpaceDetails;

const Marker = () => {
  return (
    <img
      className="pointer"
      width="30px"
      src="assets/images/marker.png"
      style={{ transform: "translate(-50%, -50%)" }}
    />
  );
};
