import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_CURRENT_LOCATION } from "../../store/types";
import { geolocated } from "react-geolocated";

const GeoLocation = ({ keywordStore, coords }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_CURRENT_LOCATION,
      payload: coords,
    });
  }, [coords]);

  return <div></div>;
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(GeoLocation);
