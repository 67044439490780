/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer } from "react";
import "./popup.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { postStripeVerificationSession } from "../api/stripe";
import { getUserInfo } from "../api/user";
import { EventEmitter } from "../utils/EventEmitter";

function reducer(prevState, nextState) {
    return { ...prevState, ...nextState }
}

const KYCPopup = ({ isManualTrigger }) => {
    const userDetails = JSON.parse(localStorage.getItem("os-user"));

    const [kyc, setKyc] = useReducer(reducer, {
        isModalOpen: false,
        kycURL: null,
    });

    useEffect(() => {
        if (!isManualTrigger) {
            checkIfKycRequired();
        }

        EventEmitter.subscribe(EventEmitter.events.KycRequired, setKyc);
        EventEmitter.subscribe(EventEmitter.events.TriggerKycProcess, kycVerified);

        return () => {
            EventEmitter.unsubscribe(EventEmitter.events.KycRequired);
            EventEmitter.unsubscribe(EventEmitter.events.TriggerKycProcess);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("focus", handleRefetchUser);

        return () => {
            window.removeEventListener("focus", handleRefetchUser);
        }
    }, [kyc.isModalOpen]);

    const checkIfKycRequired = async () => {
        try {
            const { data } = await getUserInfo({ user_id: userDetails.id });
    
            if (!data.data.kyc_status) {
                kycVerified();
                return;
            }
        } catch (err) {
            // error fetching user info
        }
    }

    const handleRefetchUser = useCallback(async () => {
        if (kyc.isModalOpen) {
            try {
                const { data } = await getUserInfo({ user_id: userDetails.id });

                if (data.data.kyc_status) {
                    setKyc({
                        isModalOpen: false,
                        kycURL: null
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
    }, [kyc.isModalOpen]);
    
    const kycVerified = async () => {
        if (userDetails.is_verified) return;

        try {
            const kycParams = new URLSearchParams();
          
            kycParams.append("type", "document");
            kycParams.append("options[document][require_live_capture]", true);
            kycParams.append("options[document][require_matching_selfie]", true);
            kycParams.append("metadata[user_id]", userDetails?.id);

            const { data } = await postStripeVerificationSession(kycParams);

            setKyc({
                isModalOpen: true,
                kycURL: data.url,
            });
        } catch (err) {
            // console.log("🚀 ~ file: PlanBuySuccess.js:27 ~ kycVerified ~ err", err)
        }
    };
    
    const onVerify = () => {
        window.open(kyc.kycURL, '_blank');
        
        // user has clicked on the button to trigger the KYC process
        if (isManualTrigger) {
            setKyc({
                isModalOpen: false,
                kycURL: ''
            });
        }
    };

    return (
        <Modal
            open={kyc.isModalOpen}
            center={true}
            onClose={() => null}
            showCloseIcon={false}
            classNames={{ modal: "kyc-popup" }}
        >
            <div className='text-center'>
                <h4><strong>To get started, we'll need to verify your ID.</strong></h4>

                <p>OfficeShare requires a verified government-issued ID to proceed.</p>

                <div className="kyc-popup__button" onClick={onVerify}>
                    Verify your identify
                </div>

                <p className='small px-2'>You'll be redirected to Stripe to complete the verification process.</p>
            </div>
        </Modal>
    );
};

export default KYCPopup;
