import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePassword } from "../store/homeAction";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";

const ChangePasswordPopup = ({ open, onClose, userId }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [showCurrentPwd, setShowCurrentPwd] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      values.change_password.length >= 6 &&
      values.confirm_password.length >= 6
    ) {
      if (values.change_password === values.confirm_password) {
        const data = {
          user_id: userId || userDetails.id,
          current_password: values.current_password,
          change_password: values.change_password,
        };

        dispatch(changePassword(data, (value) => setIsLoading(value), () => onClose()),);

        setValues({});
      } else {
        toast("New password and confirm password does not match!", {
          type: toast.TYPE.ERROR,
          autoClose: 10000,
        });
      }
      // } else {
      //   toast("All fields are required!", {
      //     type: toast.TYPE.ERROR,
      //     autoClose: 10000,
      //   });
      // }
    } else {
      toast("Passwords length must be atleast 6 characters", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "change-popup-modal" }}
    >
      <h5 className="text-primary">Change Password</h5>
      <form onSubmit={onSubmit}>
        <div className="details py-4">
          <div className="user-item">
            <input
              type={showCurrentPwd ? "text" : "password"}
              value={values.current_password}
              name="current_password"
              onChange={onInputChange}
              placeholder="Current Password"
              required
            />
            {showCurrentPwd ? (
              <i
                className="fa fa-eye text-secondary cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowCurrentPwd(!showCurrentPwd)}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash text-secondary cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowCurrentPwd(!showCurrentPwd)}
              ></i>
            )}
          </div>
          <div className="user-item">
            <input
              type={showPwd ? "text" : "password"}
              value={values.change_password}
              name="change_password"
              onChange={onInputChange}
              placeholder="New Password"
              required
            />
            {showPwd ? (
              <i
                className="fa fa-eye text-secondary cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowPwd(!showPwd)}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash text-secondary cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowPwd(!showPwd)}
              ></i>
            )}
          </div>
          <div className="user-item">
            <input
              type={showConfirmPwd ? "text" : "password"}
              value={values.confirm_password}
              name="confirm_password"
              onChange={onInputChange}
              placeholder="Confirm Password"
              required
            />
            {showConfirmPwd ? (
              <i
                className="fa fa-eye text-secondary cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowConfirmPwd(!showConfirmPwd)}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash text-secondary cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowConfirmPwd(!showConfirmPwd)}
              ></i>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="btns d-flex justify-content-center">
            <span className="fa fa-spinner fa-spin px-3"></span>
          </div>
        ) :
          <div className="btns d-flex justify-content-center">
            <button type="submit" className="btns text-primary mx-2">
              Continue
            </button>
          </div>
        }
      </form>
    </Modal>
  );
};

export default ChangePasswordPopup;
