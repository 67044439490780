import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardList,
  updateUser,
  updateImage,
  addCard,
  deleteCard,
} from "../../../store/homeAction";
import "./myPaymentMethods.scss";
import FeaturedImageUpload from "../../spaceRegisterStep/FeaturedImageUpload";
import StripePayment from "../../../components/stripePayment/StripePayment";
import ConfirmPopup from "../../../popups/ConfirmPopup";
import ChangePasswordPopup from "../../../popups/ChangePasswordPopup";
import { Link } from "react-router-dom";

const MyPaymentMethods = () => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const [loadingCardId, setLoadingCardId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState({});
  const [featuredImage, setFeaturedImage] = useState("");
  const [cardAdding, setCardAdding] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const cardsList = useSelector((state) => state.home.cardsList);

  useEffect(() => {

    const data = {
      customer_id: userDetails && userDetails.customer_id,
    };
    dispatch(getCardList(data));
    setValues(userDetails);
    setFeaturedImage(userDetails && userDetails.profile_pic);
  }, []);

  const onUpdateUser = () => {
    let data = {
      user_id: values.id,
      first_name: values.first_name,
      last_name: values.last_name,
      dob: values.dob,
      domestic_postcode: values.domestic_postcode,
    };

    dispatch(updateUser(data));
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const checkHttpInUrl = (image) => {
    if (typeof image === "string") {
      return image;
    } else {
      return URL.createObjectURL(image);
    }
  };

  const onUploadFeaturedImage = (image) => {
    setFeaturedImage(image);

    let formData = new FormData();
    formData.append("user_id", userDetails && userDetails.id);
    formData.append("image", image);

    dispatch(updateImage(formData));
  };

  const onBuyPlan = (details, type) => {
    let data = {
      customer_id: userDetails && userDetails.customer_id,
      src_token: details.id,
    };

    const user = {
      customer_id: userDetails && userDetails.customer_id,
    };

    dispatch(addCard(data, user));
  };

  const onDeleteCard = () => {
    let data = {
      customer_id: userDetails && userDetails.customer_id,
      card_id: cardId,
    };

    const user = {
      customer_id: userDetails && userDetails.customer_id,
    };
    dispatch(deleteCard(data, user));
    setOpen(false);
  };

  return (
    <div className="payments-wrapper">
      <div className="payments">
        <h5 className="payments-heading">
          My Wallet: <small>Payment Methods:</small>
        </h5>

        {cardsList && cardsList.length > 0
          ? cardsList.map((card, key) => (
              <div key={key} className="payments-item-wrapper">
                <div className="payments-item">
                  {card.brand === "Visa" ? (
                    <img
                      className="card-image"
                      src="assets/images/visa-card.png"
                      alt=""
                    />
                  ) : (
                    <img
                      className="card-image"
                      src="assets/images/master-card.png"
                      alt=""
                    />
                  )}{" "}
                  <h6>****{card.last4}</h6>
                </div>
                <i
                  className="fas fa-trash"
                  onClick={() => {
                    setOpen(true);
                    setCardId(card.id);
                  }}
                ></i>
              </div>
            ))
          : "No Card Available"}

        {cardAdding ? (
          <div className="mt-4">
            <StripePayment onBuyPlan={onBuyPlan} type={"add"} />
          </div>
        ) : (
          <div className="addButton" onClick={() => setCardAdding(true)}>
            <i className="fas fa-plus text-primary"></i>
            <b>Add new payment method</b>
          </div>
        )}
      </div>
      <div className="user-information">
        <div className="basic-info d-flex justify-content-between">
          <h5>Basic Information:</h5>{" "}
          {!editMode && (
            <img
              src="assets/images/pencil-icon.png"
              alt=""
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
        <div className="profile-image">
          <FeaturedImageUpload
            uploadImageSrc={
              (featuredImage && checkHttpInUrl(featuredImage)) || null
            }
            type="featured"
            onUpload={onUploadFeaturedImage}
          ></FeaturedImageUpload>
        </div>

        <div className="user-item">
          {editMode ? (
            <input
              type="text"
              value={values.first_name}
              name="first_name"
              onChange={onInputChange}
            />
          ) : (
            <h6>{userDetails && userDetails.first_name}</h6>
          )}
        </div>
        <div className="user-item">
          {editMode ? (
            <input
              type="text"
              value={values.last_name}
              name="last_name"
              onChange={onInputChange}
            />
          ) : (
            <h6>{userDetails && userDetails.last_name}</h6>
          )}
        </div>
        <div className="user-item">
          {editMode ? (
            <input
              type="date"
              value={values.dob}
              name="dob"
              onChange={onInputChange}
            />
          ) : (
            <h6>{userDetails && userDetails.dob}</h6>
          )}
        </div>

        <div className="user-item">
          {editMode ? (
            <input
              type="text"
              value={values.domestic_postcode}
              name="domestic_postcode"
              onChange={onInputChange}
            />
          ) : (
            <h6>{userDetails && userDetails.domestic_postcode}</h6>
          )}
        </div>

        <div className="user-item">
          <h6>{userDetails && userDetails.email}</h6>
        </div>

        <div className="text-primary py-3 text-right change-password-btn">
          <div onClick={() => setOpenChangePassword(true)}>Change Password</div>
        </div>

        {/* <div className="user-item">
          {editMode ? (
            <input
              type="date"
              value={values.dob}
              name="dob"
              onChange={onInputChange}
            />
          ) : (
            <h6>{userDetails.dob}</h6>
          )}
        </div> */}

        {editMode && (
          <div className="update-user-btn" onClick={onUpdateUser}>
            Update Profile
          </div>
        )}
      </div>
      <ConfirmPopup
        title={"Delete Card"}
        description={"Are you sure want to delete your saved card?"}
        open={open}
        onClose={() => setOpen(false)}
        onDelete={onDeleteCard}
      />
      <ChangePasswordPopup
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
    </div>
  );
};

export default MyPaymentMethods;
