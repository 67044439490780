import React, { useState, useEffect } from "react";
import { deleteAccount, onLogout } from "../../store/homeAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./myDashboard.scss";
import MyPaymentMethods from "./myPaymentsMethods/MyPaymentMethods";
import MyBooking from "./myBookings/MyBooking";
import MyLikedLocations from "./myLikedLocations/MyLikedLocations";
import ConfirmPopup from "../../popups/ConfirmPopup";

const MyDashboard = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("os-user"));
  const [currentTab, setCurrentTab] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentTab(parseInt(match.params.id));
  }, [match]);

  const onDeleteAccount = () => {
    dispatch(deleteAccount({ user_id: userDetails.id }));
    setTimeout(() => {
      localStorage.clear();
      window.location.reload();
    }, 5000);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    // const formData = new FormData();
    // formData.append("user_id", userDetails.id);
    // formData.append("firebase_token", "fdfgdfgd");
    // formData.append("device_id", 123);

    // dispatch(onLogout(formData));
  };

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-right">
        <h1>
          My <span className="text-primary">OfficeShare</span>
        </h1>
        <div className="dashboard-buttons">
          <div className={`border-none ${currentTab === 0 && "border-active"}`}>
            <div
              className={`button ${currentTab === 0 && "dashboard-active"}`}
              onClick={() => setCurrentTab(0)}
            >
              My Dashboard
            </div>
          </div>
          <div className={`border-none ${currentTab === 1 && "border-active"}`}>
            <div
              className={`button ${currentTab === 1 && "dashboard-active"}`}
              onClick={() => setCurrentTab(1)}
            >
              Liked Locations
            </div>
          </div>
          <div className={`border-none ${currentTab === 2 && "border-active"}`}>
            <div
              className={`button ${currentTab === 2 && "dashboard-active"}`}
              onClick={() => setCurrentTab(2)}
            >
              My Bookings
            </div>
          </div>
          <div className="logout-btn" onClick={handleLogout}>
            Log out
          </div>
          <div className="delete-btn" onClick={() => setOpen(true)}>
            Deactivate Account
          </div>
        </div>
        <div className="dashboard-hr"></div>
        <div className="dashboard-tabs">
          {currentTab === 0 && <MyPaymentMethods />}
          {currentTab === 1 && <MyLikedLocations />}
          {currentTab === 2 && <MyBooking />}
        </div>
      </div>
      <ConfirmPopup
        title={"Deactivate Account"}
        description={
          "An account with this email has already been registered and deactivated. It can be reinstated or permanently deleted on request, by contacting enquiries@office-share.io"
        }
        open={open}
        onClose={() => setOpen(false)}
        onDelete={onDeleteAccount}
      ></ConfirmPopup>
    </div>
  );
};

export default MyDashboard;
