import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { cancelBooking } from "../store/homeAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const CancelReasonPopup = ({ open, onClose, bookingDetails }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  const onCancelBooking = () => {
    setIsLoading(true);
    if (!reason) {
      toast("Please select any reason!", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      setIsLoading(false);
      return false;
    }

    const data = {
      booking_id: bookingDetails.booking_id,
      user_id: userDetails && userDetails.id,
      cancel_reason: reason,
    };

    dispatch(cancelBooking(data, () => setIsLoading(false)));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "cancel-grid-popup" }}
      center
    >
      <h5 className="text-primary">Reason For Cancellation</h5>
      <div className="cancel-reason-wrapper">
        <div className="cancel-reason-item">
          <label>
            <input
              type="radio"
              className="me-2"
              name="Hello"
              onChange={(e) => setReason("Change of Plan")}
            />
            <h6>Change of Plan</h6>
          </label>
        </div>
        <div className="cancel-reason-item">
          <label>
            <input
              type="radio"
              className="me-2"
              name="Hello"
              onChange={(e) => setReason("Generic Cancel Rule")}
            />
            <h6>Generic Cancel Rule</h6>
          </label>
        </div>
        <div className="cancel-reason-item">
          <label>
            <input
              type="radio"
              className="me-2"
              name="Hello"
              onChange={(e) => setReason("Schedule Change of Business Meeting")}
            />
            <h6>Schedule Change of Business Meeting</h6>
          </label>
        </div>
        <div className="cancel-reason-item">
          <label>
            <input
              type="radio"
              className="me-2"
              name="Hello"
              onChange={(e) =>
                setReason("Change of Business Event Place or Date")
              }
            />
            <h6>Change of Business Event Place or Date</h6>
          </label>
        </div>
        <div className="cancel-reason-item">
          <label>
            <input
              type="radio"
              className="me-2"
              name="Hello"
              onChange={(e) => setReason("Duplicate Booking")}
            />
            <h6>Duplicate Booking</h6>
          </label>
        </div>
        <div className="cancel-reason-item">
          <label>
            <input
              type="radio"
              className="me-2"
              name="Hello"
              onChange={(e) => setReason("Group Cancelled")}
            />
            <h6>Group Cancelled</h6>
          </label>
        </div>
      </div>
      {isLoading ? (
        <div className="open-chat-btn">
          <span className="fa fa-spinner fa-spin px-3"></span>
        </div>
      ) : (
        <div className="open-chat-btn" onClick={onCancelBooking}>
          Submit
        </div>
      )}
    </Modal>
  );
};

export default CancelReasonPopup;
