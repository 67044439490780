import {
  SET_LOADING,
  SET_LOGIN_LOADING,
  SET_REGISTER_LOADING,
  SET_OTP_LOADING,
  SET_BOOK_SPACE_LOADING,
  SET_ADD_CARD_LOADING,
  SET_REGISTER_USER_DETAIL,
  SET_SEARCH_RESULT,
  SET_BLOGS,
  SET_SAFE_SECTION,
  SET_TAB_SECTION,
  SET_FILTERS,
  SET_SELECTED_FILTERS,
  SET_REGIONS,
  SET_PROPERTY_ATTRIBUTE_LIST,
  SET_PROPERTY_BY_USER,
  SET_PROPERTY_BY_ID,
  SET_ADD_PROPERTY_DETAILS,
  SET_SPACE_TYPE,
  SET_PROPERTY_SPACES,
  SET_BOOK_SPACE_DETAILS,
  SET_DAY_AVAILABLE_DATES,
  SET_NIGHT_AVAILABLE_DATES,
  SET_TERMS_CONDITIONS,
  SET_LIKED_PROPERTY,
  SET_PLAN_LIST,
  SET_BOOKING_LIST,
  SET_BLOG_DETAILS,
  SET_LANDLORD_BOOKING_LIST,
  SET_INITIALIZE_CHAT_DETAILS,
  SET_CURRENT_LOCATION,
  SET_CARDS_LIST,
  SET_USER_DETAILS,
  SET_SPACE_AVAILIBILITY_DATES,
  SET_BUY_PLAN_DETAILS,
  SET_ADMIN_USERS_LIST,
  SET_ADD_SPACE_DETAILS,
  SET_IS_ADMIN_USER_ADDED,
  SET_FAQ_PAGE_CONTENT,
  SET_HOME_PAGE_CONTENT,
  SET_BOOKING_DETAILS,
  SET_SHOW_CONFIRM_BOOKING_POPUP,
  SET_REDIRECT_URL_ON_ENQUIRE,
  SET_EARNING_REPORT,
  SET_FORGOT_PASSWORD_OTP_SENT,
  SET_FORGOT_PASSWORD_OTP_VERIFY,
  SET_COUNTRY_LIST,
  SET_STATE_LIST,
  SET_VERIFICATION_FILES,
  SET_EMPTY_VERIFICATION_FILES,
  SET_CHAT_NOTIFICATION,
  SET_ADD_STRIPE_ACCOUNT_LOADING,
  SET_PLAN_BUY_LOADING,
  SET_WITHDRAW_LOADING,
  SET_ADD_BANK_ACCOUNT_LOADING,
  SET_CLOSE_WITHDRAW_MODAL,
  SET_CLOSE_BANK_ACCOUNT_MODAL,
  SET_BOOKING_CONFIRM_DETAILS,
  SET_KYC_DATA,
  SET_FCM_TOKEN,
  SET_PERSON_ACCOUNT_DATA,
  SET_STRIPE_ACCOUNT_DATA,
  SET_VOUCHER_CODE,
  SET_VOUCHER_LIST_DATA,
  SET_AGREEMENT_LANDLORD_DATA,
  SET_NEW_LANDLORD_DATA,
  SET_EDIT_LANDLORD_DATA,
  SET_DELETE_VOUCHER,
  SET_ALL_USERS_DATA,
  SET_CREATE_VOUCHER_CODE,
  SET_LANDLORD_AGREEMENT_FILES,
} from "./types";

const initalState = {
  selectedFilters: {
    search: "",
    selectedRating: [],
    selectedAmenities: [],
    addPropertyDetails: null,
    isAdminUserAdded: false,
  },
  redirectUrl: null,
  verificationFiles: [],
};

const homeReducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_LOGIN_LOADING:
      return {
        ...state,
        isLoginLoading: action.payload,
      };
    case SET_REGISTER_LOADING:
      return {
        ...state,
        isRegisterLoading: action.payload,
      };
    case SET_REGISTER_USER_DETAIL:
      return {
        ...state,
        registerUserDetail: action.payload,
      };
    case SET_OTP_LOADING:
      return {
        ...state,
        otpLoading: action.payload,
      };
    case SET_BOOK_SPACE_LOADING:
      return {
        ...state,
        bookSpaceLoading: action.payload,
      };
    case SET_ADD_CARD_LOADING:
      return {
        ...state,
        addCardLoading: action.payload,
      };

    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload.property,
        searchLatLng: action.payload.request,
      };
    case SET_BLOGS:
      return {
        ...state,
        setBlogs: action.payload,
      };
    case SET_SAFE_SECTION:
      return {
        ...state,
        safeSection: action.payload,
      };
    case SET_TAB_SECTION:
      return {
        ...state,
        setTab: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case SET_PROPERTY_ATTRIBUTE_LIST:
      return {
        ...state,
        propertyAttributeList: action.payload,
      };
    case SET_PROPERTY_BY_USER:
      return {
        ...state,
        propertyListByUser: action.payload,
      };
    case SET_PROPERTY_BY_ID:
      return {
        ...state,
        propertyListById: action.payload,
      };
    case SET_ADD_PROPERTY_DETAILS:
      return {
        ...state,
        addPropertyDetails: action.payload,
      };
    case SET_SPACE_TYPE:
      return {
        ...state,
        spaceType: action.payload,
      };
    case SET_PROPERTY_SPACES:
      return {
        ...state,
        propertySpaces: action.payload,
      };
    case SET_BOOK_SPACE_DETAILS:
      return {
        ...state,
        bookSpaceDetails: action.payload,
      };
    case SET_DAY_AVAILABLE_DATES:
      return {
        ...state,
        dayAvailableDates: action.payload,
      };
    case SET_NIGHT_AVAILABLE_DATES:
      return {
        ...state,
        nightAvailableDates: action.payload,
      };
    case SET_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: action.payload,
      };
    case SET_LIKED_PROPERTY:
      return {
        ...state,
        likedProperty: action.payload,
      };
    case SET_PLAN_LIST:
      return {
        ...state,
        planLists: action.payload,
      };
    case SET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
      };
    case SET_BLOG_DETAILS:
      return {
        ...state,
        blogDetails: action.payload,
      };
    case SET_LANDLORD_BOOKING_LIST:
      return {
        ...state,
        landlordBookingList: action.payload,
      };
    case SET_INITIALIZE_CHAT_DETAILS:
      return {
        ...state,
        initalizeChat: action.payload,
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    case SET_CARDS_LIST:
      return {
        ...state,
        cardsList: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_SPACE_AVAILIBILITY_DATES:
      return {
        ...state,
        spaceAvailabilityDates: action.payload,
      };
    case SET_BUY_PLAN_DETAILS:
      return {
        ...state,
        buyPlanDetails: action.payload,
      };
    case SET_ADMIN_USERS_LIST:
      return {
        ...state,
        adminUsersList: action.payload,
      };
    case SET_ADD_SPACE_DETAILS:
      return {
        ...state,
        addSpaceDetails: action.payload,
      };
    case SET_IS_ADMIN_USER_ADDED:
      return {
        ...state,
        isAdminUserAdded: action.payload,
      };
    case SET_FAQ_PAGE_CONTENT:
      return {
        ...state,
        pageContent: action.payload,
      };
    case SET_HOME_PAGE_CONTENT:
      return {
        ...state,
        homePageContent: action.payload,
      };

    case SET_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: action.payload,
      };
    case SET_SHOW_CONFIRM_BOOKING_POPUP:
      return {
        ...state,
        showConfirmBookingPopup: action.payload,
      };
    case SET_REDIRECT_URL_ON_ENQUIRE:
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case SET_EARNING_REPORT:
      return {
        ...state,
        earningReport: action.payload,
      };
    case SET_FORGOT_PASSWORD_OTP_SENT:
      return {
        ...state,
        forgotPasswordOtpSent: action.payload,
      };
    case SET_FORGOT_PASSWORD_OTP_VERIFY:
      return {
        ...state,
        forgotPasswordOtpVerified: action.payload,
      };
    case SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case SET_STATE_LIST:
      return {
        ...state,
        stateList: action.payload,
      };
    case SET_VERIFICATION_FILES:
      return {
        ...state,
        verificationFiles: [...state.verificationFiles, action.payload],
      };
    case SET_EMPTY_VERIFICATION_FILES:
      return {
        ...state,
        verificationFiles: [],
      };
    case SET_CHAT_NOTIFICATION:
      return {
        ...state,
        chatNotfications: action.payload,
      };
    case SET_ADD_STRIPE_ACCOUNT_LOADING:
      return {
        ...state,
        addStripeAccountLoading: action.payload,
      };
    case SET_PLAN_BUY_LOADING:
      return {
        ...state,
        planBuyLoading: action.payload,
      };
    case SET_WITHDRAW_LOADING:
      return {
        ...state,
        withdrawLoading: action.payload,
      };
    case SET_CLOSE_WITHDRAW_MODAL:
      return {
        ...state,
        withdrawModalClosed: action.payload,
      };
    case SET_ADD_BANK_ACCOUNT_LOADING:
      return {
        ...state,
        addBankAccountLoading: action.payload,
      };
    case SET_CLOSE_BANK_ACCOUNT_MODAL:
      return {
        ...state,
        backAccountModalClosed: action.payload,
      };
    case SET_BOOKING_CONFIRM_DETAILS:
      return {
        ...state,
        bookingConfirmDetails: action.payload,
      };
    case SET_KYC_DATA:
      return {
        ...state,
        kycDetails: action.payload,
      };
    case SET_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action.payload,
      };
    case SET_STRIPE_ACCOUNT_DATA:
      return {
        ...state,
        getStripeAccountData: action.payload,
      };
    case SET_PERSON_ACCOUNT_DATA:
      return {
        ...state,
        getPersonAccountData: action.payload,
      };
    case SET_VOUCHER_CODE:
      return {
        ...state,
        appliedVoucherCode: action.payload,
      };
    case SET_VOUCHER_LIST_DATA:
      return {
        ...state,
        getVoucherCodeList: action.payload,
      };
    case SET_AGREEMENT_LANDLORD_DATA:
      return {
        ...state,
        getAgreementLandlordList: action.payload,
      };
    case SET_NEW_LANDLORD_DATA:
      return {
        ...state,
        newLandlordData: action.payload,
      };
    case SET_EDIT_LANDLORD_DATA:
      return {
        ...state,
        editLandlordData: action.payload,
      };
    case SET_LANDLORD_AGREEMENT_FILES:
      return {
        ...state,
        landlordAgreementFiles: action.payload,
      };
    case SET_DELETE_VOUCHER:
      return {
        ...state,
        deleteVoucher: action.payload,
      };
    case SET_ALL_USERS_DATA:
      return {
        ...state,
        getAllUsersList: action.payload,
      };
    case SET_CREATE_VOUCHER_CODE:
      return {
        ...state,
        createVoucher: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
