import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

const TermsAndConditionsPopup = ({ open, onClose, termsConditions }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      classNames={{ modal: "price-grid-popup" }}
      top
    >
      <div className="user-middle">
        <div className="middle-desc">
          <h6 className="text-primary mb-3">Terms & Conditions</h6>
          <div className="four-desc">
            <input
              autoFocus
              style={{
                width: 0,
                height: 0,
                border: "1px solid #fff",
                outline: "none",
              }}
            />
            <p
              style={{
                marginTop: "-20px",
              }}
              dangerouslySetInnerHTML={{
                __html: termsConditions && termsConditions.value,
              }}
            ></p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsPopup;
