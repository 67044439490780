import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCardList, buyPlan } from "../../store/homeAction";
import StripePayment from "../../components/stripePayment/StripePayment";
import "./planList.scss";

const PlanBuy = ({ match }) => {
  const dispatch = useDispatch();
  const [loadingCardId, setLoadingCardId] = useState(null);

  const cardsList = useSelector((state) => state.home.cardsList);
  const userDetails = JSON.parse(localStorage.getItem("os-user"));

  useEffect(() => {
    const data = {
      customer_id: userDetails.customer_id,
    };
    dispatch(getCardList(data));
  }, []);

  const onBuyPlan = (details, type) => {
    setLoadingCardId(details.id);

    let data;
    if (type === "newCard") {
      data = {
        user_id: userDetails.id,
        plan_id: match.params.id,
        new: true,
        src_token: details.id,
      };
    } else {
      data = {
        user_id: userDetails.id,
        plan_id: match.params.id,
        new: false,
        src_token: details.id,
      };
    }
    dispatch(buyPlan(data));
  };

  return (
    <div>
      <div className="container card-list-wrapper py-5">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <h4 className="text-primary pb-3"> Cards List</h4>
            <div className="card card-body">
              <div className="list-group">
                {cardsList && cardsList.length > 0
                  ? cardsList.map((card, key) => (
                      <div
                        key={key}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <span>
                          {card.brand === "Visa" ? (
                            <img
                              className="card-image"
                              src="assets/images/visa-card.png"
                              alt=""
                            />
                          ) : (
                            <img
                              className="card-image"
                              src="assets/images/master-card.png"
                              alt=""
                            />
                          )}{" "}
                          <b className="card-number">
                            **** **** **** {card.last4}{" "}
                          </b>
                        </span>
                        {loadingCardId === card.id ? (
                          <span className="button btn-small">
                            <span className="fa fa-spinner fa-spin px-3"></span>
                          </span>
                        ) : (
                          <div
                            className="button btn-small"
                            onClick={() => onBuyPlan(card)}
                          >
                            Pay
                          </div>
                        )}
                      </div>
                    ))
                  : "No Card Available"}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mx-auto">
            <StripePayment onBuyPlan={onBuyPlan} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanBuy;
