import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addProperty, getPropertyById } from "../../store/homeAction";
import "./steps.scss";
import FeaturedImageUpload from "./FeaturedImageUpload";
import LandlordBar from "../../components/landlordBar/LandlordBar";
import DragAndDropImage from "./DragAndDropImage";
import { ToastContainer, toast } from "react-toastify";

const StepTwo = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const addPropertyDetails = useSelector(
    (state) => state.home.addPropertyDetails
  );
  const propertyListById = useSelector((state) => state.home.propertyListById);

  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallaryImage, setGallaryImage] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [isAdding, setIsAdding] = useState(false);
  const [deletedImageLocation, setDeletedImageLocation] = useState([]);
  const [updatedImageLocation, setUpdatedImageLocation] = useState([]);

  useEffect(() => {
    dispatch(getPropertyById({ property_id: match.params.propertyId }));
  }, []);

  useEffect(() => {
    if (propertyListById && propertyListById.Amenities.length > 0) {
      setFeaturedImage(propertyListById.featured_image);

      const gallaryImages = propertyListById.gallery_image;
      const allGalleryImage = [];
      for (let i = 0; i < 15 - gallaryImages.length; i++) {
        allGalleryImage.push(null);
      }
      setGallaryImage([...gallaryImages, ...allGalleryImage]);
    }


  }, [propertyListById]);

  const onUploadImage = (image, type, index, fixed) => {
    if (type === "featured") {
      setFeaturedImage(image);
    } else if (type === "gallary") {
      for (let i = 0; i < gallaryImage.length; i++) {
        if (i == index) {
          if (typeof file === "string") {
            gallaryImage[i] = image;
          } else {
            gallaryImage[i] = getBase64(image, i);
          }
        }
        console.log(gallaryImage);

        setGallaryImage([...gallaryImage]);
        setUpdatedImageLocation([...updatedImageLocation, index]);
      }
    }
  };

  const getBase64 = (file, index) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      for (let i = 0; i < gallaryImage.length; i++) {
        if (i == index) {
          gallaryImage[i] = reader.result;
          // gallaryImage[i] = reader.result.split(",")[1];
        }
        setGallaryImage([...gallaryImage]);
        setUpdatedImageLocation([...updatedImageLocation, index]);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onAddProperty = () => {
    if (featuredImage === null) {
      toast("Featured image is required", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    if (gallaryImage.length === {}) {
      toast("Gallary image is required", {
        type: toast.TYPE.ERROR,
        autoClose: 10000,
      });
      return false;
    }

    setIsAdding(true);

    const selectedAttribute = addPropertyDetails.facilities_values || [];
    let finalAttributes = [];
    for (let i = 0; i < selectedAttribute.length; i++) {
      if (selectedAttribute[i].selected === true) {
        finalAttributes.push(selectedAttribute[i].id);
      }
    }

    let finalGallaryImages = [];
    for (let i = 0; i < gallaryImage.length; i++) {
      if (gallaryImage[i] !== null) {
        finalGallaryImages.push(gallaryImage[i]);
      }
    }

    let formData = new FormData();
    formData.append("property_title", addPropertyDetails.property_title);
    formData.append("address_line_one", addPropertyDetails.address_line_one);
    formData.append("address_line_two", addPropertyDetails.address_line_two || '');
    formData.append("postcode", addPropertyDetails.postcode);
    formData.append("lang", addPropertyDetails.lang);
    formData.append("long", addPropertyDetails.long);
    formData.append("city", "test");
    formData.append("state", "test");
    formData.append("country", "test");
    formData.append("disability_access", addPropertyDetails.disability_access);
    formData.append(
      "describe_your_space",
      addPropertyDetails.describe_your_space
    );
    formData.append("how_to_find_us", addPropertyDetails.how_to_find_us);
    formData.append("facilities_values", finalAttributes);
    formData.append("insurance", addPropertyDetails.insurance);
    formData.append("covid_19_secure", addPropertyDetails.covid_19_secure);
    formData.append("whats_not_included", addPropertyDetails.whats_not_included);
    formData.append("user_id", addPropertyDetails.user_id);
    formData.append("featured_image", featuredImage);

    // finalGallaryImages.forEach((file) => {
    //   if (typeof file === "string") {
    //     formData.append("old_gallary_image[]", file);
    //   } else {
    //     formData.append("gallery_image", file);
    //   }
    // });

    let temp = {};
    for (let i = 0; i < finalGallaryImages.length; i++) {
      temp[i] = finalGallaryImages[i];
    }
    formData.append("gallery_image", JSON.stringify(temp));

    if (updatedImageLocation.length > 0) {
      formData.append("image_updated_location", updatedImageLocation);
    }
    // if (deletedImageLocation.length > 0) {
    //   formData.append("deleted_image_location", deletedImageLocation);
    // }

    if (match.params.propertyId) {
      formData.append("property_id", match.params.propertyId);
      dispatch(addProperty(formData, "update"));
    } else {
      dispatch(addProperty(formData));
    }
  };
  const removeFeaturedImage = () => {
    setFeaturedImage(null);
  };

  const removeImage = (key) => {

    for (let i = 0; i < gallaryImage.length; i++) {
      if (i === key) {
        gallaryImage[i] = null;
      }
    }
    setGallaryImage([...gallaryImage]);

    setDeletedImageLocation([...deletedImageLocation, key]);
  };

  const gotoBackStep = () => {

    if (match.params.propertyId) {
      history.push(`/add-property/1/${match.params.propertyId}`);
    } else {
      history.push("/add-property/1");
    }
  };

  const checkHttpInUrl = (image) => {
    if (typeof image === "string") {
      return image;
    } else {
      return URL.createObjectURL(image);
    }
  };

  return (
    <>
      <LandlordBar />
      <div className="container mt-5">
        <div className="add-property-step-wrapper">
          <div className="step-progress">
            <p className="text-text">
              <b> Step 2 :</b> Upload Photos
            </p>
            <div className="progress-bar">
              <div className="fill" style={{ width: "60%" }}></div>
            </div>
          </div>

          <div className="step-detail">
            <h5>
              <b> Making your listing shine</b>
            </h5>
            <div className="row">
              <div className="col-md-7">
                <p className="instruction">
                  Please upload at least 3 images to continue.
                </p>
                <p className="instruction">
                  <b>Drag your JPEGs or PNGs here or click the button below</b>
                </p>
              </div>
              <div className="col-md-5">
                <p className="instruction">
                  <b>Images are a key selling point, some tips:</b>
                  <br />
                  - Do you have professional images? Great! Use them. <br />
                  - Ensure your images are well-lit with natural light where
                  possible. <br />
                  - Use images of the space both empty and busy. <br />
                </p>
              </div>
            </div>
          </div>

          <div className="button">
            <label
            //  htmlFor="file"
            >
              Upload Images{" "}
            </label>{" "}
            <img
              className="camera-icon"
              width="30px"
              src="assets/images/camera.png"
            />
          </div>

          <div className="property-image-upload">
            <div className="featured-image">
              <FeaturedImageUpload
                uploadImageSrc={
                  (featuredImage && checkHttpInUrl(featuredImage)) || null
                }
                type="featured"
                onUpload={onUploadImage}
              ></FeaturedImageUpload>
              {featuredImage !== null && (
                <div
                  className="remove-icon"
                  onClick={() => removeFeaturedImage()}
                >
                  <img src="assets/icons/remove-icon.svg"></img>
                </div>
              )}
              <p className="p-2 font-weight-bold">Featured Image</p>
            </div>
            <div className="gallary-images">
              {gallaryImage.map((image, key) => (
                <div key={key} className="image-upload">
                  {image ? (
                    <FeaturedImageUpload
                      uploadImageSrc={checkHttpInUrl(image) || null}
                      type="gallary"
                      onUpload={onUploadImage}
                      index={key}
                    ></FeaturedImageUpload>
                  ) : (
                    <FeaturedImageUpload
                      uploadImageSrc={null}
                      type="gallary"
                      onUpload={onUploadImage}
                      index={key}
                      fixed={true}
                    ></FeaturedImageUpload>
                  )}

                  {image && (
                    <div
                      className="remove-icon"
                      onClick={() => removeImage(key)}
                    >
                      <img src="assets/icons/remove-icon.svg"></img>
                    </div>
                  )}
                </div>
              ))}

              {/* <div className="image-upload">
                <FeaturedImageUpload
                  uploadImageSrc="assets/images/add-image.png"
                  type="gallary"
                  onUpload={onUploadImage}
                  index={gallaryImage.length + 1}
                ></FeaturedImageUpload>
              </div> */}
            </div>
          </div>
          {/* <DragAndDropImage
            gallaryImage={gallaryImage}
            checkHttpInUrl={checkHttpInUrl}
            onUploadImage={onUploadImage}
            FeaturedImageUpload={FeaturedImageUpload}
            removeImage={removeImage}
          /> */}

          <div className="btn-wrapper d-flex justify-content-between">
            <div className="button" onClick={gotoBackStep}>
              Back
            </div>

            {isAdding ? (
              <div className="button">
                <span className="fa fa-spinner fa-spin px-2"></span>
              </div>
            ) : (
              <div className="button" onClick={onAddProperty}>
                Next
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
