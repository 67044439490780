import React, { useEffect, useState } from "react";
import "./popup.scss";
// react modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const RatingPopup = ({
  onClose,
  open,
  rating,
  onSelectExperienceRating,
}) => {
  const selectRating = (key) => {
    let count = 0;
    for (let i = 0; i < rating.length; i++) {
      rating[i] = i <= key;

      if (rating[i]) {
        count = count + 1;
      }
    }
    onSelectExperienceRating([...rating]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{ modal: "amenities-popup-modal experience-modal" }}
    >
      <i
        className="fa fa-times removeIcon"
        onClick={() => {
          onClose();
        }}
        aria-hidden="true"
      ></i>
      <h5 className="title text-primary">Add rating</h5>
      <div className="rating-wrapper">
        {rating?.map((star, key) =>
          star ? (
            <img
              key={key} // Add the unique key prop here
              alt=""
              src="assets/icons/star.png"
              onClick={selectRating.bind(this, key)}
            />
          ) : (
            <img
              key={key} // Add the unique key prop here
              alt=""
              src="assets/icons/inactive-star.png"
              onClick={selectRating.bind(this, key)}
            />
          )
        )}
      </div>
      <div className="done-btn" onClick={() => onClose('submit')}>
        <img src="assets/images/done.png" alt=""></img> Done
      </div>
    </Modal>
  );
};

export default RatingPopup;

