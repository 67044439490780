import React from 'react';

const IconDownload = ({ color = '#BCBCBC', size = "", dimentions = undefined }) => {
  const width = size || dimentions?.width;
  const height = size || dimentions?.height;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M32 22.4v6.4c0 1.767-1.433 3.2-3.2 3.2h-25.6c-1.767 0-3.2-1.433-3.2-3.2v-6.4h3.2v6.4h25.6v-6.4h3.2zM17.6 16.937l5.269-5.269 2.263 2.263-9.131 9.131-9.131-9.131 2.263-2.263 5.269 5.269v-16.937h3.2v16.937z"></path>
    </svg>
  )
};

export default IconDownload;
